/* eslint-disable */
<template>
  <div class="page-container">
    <div class="width-container">
      <div class="page-title">Bounties</div>
      <div class="home-page">
        
        <div class="main-content">
          <div class="bounties-main-content" v-if="allBounties">
            <div class="box-header-row-bounties">
                  <div class="todo-title">Bounty board</div>
                  <div class="bounty-group" v-if="userData && userData.language === 'romana'">
                    <router-link class="show-more-profile-arhiva" to="/arhivabounties">Arhiva</router-link>
                    <div class="show-more-profile" @click="showModal = true">Regulament</div>
                  </div>
                  <div class="bounty-group" v-if="userData && userData.language === 'english'">
                    <router-link class="show-more-profile-arhiva" to="/arhivabounties">Past bounties</router-link>
                    <div class="show-more-profile" @click="showModal = true">Rules</div>
                  </div>
                  
                </div>
                <div
                  class="fara-clienti"
                  v-if="allBounties.length === 0"
                >
                <span v-if="userData && userData.language === 'romana'">In momentul acesta nu exista niciun bounty activ</span>
                <span v-if="userData && userData.language === 'english'">There are no active bounties at the moment</span>
                  
                </div>
            <div class="bounties-row" v-for="bounty in allBounties" :key="bounty.id">
              
              <div class="bounties-container-1">
                <div class="bounty-calificat-notification" v-if="bounty.calificati && bounty.calificati.includes(userData.user_id)">
                  <span v-if="userData && userData.language === 'romana'">Calificat pentru acest bounty!</span>
                <span v-if="userData && userData.language === 'english'">Qualified for this bounty!</span>
                  
                </div>
                <div class="bounty-row-calificat" v-if="bounty.calificati && bounty.calificati.includes(userData.user_id)">
                  <div class="bounty-title" v-if="allFirmeStore">{{ allFirmeStore.find(ele => ele.id === bounty.firma).nume }}</div>
                  <div class="bounty-amount">{{ bounty.bonus }} RON</div>
                </div>
                <div class="bounty-row" v-if="bounty.calificati === null || !bounty.calificati.includes(userId)">
                  <div class="bounty-title" v-if="allFirmeStore">{{ allFirmeStore.find(ele => ele.id === bounty.firma).nume }}</div>
                  <div class="bounty-amount">{{ bounty.bonus }} RON</div>
                </div>
                <div class="profile-field">
                  <div class="profile-field-left" v-if="userData && userData.language === 'romana'">Cerinta</div>
                  <div class="profile-field-left" v-if="userData && userData.language === 'english'">Requirement</div>
                  <div class="profile-field-right">{{ bounty.cerinta }}</div>
                </div>

                <div class="profile-field">
                  <div class="profile-field-left">Deadline</div>
                  <div class="profile-field-right"> {{ bounty.deadline }}</div>
                </div>
                <div class="profile-field">
                  <div class="profile-field-left" v-if="userData && userData.language === 'romana'">Calificati</div>
                  <div class="profile-field-left" v-if="userData && userData.language === 'english'">Qualified</div>
                  <div class="profile-field-right" v-if="bounty.calificati">
                    <span class="sub-calificati" v-if="bounty.calificati.length < 5 && bounty.calificati.length != null">{{ bounty.calificati.length }}</span> 
                    <span class="peste-calificati" v-if="bounty.calificati.length >= 5">{{ bounty.calificati.length }}</span>
                    / 10</div>
                  <div class="profile-field-right" v-if="bounty.calificati === null"> <span class="sub-calificati">0</span> / 10</div>
                </div>
              </div>
              <div class="bounties-container-2">
                <div class="bounties-description-title" v-if="userData && userData.language === 'romana'">Descriere</div>
                <div class="bounties-description-title" v-if="userData && userData.language === 'english'">Description</div>
                <div class="bounties-description">
                  {{ bounty.description }}
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <vue-final-modal
        v-model="showModal"
        classes="modal-container"
        content-class="modal-content-regulament">
        <div class="contract-item">
         <div class="regulament-bounties" v-if="userData && userData.language === 'romana'">
          <div class="regulament-bounties-item">
            In fiecare luna sunt disponibile un maxim de 10 bounty-uri
          </div>
          <div class="regulament-bounties-item">
            Fiecare bounty este de la o firma diferita. O firma nu poate avea mai mult de un bounty activ
          </div>
          <div class="regulament-bounties-item">
            Pentru ca un bounty sa fie validat si un castigator sa fie ales, trebuie sa existe cel putin 5 persoane calificate
            Daca pana la deadline-ul bounty-ului se califica cel putin o persoana, dar mai putin de 5, bounty-ul respectiv se mai prelungeste cu inca o luna
          </div>
          <div class="regulament-bounties-item">
            O persoana se poate califica o singura data pentru acelasi bounty
          </div>
          <div class="regulament-bounties-item">
            La sfarsitul deadline-ului, este aleasa o persoana la intamplare care va castiga premiul pentru fiecare bounty in parte
          </div>
          <div class="regulament-bounties-item">
            Persoana este aleasa in mod aleator, folosind API-ul de la random.org, pentru a asigura corectitudinea si transparenta extragerii
          </div>
          
         </div>
         <div class="regulament-bounties" v-if="userData && userData.language === 'english'">
          <div class="regulament-bounties-item">
            Every month there are maximum 10 available bounties
          </div>
          <div class="regulament-bounties-item">
            Each bounty is from a different company. A company cannot have more than one bounty active at the same time
          </div>
          <div class="regulament-bounties-item">
            For a bounty to be validated and a winner to be chosen, at least 5 people must be qualified
            If by the end of the month there are less than 5 people qualified, the bounty extends for an additional month
          </div>
          <div class="regulament-bounties-item">
            A user cannot qualify more than once for the same bounty
          </div>
          <div class="regulament-bounties-item">
            At the end of the month, a random qualified user is chosen to win the bounty prize
          </div>
          <div class="regulament-bounties-item">
            The user is chosen randomly, using the random.org API, to ensure fairness and transparency
          </div>
          
         </div>
        </div>
      </vue-final-modal>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, computed, ref } from 'vue'
import { supabase } from '../clients/supabase'
import { useStore } from 'vuex'

export default {
  name: "BountiesPage",
  components: {
    
  },
  data() {
    return {
      showModal: false
    };
  },
  setup() {
    const store = useStore()
    const calificatiDinBounty = ref([])

    onMounted(() => {
      getBounties()
      getAllFirme()
    })

    const allBounties = computed(() => {
      return store.getters["contracte/getAllBounties"];
    });

    const allFirmeStore = computed(() => {
      return store.getters["firme/getAllFirme"];
    });

    const userData = computed(() => {
      return store.getters["user/getUserData"];
    });

    const userId = computed(() => {
      return store.getters["user/getUserId"];
    });

    const getBounties = async () => {
      try {
          const { data, error } = await supabase
            .from("bounties")
            .select('*')
            .eq('status', 'activ')
            
          if (error) throw error;
          
          store.dispatch("contracte/setAllBounties", data);

        }
       catch (error) {
        console.log(error);
       }
    };

    const getAllFirme = async () => {
      try {
        const {data, error } = await supabase.from('firma').select('*')
        if (error) throw error
        
        store.dispatch("firme/setAllFirme", data);
        
        
      }
      catch(error) {
        console.log(error)
      }
    }

    return {

      allBounties,
      allFirmeStore,
      userId,
      calificatiDinBounty,
      userData
     
      }
  },
  created() {},
  methods: {},
};
</script>
