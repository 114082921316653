/* eslint-disable */
<template>
  <div class="page-container">
    <div class="width-container">
      <div class="page-title">INCASARI DE CONFIRMAT</div>
      <div class="home-page">
        <div class="main-content">
          <div class="profile-main-content" v-if="contracteStore">
            <div class="box-header-row">
              <div class="todo-title">Contracte</div>

              <div class="show-more" v-if="contractePag.length != 0">
                {{ contractePag.length }}
              </div>
            </div>
            <div class="filtre-wrapper">
              <div
                class="filtru-contracte"
                :class="{ filtruselected: filtruContractNefacturat }"
                @click="filtruContractNefacturat = !filtruContractNefacturat"
              >
                Nefacturat
              </div>
              <div
                class="filtru-contracte"
                :class="{ filtruselected: filtruContractFacturat }"
                @click="filtruContractFacturat = !filtruContractFacturat"
              >
                Facturat
              </div>
              <div
                class="filtru-contracte"
                :class="{ filtruselected: filtruContractPlatit }"
                @click="filtruContractPlatit = !filtruContractPlatit"
              >
                Platit
              </div>
              <div
                class="filtru-contracte"
                :class="{ filtruselected: filtruContractPlatitAgent }"
                @click="filtruContractPlatitAgent = !filtruContractPlatitAgent"
              >
                Platit Agent
              </div>
            </div>
            <div class="fara-clienti" v-if="contracteStore.length === 0">
              Nu sunt contracte noi de confirmat
            </div>
            <table class="orders-table" v-if="contracteStore.length != 0">
              <col class="col-order-no" />
              <col class="col-order-name" />

              <col class="col-order-id" />
              <col class="col-order-ref" />

              <col class="col-order-more" />
              <tr class="products-table-head">
                <th class="col-order-no">ID</th>
                <th>Firma</th>

                <th>Suma</th>
                <th>Comision</th>
                <th>Vissia</th>
                <th>Agent</th>
                <th>Comision A</th>
                <th>Parinte</th>
                <th>Comision P</th>
                <th>Status</th>
                <th>Status Intern</th>
                <th>Comenzi</th>
              </tr>
              <tr
                class="products-table-item"
                v-for="contract in contractePag"
                :key="contract.id"
              >
                <th>{{ contract.id }}</th>
                <th class="product-name" v-if="allFirmeStore">
                  {{
                    allFirmeStore.find((ele) => ele.id === contract.firma)
                      .nume_srl
                  }}
                </th>
                <th class="product-name">{{ contract.suma }} RON</th>

                <th class="suma-tabel-contracte">
                  {{ contract.comision }}
                </th>
                <th class="suma-tabel-fee">
                  <span v-if="contract.agent_status != 'sub'">{{ (contract.comision * 0.505).toFixed(2) }}</span>
                  <span v-if="contract.agent_status === 'sub'">{{ (contract.comision * 0.03).toFixed(2) }}</span>
                  
                </th>
                <th class="incasari-iban">
                  <div class="nume-incasari-iban" v-if="allAgenti">
                    {{
                      allAgenti.find((ele) => ele.user_id === contract.agent)
                        .nume
                    }}
                    {{
                      allAgenti.find((ele) => ele.user_id === contract.agent)
                        .prenume
                    }}
                  </div>
                  <div class="iban-incasari-iban" v-if="allAgenti">
                    {{
                      allAgenti.find((ele) => ele.user_id === contract.agent)
                        .iban
                    }}
                  </div>
                </th>
                <th class="suma-tabel-contracte">
                  <span v-if="contract.agent_status != 'sub'">{{ (contract.comision * 0.445).toFixed(2) }}</span>
                  <span v-if="contract.agent_status === 'sub'">{{ (contract.comision * 0.87).toFixed(2) }}</span>
                  
                </th>
                <th class="incasari-iban2">
                  <div class="nume-incasari-iban" v-if="allAgenti">
                    {{
                      allAgenti.find((ele) => ele.user_id === ele.invited_by)
                        .nume
                    }}
                    {{
                      allAgenti.find((ele) => ele.user_id === ele.invited_by)
                        .prenume
                    }}
                  </div>
                  <div class="iban-incasari-iban" v-if="allAgenti">
                    {{
                      allAgenti.find((ele) => ele.user_id === ele.invited_by)
                        .iban
                    }}
                  </div>
                </th>
                <th class="suma-tabel-contracte">
                  <span v-if="contract.agent_status != 'sub'">{{ (contract.comision * 0.05).toFixed(2) }}</span>
                  <span v-if="contract.agent_status === 'sub'">{{ (contract.comision * 0.1).toFixed(2) }}</span>
                  
                </th>
                <th class="product-name">{{ contract.status }} <span v-if="contract.durata > 1"> {{ contract.rate_platite }} / {{ contract.durata }}</span></th>
                <th>{{ contract.status_intern }}</th>
                <th>
                  <div class="comenzi-group">
                    <div
                      class="facturat"
                      @click="
                        trimisFactura(contract.id, contract.status_intern)
                      "
                      v-if="contract.status_intern === 'nefacturat'"
                    >
                      <img
                        src="../assets/img/facturat.png"
                        class="invite-icon"
                      />
                    </div>
                    <div
                      class="facturatplatit"
                      @click="
                        incasatFactura(contract.id, contract.status_intern)
                      "
                      v-if="contract.status_intern === 'facturat'"
                    >
                      <img src="../assets/img/platit.png" class="invite-icon" />
                    </div>
                    <div
                      class="platitagent"
                      @click="platitAgent(contract.id, contract.status_intern, contract.status)"
                      v-if="(contract.afiseaza) && contract.status_intern != 'nefacturat'"
                    >
                      <img
                        src="../assets/img/platitagent.png"
                        class="invite-icon"
                      />
                    </div>
                  </div>
                </th>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, ref, computed, onUnmounted, watch } from "vue";
import { supabase } from "../clients/supabase";
import { useStore } from "vuex";

export default {
  name: "AdminIncasari",
  components: {},
  data() {
    return {
      showModal: false,
      error: false,
    };
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      getAllFirme();
      getAllRecruti();
      getInvitedByName();
      getInvitesOwned();
      getUploadedContracts();
      getContracte();
      getAllClients();
      getContractePag();
      getAllAgenti();
    });

    onUnmounted(() => {
      resetThisFirma();
    });

    // formular

    // contracte

    const inviteMail = ref();
    const listaFirme = ref("");
    const totalRecruti = ref(0);

    const recomandariKey = ref(0);

    const arrayFiltrePag = ref([
      "nefacturat",
      "facturat",
      "platit",
      "platit_agent",
      "asteapta_urm_rata"
    ]);

    const filtruContractNefacturat = ref(true);
    const filtruContractFacturat = ref(true);
    const filtruContractPlatit = ref(true);
    const filtruContractPlatitAgent = ref(true);

    const userId = computed(() => {
      return store.getters["user/getUserId"];
    });

    const contracteStore = computed(() => {
      return store.getters["contracte/getAllContracte"];
    });

    const allClientsStore = computed(() => {
      return store.getters["clienti/getAllClients"];
    });

    const allFirmeStore = computed(() => {
      return store.getters["firme/getAllFirme"];
    });

    const invitedByName = computed(() => {
      return store.getters["user/getInvitedByName"];
    });

    const recommendedClientsStore = computed(() => {
      return store.getters["clienti/getRecommendedClients"];
    });

    const userData = computed(() => {
      return store.getters["user/getUserData"];
    });

    const allRecruti = computed(() => {
      return store.getters["user/getAllRecruti"];
    });

    const invitesOwned = computed(() => {
      return store.getters["user/getInvitesOwned"];
    });

    const uploadedContracts = computed(() => {
      return store.getters["user/getUploadedContracts"];
    });

    const contractePag = computed(() => {
      return store.getters["contracte/getContractePag"];
    });

    const allAgenti = computed(() => {
      return store.getters["user/getAllAgenti"];
    });

    watch(filtruContractNefacturat, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("nefacturat");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "nefacturat"
        );

        getContractePag();
      }
    });

    watch(filtruContractFacturat, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("facturat");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "facturat"
        );

        getContractePag();
      }
    });

    watch(filtruContractPlatit, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("platit");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "platit"
        );

        getContractePag();
      }
    });

    watch(filtruContractPlatitAgent, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("platit_agent");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "platit_agent"
        );

        getContractePag();
      }
    });

    function resetThisFirma() {
      store.dispatch("firme/setThisFirma", null);
    }

    const getContractePag = async () => {
      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .in("status", ["finalizat", "derulare"])
          .in("status_intern", arrayFiltrePag.value)
          .order("created_at", { ascending: false });

        store.dispatch("contracte/setContractePag", data);
        if (error) throw error;
      } catch (error) {
        console.log(error);
      }
    };

    const getAllAgenti = async () => {
      try {
        const { data, error } = await supabase.from("roles").select();

        store.dispatch("user/setAllAgenti", data);
        if (error) throw error;
      } catch (error) {
        console.log(error);
      }
    };

    const trimisFactura = async (id, status) => {
      if (status === "facturat") {
        return;
      }
      try {
        const { error } = await supabase
          .from("contract")
          .update({
            status_intern: "facturat",
          })
          .eq("id", id);

        getContractePag();
        if (error) throw error;
      } catch (error) {
        console.log(error);
      }
    };

    const incasatFactura = async (id, status) => {
      if (status === "platit") {
        return;
      }
      try {
        await inregistreazaTranzactie("platit", id);

        const { error } = await supabase
          .from("contract")
          .update({
            status_intern: "platit",
          })
          .eq("id", id);

        getContractePag();
        if (error) throw error;
      } catch (error) {
        console.log(error);
      }
    };

    const platitAgent = async (id) => {

      try {
        await inregistreazaTranzactie('platit_agent', id);

        getContractePag();
        
      } catch (error) {
        console.log(error);
      }
    };

    const inregistreazaTranzactie = async (type, id) => {
        console.log(type)
        console.log(id)

      const { data: contractInfo, error: contractInfoError } = await supabase
        .from("contract")
        .select()
        .eq("id", id);

        console.log(contractInfo)

      if (contractInfoError) {
        console.log(contractInfoError);
       
      }

      const firmaNume = allFirmeStore.value.find(
        (ele) => ele.id === contractInfo[0].firma
      ).nume_srl;
      console.log(firmaNume)

      try {

        if (type === "platit") {
          const { error } = await supabase.from("tranzactii").insert({
            type: "platit",
            firma_id: contractInfo[0].firma,
            firma_nume: firmaNume,
            contract_id: id,
            suma: Number(contractInfo[0].suma),
            rata: Number(1)
          });
          
          if (error) throw error;
          return
        }
          if (type === "platit_agent") {
            const parinteId = allAgenti.value.find(
        (ele) => ele.user_id === contractInfo[0].agent
      ).invited_by

            let totalRate = 1
            let rataDePlatit = 1
            if(contractInfo[0].durata > 1) {
                totalRate = Number(contractInfo[0].durata)
                rataDePlatit = Number(contractInfo[0].rate_platite)
            }

          const { error: errorPlatitAgent } = await supabase.from("tranzactii").insert({
            type: "platit_agent",
            firma_id: contractInfo[0].firma,
            firma_nume: firmaNume,
            contract_id: id,
            suma: Number(contractInfo[0].suma),
            total_rate: totalRate,
            rata: rataDePlatit,
            agent_id: contractInfo[0].agent,
            agent: allAgenti.value.find(
        (ele) => ele.user_id === contractInfo[0].agent
      ).nume + '' + allAgenti.value.find(
        (ele) => ele.user_id === contractInfo[0].agent
      ).prenume,
      iban_agent: allAgenti.value.find(
        (ele) => ele.user_id === contractInfo[0].agent
      ).iban,
            comision_agent: Number(contractInfo[0].comision * 0.87).toFixed(2),
            parinte_id: allAgenti.value.find(
        (ele) => ele.user_id === contractInfo[0].agent
      ).invited_by,
            parinte: allAgenti.value.find(
        (ele) => ele.user_id === parinteId
      ).nume + ' ' + allAgenti.value.find(
        (ele) => ele.user_id === parinteId
      ).prenume,
      iban_parinte: allAgenti.value.find(
        (ele) => ele.user_id === parinteId
      ).iban,
            comision_parinte: Number(contractInfo[0].comision * 0.1).toFixed(2),
            fee: Number(contractInfo[0].comision * 0.03).toFixed(2)
          });

          
          
          if (errorPlatitAgent) {
            console.log(errorPlatitAgent)
            return
          }
        }
    
        // TO DO type platit_agent
        let statusulNou = 'platit_agent'
        
            if (contractInfo[0].status === 'derulare' && type === 'platit_agent') {
                statusulNou = 'asteapta_urm_rata'
                const { error: updateContractError } = await supabase
                .from("contract")
                .update({ afiseaza: 'false', status_intern: statusulNou })
                .eq("id", id);
      
            if(updateContractError) {
                console.log(updateContractError)
            }
            return
            }

            console.log(statusulNou)
            const { error: updateContractError } = await supabase
            .from("contract")
            .update({ afiseaza: 'false', status_intern: statusulNou })
            .eq("id", id);
            
            if(updateContractError) {
                console.log(updateContractError)
            }

            getContractePag();

      } catch (error) {
        console.log(error);
      }
    }

    const getContracte = async () => {
      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .order("created_at", { ascending: false });

        if (error) throw error;

        store.dispatch("contracte/setAllContracte", data);
      } catch (error) {
        console.log(error);
      }
    };

    const getAllClients = async () => {
      try {
        {
          const { data, error } = await supabase
            .from("client")
            .select()

            .order("created_at", { ascending: false });
          if (error) throw error;

          store.dispatch("clienti/setAllClients", data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const confirmaContract = async (userIdContract) => {
      const { data, error } = await supabase
        .from("roles")
        .update({ contract_status: "confirmat" })
        .eq("user_id", userIdContract);
      if (error) throw error;
      else {
        getUploadedContracts();
      }
      console.log(data);
    };

    const getAllRecruti = async () => {
      const arrayInvitati = ref([]);
      let roluri = {};

      try {
        const { data, error } = await supabase
          .from("roles")
          .select()
          .eq("invited_by", userId.value);
        if (error) throw error;

        arrayInvitati.value = data.map(({ user_id }) => user_id);
        totalRecruti.value = arrayInvitati.value.length;
        roluri = data;
      } catch (error) {
        console.log(error);
      }

      let iduriInvitati = arrayInvitati.value.map((id) => id);

      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .in("agent", iduriInvitati);
        if (error) throw error;

        let incasariPerAgent = {};

        data.forEach((item) => {
          if (
            !Object.prototype.hasOwnProperty.call(incasariPerAgent, item.agent)
          ) {
            incasariPerAgent[item.agent] = 0;
          }

          incasariPerAgent[item.agent] += parseInt(item.total_incasat);
        });
        let final = roluri.map((item) => {
          item.total_incasat = incasariPerAgent[item.user_id] ?? 0;
          item.venit_incasat =
            item.total_incasat > 0 ? item.total_incasat / 10 : 0;

          return item;
        });
        store.dispatch("user/setAllRecruti", final);
      } catch (error) {
        console.log(error);
      }
    };

    const sendInvite = async () => {
      console.log(inviteMail.value);
      try {
        const { data, error } = await supabase.functions.invoke("send-invite", {
          body: { email: inviteMail.value },
        });
        console.log(data);
        if (error) {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getInvitedByName = async () => {
      try {
        const { data, error } = await supabase
          .from("roles")
          .select()
          .eq("user_id", userData.value.invited_by);
        if (error) throw error;

        store.dispatch(
          "user/setInvitedByName",
          data[0].nume + " " + data[0].prenume
        );
      } catch (error) {
        console.log(error);
      }
    };

    const getUploadedContracts = async () => {
      try {
        const { data, error } = await supabase
          .from("roles")
          .select()
          .eq("contract_status", "uploaded");
        if (error) throw error;

        store.dispatch("user/setUploadedContracts", data);
      } catch (error) {
        console.log(error);
      }
    };

    const getInvitesOwned = async () => {
      try {
        const { data, error } = await supabase
          .from("invites")
          .select()
          .eq("owner_id", userId.value);
        if (error) throw error;
        store.dispatch("user/setInvitesOwned", data);
      } catch (error) {
        console.log(error);
      }
    };

    // Send contract

    // Get firme

    const getAllFirme = async () => {
      try {
        const { data, error } = await supabase.from("firma").select("*");
        if (error) throw error;
        listaFirme.value = data;
        store.dispatch("firme/setAllFirme", data);
      } catch (error) {
        console.log(error);
      }
    };

    return {
      filtruContractNefacturat,
      filtruContractFacturat,
      filtruContractPlatit,
      filtruContractPlatitAgent,
      getAllFirme,
      listaFirme,
      invitedByName,
      uploadedContracts,
      userId,
      contracteStore,
      allClientsStore,
      allFirmeStore,
      recomandariKey,
      recommendedClientsStore,
      allRecruti,
      invitesOwned,
      sendInvite,
      inviteMail,
      confirmaContract,
      contractePag,
      trimisFactura,
      platitAgent,
      incasatFactura,
      getAllAgenti,
      allAgenti,
    };
  },
  created() {},
  methods: {},
};
</script>
