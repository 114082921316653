/* eslint-disable */
<template>
  <div>
<AgentDashboard v-if="userRole === 'agent'" />
<FirmaDashboard v-if="isFirma" />
<AdminDashboard v-if="userRole === 'admin'" />
</div>
</template>

<script>

import AgentDashboard from './AgentDashboard.vue'
import FirmaDashboard from './FirmaDashboard.vue'
import AdminDashboard from './AdminDashboard.vue'
import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'


export default {
  name: "HomePage",
  components: {
    AgentDashboard,
    FirmaDashboard,
    AdminDashboard
  },
  data() {
    return {
      showModal: false,
      error: false,
      
      
    };
  },
  setup() {
    const store = useStore()

    const isAuth = computed(() => {
      return store.getters['user/isAuth']
    })

    const isAdmin = computed(() => {
      return store.getters['user/isAdmin']
    })

    const userRole = computed(() => {
      return store.getters['user/user_role']
    })

    const isFirma = computed(() => {
      return store.getters['user/isFirma']
    })

 
    
    onMounted(() => {
     
    
    });


 

    return {
      isAuth,
      isAdmin,
      isFirma,
      userRole
    }
  }
}
</script>
