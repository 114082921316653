<template>
  <div class="signin-page2">
    <a href="https://invite.vissia.ro/utilizator" target="_blank">
    <div class="sticky-action-btn">
      <div class="sticky-btn-text">
        Aplica acum
      </div>
      
    </div>
  </a>

    <div class="a-header">
      
      
      <form ref="form" @submit.prevent="onSubmit" class="login-form2">
        <div class="input-field2">
          <label class="login-label2">Email</label>
          <input type="email" v-model="email" class="login-input2" />
        </div>
        <div class="input-field3">
          <label class="login-label2">Parola</label>
          <input type="password" v-model="password" class="login-input2" />
        </div>
        <div class="error-login-row">
          <div class="error-text">
            <span v-if="emailPassError"
              >Trebuie sa introduceti email si parola!</span
            >
            <span v-if="emailPassWrong">Email sau parola gresita!</span>
          </div>
        </div>
        <button type="submit" class="signin-button2" @click.prevent="login()">
          Login
        </button>

        <div class="row-forgot2">
          <div class="forgot" @click="seeCurrentUser()">Am uitat parola</div>
          
        </div>
      </form>
    <div class="header-top">
      <img src="../assets/img/vissia-logo2.png" class="header-logo">
      
     
       

      <div class="header-text2">
        Prima platforma de affiliate marketing offline din Romania cu circuit inchis

      </div>
     
    </div>
    <video autoplay muted loop id="myVideo2" class="video-login">
        <source src="../assets/img/vid2.mp4" type="video/mp4" />
      </video>
  </div>
  <div class="a-main-content">
    <div class="a-section-separator-t">
      <div class="boxes-container">
        <div class="box">
          <div class="box-header">
            <div class="box-header-left">Vissia</div>
            <div class="box-header-right"></div>
          </div>
         
              <div class="box-title">
                Firme <span class="red-accent">.</span>
              </div>
        
          <div class="box-text">
            Primesti cele mai bune lead-uri de la cei peste 10000 de utilizatori din platforma noastra. Doar recomandari din gura in gura, de cea mai buna calitate, cu cea mai mare rata de conversie.
          </div>
          <!-- <a href="#services-tag" class="lm"><div class="learn-more">
          Learn More &rarr;
        </div></a> -->
        </div>
        <div class="box">
          <div class="box-header">
            <div class="box-header-left">Vissia</div>
            <div class="box-header-right"></div>
          </div>
         
              <div class="box-title">
                Utilizatori <span class="red-accent">.</span>
              </div>
      
          <div class="box-text">
            Avem zeci de firme care iti platesc comision din lead-urile trimise ce se transforma in clienti. Monetizeaza recomandarile pe care le faci zi de zi prietenilor, cunostintelor sau colegilor tai.
          </div>
          <!-- <a href="#services-tag" class="lm"><div class="learn-more">
          Learn More &rarr;
        </div></a> -->
        </div>
        <div class="box">
          <div class="box-header">
            <div class="box-header-left">Vissia</div>
            <div class="box-header-right"></div>
          </div>
         
              <div class="box-title">
                Circuit inchis <span class="red-accent">.</span>
              </div>
      
          <div class="box-text">
             Accesul pe platforma se face doar pe baza de invitatie. Atat calitatea serviciilor oferite de firme, cat si calitatea lead-urilor trimise catre acestea sunt foarte importante pentru noi.
          </div>
          <!-- <a href="#services-tag" class="lm"><div class="learn-more">
          Learn More &rarr;
        </div></a> -->
        </div>
      </div>
    </div>
    


      


      <div class="section-2">
      <div class="title-row">
      <div class="section-title">PENTRU UTILIZATORI</div>
      <div class="line-separator"></div>
    </div>
        <div class="row-s1">
          <div class="s2-left">
            <img src="../assets/img/users-hp.jpg" class="s2-img">
          </div>
          <div class="s2-right">
            <div class="section-text-serv">
              Cu totii recomandam diverse servicii si produse prietenilor, cunoscutilor sau altor persoane cu care intram in contact in diferite situatii sau contexte. Cum ar fi sa castigi si tu din toate aceste recomandari? Pe platforma Vissia primesti comisionul direct in cont de fiecare data cand o astfel de recomandare se transforma in client. 

              <br /><br />Ca utilizator Vissia, ai la dispozitie un arsenal de servicii si produse diverse de la peste 100 firme din multiple domenii. Aceste firme iti platesc un comision din clientii sau vanzarile generate de tine. 

              
            </div>
            <a href="https://invite.vissia.ro/utilizator" target="_blank">
            <div class="apply-btn">Aplica pentru membership</div>
            </a>
          </div>
        </div>

        
      </div>
      
      <div class="services-items-row">
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Oferta generoasa de firme
          </div>
        </div>
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Comisioane atractive
          </div>
        </div>
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Servicii si produse diverse
          </div>
        </div>
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Plata automata
          </div>
        </div>
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Bonusuri consistente
          </div>
        </div>
      </div>



      <div class="section-1-s">

<div class="row-s1">
  <div class="s1-left-s">
    <img src="../assets/img/folder2.png" class="s1-img-icon">
  </div>
  <div class="s1-right-s">
    <div class="section-text-serv">
      Accesul pe platforma, atat pentru firme, cat si pentru utilizatori, se face doar pe baza de invitatie si avem un numar limitat de 133 locuri pentru firme si 10000 de utilizatori.
      <br><br> Aceste numere sunt perfecte pentru un raport firme-utilizatori sanatos, ce asigura un flux bun de recomandari pentru firme si acopera o gama cat mai variata de servicii si produse pentru utilizatori.
    </div>

  </div>
</div>


</div>

<div class="section2-small">
  <a href="https://invite.vissia.ro/" target="_blank">
  <div class="afla-detalii-btn">
    Mai multe detalii
  </div>
  </a>
</div>

<div class="services-items-row">

<div class="services-item-img">
  <a href="https://monarcstudio.com" target="_blank">
  <img src="https://kmgppqgrgckdzebbtvqy.supabase.co/storage/v1/object/public/images/logo-monarc.png" class="hp-logo-img">
</a>
</div>

<div class="services-item-img">
  <a href="https://fortin.agency" target="_blank">
  <img src="https://kmgppqgrgckdzebbtvqy.supabase.co/storage/v1/object/public/images/fortin_logo-negru.png" class="hp-logo-img">
  </a>
</div>

<div class="services-item-img">
  <a href="https://ro-animation.com" target="_blank">
  <img src="https://kmgppqgrgckdzebbtvqy.supabase.co/storage/v1/object/public/images/roa-logo2.png" class="hp-logo-img">
  </a>
</div>
<div class="services-item-img">
  <a href="https://graphis.ro" target="_blank">
  <img src="https://kmgppqgrgckdzebbtvqy.supabase.co/storage/v1/object/public/images/graphis-logo-bd.png" class="hp-logo-img">
  </a>
</div>
<div class="services-item-img">
  <a href="https://zavate.eu" target="_blank">
  <img src="https://kmgppqgrgckdzebbtvqy.supabase.co/storage/v1/object/public/images/logo-zavate.png" class="hp-logo-img">
  </a>
</div>

<div class="services-item-img">
  <a href="https://w4energy.com" target="_blank">
  <img src="https://kmgppqgrgckdzebbtvqy.supabase.co/storage/v1/object/public/images/w4-logo2.png" class="hp-logo-img">
  </a>
</div>

<div class="services-item-img">
  <a href="https://duamagency.ro" target="_blank">
 <img src="https://kmgppqgrgckdzebbtvqy.supabase.co/storage/v1/object/public/images/duam-logo.png" class="hp-logo-img">
  </a>
</div>
<div class="services-item-img">
  <a href="https://gbcgroup.ro" target="_blank">
  <img src="https://kmgppqgrgckdzebbtvqy.supabase.co/storage/v1/object/public/images/gbc.png" class="hp-logo-img">
  </a>
</div>

</div>


      <div class="section-1">
      
      <div class="title-row">
      <div class="section-title">PENTRU FIRME</div>
      <div class="line-separator"></div>
    </div>
        <div class="row-s1">
          <div class="s1-left">
            <img src="../assets/img/firme-hp.jpg" class="s1-img">
          </div>
          <div class="s1-right">
            <div class="section-text-serv">
              Firmele beneficiaza de o 'armata' de 10000 de utilizatori si de cercul lor de cunostinte pentru a primi recomandari de cea mai buna calitate, foarte usor de transformat in clienti sau vanzari pentru produsele tale.

              <br /><br />Mai mult, firmele nu platesc comision decat <b>dupa ce incaseaza</b> banii de la client. Pe Vissia nu se platesc lead-urile primite. La noi se platesc doar recomandarile care se concretizeaza in clienti. 
              
            </div>
            <a href="https://invite.vissia.ro/firma" target="_blank">
            <div class="apply-btn">
              Aplica pentru listare
            </div>
          </a>
            
          </div>
        </div>

        
      </div>
      
      <div class="services-items-row">
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Usor de folosit
          </div>
        </div>
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Recomandari premium
          </div>
        </div>
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Rata de conversie mare
          </div>
        </div>
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Peste 3000 utilizatori
          </div>
        </div>
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Notificari real-time
          </div>
        </div>
      </div>





   
  </div>



<div class="footer2">
      <div class="footer-container">
        <div class="footer-top">
          <div class="footer-row">
          <div class="footer-left">
            <img src="../assets/img/iconimg.png" class='footer-icon-img'>
          </div>
          <div class="footer-right">
            <div class="footer-right-col">
              <div class="footer-text">
               Telefon: +40 756 710 477
              </div>
              <div class="footer-text">
               Email: office@vissia.ro
              </div>
            </div>
          </div>
        </div>
        </div>
        <div class="footer-bottom">© Copyright 2024 Vissia Software S.R.L.</div>
        
      </div>
    </div>





 
  </div>
</template>

<script>
import { supabase } from "../../src/clients/supabase";
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";




export default {
  name: "LoginPage",
  components: {

  },
  data() {
    return {
      error: false,
    };
  },
  setup() {
    const store = useStore();


    const isAuth = computed(() => {
      return store.getters["user/isAuth"];
    });

    const isAdmin = computed(() => {
      return store.getters["user/isAdmin"];
    });

    onMounted(() => {

      
    })

    const userRole = ref("");
    const userId = ref("");
    const email = ref("");
    const password = ref("");
    const session_token = ref("");
    const firmaId = ref(0);
    const emailPassError = ref(false);
    const emailPassWrong = ref(false);
    const contactName = ref('')
    const contactEmail = ref('')
    const contactTelephone = ref('')
    const contactCompany = ref('')
    const contactMessage = ref('')

    const login = async () => {
      if (email.value === "" || password.value === "") {
        emailPassError.value = true;
        setTimeout(() => {
          emailPassError.value = false;
        }, 3000);
        return;
      }
      const { data, error } = await supabase.auth.signInWithPassword({
        email: email.value,
        password: password.value,
      });
      if (error) {
        console.log(error);
        emailPassWrong.value = true;

        setTimeout(() => {
          emailPassWrong.value = false;
        }, 3000);
      } else {
        console.log(data);
        userId.value = data.user.id;
        session_token.value = data.session.access_token;
        store.dispatch("user/signInToken", data.session.access_token);
        console.log(data.user.id);
        store.dispatch("user/signIn", data);
      }
    };

    function onSubmit() {

      console.log('send')
    }

    const checkRole = async () => {
      const { data, error } = await supabase
        .from("roles")
        .select()
        .eq("user_id", userId.value);

      if (error) {
        console.log(error);
      } else {
        console.log("fromcheckrolelogin");
        console.log(data);
        store.dispatch("user/setInvitedBy", data[0].invited_by);
        store.dispatch("user/setUserData", data[0]);
        if (data.length != 0) {
          if (data[0].firma_id != null) {
            store.dispatch("user/setFirmaId", data[0].firma_id);
            firmaId.value = data[0].firma_id;
            getFirmaName();
          }
          store.dispatch("user/setRole", data[0].role);
          store.dispatch("user/setUserData", data[0]);
        } else {
          if (data[0].role === "admin") {
            store.dispatch("user/setRole", "admin");
            return;
          } else if (data[0].role === "agent" || data[0].role === null) {
            store.dispatch("user/setRole", "agent");
            store.dispatch("user/setInvitedBy", data[0].invited_by);
            console.log("de la user Data");
            console.log(data[0]);
          }
        }
      }
    };

    const getFirmaName = async () => {
      const { data, error } = await supabase
        .from("firma")
        .select()
        .eq("id", firmaId.value);

      if (error) {
        console.log(error);
      } else {
        console.log(data);

        store.dispatch("user/setFirmaName", data[0].nume);
      }
    };

    watch(isAuth, (newValue) => {
      if (newValue === true) {
        checkRole();
      }
    });

    return {
      userRole,
      login,
      isAuth,
      isAdmin,
      userId,
      email,
      password,
      store,
      emailPassError,
      emailPassWrong,
      contactName,
      contactCompany,
      contactEmail,
      contactTelephone,
      contactMessage,
      onSubmit
    };
  },
  methods: {},
};
</script>

<style>
@import "../css/mainpage.css";

</style>
