/* eslint-disable */
<template>
  <div class="page-container">
    <div class="width-container">
      <div class="page-title" v-if="userData && userData.language === 'romana'">Profil</div>
      <div class="page-title" v-if="userData && userData.language === 'english'">Profile</div>
      <div class="home-page">
        <div class="main-content">
          <div class="profile-main-content">
            <div class="profile-container-1">
              <div class="box-header-row">
                  <div class="todo-title" v-if="userData && userData.language === 'romana'">Date generale</div>
                  <div class="todo-title" v-if="userData && userData.language === 'english'">General info</div>

                  <div class="show-more-profile" @click="activeazaModifica()" v-if="!modificaDate">
                    
                    <span v-if="userData && userData.language === 'romana'">Modifica</span>
                    <span v-if="userData && userData.language === 'english'">Edit</span>
                  </div>
                  
                </div>
                <div class="profile-row-wrapper">
              <div class="profile-field-new" v-if="!modificaDate">
                <div class="profile-field-top-new-sub"><span v-if="userData && userData.language === 'romana'">Cont premium</span><span v-if="userData && userData.language === 'english'">Premium account</span> <span v-if="!stripeSubStore" class="anuleaza-sub"> <span v-if="userData && userData.language === 'romana'">Plata recurenta dezactivata</span><span v-if="userData && userData.language === 'english'">Recurring payment disabled</span> </span> <span v-if="stripeSubStore" class="anuleaza-sub" @click="anuleazaSub()"> <span v-if="userData && userData.language === 'romana'">Anuleaza</span><span v-if="userData && userData.language === 'english'">Cancel</span></span></div>
               
                <div class="profile-field-bottom-new" v-if="userData && userData.subscription_status === 'sub'">
                  <div class="substatustext" v-if="userData && userData.language === 'romana'">
                  Activ - expira {{ dataExpirare() }}
                </div>
                <div class="substatustext" v-if="userData && userData.language === 'english'">
                  Active - expires on {{ dataExpirare() }}
                </div>
                </div>
                <div class="profile-field-bottom-new" v-if="userData && userData.subscription_status === 'not_sub'">
                  <div v-if="userData && userData.language === 'romana'">
                  Inactiv
                </div>
                <div v-if="userData && userData.language === 'english'">
                  Inactive
                </div>
                </div>
                <div class="profile-field-bottom-new" v-if="userData && userData.subscription_status === 'sub_expired'">
                  
                  <div class="substatustext" v-if="userData && userData.language === 'romana'">
                  Expirat - {{ dataExpirare() }}
                </div>
                <div class="substatustext" v-if="userData && userData.language === 'english'">
                  Expired - {{ dataExpirare() }}
                </div>
                </div>
              </div>
              <div class="profile-field-new" v-if="!modificaDate">
                <div class="profile-field-top-new" v-if="userData && userData.language === 'romana'">Nume</div>
                <div class="profile-field-top-new" v-if="userData && userData.language === 'english'">Last name</div>
                <div class="profile-field-bottom-new" v-if="userData">{{ userData.nume }}</div>
              </div>
              <div class="profile-field-new" v-if="!modificaDate">
                <div class="profile-field-top-new" v-if="userData && userData.language === 'romana'">Prenume</div>
                <div class="profile-field-top-new" v-if="userData && userData.language === 'english'">First name</div>
                <div class="profile-field-bottom-new" v-if="userData">{{ userData.prenume }}</div>
              </div>
              <div class="profile-field-new" v-if="!modificaDate">
                <div class="profile-field-top-new" v-if="userData && userData.language === 'romana'">Telefon</div>
                <div class="profile-field-top-new" v-if="userData && userData.language === 'english'">Telephone</div>
                <div class="profile-field-bottom-new" v-if="userData">{{ userData.telefon }}</div>
              </div>
              <div class="profile-field-new" v-if="!modificaDate">
                <div class="profile-field-top-new">Email</div>
                <div class="profile-field-bottom-new" v-if="userData">{{ userData.email }}</div>
              </div>
              
              <div class="profile-field-new" v-if="!modificaDate">
                <div class="profile-field-top-new" v-if="userData && userData.language === 'romana'">Firma / PFA</div>
                <div class="profile-field-top-new" v-if="userData && userData.language === 'english'">Company name</div>
                <div class="profile-field-bottom-new" v-if="userData">{{ userData.firma_pfa }}</div>
              </div>
              <div class="profile-field-new" v-if="!modificaDate">
                <div class="profile-field-top-new">IBAN</div>
                <div class="profile-field-bottom-new" v-if="userData">{{ userData.iban }}</div>
              </div>
              <div class="profile-field-new" v-if="!modificaDate">
                <div class="profile-field-top-new" v-if="userData && userData.language === 'romana'">Banca</div>
                <div class="profile-field-top-new" v-if="userData && userData.language === 'english'">Bank name</div>
                <div class="profile-field-bottom-new">{{ userData.banca }}</div>
              </div>
              
              <div class="profile-field-new" v-if="!modificaDate && userData.contract_status === 'not_uploaded'">
                <div class="profile-field-top-new">Contract</div>
                
                <div class="profile-field-right-input">
                  <div class="profile-con-btn">
                  <div class="download-pdf-icon-profile">
                    <img src="../assets/img/pdf-icon.png" class="pdf-icon-img-profile"> Contract 
                  </div>
                 
                  
                </div>
                  <input type="file" @change="uploadContract($event)" accepp="application/pdf" :disabled="uploadingFile" class="fileUpload">
                  <div class="file-input-overlay upload-btn">Upload</div>
                  
                </div>
              </div>
              <div class="profile-field-new" v-if="!modificaDate && userData.contract_status != 'not_uploaded'">
                <div class="profile-field-top-new">Contract</div>
                <div class="profile-field-bottom-new"><a :href="`${userData.contract_link}`" target="_blank" class="link-contract" v-if="userData.contact_link"> <span v-if="userData && userData.language === 'romana'">vezi contract</span><span v-if="userData && userData.language === 'english'">see contract</span><img src="../assets/img/expand.png" class="expand-icon"></a></div>
              </div>
              <div class="profile-field-new" v-if="!modificaDate && userData.contract_status != 'not_uploaded'">
                <div class="profile-field-top-new" v-if="userData && userData.language === 'romana'">Status contract</div>
                <div class="profile-field-top-new" v-if="userData && userData.language === 'english'">Contract status</div>
                <div class="profile-field-bottom-new" v-if="userData.contract_status === 'uploaded'"><span class="uploadatCuloare" v-if="userData && userData.language === 'romana'">Uploadat - asteapta verificare</span><span class="uploadatCuloare" v-if="userData && userData.language === 'english'">Uploaded - awaiting review</span></div>
                <div class="profile-field-bottom-new" v-if="userData.contract_status === 'confirmat'"><span class="confirmatCuloare" v-if="userData && userData.language === 'romana'">Confirmat</span><span class="confirmatCuloare" v-if="userData && userData.language === 'english'">Confirmed</span></div>
              </div>
            </div>
            <div class="profile-row-wrapper">
              <div class="profile-field-new" v-if="modificaDate">
                <div class="profile-field-top-new" v-if="userData && userData.language === 'romana'">Cont premium</div>
                <div class="profile-field-top-new" v-if="userData && userData.language === 'english'">Premium account</div>
                <div class="profile-field-bottom-new" v-if="userData && userData.subscription_status === 'sub'">
                  <div class="substatustext" v-if="userData && userData.language === 'romana'">
                  Activ - expira {{ dataExpirare() }}
                </div>
                <div class="substatustext" v-if="userData && userData.language === 'english'">
                  Active - expires on {{ dataExpirare() }}
                </div>
                </div>
                <div class="profile-field-bottom-new" v-if="userData && userData.subscription_status === 'not_sub'">
                  <div v-if="userData && userData.language === 'romana'">
                  Inactiv
                </div>
                <div v-if="userData && userData.language === 'english'">
                  Inactive
                </div>
                </div>
                <div class="profile-field-bottom-new" v-if="userData && userData.subscription_status === 'sub_expired'">
                  
                  <div class="substatustext" v-if="userData && userData.language === 'romana'">
                  Expirat - {{ dataExpirare() }}
                </div>
                <div class="substatustext" v-if="userData && userData.language === 'english'">
                  Expired - {{ dataExpirare() }}
                </div>
                </div>
              </div>
              <div class="profile-field-new" v-if="modificaDate">
                <div class="profile-field-top-new" v-if="userData && userData.language === 'romana'">Nume</div>
                <div class="profile-field-top-new" v-if="userData && userData.language === 'english'">Last name</div>
                <div class="profile-field-bottom-new" v-if="userData"><input class='input-profile-edit' v-model="formNume" type="text" :placeholder="`${userData.nume}`"></div>
              </div>
              <div class="profile-field-new" v-if="modificaDate">
                <div class="profile-field-top-new" v-if="userData && userData.language === 'romana'">Prenume</div>
                <div class="profile-field-top-new" v-if="userData && userData.language === 'english'">First name</div>
                <div class="profile-field-bottom-new" v-if="userData"><input class='input-profile-edit' v-model="formPrenume" type="text" :placeholder="`${userData.prenume}`"></div>
              </div>
              <div class="profile-field-new" v-if="modificaDate">
                <div class="profile-field-top-new" v-if="userData && userData.language === 'romana'">Telefon</div>
                <div class="profile-field-top-new" v-if="userData && userData.language === 'english'">Telephone</div>
                <div class="profile-field-bottom-new" v-if="userData"><input class='input-profile-edit' v-model="formTelefon" type="text" :placeholder="`${userData.telefon}`"></div>
              </div>
              <div class="profile-field-new" v-if="modificaDate">
                <div class="profile-field-top-new">Email</div>
                <div class="profile-field-bottom-new" v-if="userData">{{ userData.email }}</div>
              </div>
              
              <div class="profile-field-new" v-if="modificaDate">
                <div class="profile-field-top-new" v-if="userData && userData.language === 'romana'">Firma / PFA</div>
                <div class="profile-field-top-new" v-if="userData && userData.language === 'english'">Company name</div>
                <div class="profile-field-bottom-new" v-if="userData"><input class='input-profile-edit' v-model="formFirmaPFA" type="text" :placeholder="`${userData.firma_pfa}`"></div>
              </div>
              <div class="profile-field-new" v-if="modificaDate">
                <div class="profile-field-top-new">IBAN</div>
                <div class="profile-field-bottom-new" v-if="userData"><input class='input-profile-edit' v-model="formIBAN" type="text" :placeholder="`${userData.iban}`"></div>
              </div>
              <div class="profile-field-new" v-if="modificaDate">
                <div class="profile-field-top-new" v-if="userData && userData.language === 'romana'">Banca</div>
                <div class="profile-field-top-new" v-if="userData && userData.language === 'english'">Bank name</div>
                <div class="profile-field-bottom-new" v-if="userData"><input class='input-profile-edit' v-model="formBanca" type="text" :placeholder="`${userData.banca}`"></div>
              </div>
              
              <div class="profile-field-new" v-if="modificaDate && userData.contract_status === 'not_uploaded'">
                <div class="profile-field-top-new">Contract</div>
                
                <div class="profile-field-right-input">
                  <div class="profile-con-btn">
                  <div class="download-pdf-icon-profile">
                    <img src="../assets/img/pdf-icon.png" class="pdf-icon-img-profile"> Contract 
                  </div>
                 
                  
                </div>
                  <input type="file" @change="uploadContract($event)" accepp="application/pdf" :disabled="uploadingFile" class="fileUpload">
                  <div class="file-input-overlay upload-btn">Upload</div>
                  
                </div>
              </div>
              <div class="profile-field-new" v-if="modificaDate && userData.contract_status != 'not_uploaded'">
                <div class="profile-field-top-new">Contract</div>
                <div class="profile-field-bottom-new"><a :href="`${userData.contract_link}`" target="_blank" class="link-contract" v-if="userData.contact_link"> <span v-if="userData && userData.language === 'romana'">vezi contract</span><span v-if="userData && userData.language === 'english'">see contract</span><img src="../assets/img/expand.png" class="expand-icon"></a></div>
              </div>
              <div class="profile-field-new" v-if="modificaDate && userData.contract_status != 'not_uploaded'">
                <div class="profile-field-top-new" v-if="userData && userData.language === 'romana'">Status contract</div>
                <div class="profile-field-top-new" v-if="userData && userData.language === 'english'">Contract status</div>
                <div class="profile-field-bottom-new" v-if="userData.contract_status === 'uploaded'"><span class="uploadatCuloare" v-if="userData && userData.language === 'romana'">Uploadat - asteapta verificare</span><span class="uploadatCuloare" v-if="userData && userData.language === 'english'">Uploaded - awaiting review</span></div>
                <div class="profile-field-bottom-new" v-if="userData.contract_status === 'confirmat'"><span class="confirmatCuloare" v-if="userData && userData.language === 'romana'">Confirmat</span><span class="confirmatCuloare" v-if="userData && userData.language === 'english'">Confirmed</span></div>
              </div>
            </div>
              <div class="box-header-row-bot" v-if="modificaDate">
                <div class="show-more-profile-anuleaza" @click="anuleazaDate()" v-if="userData && userData.language === 'romana'">Anuleaza</div>
                <div class="show-more-profile-anuleaza" @click="anuleazaDate()" v-if="userData && userData.language === 'english'">Cancel</div>
                <div class="show-more-profile-salveaza" @click="salveazaDate()" v-if="userData && userData.language === 'romana'">Salveaza</div>
                <div class="show-more-profile-salveaza" @click="salveazaDate()" v-if="userData && userData.language === 'english'">Save</div>
              </div>
              
            </div>
<!--             <div class="profile-container-2">
             
              <div class="todo-title">Castiguri</div>
              <div class="profile-row-wrapper">
              <div class="profile-field-new">
                <div class="profile-field-top-new">Total incasat</div>
                <div class="profile-field-bottom-new incasat-tabel" v-if="allIncasat">{{
                    (Number(allIncasat) * 0.9 + Number(allIncasatRecruti)).toFixed(2)
                  }}
                  RON</div>
              </div>
              <div class="profile-field-new">
                <div class="profile-field-top-new">Incasat contracte proprii</div>
                <div class="profile-field-bottom-new" v-if="allIncasat">{{ (allIncasat * 0.9).toFixed(2) }} RON</div>
              </div>
              <div class="profile-field-new">
                <div class="profile-field-top-new">Incasat recruti</div>
                <div class="profile-field-bottom-new" v-if="allIncasatRecruti">{{ (allIncasatRecruti / 10).toFixed(2) }} RON</div>
              </div>
              <div class="profile-field-new">
                <div class="profile-field-top-new">Incasat luna aceasta</div>
                <div class="profile-field-bottom-new">De calculat</div>
              </div>
              <div class="profile-field-new">
                <div class="profile-field-top-new">De incasat contracte semnate <span class="profile-star">*</span><br><span class="info-castiguri"> Aceasta suma poate varia in realitate, deoarece este posibil ca unele contracte sa fie anulate</span></div>
                <div class="profile-field-bottom-new de-incasat-profil" v-if="deIncasatSemnate && deIncasatSemnate != 0">{{ (deIncasatSemnate * 0.9).toFixed(2) }} RON</div>
                <div class="profile-field-bottom-new de-incasat-profil" v-if="deIncasatSemnate === null || deIncasatSemnate === 0">0 RON</div>
                
              </div>
              <div class="profile-field-new">
                <div class="profile-field-top-new">De incasat contracte recurente total <span class="profile-star">*</span><br> <span class="info-castiguri">Suma reprezinta totalul ramas de incasat din contractele recurente</span></div>
                <div class="profile-field-bottom-new de-incasat-profil" v-if="deIncasatRecurentTotal && deIncasatRecurentTotal != 0">{{ (deIncasatRecurentTotal * 0.9).toFixed(2) }} RON</div>
                <div class="profile-field-bottom-new de-incasat-profil" v-if="deIncasatRecurentTotal === null || deIncasatRecurentTotal === 0">0 RON</div>
              </div>
              <div class="profile-field-new">
                <div class="profile-field-top-new">De incasat contracte recurente urmatoarea transa <span class="profile-star">*</span> <br> <span class="info-castiguri">Suma reprezinta totalul de incasat din contractele recurente pentru urmatoarea transa</span></div>
                <div class="profile-field-bottom-new de-incasat-profil" v-if="deIncasatRecurent && deIncasatRecurent !=0">{{ (deIncasatRecurent * 0.9).toFixed(2) }} RON</div>
                <div class="profile-field-bottom-new de-incasat-profil" v-if="deIncasatRecurent === null || deIncasatRecurent === 0">0 RON</div>
              </div>
             
            </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, ref, computed, onUnmounted } from "vue";
import { supabase } from "../clients/supabase";
import { useStore } from "vuex";

export default {
  name: "ProfilePage",
  components: {},
  data() {
    return {
      error: false,
    };
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      getAllFirme();
      seeCurrentUser();
      getAllClients();
      getInvitedByName();
      checkStripeSub()
    });

    onUnmounted(() => {
      resetContractStore();
    });

    // formular
    const formNume = ref("");
    const formPrenume = ref("");
    const formTelefon = ref("");
    const formEmail = ref("");
    const formFirmaPFA = ref("");
    const formIBAN = ref("");
    const formBanca = ref("");

    const formAgentId = ref("");

    // contracte


    const listaFirme = ref("");
    const allClients = ref();


    const recomandariKey = ref(0);

    const modalContract = ref();
    const modalName = ref("");
    const modalDate = ref("");

    const modalRatePlatite = ref(0);
    const modalDurata = ref(0);
    const modalStatus = ref("");
    const anuleazaToggle = ref(false);
    const modalComision = ref(0);
    const modalTotalIncasat = ref(0);
    const totalRecruti = ref(0);

    const firstPage = ref(true);
    const lastPage = ref(true);
    const modificaDate = ref(false)


    const filtruContractSemnat = ref(true);
    const filtruContractDerulare = ref(true);
    const filtruContractFinalizat = ref(true);
    const filtruContractAnulat = ref(true);

    const uploadingFile = ref(false)

    const fileNameForURL = ref()
    const linkDeContract = ref()

    const userId = computed(() => {
      return store.getters["user/getUserId"];
    });

    const stripeSubStore = computed(() => {
      return store.getters["user/getStripeSub"];
    });

    const contracteStore = computed(() => {
      return store.getters["contracte/getAllContracte"];
    });

    

    const userData = computed(() => {
      return store.getters["user/getUserData"];
    });

    const allClientsStore = computed(() => {
      return store.getters["clienti/getAllClients"];
    });

    const allFirmeStore = computed(() => {
      return store.getters["firme/getAllFirme"];
    });

    const allIncasat = computed(() => {
      return store.getters["user/getAllIncasat"];
    });

    const allRecruti = computed(() => {
      return store.getters["user/getAllRecruti"];
    });


    const invitedByName = computed(() => {
      return store.getters["user/getInvitedByName"];
    });

    const recommendedClientsStore = computed(() => {
      return store.getters["clienti/getRecommendedClients"];
    });

    

    function resetData() {
      modalContract.value = "";
      modalContract.value = "";
      modalName.value = "";
      modalDate.value = "";
      modalRatePlatite.value = "";
      modalDurata.value = "";
      modalStatus.value = "";
      modalComision.value = "";
      modalTotalIncasat.value = "";
    }

    function resetContractStore() {
      store.dispatch("contracte/setContractePag", null);
      store.dispatch("contracte/setAllContracte", null);
      store.dispatch("clienti/setRecommendedClients", null);
    }

    function anuleazaContractToggle() {
      anuleazaToggle.value = true;
      setTimeout(() => {
        anuleazaToggle.value = false;
      }, 3000);
    }

  

    function activeazaModifica() {
      formNume.value = userData.value.nume
      formPrenume.value = userData.value.prenume
      formTelefon.value = userData.value.telefon
      formEmail.value = userData.value.email
      formFirmaPFA.value = userData.value.firma_pfa
      formIBAN.value = userData.value.iban
      formBanca.value = userData.value.banca

      modificaDate.value = true
    }

    function dataExpirare() { 
      if(!userData.value.sub_expiry) {
        return
      }
      let data = ''
      const monthNames = ["Ianuarie", "Februarie", "Martie", "Aprilie", "Mai", "Iunie",
  "Iulie", "August", "Septembrie", "Octombrie", "Noiembrie", "Decembrie"
]
      const ziua = userData.value.sub_expiry.toString().slice(8, 10)
      const luna = monthNames[parseInt(userData.value.sub_expiry.toString().slice(6, 8))]
      const anul = userData.value.sub_expiry.toString().slice(0, 4)

      data = ziua + ' ' + luna + ' ' + anul

      return data

    }

    const checkStripeSub = async () => {



      const { data, error } = await supabase.functions.invoke('unsubscribe-stripe', {
        body: { email: userData.value.email, type: 'check' }
      })


      if(data) {
        console.log('fromstripesub')
        console.log(data)
        
        store.commit("user/setStripeSub", data.filteredSubscription);
      }
      if(error) {
      console.log(error)

      }
      }


    const salveazaDate = async () => {

      try {
        const { data, error } = await supabase
        .from('roles')
        .update({
         
          nume: formNume.value,
          prenume: formPrenume.value,
          telefon: formTelefon.value,
           email: formEmail.value,
          firma_pfa: formFirmaPFA.value,
           iban: formIBAN.value,
           banca: formBanca.value 
          })
        .eq('user_id', userId.value)
        

        console.log(data)
        if (error) {
          throw error
        }
      }
      catch(error) {
        console.log(error)
      }
      console.log('salveaza')
      await updateUserData()
      store.commit("user/setToaster", 'Date modificate cu succes!')
      modificaDate.value = false
    }

    async function uploadContract(event) {
      try {
        uploadingFile.value = true

        if(!event.target.files || event.target.files.length === 0) {
          store.commit("user/setToaster", 'Va rugam sa selectati un fisier PDF pentru upload!')
          throw new Error('You must select a PDF to upload!')
          
          
        }

        const file = event.target.files[0]
        const fileExt = file.name.split(".").pop()
        const fileName = `${Math.random()}.${fileExt}`
        const filePath = `${fileName}`;

        let { error: uploadError, data: uploadData } = await supabase.storage.from('contracte').upload(filePath, file)

        if (uploadError) {
          throw uploadError
        }
        console.log('de la upload')
        console.log(uploadData)
        fileNameForURL.value = uploadData.path
        await getSignedUrl()

        const { data, error } = await supabase.from('roles').update({contract_status: 'uploaded', contract_link: linkDeContract.value}).eq('user_id', userId.value)
        console.log(data)
        if (error) throw error
      }
      catch(error) {
        console.log(error)
      } finally {
        store.commit("user/setToaster", 'Contract uploadat cu succes!')
        uploadingFile.value = false
        updateUserData()
      }
    }

    const getSignedUrl = async () => {
          const { data, error } = await supabase.storage.from('contracte').createSignedUrl(fileNameForURL.value, 99999999999999)
          console.log('de la getsigned url')
            console.log(data.signedUrl)
            linkDeContract.value = data.signedUrl
          if(error) {
            console.log(error)
          }
          else {
            linkDeContract.value = data.signedUrl
              } 
              
            
          }

        const updateUserData = async () => {
          const { data, error } = await supabase.from('roles').select().eq('user_id', userId.value)

          if(error) {
            console.log(error)
          }
          else {
                store.dispatch('user/setRole', data[0].role)
                store.dispatch('user/setUserData', data[0])
              } 
              
            
          }
        

    function anuleazaDate() {
      modificaDate.value = false
      console.log('anuleaza')
    }


    const getAllClients = async () => {
      try {
        const { data, error } = await supabase
          .from("client")
          .select("*")
          .eq("agent_id", userId.value);
        if (error) throw error;
        allClients.value = data;
        store.dispatch("clienti/setAllClients", data);
        console.log(allClients.value);
      } catch (error) {
        console.log(error);
      }
    };

 

 

    const getInvitedByName = async () => {
      try {
        const { data, error } = await supabase
          .from("roles")
          .select()
          .eq("user_id", userData.value.invited_by);
        if (error) throw error;
        console.log('de la invitebyname')
        console.log(data)
        store.dispatch("user/setInvitedByName", data[0].nume + ' ' + data[0].prenume);
      } catch (error) {
        console.log(error);
      }
    };

    const seeCurrentUser = async () => {
      try {
        const { data } = await supabase.auth.getUser();
        
        formAgentId.value = data.user.id;
      } catch (error) {
        console.log(error);
      }
    };

    // Send contract



    // Get firme

    const anuleazaSub = async () => {

      
      const { data, error } = await supabase.functions.invoke('unsubscribe-stripe', {
        body: { email: userData.value.email, type: 'cancel' }
      })

     
      if(data) {
        store.commit("user/setToaster", "Subscriptie anulata cu succes!");
      }
      if(error) {
      console.log(error)
      store.commit("user/setToasterError", "A fost o eroare cu anularea subscriptiei!");
      }
      }

    const getAllFirme = async () => {
      try {
        const { data, error } = await supabase.from("firma").select("*");
        if (error) throw error;
        listaFirme.value = data;
        store.dispatch("firme/setAllFirme", data);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

    return {
      
      formNume,
      formPrenume,
      formTelefon,
      formEmail,
      anuleazaSub,
      anuleazaContractToggle,
      stripeSubStore,
      
      anuleazaToggle,
      getAllFirme,
      listaFirme,
     
      seeCurrentUser,

      userId,
      contracteStore,
      allClientsStore,
      allFirmeStore,
      recomandariKey,
      recommendedClientsStore,
      resetData,
      allIncasat,
 
      modalTotalIncasat,
      modalName,
      modalDate,
      modalContract,

      totalRecruti,
 
      firstPage,
      lastPage,
      resetContractStore,
      filtruContractSemnat,
      filtruContractDerulare,
      filtruContractFinalizat,
      filtruContractAnulat,
      allRecruti,
      invitedByName,
      userData,
      modificaDate,
      formFirmaPFA,
      formIBAN,
      formBanca,
      anuleazaDate,
      salveazaDate,
      activeazaModifica,
      uploadContract,
      uploadingFile,
      dataExpirare
    };
  },
  created() {},
  methods: {},
};
</script>
