import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import Companii from '../views/Companii.vue'
import Companie from '../views/Companie.vue'
import Profile from '../views/Profile.vue'
import About from '../views/About.vue'
import Contracte from '../views/Contracte.vue'
import Firma from '../views/FirmaDashboard.vue'
import FirmaOrder from '../views/FirmaOrder.vue'
import Bounties from '../views/Bounties.vue'
import LoginPage from '../views/LoginPage.vue'
import SignedIn from '../views/SignedIn.vue'
import Retea from '../views/Retea.vue'
import SignUp from '../views/SignUp.vue'
import Recomandari from '../views/Recomandari.vue'
import Castiguri from '../views/Castiguri.vue'
import FAQ from '../views/FAQ.vue'
import LoadingPage from '../views/LoadingPage.vue'
import AdminContracte from '../views/AdminContracte.vue'
import ArhivaBounties from '../views/ArhivaBounties.vue'
import AdminIncasari from '../views/AdminIncasari.vue'
import AdminVerificari from '../views/AdminVerificari.vue'
import Reclamatie from '../views/Reclamatie.vue'
import AdminAddFirma from '../views/AdminAddFirma.vue'
import OnboardingFirma from '../views/OnboardingFirma.vue'
import LoginPageDemo from '../views/LoginPageDemo.vue'
import AplicaFirma from '../views/AplicaFirma.vue'
import AplicaUtilizator from '../views/AplicaUtilizator.vue'
import RecomandariArhivate from '../views/RecomandariArhivate.vue'
import TutorialeVideo from '../views/TutorialeVideo.vue'
import ArhivaRecomandariFirma from '../views/ArhivaRecomandariFirma.vue'
import AdminUsers from '../views/AdminUsers.vue'

import { supabase } from "../clients/supabase"

import { useStore } from 'vuex'






const routes = [
    {
      path: '/',
      name: 'HomePage',
      component: HomePage,
      
    },
    {
      path: '/companii',
      name: 'Companii',
      component: Companii,
      meta: { isAgent: true }
    },
    {
      path: '/demologin',
      name: 'LoginPageDemo',
      component: LoginPageDemo
    },
    {
      path: '/firma',
      name: 'OnboardingFirma',
      component: OnboardingFirma
    },
    {
      path: '/aplicafirma',
      name: 'AplicaFirma',
      component: AplicaFirma
    },
    {
      path: '/aplicautilizator',
      name: 'AplicaUtilizator',
      component: AplicaUtilizator
    },
    {
      path: '/castiguri',
      name: 'Castiguri',
      component: Castiguri,
      meta: { isAgent: true }
    },
    {
      path: '/tutoriale',
      name: 'TutorialeVideo',
      component: TutorialeVideo,
      meta: { isAgent: true }
    },
    {
      path: '/recomandariarhivate',
      name: 'RecomandariArhivate',
      component: RecomandariArhivate,
      meta: { isAgent: true }
    },
    {
      path: '/recomandari',
      name: 'Recomandari',
      component: Recomandari,
      meta: { isAgent: true }
    },
    {
      path: '/faq',
      name: 'FAQ',
      component: FAQ,
      meta: { isAgent: true }
    },
    {
      path: '/retea',
      name: 'Retea',
      component: Retea,
      meta: { isAgent: true }
    },
    {
      path: '/signedin',
      name: 'SignedIn',
      component: SignedIn,
    },
    {
      path: '/arhivabounties',
      name: 'ArhivaBounties',
      component: ArhivaBounties,
    },
    {
      path: '/signup',
      name: 'SignUp',
      component: SignUp,
    },
    {
      path: '/loading',
      name: 'LoadingPage',
      component: LoadingPage,
    },
    {
      path: '/companie/:id',
      name: 'CompaniePage',
      component: Companie,
      meta: { isAgent: true }
    },
    {
      path: '/profile',
      name: 'ProfilePage',
      component: Profile,
      meta: { isAgent: true }
    },
    {
      path: '/reclamatie',
      name: 'ReclamatiePage',
      component: Reclamatie,
      meta: { isAgent: true }
    },
    {
      path: '/bounties',
      name: 'BountiesPage',
      component: Bounties
    },
    {
      path: '/admincontracte',
      name: 'AdminContracte',
      component: AdminContracte,
      meta: { isAdmin: true }
    },
    {
      path: '/adminusers',
      name: 'AdminUsers',
      component: AdminUsers,
      meta: { isAdmin: true }
    },
    {
      path: '/adminaddfirma',
      name: 'AdminAddFirma',
      component: AdminAddFirma,
      meta: { isAdmin: true }
    },
    {
      path: '/adminverificari',
      name: 'AdminVerificari',
      component: AdminVerificari,
      meta: { isAdmin: true }
    },
    {
      path: '/adminincasari',
      name: 'AdminIncasari',
      component: AdminIncasari,
      meta: { isAdmin: true }
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: About,
      meta: { isAgent: true }
    },
    {
      path: '/contracte',
      name: 'ContractePage',
      component: Contracte,
      meta: { isAgent: true }
    },
    {
      path: '/firmadashboard',
      name: 'FirmaDashboard',
      component: Firma,
      meta: { isFirma: true }
    },
    {
      path: '/arhivarecomandari',
      name: 'ArhivaRecomandariFirma',
      component: ArhivaRecomandariFirma,
      meta: { isFirma: true }
    },
    {
      path: '/firmaorder/:id',
      name: 'FirmaOrder',
      component: FirmaOrder,
      meta: { isFirma: true }
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: LoginPage
    },

   
]



const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
      // always scroll to top
      return { top: 0 }
    },
  })

  router.beforeEach(async (to, from, next) => {

    
    const store = useStore()
    const role = store.getters["user/user_role"]

    if(to.path === '/firma') {
      next()
      return
    }

    if(to.path === '/aplicafirma') {
      next()
      return
    }

    if(to.path === '/aplicautilizator') {
      next()
      return
    }

    if(to.path === '/demologin') {
      next()
      return
    }
    
    if(to.path === '/signup') {
      next()
      return
    }
    if(localStorage.getItem('token')) {
      const { data: { user }, error } = await supabase.auth.getUser(localStorage.getItem('token'))
        
        if(error) {
          localStorage.setItem('token', null)
        }
        if(error && to.path !== '/login') {
          store.commit('user/setAuthUser', 'no')
          next('/login')
          return
        }
        if(user) {
          if(to.meta.isAgent && role === 'agent') {
            next()
            return
          } if(to.meta.isAgent && role !== 'agent') {
            next(false)
            return
          }
          
        }
        if(user) {
          if(to.meta.isAdmin && role === 'admin') {
            next()
            return
          } if(to.meta.isAdmin && role !== 'admin') {
            next(false)
            return
          }
          
        }
        if(user) {
          if(to.meta.isFirma && role === 'firma') {
            next()
            return
          } if(to.meta.isFirma && role !== 'firma') {
            next(false)
            return
          }
          
        }
    } 
    if(!localStorage.getItem('token') && to.path !== '/login') {
      next('/login')
     return
    }
    else {
      next()
    }
});

export default router