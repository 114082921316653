/* eslint-disable */
<template>
  <div class="page-container">
    <div class="width-container">
      
      <div class="home-page">
        <div class="main-content">
          <div class="companie-main-content">
            <div class="contracte-container-home" v-if="thisFirma">
              <div class="show-more-comp">
               <router-link to="/companii" class="show-toate-firme">Toate firmele</router-link>
              </div>
              <div class="companie-first-row">
                <div class="companie-row-box">
                  
                  <div class="companie-detalii-box">
                    
                    <div class="companie-data-nume">
                      {{ thisFirma.nume }}
                      <div class="companie-data-comision" v-if="userData && userData.subscription_status != 'sub'">
                        <span class="cutout-comision">{{ thisFirma.comision }}%</span>
                  
                  <span class="half-comision">{{ thisFirma.comision / 2 }}%</span> 
                        <span class='firma-page-comision' v-if="userData && userData.language === 'romana'">comision</span>
                        <span class='firma-page-comision' v-if="userData && userData.language === 'english'">commission</span>
                      </div>
                      <div class="companie-data-comision" v-if="userData && userData.subscription_status === 'sub'">
                        <span class="comision-full-companie">{{ thisFirma.comision }}%</span>
                  
                  
                        <span class='firma-page-comision' v-if="userData && userData.language === 'romana'">comision</span>
                        <span class='firma-page-comision' v-if="userData && userData.language === 'english'">commission</span>
                      </div>
                      <div class="materiale-box">
                    <a
                      :href="`https://kmgppqgrgckdzebbtvqy.supabase.co/storage/v1/object/public/prezentari/${thisFirma.prezentare}`"
                      target="_blank"
                      class="material-link"
                      v-if="thisFirma && thisFirma.prezentare"
                    >
                      <img src="../assets/img/file.png" class="logo-img-file" />
                      <div class="materiale-title">
                        <span v-if="userData && userData.language === 'romana'">Prezentare</span>
                        <span v-if="userData && userData.language === 'english'">Presentation</span>
                        </div>
                    </a>
                  </div>
                    </div>
                  </div>
                  <div class="companie-detalii-box">
                    
                    <div class="companie-data-domeniu">
                      {{ thisFirma.domeniu }}
                    </div>
                  </div>
                  <div class="companie-detalii-box" v-if="thisFirma.locatie">
                    <span class="firma-locatie">
                    {{ thisFirma.locatie }}
                  </span>
                  </div>
                  <div class="companie-detalii-box">
                    
                    <div class="companie-data">
                      <a
                        :href="`${thisFirma.website}`"
                        target="_blank"
                        class="site-link"
                        >{{ thisFirma.website }}</a
                      >
                    </div>
                    
                  </div>
                  
                  <div class="companie-detalii-box2">
                    <div class="recomanda-btn-companie" @click="showModal = true; modalContract = true">
                      Trimite recomandare
                    </div>
                  </div>
                  
                </div>
                <div class="companie-row-box-logo" v-if="thisFirma.logo_image">
                  <div class="logo-box">
                    <img
                      :src="`https://kmgppqgrgckdzebbtvqy.supabase.co/storage/v1/object/public/images/${thisFirma.logo_image}`"
                      class="logo-img-companie"
                    />
                  </div>
                </div>
                
              </div>
              
              <div class="companie-cont">
              
              <div class="box-header-row" v-if="thisFirma.descriere">
                <div class="todo-title" v-if="userData && userData.language === 'romana'">Descriere</div>
                <div class="todo-title" v-if="userData && userData.language === 'english'">Description</div>
              </div>

              <div class="companie-data-mare">
                {{ thisFirma.descriere }}
              </div>

              <div class="box-header-row-inner" v-if="thisFirma.recomandare">
                <div class="todo-title" v-if="userData && userData.language === 'romana'">Recomandari vanzari</div>
                <div class="todo-title" v-if="userData && userData.language === 'english'">Sale advices</div>
              </div>
              <div class="companie-inner-title-first" v-if="thisFirma.recomandare">
                <span v-if="userData && userData.language === 'romana'">Recomandari generale</span>
                        <span v-if="userData && userData.language === 'english'">Generic advice</span>
                </div>
              <div class="companie-data-mare" v-if="thisFirma.recomandare">
                {{ thisFirma.recomandare }}
              </div>
              <div class="companie-inner-title" v-if="thisFirma.recomandare && thisFirma.forte">
                <span v-if="userData && userData.language === 'romana'">Puncte forte</span>
                        <span v-if="userData && userData.language === 'english'">Strengths</span></div>
              <div class="companie-data-mare" v-if="thisFirma.recomandare && thisFirma.forte">
                {{ thisFirma.forte }}
              </div>
              <div class="box-header-row-inner" v-if="thisFirma.exemplu">
                <div class="todo-title"><span v-if="userData && userData.language === 'romana'">Exemplu incasari</span>
                        <span v-if="userData && userData.language === 'english'">Income example</span></div>
              </div>

              <div class="companie-data-mare" v-if="thisFirma.exemplu">
                {{ thisFirma.exemplu }}
             
              </div>
              
            </div>
          </div>
        </div>
          <div class="companie-gallery" v-if="thisFirma && thisFirma.galerie">
            <div class="box-header-row">
                <div class="todo-title"><span v-if="userData && userData.language === 'romana'">Galerie</span>
                        <span v-if="userData && userData.language === 'english'">Gallery</span></div>
              </div>

              <swiper :slides-per-view="1"
              :breakpoints="{450:{slidesPerView: 2}, 900:{slidesPerView: 3}}"
              :space-between="10"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
              class="galerie-container">
                <swiper-slide v-for="(poza, index) in thisFirma.galerie" :key="index + 'img'">
                  <img :src="`https://kmgppqgrgckdzebbtvqy.supabase.co/storage/v1/object/public/images/${thisFirma.gallery_name}/${poza}`" class='galerie-img'>
                </swiper-slide>
                
              
              </swiper>
          </div>
        </div>
      </div>
    </div>
    <vue-final-modal
        v-model="showModal"
        classes="modal-container"
        content-class="modal-content-comp"
        v-if="modalContract"
        @closed="resetData()"
      >
      <div class="home-mid-row-companie">

<div
  class="cover-recomandare"
  v-if="
    userData &&
    userData.subscription_status === 'sub' &&
    userData.contract_status === 'not_uploaded'
  "
>
  <div class="cover-con-btn">
    <div class="download-pdf-icon">
      <img
        src="../assets/img/pdf-icon.png"
        class="pdf-icon-img"
      />
    </div>
    Contract
  </div>
  <span v-if="userData.language === 'romana'">Va rugam semnati si sa uploadati contractul de colaborare pentru a
putea trimite recomandari</span>
<span v-if="userData.language === 'english'">Please sign and upload the contract to be able to start sending recommendations</span>

  <div class="cover-upload">
    <input
      type="file"
      @change="uploadContract($event)"
      accepp="application/pdf"
      :disabled="uploadingFile"
      class="fileUpload-dashboard"
    />
    <div class="file-input-overlay-dashboard upload-btn">
      Upload
    </div>
  </div>
</div>
<div
  class="cover-recomandare-uploaded"
  v-if="
    userData &&
    userData.subscription_status === 'sub' &&
    userData.contract_status === 'uploaded'
  "
>
<span v-if="userData.language === 'romana'">Contractul dumneavoastra este sub verificare. Veti putea
  trimite recomandari imediat ce confirmam ca totul este in
  regula</span>
  <span v-if="userData.language === 'english'">Your contract is under review. You will be able to start sending recommendations as soon as we finish the reviewing process</span>
  
</div>
<div class="contract-container">
  <div class="todo-title" v-if="userData && userData.language === 'romana'">Recomandare noua</div>
<div class="todo-title" v-if="userData && userData.language === 'english'">New recommendation</div>

  <form
    ref="contractform"
    @submit.prevent="submitContract"
    class="contract-form"
  >
    <div class="contract-row">
      <div class="contract-top-left">
        <div class="input-field-left">
          <label v-if="userData && userData.language === 'romana'">Nume</label>
          <label v-if="userData && userData.language === 'english'">Last name</label>
          <input type="text" v-model="formNume" />
        </div>
        <div class="input-field">
          <label class="label-home-space" v-if="userData && userData.language === 'romana'">Prenume</label>
          <label class="label-home-space" v-if="userData && userData.language === 'english'">First name</label>
          <input type="text" v-model="formPrenume" />
        </div>
      </div>
      <div class="contract-top-right">
        <div class="input-field-left">
          <label>Email</label>
          <input type="text" v-model="formEmail" />
        </div>
        <div class="input-field">
          <label class="label-home-space" v-if="userData && userData.language === 'romana'">Telefon client</label>
          <label class="label-home-space" v-if="userData && userData.language === 'english'">Client telephone</label>
          <input type="text" v-model="formTelefon" />
        </div>
      </div>
    </div>
    <div class="contract-detalii-row">
  <div class="input-field">
    <label v-if="userData && userData.language === 'romana'">Firma (optional)</label>
    <label v-if="userData && userData.language === 'english'">Company (optional)</label>
    <input type="text" v-model="formFirmaRecomandata" />
  </div>
</div>
    <div class="contract-detalii-row">
      <div class="input-field">
        <label v-if="userData && userData.language === 'romana'">Din partea</label>
        <label v-if="userData && userData.language === 'english'">Recommended by</label>
        <input type="text" v-model="formDinPartea" />
      </div>
    </div>
    <div class="contract-detalii-row2">
      <div class="hint-detalii" v-if="userData && userData.language === 'romana'">
        Detalii despre temperament, cum ii place sa fie abordat,
        ce buget are si orice alte informatii care pot sa ajute
        firma sa inchida vanzarea.
      </div>
      <div class="hint-detalii" v-if="userData && userData.language === 'english'">
        Details about the client, how he likes to be approached, what is his budget and any other informations that can help the company close the deal.
      </div>
    </div>
    <div class="contract-detalii-row">
      <div class="input-field">
        <label v-if="userData && userData.language === 'romana'">Detalii</label>
        <label v-if="userData && userData.language === 'english'">Details</label>
        <textarea
          rows="5"
          cols="20"
          v-model="formDetalii"
          class="contract-textarea"
        />
      </div>
    </div>

    
     
      <div class="firma-group-companie">
        <label v-if="userData && userData.language === 'romana'">Firma</label>
        <label v-if="userData && userData.language === 'english'">Company</label>
        <div class="firma-selectata-companie">
          {{ thisFirma.nume }}
        </div>
      </div>
    
   
    <div
      class="submit-recomandare-btn"
      @click="sendClientButton()"
    >
    <span v-if="userData && userData.language === 'romana'">Trimite</span>
    <span v-if="userData && userData.language === 'english'">Send</span>
    </div>
  </form>
</div>
</div>
      </vue-final-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, ref, computed, onUnmounted } from "vue";
import { supabase } from "../clients/supabase";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { $vfm } from 'vue-final-modal'
import 'swiper/css';


export default {
  name: "CompaniePage",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      showModal: false,
      modalContract: false,
      error: false,
    };
  },
  setup() {
    const store = useStore();
    

    onMounted(() => {
      getAllFirme();
      getThisFirma();
      
    });

    onUnmounted(() => {
      resetThisFirma();
    });

    // formular

    // contracte

    const route = useRoute();
    const listaFirme = ref("");
    
    const formNume = ref("");
    const formPrenume = ref("");
    const formTelefon = ref("");
    const formEmail = ref("");
    const formDetalii = ref("");
    const formFirma = ref("");
    const formDinPartea = ref("");
    const formFirmaRecomandata = ref("")

    const modalContract = ref();
    const modalName = ref("");
    const modalDate = ref("");

    const modalRatePlatite = ref(0);
    const modalDurata = ref(0);
    const modalStatus = ref("");

    
    const modalComision = ref(0);
    const modalTotalIncasat = ref(0);

    const recomandariKey = ref(0);
    const cautaFirmaInput = ref('')

  


   

    const onSwiper = (swiper) => {
        console.log(swiper);
      };
      const onSlideChange = () => {
        console.log('slide change');
      };

    const userId = computed(() => {
      return store.getters["user/getUserId"];
    });

    const contracteStore = computed(() => {
      return store.getters["contracte/getAllContracte"];
    });

    const allClientsStore = computed(() => {
      return store.getters["clienti/getAllClients"];
    });

    const allFirmeStore = computed(() => {
      return store.getters["firme/getAllFirme"];
    });

    const allFirmeSearched = computed(() => {
      return store.getters["firme/getFirmeSearched"];
    });

    const recommendedClientsStore = computed(() => {
      return store.getters["clienti/getRecommendedClients"];
    });

    const thisFirma = computed(() => {
      return store.getters["firme/getThisFirma"];
    });

    const userData = computed(() => {
      return store.getters["user/getUserData"];
    });

    function resetThisFirma() {
      store.dispatch("firme/setThisFirma", null);
    }

    // Send contract


    // Get firme

 

  

    function resetData() {
      modalContract.value = "";
      modalContract.value = "";
      modalName.value = "";
      modalDate.value = "";
      modalRatePlatite.value = "";
      modalDurata.value = "";
      modalStatus.value = "";
      modalComision.value = "";
      modalTotalIncasat.value = "";
    }

    const getAllFirme = async () => {
      try {
        const { data, error } = await supabase.from("firma").select("*");
        if (error) throw error;
        listaFirme.value = data;
        store.dispatch("firme/setAllFirme", data);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

    const sendClientButton = async () => {
     

      if (userData.value.contract_status === "not_uploaded") {
        console.log("Semnati contractul de colaborare!");
        return;
      }
      if (
        
        formNume.value === "" ||
        formPrenume.value === ""
      ) {
        store.commit(
          "user/setToasterError",
          "Va rugam introduceti toate datele obligatorii!"
        );
        return;
      }
      if (formEmail.value === "" && formTelefon.value === "") {
        store.commit(
          "user/setToasterError",
          "Va rugam introduceti cel putin o modalitate de contact!"
        );
        return;
      }

      try {
        const { error } = await supabase.from("client").insert({
          status: "recomandat",
          firma: thisFirma.value.id,
          nume: formNume.value,
          prenume: formPrenume.value,
          email: formEmail.value,
          telefon: formTelefon.value,
          detalii: formDetalii.value,
          agent_id: userId.value,
          din_partea: formDinPartea.value,
          firma_recomandata: formFirmaRecomandata.value,
          agent_status: userData.value.subscription_status
        });

        const { data: notificationData, error: notificationError } =
          await supabase.functions.invoke("send-notification", {
            body: {
              titlu: "Ati primit o recomandare noua!",
              continut: `Tocmai ati primit o recomandare pentru ${thisFirma.value.nume}!
              <br><br> Nume: <strong>${formNume.value} ${formPrenume.value}</strong>
              <br> Email: <strong>${formEmail.value}</strong>
              <br> Telefon: <strong>${formTelefon.value}</strong>
              <br> Firma Recomandata: <strong>${formFirmaRecomandata.value}</strong>
              <br> Din partea: <strong>${formDinPartea.value}</strong>
              <br> Detalii: <strong>${formDetalii.value}</strong>`,
              email: thisFirma.value.email_contact,
            },
          });
        if (notificationData) {
          console.log(notificationData);
        }
        if (notificationError) {
          console.log(notificationError);
        }

        
        recomandariKey.value += 1;
        formFirma.value = "";
        formNume.value = "";
        formPrenume.value = "";
        formEmail.value = "";
        formTelefon.value = "";
        formDetalii.value = "";
        
        formDinPartea.value = "";
        formFirmaRecomandata.value = "";
        
        store.commit("user/setToaster", "Recomandare inregistrata cu succes!");
        $vfm.hideAll()
        if (error) throw error;
      } catch (error) {
        console.log(error);
      }
    };

    const getThisFirma = async () => {
      try {
        const { data, error } = await supabase
          .from("firma")
          .select()
          .eq("id", route.params.id);
        console.log("query");

        if (error) throw error;

        store.dispatch("firme/setThisFirma", data[0]);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

    return {
      getAllFirme,
      listaFirme,
      
      userId,
      contracteStore,
      allClientsStore,
      allFirmeStore,
      recomandariKey,
      recommendedClientsStore,
      thisFirma,
      onSwiper,
      onSlideChange,
      userData,
      
      formFirma,
       resetData,
       sendClientButton,
       cautaFirmaInput,
       formDetalii,
       allFirmeSearched,
       formDinPartea,
       formFirmaRecomandata,
       formNume,
       formPrenume,
       formTelefon,
       formEmail
    };
  },
  created() {},
  methods: {},
};
</script>
