import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store/index.js'
import { vfmPlugin } from 'vue-final-modal'
import * as moment from 'moment'
import UUID from 'vue3-uuid'
import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'






createApp(App).use(router).use(store).use(VueViewer).use(UUID).use(VueVideoPlayer).use(moment).use(vfmPlugin({
    key: '$vfm',
    componentName: 'VueFinalModal',
    dynamicContainerName: 'ModalsContainer',
    
  })).mount('#app')
