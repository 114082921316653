



export default {
    namespaced: true,
    state: {
        
        allFirme: null,
        thisFirma: null,
        randomFirma: null,
        firmeSubbed: null,
        firmeSearched: null
    },
    getters: {
        getAllFirme(state) {
           return state.allFirme
        },
        getRandomFirma(state) {
            return state.randomFirma
         },
        getThisFirma(state) {
            return state.thisFirma
         },
         getFirmeSubbed(state) {
            return state.firmeSubbed
         },
         getFirmeSearched(state) {
            return state.firmeSearched
         },
    },
    
    mutations: {
        setAllFirme(state, response) {
            state.allFirme = response
        },
        setRandomFirma(state, response) {
            state.randomFirma = response
        },
        setThisFirma(state, response) {
            state.thisFirma = response
        },
        setFirmeSubbed(state, response) {
            state.firmeSubbed = response
        },
        setFirmeSearched(state, response) {
            state.firmeSearched = response
        },
        setReset(state, response) {
            if(response === 'resetAll') {
                state.allFirme = null
            }
            
        }
    },
    actions: {
        setAllFirme({ commit }, payload) {
            commit('setAllFirme', payload)
        },
        setFirmeSubbed({ commit }, payload) {
            commit('setFirmeSubbed', payload)
        },
        setFirmeSearched({ commit }, payload) {
            commit('setFirmeSearched', payload)
        },
        setRandomFirma({ commit }, payload) {
            commit('setRandomFirma', payload)
        },
        reset({ commit }, payload) {
            if(payload === 'resetAll') {
                commit('setReset', 'resetAll')
            }
            
        },
        setThisFirma({ commit }, payload) {
            commit('setThisFirma', payload)
        },
    }
}
