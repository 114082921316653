/* eslint-disable */
<template>
  <div class="page-container">
    <div class="width-container">
      <div class="page-title">Dashboard - ADMIN</div>
      <div class="home-page">
        <div class="main-content">
          <div class="contracte-main-content">
            <div class="container-meniu-admin">
                <router-link to="/admincontracte">
                  <div class="admin-box-meniu">
                      <div class="admin-meniu-text">
                          Contracte de confirmat
                      </div>
                  </div>
                </router-link>
                <router-link to="/adminincasari">
                  <div class="admin-box-meniu">
                      <div class="admin-meniu-text">
                          Incasari
                      </div>
                  </div>
                </router-link>
                <router-link to="/adminverificari">
                  <div class="admin-box-meniu">
                      <div class="admin-meniu-text">
                          Verificari timere
                      </div>
                  </div>
                </router-link>
                <router-link to="/adminaddfirma">
                  <div class="admin-box-meniu">
                      <div class="admin-meniu-text">
                          Adauga Firma Noua
                      </div>
                  </div>
                </router-link>
                <router-link to="/adminusers">
                  <div class="admin-box-meniu">
                      <div class="admin-meniu-text">
                          Lista useri
                      </div>
                  </div>
                </router-link>
            </div>
            <div class="adauga-invite-container">
              <input type="text" v-model="inviteAddEmail" class="add-inv-input">
              <div class="add-inv-btn" @click="adaugaInvite()">
                Adauga invitatie
              </div>
            </div>
            <div class="search-container-firma">
              <div class="search-title">Cautare</div>
              <form @submit.prevent="cautaButton" class="search-form">
              <input type="text" class="search-input" v-model="searchString" placeholder="Cauta dupa nume / prenume">
              <div class="search-btn" @click="cautaButton()">Cauta</div>
            </form>
            </div>
            <div class="contracte-container-firma-dashboard" v-if="cautaActive && searchResponse" @keyup.esc="cautaActive = false">
              <div class="box-header-row">
                  <div class="todo-title">Cautare</div>
                  <div class="close-search" @click="inchideSearch()">X</div>
                </div>
                <div class="fara-clienti" v-if="searchResponse.length === 0">
                Nu s-a gasit niciun rezultat
              </div>
              <table class="orders-table" v-if="searchResponse.length != 0">
                <col class="col-order-no" />
                <col class="col-order-name" />

                <col class="col-order-id" />
                <col class="col-order-ref" />

                <col class="col-order-more" />
                <tr class="products-table-head">
                  <th class="col-order-no">ID</th>
                  <th>Client</th>
                  

                  <th>Serviciu</th>
                  <th>Suma</th>
                  <th>Comision</th>

                  <th>Data</th>
                  <th>Tip</th>
                  <th>Status</th>
                </tr>
                <tr v-for="contract in searchResponse"
                  :key="contract.id + 's'" class="products-table-item" @click="activateModal(contract.client_id, contract.id); showModal = true">
                  <th> {{ contract.id }}</th>
                  <th class="product-name" v-if="allClientsComp && searchResponse">
                    {{ allClientsComp.find(ele => ele.id === contract.client_id)?.prenume ?? 'Nume' }} {{ allClientsComp.find(ele => ele.id === contract.client_id)?.nume ?? 'Necunoscut'}}
                  </th>
                  

                  <th>{{ contract.serviciu }}</th>
                  <th class="suma-tabel-contracte">{{ contract.suma.toLocaleString() }} RON</th>
                  <th class="comision-tabel-contracte">{{ contract.comision.toLocaleString() }} RON <span v-if="contract.tip === 'recurentCheck'">/ luna</span></th>

                  <th class="data-tabel">{{ contract.created_at.slice(0, 10) }}</th>
                  
                  <th class="product-name" v-if="contract.tip === 'oneTimeCheck'">One Time</th>
                  <th class="product-name" v-if="contract.tip === 'recurentCheck'">Recurent <span v-if="contract.tip === 'recurentCheck' && contract.status != 'finalizat'" class="rate-platite-tabel">{{ contract.rate_platite }} / {{ contract.durata }}</span> <span v-if="contract.tip === 'recurentCheck' && contract.status === 'finalizat'" class="rate-platite-final-tabel">{{ contract.rate_platite }} / {{ contract.durata }}</span></th>
                  <th class="status-col">
                    <span v-if="contract.status === 'derulare'">In derulare</span>
                    <span v-if="contract.status != 'derulare'">{{ contract.status }}</span>
                  <div class="status-confirmat" v-if="contract.status === 'semnat' "></div>
                  <div class="status-incasat" v-if="contract.status === 'derulare'"></div>
                  <div class="status-finalizat" v-if="contract.status === 'finalizat'"></div>
                  <div class="status-anulat" v-if="contract.status === 'anulat'"></div>
                  </th>
                </tr>
              </table>
            </div>

            
            <div class="contracte-container-firma-dashboard" v-if="recommendedClients && !cautaActive">
              <div class="todo-title">Recomandari primite</div>
              <div class="fara-clienti" v-if="recommendedClients.length === 0">
                Nu aveti nicio recomandare
              </div>
              <table class="orders-table" v-if="recommendedClients.length != 0">
                <col class="col-order-no" />
                <col class="col-order-name" />

                <col class="col-order-id" />
                <col class="col-order-ref" />

                <col class="col-order-more" />
                <tr class="products-table-head">
                  <th class="col-order-no">ID</th>
                  <th>Nume</th>

                  <th>Telefon</th>
                  <th>Email</th>
                  <th>Detalii</th>
                  <th>Data</th>
                </tr>
                <tr
                  class="products-table-item"
                  @click="toOrder(client.id)"
                  v-for="client in recommendedClientsStore"
                  :key="client.id"
                 
                >
                  <th class="table-item">{{ client.id }}</th>
                  <th class="product-name">
                    {{ client.prenume }} {{ client.nume }}
                  </th>

                  <th class="table-item">{{ client.telefon }}</th>
                  <th class="table-item">{{ client.email }}</th>
                  <th class="table-item-detalii">{{ client.detalii }}</th>
                  <th class="table-item">{{ client.created_at.slice(0, 10) }}</th>
                </tr>
              </table>
            </div>
            <div class="contracte-container" v-if="contractePag && !cautaActive">
              <div class="box-header-row">
                <div class="todo-title">Contracte</div>
              </div>
              <div class="filtre-wrapper">
                <div
                  class="filtru-contracte"
                  :class="{ filtruselected: filtruContractSemnat }"
                  @click="filtruContractSemnat = !filtruContractSemnat"
                >
                  Semnat
                </div>
                <div
                  class="filtru-contracte"
                  :class="{ filtruselected: filtruContractDerulare }"
                  @click="filtruContractDerulare = !filtruContractDerulare"
                >
                  In derulare
                </div>
                <div
                  class="filtru-contracte"
                  :class="{ filtruselected: filtruContractFinalizat }"
                  @click="filtruContractFinalizat = !filtruContractFinalizat"
                >
                  Finalizat
                </div>
                <div
                  class="filtru-contracte"
                  :class="{ filtruselected: filtruContractAnulat }"
                  @click="filtruContractAnulat = !filtruContractAnulat"
                >
                  Anulat
                </div>
              </div>
              <div class="fara-clienti" v-if="contractePag.length === 0">
                Nu aveti niciun contract
              </div>
              <table class="orders-table" v-if="contractePag.length != 0">
                <col class="col-order-no" />
                <col class="col-order-name" />

                <col class="col-order-id" />
                <col class="col-order-ref" />

                <col class="col-order-more" />
                <tr class="products-table-head">
                  <th class="col-order-no">ID</th>
                  <th>Client</th>
                  

                  <th>Serviciu</th>
                  <th>Suma</th>
                  <th>Comision</th>

                  <th>Data</th>
                  <th>Tip</th>
                  <th>Status</th>
                </tr>
                <tr v-for="contract in contractePag"
                  :key="contract.id" class="products-table-item" @click="activateModal(contract.client_id, contract.id); showModal = true">
                  <th> {{ contract.id }}</th>
                  <th class="product-name" v-if="allClientsComp">
                    {{ allClientsComp.find(ele => ele.id === contract.client_id).prenume }} {{ allClientsComp.find(ele => ele.id === contract.client_id).nume }}
                  </th>
                  

                  <th>{{ contract.serviciu }}</th>
                  <th class="suma-tabel-contracte">{{ contract.suma.toLocaleString() }} RON</th>
                  <th class="comision-tabel-contracte">{{ contract.comision.toLocaleString() }} RON <span v-if="contract.tip === 'recurentCheck'">/ luna</span></th>

                  <th class="data-tabel">{{ contract.created_at.slice(0, 10) }}</th>
                  
                  <th class="product-name" v-if="contract.tip === 'oneTimeCheck'">One Time</th>
                  <th class="product-name" v-if="contract.tip === 'recurentCheck'">Recurent <span v-if="contract.tip === 'recurentCheck' && contract.status != 'finalizat'" class="rate-platite-tabel">{{ contract.rate_platite }} / {{ contract.durata }}</span> <span v-if="contract.tip === 'recurentCheck' && contract.status === 'finalizat'" class="rate-platite-final-tabel">{{ contract.rate_platite }} / {{ contract.durata }}</span></th>
                  <th class="status-col">
                    <span v-if="contract.status === 'derulare'">In derulare</span>
                    <span v-if="contract.status != 'derulare'">{{ contract.status }}</span>
                  <div class="status-confirmat" v-if="contract.status === 'semnat' "></div>
                  <div class="status-incasat" v-if="contract.status === 'derulare'"></div>
                  <div class="status-finalizat" v-if="contract.status === 'finalizat'"></div>
                  <div class="status-anulat" v-if="contract.status === 'anulat'"></div>
                  </th>
                </tr>
              </table>
              <div class="buttons-table-wrapper" v-if="!firstPage || !lastPage">
                <div
                  class="btn-pagination"
                  @click="getPrev()"
                  v-if="!firstPage"
                >
                  Inapoi
                </div>
                <div class="btn-pagination-cancelled" v-if="firstPage">
                  Inapoi
                </div>
                <div class="btn-pagination-cancelled" v-if="lastPage">
                  Inainte
                </div>
                <div class="btn-pagination" @click="getNext()" v-if="!lastPage">
                  Inainte
                </div>
              </div>
            </div>
            <div class="contracte-container" v-if="clientClients && !cautaActive">
              <div class="todo-title">Clienti</div>
              <div class="fara-clienti" v-if="clientClients.length === 0">
                Nu aveti niciun client
              </div>
              <table class="orders-table" v-if="clientClients.length != 0">
                <col class="col-order-no" />
                <col class="col-order-name" />

                <col class="col-order-id" />
                <col class="col-order-ref" />


                <tr class="products-table-head">
                  <th class="col-order-no">ID</th>

                  <th>Client</th>
                  <th>Telefon</th>
                  <th>Email</th>
                  <th>Data</th>
                </tr>
                <tr
                  class="products-table-item"
                  @click="toOrder(clienta.id)"
                  v-for="clienta in clientClientsStore"
                  :key="clienta.id"
                 
                >
                  <th class="table-item">{{ clienta.id }}</th>
                  <th class="product-name">
                    {{ clienta.prenume }} {{ clienta.nume }}
                  </th>

                  <th class="table-item">{{ clienta.telefon }}</th>
                  <th class="table-item">{{ clienta.email }}</th>
                  <th class="table-item">{{ clienta.created_at.slice(0, 10) }}</th>
                </tr>
              </table>
            </div>
          
            
          </div>
        </div>
      </div>
      <vue-final-modal
        v-model="showModal"
        classes="modal-container"
        content-class="modal-content"
        v-if="modalContract"
        @closed="resetData()"
      >
        <div class="contract-item" v-if="!dejaCalificat">
          <div class="modal-status-semnat" v-if="modalContract.status === 'semnat'" />
          <div class="modal-status-derulare" v-if="modalContract.status === 'derulare'" />
          <div class="modal-status-finalizat" v-if="modalContract.status === 'finalizat'" />
          <div class="modal-status-anulat" v-if="modalContract.status === 'anulat'" />
          <div class="profile-field">
            <div class="profile-field-left">ID</div>
            <div class="profile-field-right"> {{ modalContract.id }}</div>
          </div>
          <div class="profile-field">
            <div class="profile-field-left">Client</div>
            <div class="profile-field-right"> {{ modalName }}</div>
          </div>
          <div class="profile-field">
            <div class="profile-field-left">Serviciu</div>
            <div class="profile-field-right">{{ modalContract.serviciu }}</div>
          </div>
          <div class="profile-field-detalii2" v-if="modalContract.detalii">
            <div class="profile-field-left">Detalii</div>
            <div class="profile-field-right-detalii2">{{ modalContract.detalii }}</div>
          </div>
          <div class="profile-field">
            <div class="profile-field-left">Data semnarii</div>
            <div class="profile-field-right">{{ modalDate }}</div>
          </div>
          <div class="profile-field">
            <div class="profile-field-left">Suma</div>
            <div class="profile-field-right">{{ modalContract.suma }} RON</div>
          </div>
          <div class="profile-field">
            <div class="profile-field-left">Comision</div>
            <div class="profile-field-right">{{ modalContract.comision }} RON<span v-if="modalContract.tip === 'recurentCheck'"> / luna</span></div>
          </div>
          <div class="profile-field" v-if="modalContract.tip === 'recurentCheck'">
            <div class="profile-field-left">Platit</div>
            <div class="profile-field-right">{{ modalContract.rate_platite }} / {{ modalContract.durata }}</div>
          </div>
          <div class="profile-field" v-if="modalContract.status === 'semnat' && modalContract.tip === 'oneTimeCheck' && !confirmBounty">
            <div class="contract-btn" @click="contractIncasat()">Incasat</div>
          </div>
          <div class="profile-field" v-if="modalContract.tip === 'recurentCheck' && modalContract.status != 'finalizat' && modalContract.status != 'anulat' && !confirmBounty">
            <div class="contract-btn" @click="platesteTransa()">Plateste transa</div>
          </div>
          <div class="profile-field" v-if="modalContract.status != 'finalizat' && modalContract.status != 'anulat' && !anuleazaToggle && !confirmBounty">
            <div class="contract-btn-anulat" @click="anuleazaContractToggle()">Anuleaza contractul</div>
          </div>
          <div class="profile-field" v-if="anuleazaToggle">
            <div class="profile-field-anuleaza-left" @click="anuleazaContract()">Sunteti sigur ca vreti sa anulati?</div>
            <div class="profile-field-anuleaza-right" @click="anuleazaContract()">Da, anuleaza</div>
          </div>
          
        </div>
        <div class="bounty-confirm-screen" v-if="confirmBounty && !dejaCalificat">
          <div class="bounty-header">Conform sumei, se pare ca acest contract se califica la bounty-ul dumneavoastra</div>
          <div class="profile-field" v-if="bountyCerinta">
            <div class="profile-field-left">Cerinta bounty</div>
            <div class="profile-field-right">{{ bountyCerinta }}</div>
          </div>
          <div class="profile-field" v-if="bountySuma">
            <div class="profile-field-left">Suma bounty</div>
            <div class="profile-field-right">{{ bountySuma }} RON</div>
          </div>
          <div class="bounty-confirm-row">
            <div class="cancel-bounty" @click="nuAdaugaBounty()">
              Nu se incadreaza
            </div>
            <div class="accept-bounty" @click="adaugaLaBounty()">
              Da, adauga la bounty!
            </div>
          </div>
          
        </div>
        <div class="deja-calificat" v-if="dejaCalificat">
          Agentul este deja calificat pentru acest bounty!
        </div>
      </vue-final-modal>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { supabase } from "../clients/supabase";
import { useStore } from "vuex";
import { uuid } from 'vue3-uuid'


export default {
  name: "AdminPage",
  components: {
    
  },
  data() {
    return {
      showModal: false,
      error: false,
    
    };
  },
  setup() {
    const store = useStore();
    const allClients = ref("");
    const recommendedClients = ref('')
    const clientClients = ref('')
    const contracte = ref('')
    const modalId = ref('')
    const contractId = ref('')
    const modalContract = ref()
    const modalName = ref('')
    const modalDate = ref('')
    const rataNoua = ref(0)
    const modalRatePlatite = ref(0)
    const modalDurata = ref(0)
    const modalStatus = ref('')
    const anuleazaToggle = ref(false)
    const modalComision = ref(0)
    const modalTotalIncasat = ref(0)
    const bountySuma = ref()
    const bountyCerinta = ref()
    const modalSuma = ref()
    const confirmBounty = ref(false)
    const modalAgent = ref()
    const dejaCalificat = ref(false)
    const cautaActive = ref(false)
    const searchString = ref('')
    const inviteAddEmail = ref('')
    
    

    const arrayFiltrePag = ref(["finalizat", "anulat", "derulare", "semnat"]);

    const filtruContractSemnat = ref(true);
    const filtruContractDerulare = ref(true);
    const filtruContractFinalizat = ref(true);
    const filtruContractAnulat = ref(true);

    const contractPagePagination = ref(0);
    const firstPage = ref(true);
    const lastPage = ref(true);
    

    const userId = computed(() => {
      return store.getters["user/getUserId"];
    });

    const firmaId = computed(() => {
      return store.getters["user/getFirmaId"];
    });

    const isAdmin = computed(() => {
      return store.getters["user/isAdmin"];
    });

    const allClientsComp = computed(() => {
      return store.getters["clienti/getAllClients"];
    });

    const clientClientsStore = computed(() => {
      return store.getters["clienti/getClientClients"];
    });

    const recommendedClientsStore = computed(() => {
      return store.getters["clienti/getRecommendedClients"];
    });

    const contracteStore = computed(() => {
      return store.getters["contracte/getAllContracte"];
    });

    const firmaName = computed(() => {
      return store.getters["user/getFirmaName"];
    });

    const contractePag = computed(() => {
      return store.getters["contracte/getContractePag"];
    });

    const contractePagAll = computed(() => {
      return store.getters["contracte/getContractePagAll"];
    });

    const searchResponse = computed(() => {
      return store.getters["user/getSearchResponse"];
    });

    onMounted(() => {
      getAllClients();
      seeCurrentUser();
      getRecommendedClients();
      getFirmaComision();
      getClientClients();
      getContracte();
      getContractePag();
      getContractePagAll()
    });

    onUnmounted(() => {
      resetAllStores()
    })

    watch(filtruContractSemnat, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("semnat");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "semnat"
        );

        getContractePag();
      }
    });

    watch(filtruContractDerulare, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("derulare");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "derulare"
        );

        getContractePag();
      }
    });

    watch(filtruContractFinalizat, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("finalizat");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "finalizat"
        );

        getContractePag();
      }
    });

    watch(filtruContractAnulat, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("anulat");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "anulat"
        );

        getContractePag();
      }
    });

    const seeCurrentUser = async () => {
      const { data, error } = await supabase.auth.getSession();
      
      store.dispatch("user/setUserId", data.session.user.id);
      if (error) {
        console.log(error);
      }
    };

    const getContractePagAll = async () => {
      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .in("status", arrayFiltrePag.value)
          .order('created_at', { ascending: false })

        

        if (error) throw error;

        store.dispatch("contracte/setContractePagAll", data);
      } catch (error) {
        console.log(error);
      }
    };

    const getContractePag = async () => {
      contractPagePagination.value = 0;
      firstPage.value = true;
      await getContractePagAll()
      if (contractPagePagination.value + 20 >= contractePagAll.value.length) {
        lastPage.value = true;
        
      } else {
        lastPage.value = false;
      }
      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .in("status", arrayFiltrePag.value)
          .range(0, 19)
          .order('created_at', { ascending: false})

        store.dispatch("contracte/setContractePag", data);
        if (error) throw error;
        
          
        
      } catch (error) {
        console.log(error);
      }
    };

    const getNext = async () => {
      try {
        if (contractPagePagination.value + 20 >= contractePagAll.value.length) {
          lastPage.value = true;
          
        } else {
          lastPage.value = false;
          contractPagePagination.value += 20;
          let rangePagination = contractPagePagination.value + 19;
          const { data, error } = await supabase
            .from("contract")
            .select()
            .in("status", arrayFiltrePag.value)
            .range(contractPagePagination.value, rangePagination)
            .order('created_at', { ascending: false})

          if (
            contractPagePagination.value + 20 >=
            contractePagAll.value.length
          ) {
            lastPage.value = true;
          }
          if (contractPagePagination.value >= 0) {
            firstPage.value = false;
            
          }
          if (error) throw error;

          store.dispatch("contracte/setContractePag", data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getPrev = async () => {
      try {
        if (contractPagePagination.value <= 0) {
          firstPage.value = true;
          
        } else {
          firstPage.value = false;
          let rangePagination = contractPagePagination.value - 20;
          const { data, error } = await supabase
            .from("contract")
            .select()
            .in("status", arrayFiltrePag.value)
            .range(rangePagination, contractPagePagination.value - 1)
            .order('created_at', { ascending: false})
          contractPagePagination.value -= 20;
          if (contractPagePagination.value <= 0) {
            firstPage.value = true;
            
          }
          if (
            contractPagePagination.value + 20 <=
            contractePagAll.value.length
          ) {
            lastPage.value = false;
          }
          if (error) throw error;

          store.dispatch("contracte/setContractePag", data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    

    function activateModal(id, contractid) {
     
      
      modalId.value = id
      contractId.value = contractid
      getModalInfo()
    }

    function inchideSearch() {
      cautaActive.value = false
      store.commit('user/setSearchResponse', null)
      searchString.value = ''
    }

    function resetAllStores() {
      store.dispatch('contracte/reset', 'resetAll')
    }

    function resetData() {
      modalContract.value = ''
      modalContract.value = ''
       modalName.value = ''
      modalDate.value = ''
      modalRatePlatite.value = ''
      modalDurata.value = ''
      modalStatus.value = ''
      modalComision.value = ''
      modalTotalIncasat.value = ''
      modalSuma.value = ''
      bountySuma.value = ''
      confirmBounty.value = false
      modalAgent.value = ''
    }

    const cautaButton = async () => {
     if(searchString.value === '') {
      return
     }
      console.log('cauta')
      console.log(searchString.value)
      if(cautaActive.value === false) {
        cautaActive.value = true
        
      }
      
      try {
        let cautaText = searchString.value
        console.log(cautaText)
        const { data, error } = await supabase.rpc('search_contracts_by_client_name', {search_query: cautaText, firma_id: firmaId.value });
        // trebuie bagat sa caute doar contractele cu firma ID !!!!!

          if (error) throw error;
          console.log('from search')
          console.log(data)
          store.dispatch('user/setSearchResponse', data)
      } catch (error) {
        console.log(error)
      }
    
  
    }

    function anuleazaContractToggle() {
      anuleazaToggle.value = true
      setTimeout(() => {
        anuleazaToggle.value = false
      }, 3000)
    }

    const getBountySuma = async () => {
      try {
        const { data, error } = await supabase.from("bounties")
        .select()
        .eq("firma", firmaId.value);
          if (error) throw error;
          
          if(data[0].suma != null) {
            bountySuma.value = data[0].suma
            bountyCerinta.value = data[0].cerinta
            
          }
          if(data[0].suma === null) {
            bountySuma.value = null
           
          }
      } catch (error) {
        console.log(error)
      }
    }

    

    const contractIncasat = async () => {

      const statusUpdate = ref(false)
      const totalIncasatUpdate = ref(false)

      await getBountySuma()
      if(modalSuma.value <= bountySuma.value || bountySuma.value === null) {
        
        try {
        const { data, error } = await supabase.from("contract").update({ status: 'finalizat' }).eq("id", contractId.value);
          if (error) throw error;
          
          console.log(data)
          statusUpdate.value = true
          
          getContracte();
          getContractePag()
          getModalInfo()
      } catch (error) {
        console.log(error)
      }
      try {
        const time = new Date().toISOString()
        const { data, error } = await supabase.from("contract").update({ total_incasat: modalComision.value, incasat_at: time }).eq("id", contractId.value);
          if (error) throw error;
          
          console.log(data)
          totalIncasatUpdate.value = true
          getContracte();
          getContractePag()
          getModalInfo()
      } catch (error) {
        console.log(error)
      }

      if (statusUpdate.value && totalIncasatUpdate.value) {
        store.commit('user/setToaster', 'Contract finalizat!')
      }
      }
      else {
        console.log('se califica la bounty')
        confirmBounty.value = true

      }
      
    }

    const nuAdaugaBounty = async () => {

const statusUpdate = ref(false)
const totalIncasatUpdate = ref(false)
confirmBounty.value = false

  
  try {
  const { data, error } = await supabase.from("contract").update({ status: 'finalizat' }).eq("id", contractId.value);
    if (error) throw error;
    
    console.log(data)
    statusUpdate.value = true
    
    getContracte();
    getContractePag()
    getModalInfo()
} catch (error) {
  console.log(error)
}
try {
  const time = new Date().toISOString()
  const { data, error } = await supabase.from("contract").update({ total_incasat: modalComision.value, incasat_at: time }).eq("id", contractId.value);
    if (error) throw error;
    
    console.log(data)
    totalIncasatUpdate.value = true
    getContracte();
    getContractePag()
    getModalInfo()
} catch (error) {
  console.log(error)
}

if (statusUpdate.value && totalIncasatUpdate.value) {
  store.commit('user/setToaster', 'Contract finalizat!')
}


}

    const adaugaLaBounty = async () => {
      const arrayCalificati = ref([])
      const statusUpdate = ref(false)
      const totalIncasatUpdate = ref(false)

      try {
        const { data, error } = await supabase.from("contract").update({ status: 'finalizat' }).eq("id", contractId.value);
          if (error) throw error;
          
          console.log(data)
          statusUpdate.value = true
          
          getContracte();
          getModalInfo()
          getContractePag()
      } catch (error) {
        console.log(error)
      }
      try {
        const time = new Date().toISOString()
        const { data, error } = await supabase.from("contract").update({ total_incasat: modalComision.value, incasat_at: time }).eq("id", contractId.value);
          if (error) throw error;
          
          console.log(data)
          totalIncasatUpdate.value = true
          getContracte();
          getModalInfo()
          getContractePag()
      } catch (error) {
        console.log(error)
      }
      try {

        const { data, error } = await supabase.from("bounties").select().eq("id", firmaId.value);
          if (error) throw error;
          
          console.log('de la bounties calificati')
          arrayCalificati.value = data[0].calificati || []
          console.log(arrayCalificati.value)
          if(arrayCalificati.value.includes(modalAgent.value)) {
            dejaCalificat.value = true
            setTimeout(() => {
              dejaCalificat.value = false
            }, 3000)
            getContracte();
          getModalInfo()
          getContractePag()
          }

          if(!arrayCalificati.value.includes(modalAgent.value)) {
            arrayCalificati.value.push(modalAgent.value)
            console.log(arrayCalificati.value)
            try {

            const { data, error } = await supabase.from("bounties").update({ calificati: arrayCalificati.value }).eq("id", firmaId.value);
              if (error) throw error;
              
              console.log(data)
              getContracte();
          getModalInfo()
          getContractePag()
            } catch (error) {
            console.log(error)
            }
          }
          
          getContracte();
          getModalInfo()
          getContractePag()

            confirmBounty.value = false
        } catch (error) {
        console.log(error)
        }
        

        if (statusUpdate.value && totalIncasatUpdate.value) {
        store.commit('user/setToaster', 'Contract finalizat!')
        getContracte();
          getModalInfo()
          getContractePag()
      }
    }

    const platesteTransa = async () => {
      try {
       

        if(modalStatus.value === 'semnat') {
            const { data, error } = await supabase.from("contract").update({ status: 'derulare' }).eq("id", contractId.value);
            
            getContracte();
            getContractePag()
            getModalInfo()
            if (error) throw error;
            console.log(data)
          }

        rataNoua.value = modalRatePlatite.value + 1

        if(rataNoua.value < modalDurata.value && rataNoua.value != 0) {

          const { data, error } = await supabase.from("contract").update({ rate_platite: rataNoua.value, total_incasat: modalTotalIncasat.value + modalComision.value }).eq("id", contractId.value);
            if (error) throw error;
            console.log(data)
            store.commit('user/setToaster', 'Transa platita!')
            getContracte();
            getContractePag()
            getModalInfo()
          
        }  
           else if(rataNoua.value === modalDurata.value) {
            const { data, error } = await supabase.from("contract").update({ rate_platite: rataNoua.value, status: 'finalizat', total_incasat: modalTotalIncasat.value + modalComision.value }).eq("id", contractId.value);
            if (error) throw error;
            console.log(data)
            store.commit('user/setToaster', 'Ultima transa platita! Contract finalizat!')
            getContracte();
            getContractePag()
            getModalInfo()
          }
          
          
        }
       catch (error) {
        console.log(error)
      }
    }

    const anuleazaContract = async () => {
      try {
        const { data, error } = await supabase.from("contract").update({ status: 'anulat' }).eq("id", contractId.value);
          if (error) throw error;
          console.log(data)
          store.commit('user/setToaster', 'Contract anulat!')
          anuleazaToggle.value = false
          getContracte();
          getContractePag()
          getModalInfo()
      } catch (error) {
        console.log(error)
      }
    }

    const getModalInfo = async () => {
      try {
        const { data, error } = await supabase.from("contract").select().eq('id', contractId.value);
        if (error) throw error;
        
       modalContract.value = data[0]
       modalName.value = allClientsComp.value.find(ele => ele.id === modalId.value).prenume + ' ' + allClientsComp.value.find(ele => ele.id === modalId.value).nume
        modalDate.value = data[0].created_at.slice(0, 10)
        modalRatePlatite.value = data[0].rate_platite
        modalDurata.value = data[0].durata
        modalStatus.value = data[0].status
        modalComision.value = data[0].comision
        modalTotalIncasat.value = data[0].total_incasat
        modalSuma.value = data[0].suma
        modalAgent.value = data[0].agent
        
      } catch (error) {
        console.log(error)
      }
    }

    const getFirmaComision = async () => {
      try {
        const { data, error } = await supabase.from("firma").select();
        if (error) throw error;
        store.dispatch('user/setComision', data[0].comision)
      } catch (error) {
        console.log(error)
      }
    }

    const adaugaInvite = async () => {
      const UUID = ref(null)
        UUID.value = uuid.v4()
        
        const { data: userInfo } = await supabase.from('roles').select().eq('email', inviteAddEmail.value)

        let ownerId = userInfo[0].user_id

        const { data, error } = await supabase.from('invites').select().eq("status", 'available')
      .limit(1)
      .single()

      console.log(data.id)
      if(error) {
        console.log(error)
      }

      const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
    const inviteTime = new Date();
    inviteTime.setDate(inviteTime.getDate());
    const inviteTimestamp = inviteTime.getFullYear() +
        '-' + pad(inviteTime.getMonth() + 3) +
        '-' + pad(inviteTime.getDate()) +
        ' ' + pad(inviteTime.getHours()) +
        ':' + pad(inviteTime.getMinutes()) +
        ':' + pad(inviteTime.getSeconds());

        const { data: updateInvite, error: updateInviteError } = await supabase
    .from('invites')
    .update({
      status: 'owned',
      owner_id: ownerId,
      global_expiry_time: inviteTimestamp,
      hash: UUID.value
    })
    .eq('id', data.id)
    .select()

    console.log(updateInvite)
    if (updateInviteError) {
      console.log(updateInviteError)
    }

    }

    const getContracte = async () => {
      try {
        if (isAdmin.value) {
          const { data, error } = await supabase.from("contract").select('*');
          if (error) throw error;
          
          store.dispatch("contracte/setAllContracte", data);
          
        } else {
          const { data, error } = await supabase
            .from("contract")
            .select()
            
            .order('created_at', { ascending: false})
          if (error) throw error;
          
          store.dispatch("contracte/setAllContracte", data);
          
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getAllClients = async () => {
      try {
        if (isAdmin.value) {
          const { data, error } = await supabase.from("client").select("*");
          if (error) throw error;
          allClients.value = data;
          store.dispatch("clienti/setAllClients", data);
          console.log(allClients.value);
        } else {
          const { data, error } = await supabase
            .from("client")
            .select()
            
            .order('created_at', { ascending: false})
          if (error) throw error;
          allClients.value = data;
          store.dispatch("clienti/setAllClients", data);
          console.log(allClients.value);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getRecommendedClients = async () => {
      try {
        if (isAdmin.value) {
          const { data, error } = await supabase.from("client").select('').eq('status', 'recomandat');
          if (error) throw error;
          recommendedClients.value = data;
          console.log(data)
          store.dispatch("clienti/setRecommendedClients", data);
          console.log(recommendedClients.value);
        } else {
          const { data, error } = await supabase
            .from("client")
            .select()
            .eq('status', 'recomandat')
            .order('created_at', { ascending: false})
          if (error) throw error;
          recommendedClients.value = data;
          store.dispatch("clienti/setRecommendedClients", data);
          console.log(recommendedClients.value);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getClientClients = async () => {
      try {
        if (isAdmin.value) {
          const { data, error } = await supabase.from("client").select('').eq('status', 'client');
          if (error) throw error;
          clientClients.value = data;
          
          console.log(data)
          store.dispatch("clienti/setClientClients", data);
          console.log(clientClients.value);
        } else {
          const { data, error } = await supabase
            .from("client")
            .select()
            .eq('status', 'client')
            .order('created_at', { ascending: false})
          if (error) throw error;
          clientClients.value = data;
          console.log('clientclientsdata')
          store.dispatch("clienti/setClientClients", data);
          console.log(clientClients.value);
        }
      } catch (error) {
        console.log(error);
      }
    };

    return {
      getAllClients,
      getRecommendedClients,
      getClientClients,
      recommendedClients,
      seeCurrentUser,
      allClients,
      userId,
      getFirmaComision,
      clientClients,
      getContracte,
      contracte,
      allClientsComp,
      activateModal,
      modalContract,
      contracteStore,
      modalName,
      modalDate,
      clientClientsStore,
      recommendedClientsStore,
      contractIncasat,
      anuleazaContract,
      platesteTransa,
      firmaName,
      anuleazaContractToggle,
      anuleazaToggle,
      resetData,
      modalComision,
      modalTotalIncasat,
      modalSuma,
      confirmBounty,
      bountyCerinta,
      bountySuma,
      adaugaLaBounty,
      dejaCalificat,
      getNext,
      getPrev,
      contractePag,
      firstPage,
      lastPage,
      filtruContractAnulat,
      filtruContractDerulare,
      filtruContractFinalizat,
      filtruContractSemnat,
      cautaButton,
      cautaActive,
      searchString,
      searchResponse,
      inchideSearch,
      nuAdaugaBounty,
      inviteAddEmail,
      adaugaInvite
     
    };
  },
  created() {},
  methods: {
    setFirma(e) {
      console.log(e);
    },
    toOrder(id) {
      this.$router.push("/firmaorder/" + id);
    },
  },
};
</script>
