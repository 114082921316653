/* eslint-disable */
<template>
  <div class="page-container">
    <div class="width-container">
      <div class="page-title">CONTRACTE DE CONFIRMAT</div>
      <div class="home-page">
        <div class="main-content">

          <div class="profile-main-content" v-if="uploadedContracts">
            <div class="todo-title">Contracte de confirmat</div>
            <div
                  class="fara-clienti"
                  v-if="uploadedContracts.length === 0"
                >
                  Nu sunt contracte noi de confirmat
                </div>
            <table class="orders-table" v-if="uploadedContracts.length != 0">
              <col class="col-order-no" />
              <col class="col-order-name" />

              <col class="col-order-id" />
              <col class="col-order-ref" />

              <col class="col-order-more" />
              <tr class="products-table-head">
                <th class="col-order-no">ID</th>
                <th>Nume</th>

                <th>Email</th>
                <th>Telefon</th>

                <th>Contract</th>
                <th>Confirma</th>
              </tr>
              <tr
                class="products-table-item"
                
                v-for="contract in uploadedContracts"
                :key="contract.id"
              >
                <th>{{ contract.id }}</th>
                <th class="product-name">
                  {{ contract.nume }}
                  {{ contract.prenume }}
                </th>
                <th class="product-name">{{ contract.email }}</th>

                <th class="suma-tabel-contracte">
                  {{ contract.telefon }}
                </th>
                <th class="product-name"> <a :href="`${contract.contract_link}`" target="_blank">Vezi contract</a></th>
                <th @click="confirmaContract(contract.user_id)">Confirma</th>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, ref, computed, onUnmounted } from "vue";
import { supabase } from "../clients/supabase";
import { useStore } from "vuex";


export default {
  name: "AdminContracte",
  components: {},
  data() {
    return {
      showModal: false,
      error: false,
    };
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      getAllFirme();
      getAllRecruti()
      getInvitedByName();
      getInvitesOwned()
      getUploadedContracts()
    });

    onUnmounted(() => {
      resetThisFirma();
    });

    // formular



    // contracte

 
    const inviteMail = ref()
    const listaFirme = ref("");
    const totalRecruti = ref(0);


    const recomandariKey = ref(0);



    const userId = computed(() => {
      return store.getters["user/getUserId"];
    });

    const contracteStore = computed(() => {
      return store.getters["contracte/getAllContracte"];
    });


    const allClientsStore = computed(() => {
      return store.getters["clienti/getAllClients"];
    });

    const allFirmeStore = computed(() => {
      return store.getters["firme/getAllFirme"];
    });

    const invitedByName = computed(() => {
      return store.getters["user/getInvitedByName"];
    });

    const recommendedClientsStore = computed(() => {
      return store.getters["clienti/getRecommendedClients"];
    });

    const userData = computed(() => {
      return store.getters["user/getUserData"];
    });

    const allRecruti = computed(() => {
      return store.getters["user/getAllRecruti"];
    });

    const invitesOwned = computed(() => {
      return store.getters["user/getInvitesOwned"];
    });

    const uploadedContracts = computed(() => {
      return store.getters["user/getUploadedContracts"];
    });


    function resetThisFirma() {
      store.dispatch("firme/setThisFirma", null);
      
    }

    const confirmaContract = async (userIdContract) => {
        const { data, error } = await supabase.from("roles").update({ contract_status: 'confirmat' }).eq('user_id', userIdContract)
        if (error) throw error
        else {
            getUploadedContracts()
        }
        console.log(data)
    }

    const getAllRecruti = async () => {
      const arrayInvitati = ref([]);
      let roluri = {}

      try {
        const { data, error } = await supabase
          .from("roles")
          .select()
          .eq("invited_by", userId.value);
        if (error) throw error;
        
        arrayInvitati.value = data.map(({ user_id }) => user_id);
        totalRecruti.value = arrayInvitati.value.length;
        roluri = data
      } catch (error) {
        console.log(error);
      }

      let iduriInvitati = arrayInvitati.value.map((id) => id);
      
      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .in("agent", iduriInvitati);
        if (error) throw error;

        let incasariPerAgent = {}
        
        data.forEach((item) => {
          if(!Object.prototype.hasOwnProperty.call(incasariPerAgent, item.agent)) {
            incasariPerAgent[item.agent] = 0
          }

          incasariPerAgent[item.agent] += parseInt(item.total_incasat)
        })
        let final = roluri.map(item => {
          item.total_incasat = incasariPerAgent[item.user_id] ?? 0
          item.venit_incasat = item.total_incasat > 0 ? item.total_incasat / 10 : 0
          
          return item
        })
        store.dispatch("user/setAllRecruti", final);

      } catch (error) {
        console.log(error);
      }
    };

    const sendInvite = async () => {
      console.log(inviteMail.value)
      try {
        const { data, error } = await supabase.functions.invoke('send-invite', {
        body: { email: inviteMail.value }
     })
     console.log(data)
     if(error) {
      console.log(error)
     }
    }
     catch(error) {
      console.log(error)
     }
    
     
    }

    const getInvitedByName = async () => {
      try {
        const { data, error } = await supabase
          .from("roles")
          .select()
          .eq("user_id", userData.value.invited_by);
        if (error) throw error;
        
        store.dispatch("user/setInvitedByName", data[0].nume + ' ' + data[0].prenume);
      } catch (error) {
        console.log(error);
      }
    };

    const getUploadedContracts = async () => {
      try {
        const { data, error } = await supabase
          .from("roles")
          .select()
          .eq("contract_status", 'uploaded');
        if (error) throw error;
        
        store.dispatch("user/setUploadedContracts", data);
      } catch (error) {
        console.log(error);
      }
    };

    const getInvitesOwned = async () => {
      try {
        const { data, error } = await supabase
          .from("invites")
          .select()
          .eq("owner_id", userId.value);
        if (error) throw error;
        store.dispatch("user/setInvitesOwned", data)
        
      } catch (error) {
        console.log(error);
      }
    };

    // Send contract

    

    // Get firme

    const getAllFirme = async () => {
      try {
        const { data, error } = await supabase.from("firma").select("*");
        if (error) throw error;
        listaFirme.value = data;
        store.dispatch("firme/setAllFirme", data);
        
      } catch (error) {
        console.log(error);
      }
    };



    

    return {

      
      
      getAllFirme,
      listaFirme,
      invitedByName,
      uploadedContracts,
      userId,
      contracteStore,
      allClientsStore,
      allFirmeStore,
      recomandariKey,
      recommendedClientsStore,
      allRecruti,
      invitesOwned,
      sendInvite,
      inviteMail,
      confirmaContract

    };
  },
  created() {},
  methods: {},
};
</script>
