<template>
    <div class="signin-page">
    <video autoplay muted loop id="myVideo">
      <source src="../assets/img/vid2.mp4" type="video/mp4" />
    </video>
    <div class="signin-section-1">
      <h1 class="signin-header">-- -</h1>
      <img src="../../src/assets/img/arkana-color.png" class="login-logo-color">
      <h1 class="signin-description"><span class="signin-accent">secret knowledge</span><br>
Noun. arkana. arcana (<span class="secret-highlight">secret knowledge</span>)</h1>
        <div class="loading-text">Loading Data ...</div>
    </div>
  </div>
</template>

<script>
import { supabase } from "../../src/clients/supabase"
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from "vue-router"

export default {
  name: "LoginPage",
  components: {
   
  },
  data() {
    return {
      error: false,
    };
  },
  setup() {
    const router = useRouter()
    const store = useStore()

    const isAuth = computed(() => {
      return store.getters['user/isAuth']
    })

    const isAdmin = computed(() => {
      return store.getters['user/isAdmin']
    })
    const userRole = ref('')
    const userId = ref('')
    const email = ref('')
    const password = ref('')
    const firmaId = ref(0)


    const checkRole = async () => {
          const { data, error } = await supabase.from('roles').select().eq('user_id', userId.value)

          if(error) {
            console.log(error)
          }
          else {
            console.log('fromcheckrolelogin')
              console.log(data)
              store.dispatch('user/setInvitedBy', data[0].invited_by)
              if(data.length != 0) {
                if(data[0].firma_id != null) {
                  store.dispatch('user/setFirmaId', data[0].firma_id)
                  firmaId.value = data[0].firma_id
                  getFirmaName()
                }
                store.dispatch('user/setRole', data[0].role)
                store.dispatch('user/setUserData', data[0])
              } else {
                store.dispatch('user/setRole', 'agent')
                store.dispatch('user/setInvitedBy', data[0].invited_by)
                console.log('de la user Data')
                console.log(data[0])
                
              }
              
            
          }
        }

        const getFirmaName = async () => {
          const { data, error } = await supabase.from('firma').select().eq('id', firmaId.value)

          if(error) {
            console.log(error)
          }
          else {
            console.log(data)
            
                store.dispatch('user/setFirmaName', data[0].nume)
              
              
            
          }
        }

    watch(isAuth, (newValue) => {
      if(newValue === 'yes') {
        checkRole()
        router.push('/')
      }
      if(newValue === 'no' || newValue === null) {
        
        router.push('/login')
      }
    })

    return {
      userRole,
      isAuth,
      isAdmin,
      userId,
      email,
      password,
      store
    }

  },
  methods: {
    
  },
}
</script>

