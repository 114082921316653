s/* eslint-disable */
<template>
  <div class="page-container">
    <div class="width-container">
      <div class="page-title firma-order-title">
        <div>Client <span v-if="clientStatus === 'recomandat'">Recomandat</span>
        {{ clientNume + " " + clientPrenume }}</div> 
      </div>
      
      <div class="home-page">
        <div class="main-content">
          <div class="row-posibil-client">
            <div class="client-date-container">
              <div class="box-header-row">
                  <div class="todo-title">Informatii client</div>

                  <div class="arhiveaza-recomandare" @click="arhiveazaRecomandare()">Arhiveaza</div>
                  
                </div>
              <div class="detalii-client">
                <div class="profile-field">
                  <div class="profile-field-left">ID Client</div>
                  <div class="profile-field-right">{{ clientId }}</div>
                </div>
                <div class="profile-field">
                  <div class="profile-field-left">Nume</div>
                  <div class="profile-field-right">
                    {{ clientNume + " " + clientPrenume }}
                  </div>
                </div>

                <div class="profile-field">
                  <div class="profile-field-left">Telefon</div>
                  <div class="profile-field-right">{{ clientTelefon }}</div>
                </div>

                <div class="profile-field">
                  <div class="profile-field-left">Email</div>
                  <div class="profile-field-right">{{ clientEmail }}</div>
                </div>

                <div class="profile-field">
                  <div class="profile-field-left">Din partea</div>
                  <div class="profile-field-right"> <span v-if="clientDinPartea === null">-</span> <span v-if="clientDinPartea != null">{{ clientDinPartea }}</span></div>
                </div>

                <div class="detalii-comanda">
                  <div class="detalii-comanda-title">Detalii</div>
                  <div class="detalii-comanda-field">
                    {{ clientDetalii }}
                  </div>
                </div>
                <div class="notite-comanda">
                  <div class="detalii-comanda-title">Notite</div>
                  <textarea
                    rows="7"
                    cols="40"
                    v-model="clientNotite"
                    class="notite-textarea"
                    :key="notiteKey"
                  />
                </div>
                <div class="submit-contract-btn" @click="salveazaNotite()">
                  Salveaza
                </div>
              </div>
            </div>
            <div class="client-gestiune-container">
              <div class="todo-title">Genereaza contract</div>
              <form
                ref="contractform"
                @submit.prevent="submitContract"
                class="contract-form"
              >
                <div class="contract-row">
                  <div class="contract-top-left">
                    <div class="input-field">
                      <label>Serviciu</label>
                      <input type="text" v-model="formServiciu" class="contract-input-form" />
                    </div>
                    <div class="input-field contract-top-input">
                      <label>Suma RON</label>
                      <input
                        type="text"
                        v-model="formSuma"
                        @change="setComision()"
                        class="contract-input-form" 
                      />
                    </div>
                  </div>
                  <div class="contract-mid-group">
                    <div class="client-radio-group">
                      <div class="radio-field" @click="contactClient()">
                        <div class="radio-button">
                          <div
                            class="radio-button-filled"
                            v-if="oneTimeCheck"
                          ></div>
                        </div>
                        <div class="radio-label">One time</div>
                      </div>
                      <div class="radio-field" @click="contactFirma()">
                        <div class="radio-button">
                          <div
                            class="radio-button-filled"
                            v-if="recurentCheck"
                          ></div>
                        </div>
                        <div class="radio-label">Recurent</div>
                      </div>
                    </div>
                    <div class="durata-group" v-if="recurentCheck">
                      <div class="durata-contract-title">Durata</div>
                      <div class="input-field">
                        <input
                          type="number"
                          placeholder="0"
                          class="input-durata"
                          v-model="formDurata"
                        />
                      </div>

                      <div class="durata-percent">luni</div>
                    </div>
                  </div>
                  <div class="comision-group">
                    <div class="comision-contract-title">Comision</div>
                    <div class="comision-percent">{{ comision }} %</div>
                    <div class="comision-suma" v-if="formSuma">
                      {{ (formSuma * comision) / 100 }} RON
                    </div>
                  </div>
                </div>
                <div class="contract-top-right">
                  <div class="input-field">
                    <label>Detalii</label>
                    <textarea
                      rows="5"
                      cols="50"
                      v-model="formDetalii"
                      class="contract-textarea"
                    />
                  </div>
                </div>
                <div class="submit-contract-btn" @click="genereazaContract()">
                  Genereaza
                </div>
              </form>
            </div>
          </div>
          <div
            class="contracte-container"
            v-if="contracteStore && clientStatus === 'client'"
            :key="keyUpdate"
          >
            <div class="todo-title">Contracte</div>
            <div class="fara-clienti" v-if="contracteStore.length === 0">
              Nu aveti niciun contract pentru aceasta recomandare
            </div>
            <div class="overflow-wrapper">
              <table class="orders-table" v-if="contracteStore.length >= 1">
                <col class="col-order-no" />
                <col class="col-order-name" />

                <col class="col-order-id" />
                <col class="col-order-ref" />

                <col class="col-order-more" />
                <tr class="products-table-head">
                  <th class="col-order-no">ID</th>
                  <th>Client</th>

                  <th>Serviciu</th>
                  <th>Suma</th>
                  <th>Comision</th>

                  <th>Data</th>
                  <th>Tip</th>
                  <th>Status</th>
                </tr>
                <tr
                  v-for="contract in contracteStore"
                  :key="contract.id"
                  class="products-table-item"
                  @click="
                    activateModal(contract.client_id, contract.id);
                    showModal = true;
                  "
                >
                  <th class="client-cell">{{ contract.id }}</th>
                  <th class="product-name client-cell" v-if="allClientsComp">
                    {{
                      allClientsComp.find(
                        (ele) => ele.id === contract.client_id
                      ).prenume
                    }}
                    {{
                      allClientsComp.find(
                        (ele) => ele.id === contract.client_id
                      ).nume
                    }}
                  </th>

                  <th>{{ contract.serviciu }}</th>
                  <th class="suma-tabel-contracte client-cell">
                    {{ contract.suma.toLocaleString() }} RON
                  </th>
                  <th class="comision-tabel-contracte client-cell">
                    {{ contract.comision.toLocaleString() }} RON
                    <span v-if="contract.tip === 'recurentCheck'">/ luna</span>
                  </th>

                  <th class="data-tabel client-cell">
                    {{ contract.created_at.slice(0, 10) }}
                  </th>

                  <th
                    class="product-name client-cell"
                    v-if="contract.tip === 'oneTimeCheck'"
                  >
                    One Time
                  </th>
                  <th
                    class="product-name client-cell"
                    v-if="contract.tip === 'recurentCheck'"
                  >
                    Recurent
                    <span
                      v-if="
                        contract.tip === 'recurentCheck' &&
                        contract.status != 'finalizat'
                      "
                      class="rate-platite-tabel"
                      >{{ contract.rate_platite }} / {{ contract.durata }}</span
                    >
                    <span
                      v-if="
                        contract.tip === 'recurentCheck' &&
                        contract.status === 'finalizat'
                      "
                      class="rate-platite-final-tabel"
                      >{{ contract.rate_platite }} / {{ contract.durata }}</span
                    >
                  </th>
                  <th class="status-col client-cell">
                    <span v-if="contract.status === 'derulare'"
                      >In derulare</span
                    >
                    <span v-if="contract.status != 'derulare'">{{
                      contract.status
                    }}</span>
                    <div
                      class="status-confirmat"
                      v-if="contract.status === 'semnat'"
                    ></div>
                    <div
                      class="status-incasat"
                      v-if="contract.status === 'derulare'"
                    ></div>
                    <div
                      class="status-finalizat"
                      v-if="contract.status === 'finalizat'"
                    ></div>
                    <div
                      class="status-anulat"
                      v-if="contract.status === 'anulat'"
                    ></div>
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <vue-final-modal
        v-model="showModal"
        classes="modal-container"
        content-class="modal-content"
        v-if="modalContract"
        @closed="resetData()"
      >
        <div class="contract-item">
          <div
            class="modal-status-semnat"
            v-if="modalContract.status === 'semnat'"
          />
          <div
            class="modal-status-derulare"
            v-if="modalContract.status === 'derulare'"
          />
          <div
            class="modal-status-finalizat"
            v-if="modalContract.status === 'finalizat'"
          />
          <div
            class="modal-status-anulat"
            v-if="modalContract.status === 'anulat'"
          />
          <div class="profile-field">
            <div class="profile-field-left">Client</div>
            <div class="profile-field-right">{{ modalName }}</div>
          </div>
          <div class="profile-field">
            <div class="profile-field-left">Serviciu</div>
            <div class="profile-field-right">{{ modalContract.serviciu }}</div>
          </div>
          <div class="profile-field-detalii2" v-if="modalContract.detalii">
            <div class="profile-field-left">Detalii</div>
            <div class="profile-field-right-detalii2">
              {{ modalContract.detalii }}
            </div>
          </div>
          <div class="profile-field">
            <div class="profile-field-left">Data semnarii</div>
            <div class="profile-field-right">{{ modalDate }}</div>
          </div>
          <div class="profile-field">
            <div class="profile-field-left">Suma</div>
            <div class="profile-field-right">{{ modalContract.suma }} RON</div>
          </div>
          <div class="profile-field">
            <div class="profile-field-left">Comision</div>
            <div class="profile-field-right">
              {{ modalContract.comision }} RON<span
                v-if="modalContract.tip === 'recurentCheck'"
              >
                / luna</span
              >
            </div>
          </div>
          <div
            class="profile-field"
            v-if="modalContract.tip === 'recurentCheck'"
          >
            <div class="profile-field-left">Platit</div>
            <div class="profile-field-right">
              {{ modalContract.rate_platite }} / {{ modalContract.durata }}
            </div>
          </div>


          <div class="profile-field" v-if="modalContract.status === 'anulat'">
            <div class="profile-field-left">Data anularii</div>
            <div class="profile-field-right">{{ modalDate }}</div>
          </div>
          <div
            class="profile-field-agent"
            v-if="modalContract.status === 'anulat'"
          >
            <div class="profile-field-left">Motivul anularii</div>
            <div class="profile-field-right">
              {{ modalContract.motiv_anulare }}
            </div>
          </div>


        </div>
      </vue-final-modal>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { computed, onMounted, ref } from "vue";
import { supabase } from "../clients/supabase";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  name: "FirmaOrder",
  components: {},
  data() {
    return {
      showModal: false,
      error: false,
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const formServiciu = ref("");
    const formSuma = ref("");
    const formComision = ref("");
    const formContact = ref("oneTimeCheck");
    const formDetalii = ref("");
    const formDurata = ref(0);

    const allClients = ref("");
    const clientNume = ref("");
    const clientPrenume = ref("");
    const clientId = ref("");
    const clientEmail = ref("");
    const clientDetalii = ref("");
    const clientTelefon = ref("");
    const clientContact = ref("");
    const clientAgent = ref("");
    const clientNotite = ref("");
    const clientStatus = ref("");
    const clientDinPartea = ref("")
    const oneTimeCheck = ref(true);
    const recurentCheck = ref(false);
    const notiteKey = ref(0);

    const modalId = ref("");
    const contractId = ref("");
    const modalContract = ref("");
    const modalName = ref("");
    const modalDate = ref("");
    const modalRatePlatite = ref(0);
    const modalDurata = ref(0);
    const modalStatus = ref("");
    const rataNoua = ref(0);
    const keyUpdate = ref(0);

    const modalComision = ref(0);
    const modalTotalIncasat = ref(0);

    const anuleazaToggle = ref(false);

    const userId = computed(() => {
      return store.getters["user/getUserId"];
    });

    const firmaId = computed(() => {
      return store.getters["user/getFirmaId"];
    });

    const isAdmin = computed(() => {
      return store.getters["user/isAdmin"];
    });

    const comision = computed(() => {
      return store.getters["user/getComision"];
    });

    const contracteStore = computed(() => {
      return store.getters["contracte/getAllContracte"];
    });

    const allClientsComp = computed(() => {
      return store.getters["clienti/getAllClients"];
    });

    onMounted(() => {
      getAllClients();
      getRecommendedClient();
      getContracte();
    });

    function activateModal(id, contractid) {
      modalId.value = id;
      contractId.value = contractid;
      getModalInfo();
    }

    function anuleazaContractToggle() {
      anuleazaToggle.value = true;
      setTimeout(() => {
        anuleazaToggle.value = false;
      }, 3000);
    }

    function resetData() {
      modalContract.value = "";
      modalContract.value = "";
      modalName.value = "";
      modalDate.value = "";
      modalRatePlatite.value = "";
      modalDurata.value = "";
      modalStatus.value = "";
      modalComision.value = "";
      modalTotalIncasat.value = "";
    }

    const contractIncasat = async () => {
      if(firmaId.value === 2) {
        return
      }
      const statusUpdate = ref(false);
      const totalIncasatUpdate = ref(false);
      
      

      try {
        const { data, error } = await supabase
          .from("contract")
          .update({ status: "finalizat" })
          .eq("id", contractId.value)
          .select()
        if (error) throw error;
        console.log('from contract data')
        console.log(data);
        statusUpdate.value = true;

        getContracte();
        getModalInfo();
      } catch (error) {
        console.log(error);
      }
      try {
        const { data, error } = await supabase
          .from("contract")
          .update({ total_incasat: modalComision.value })
          .eq("id", contractId.value);
        if (error) throw error;

        console.log(data);
        totalIncasatUpdate.value = true;
        getContracte();
        getModalInfo();
      } catch (error) {
        console.log(error);
      }

      if (statusUpdate.value && totalIncasatUpdate.value) {
        store.commit("user/setToaster", "Contract finalizat!");
      }
    };

    const platesteTransa = async () => {
      if(firmaId.value === 2) {
        return
      }
      try {
        if (modalStatus.value === "semnat") {
          const { data, error } = await supabase
            .from("contract")
            .update({ status: "derulare" })
            .eq("id", contractId.value);

          getContracte();
          getModalInfo();
          if (error) throw error;
          console.log(data);
        }

        rataNoua.value = modalRatePlatite.value + 1;

        if (rataNoua.value < modalDurata.value && rataNoua.value != 0) {
          const { data, error } = await supabase
            .from("contract")
            .update({
              rate_platite: rataNoua.value,
              total_incasat: modalTotalIncasat.value + modalComision.value,
            })
            .eq("id", contractId.value);
          if (error) throw error;
          console.log(data);
          store.commit("user/setToaster", "Transa platita!");
          getContracte();
          getModalInfo();
        } else if (rataNoua.value === modalDurata.value) {
          const { data, error } = await supabase
            .from("contract")
            .update({
              rate_platite: rataNoua.value,
              status: "finalizat",
              total_incasat: modalTotalIncasat.value + modalComision.value,
            })
            .eq("id", contractId.value);
          if (error) throw error;
          console.log(data);
          store.commit(
            "user/setToaster",
            "Ultima transa platita! Contract finalizat!"
          );
          getContracte();
          getModalInfo();
        }
      } catch (error) {
        console.log(error);
      }
    };

    const anuleazaContract = async () => {
      if(firmaId.value === 2) {
        return
      }
      try {
        const { data, error } = await supabase
          .from("contract")
          .update({ status: "anulat" })
          .eq("id", contractId.value);
        if (error) throw error;
        console.log(data);
        store.commit("user/setToaster", "Contract anulat!");
        getContracte();
      } catch (error) {
        console.log(error);
      }
    };

    const getModalInfo = async () => {
      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("id", contractId.value);
        if (error) throw error;

        modalContract.value = data[0];
        modalName.value =
          allClientsComp.value.find((ele) => ele.id === modalId.value).prenume +
          " " +
          allClientsComp.value.find((ele) => ele.id === modalId.value).nume;
        modalDate.value = data[0].created_at.slice(0, 10);
        modalRatePlatite.value = data[0].rate_platite;
        modalDurata.value = data[0].durata;
        modalStatus.value = data[0].status;
        modalComision.value = data[0].comision;
        modalTotalIncasat.value = data[0].total_incasat;
      } catch (error) {
        console.log(error);
      }
    };

    function contactClient() {
      if (oneTimeCheck.value) {
        return
      } else {
        recurentCheck.value = false;
        oneTimeCheck.value = true;
        formDurata.value = null
      }
      formContact.value = "oneTimeCheck";
    }

    function contactFirma() {
      if (recurentCheck.value) {
        return
      } else {
        recurentCheck.value = true;
        oneTimeCheck.value = false;
      }
      formContact.value = "recurentCheck";
    }

    function setComision() {
      formComision.value = (formSuma.value * comision.value) / 100;
    }

    const getContracte = async () => {
      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("client_id", route.params.id)
          .order("created_at", { ascending: false });
        if (error) throw error;

        store.dispatch("contracte/setAllContracte", data);
      } catch (error) {
        console.log(error);
      }
    };

    const genereazaContract = async () => {
      if(firmaId.value === 2) {
        return
      }

      try {

        console.log(clientAgent.value)
        console.log(clientId.value)

        const { data: agent, error: agentError } = await supabase
        .from('roles')
        .select()
        .eq('user_id', clientAgent.value)

        
        console.log(agent)

      if(agentError) {
        console.log(agentError)
      }

        const { data: recomandare, error: recomandareError } = await supabase
        .from('client')
        .select()
        .eq('id', clientId.value)
        console.log('recomandare')
        console.log(recomandare)
        console.log(recomandare.agent_status)

      if(recomandareError) {
        console.log(recomandareError)
      }

      if(formSuma.value < 1) {
        store.commit("user/setToaster", "Va rugam introduceti suma!");
        return
      }

      if(formServiciu.value === '' || formServiciu.value === null) {
        store.commit("user/setToaster", "Va rugam introduceti serviciul!");
        return
      }

        const { error } = await supabase
          .from("contract")
          .insert({
            client_id: clientId.value,
            status: "semnat",
            firma: firmaId.value,
            agent: clientAgent.value,
            serviciu: formServiciu.value,
            suma: Number(formSuma.value),
            comision: Number(formComision.value),
            tip: formContact.value,
            detalii: formDetalii.value,
            durata: formDurata.value,
            agent_status: recomandare[0].agent_status
          })
          .select();

          getContracte()

        const { data: notificationData, error: notificationError } =
          await supabase.functions.invoke("send-notification", {
            body: {
              titlu: "Contract nou generat!",
              continut: `A fost generat un contract nou pentru ${recomandare[0].nume} ${recomandare[0].prenume}, in valoare de ${formSuma.value} RON. Comisionul dvs. este de ${formComision.value} RON`,
              email: agent[0].email,
            },
          });
        if (notificationData) {
          console.log(notificationData);
        }
        if (notificationError) {
          console.log(notificationError);
        }

        await getContracte();

        formServiciu.value = "";
        formSuma.value = "";
        formContact.value = "";
        formComision.value = "";
        formDetalii.value = "";
        formDurata.value = 0;
        recurentCheck.value = false;
        oneTimeCheck.value = false;

        store.commit("user/setToaster", "Contract generat cu succes!");
        if (error) throw error;
      } catch (error) {
        console.log(error);
      }
      try {
        const { data, error } = await supabase
          .from("client")
          .update({ status: "client" })
          .eq("id", clientId.value);
        if (error) throw error;
        console.log(data);
        
        await getContracte();
      } catch (error) {
        console.log(error);
      }
      keyUpdate.value += 1;
      await getContracte();
      getRecommendedClient()
    };

    const salveazaNotite = async () => {
      if(firmaId.value === 2) {
        return
      }
      try {
        const { data, error } = await supabase
          .from("client")
          .update({ notite: clientNotite.value })
          .eq("id", route.params.id);
        if (error) throw error;
        console.log(data);
        store.commit("user/setToaster", "Notite salvate cu succes!");
        notiteKey.value += 1;
      } catch (error) {
        console.log(error);
      }
    };

    const arhiveazaRecomandare = async () => {
      if(firmaId.value === 2) {
        return
      }
      try {
        const { data, error } = await supabase
          .from("client")
          .update({ status: 'arhivat' })
          .eq("id", route.params.id)
          .select()
        if (error) throw error;
        console.log(data);
        store.commit("user/setToaster", "Recomandare arhivata cu succes!");
        
      } catch (error) {
        console.log(error);
      }
    };

    const getAllClients = async () => {
      try {
        if (isAdmin.value) {
          const { data, error } = await supabase.from("client").select("*");
          if (error) throw error;
          allClients.value = data;
          store.dispatch("clienti/setAllClients", data);
          console.log(allClients.value);
        } else {
          const { data, error } = await supabase
            .from("client")
            .select()
            .eq("firma", firmaId.value)
            .order("created_at", { ascending: false });
          if (error) throw error;
          allClients.value = data;
          store.dispatch("clienti/setAllClients", data);
          console.log(allClients.value);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getRecommendedClient = async () => {
      try {
        if (isAdmin.value) {
          const { data, error } = await supabase
            .from("client")
            .select("")
            .eq("id", route.params.id);
          if (error) throw error;
          clientNume.value = data[0].nume;
          clientPrenume.value = data[0].prenume;
          clientId.value = data[0].id;
          clientEmail.value = data[0].email;
          clientDetalii.value = data[0].detalii;
          clientTelefon.value = data[0].telefon;
          clientAgent.value = data[0].agent_id;
          clientNotite.value = data[0].notite;
          clientStatus.value = data[0].status;
          clientDinPartea.value = data[0].din_partea
          console.log("testing agent");
          console.log(clientAgent.value);
          if (data[0].contact === "firmapeclient") {
            clientContact.value = "Firma contacteaza clientul";
          }
          if (data[0].contact === "clientpefirma") {
            clientContact.value = "Clientul contacteaza firma";
          }
        } else {
          const { data, error } = await supabase
            .from("client")
            .select()
            .eq("id", route.params.id);
          if (error) throw error;
          console.log(route.params.id);
          clientNume.value = data[0].nume;
          clientPrenume.value = data[0].prenume;
          clientId.value = data[0].id;
          clientEmail.value = data[0].email;
          clientDetalii.value = data[0].detalii;
          clientTelefon.value = data[0].telefon;
          clientAgent.value = data[0].agent_id;
          clientNotite.value = data[0].notite;
          clientStatus.value = data[0].status;
          clientDinPartea.value = data[0].din_partea
          if (data[0].contact === "firmapeclient") {
            clientContact.value = "Firma contacteaza clientul";
          }
          if (data[0].contact === "clientpefirma") {
            clientContact.value = "Clientul contacteaza firma";
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    return {
      getAllClients,
      getRecommendedClient,
      clientNume,
      clientPrenume,
      clientId,
      clientEmail,
      clientDetalii,
      clientTelefon,
      clientContact,
      clientAgent,
      clientDinPartea,
      formDetalii,
      allClients,
      userId,
      contactClient,
      contactFirma,
      comision,
      oneTimeCheck,
      recurentCheck,
      formContact,
      formServiciu,
      formSuma,
      formComision,
      genereazaContract,
      setComision,
      salveazaNotite,
      clientNotite,
      notiteKey,
      contracteStore,
      allClientsComp,
      activateModal,
      clientStatus,
      formDurata,
      anuleazaContract,
      platesteTransa,
      contractIncasat,
      modalContract,
      modalDate,
      modalName,
      anuleazaToggle,
      anuleazaContractToggle,
      keyUpdate,
      resetData,
      arhiveazaRecomandare
    };
  },
  created() {},
  methods: {
    setFirma(e) {
      console.log(e);
    },
  },
};
</script>
