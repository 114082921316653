/* eslint-disable */
<template>
  <div class="page-container">
    <div class="width-container">
      <div class="page-title">Arhiva Bounties</div>
      <div class="home-page">
        <div class="main-content">
          <div class="profile-main-content" v-if="arhivaBounties">
            <div class="todo-title">Arhiva Bounties</div>
            <div
                  class="fara-clienti"
                  v-if="arhivaBounties.length === 0"
                >
                  Nu exista niciun bounty in arhiva
                </div>
            <table class="orders-table" v-if="arhivaBounties.length != 0">
              <col class="col-order-no" />
              <col class="col-order-name" />

              <col class="col-order-id" />
              <col class="col-order-ref" />

              <col class="col-order-more" />
              <tr class="products-table-head">
                <th class="col-order-no">ID</th>
                <th>Firma</th>

                <th>Premiu</th>
                <th>Calificati</th>

                <th>Castigator</th>
                <th>Data extragerii</th>
              </tr>
              <tr
                class="products-table-item"
                
                v-for="bounty in arhivaBounties"
                :key="bounty.id"
              >
                <th>{{ bounty.id }}</th>
                <th class="product-name" v-if="allFirmeStore">
                    {{
                        allFirmeStore.find((ele) => ele.id === bounty.firma).nume
                      }}
                  
                </th>
                <th class="product-name">{{ bounty.bonus }} RON</th>

                <th class="product-name">
                  <span v-if="bounty.calificati">{{ bounty.calificati.length }}</span> / 10 
                </th>
                <th class="suma-tabel-contracte" v-if="allNames">{{ allNames.find((ele) => ele.user_id === bounty.castigator).nume }} {{ allNames.find((ele) => ele.user_id === bounty.castigator).prenume }}</th>
                <th>{{ bounty.deadline}}</th>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, ref, computed, onUnmounted } from "vue";
import { supabase } from "../clients/supabase";
import { useStore } from "vuex";


export default {
  name: "CompaniePage",
  components: {},
  data() {
    return {
      showModal: false,
      error: false,
    };
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      getAllFirme();
      
      getInvitedByName();
      getAllNames()
      getArhivaBounties()
    });

    onUnmounted(() => {
      resetThisFirma();
    });

    // formular



    // contracte

 
    const inviteMail = ref()
    const listaFirme = ref("");
    


    const recomandariKey = ref(0);



    const userId = computed(() => {
      return store.getters["user/getUserId"];
    });

    const contracteStore = computed(() => {
      return store.getters["contracte/getAllContracte"];
    });


    const allClientsStore = computed(() => {
      return store.getters["clienti/getAllClients"];
    });

    const allFirmeStore = computed(() => {
      return store.getters["firme/getAllFirme"];
    });

    const invitedByName = computed(() => {
      return store.getters["user/getInvitedByName"];
    });

    const recommendedClientsStore = computed(() => {
      return store.getters["clienti/getRecommendedClients"];
    });

    const userData = computed(() => {
      return store.getters["user/getUserData"];
    });

    const allRecruti = computed(() => {
      return store.getters["user/getAllRecruti"];
    });

    const invitesOwned = computed(() => {
      return store.getters["user/getInvitesOwned"];
    });

    const arhivaBounties = computed(() => {
      return store.getters["user/getArhivaBounties"];
    });

    const allNames = computed(() => {
      return store.getters["user/getAllNames"];
    });

    function resetThisFirma() {
      store.dispatch("firme/setThisFirma", null);
      
    }

    const getArhivaBounties = async () => {

      try {
        const { data, error } = await supabase
          .from("bounties")
          .select()
          .eq('status', 'terminat')
          
        if (error) throw error;
        
        store.dispatch('user/setArhivaBounties', data)
      } catch (error) {
        console.log(error);
      }
    };

    const sendInvite = async () => {
      console.log(inviteMail.value)
      try {
        const { data, error } = await supabase.functions.invoke('send-invite', {
        body: { email: inviteMail.value }
     })
     console.log(data)
     if(error) {
      console.log(error)
     }
    }
     catch(error) {
      console.log(error)
     }
    
     
    }

    const getInvitedByName = async () => {
      try {
        const { data, error } = await supabase
          .from("roles")
          .select()
          .eq("user_id", userData.value.invited_by);
        if (error) throw error;
        
        store.dispatch("user/setInvitedByName", data[0].nume + ' ' + data[0].prenume);
      } catch (error) {
        console.log(error);
      }
    };

    const getAllNames = async () => {
      try {
        const { data, error } = await supabase
          .from("roles")
          .select('nume, prenume, user_id')
          
        if (error) throw error;
        store.dispatch("user/setAllNames", data)
        
      } catch (error) {
        console.log(error);
      }
    };

    // Send contract

    

    // Get firme

    const getAllFirme = async () => {
      try {
        const { data, error } = await supabase.from("firma").select("*");
        if (error) throw error;
        listaFirme.value = data;
        store.dispatch("firme/setAllFirme", data);
        
      } catch (error) {
        console.log(error);
      }
    };



    

    return {

      
      
      getAllFirme,
      listaFirme,
      invitedByName,
      arhivaBounties,
      userId,
      contracteStore,
      allClientsStore,
      allFirmeStore,
      recomandariKey,
      recommendedClientsStore,
      allRecruti,
      invitesOwned,
      sendInvite,
      inviteMail,
      allNames

    };
  },
  created() {},
  methods: {},
};
</script>
