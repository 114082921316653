<template>
    <div class="signin-page">

      <div class="signin-section-1">
        <div class="aplica-spacer">

        </div>
        <img
          src="../../src/assets/img/vissia-logo2.png"
          class="login-logo-color"
        />
        <div class="aplicatie-firma">
            Aplica pentru listare firma
        </div>

        <div class="success-apply" v-if="successOn">
            Aplicatie inregistrata cu succes!
        </div>
  
        <form ref="form" @submit.prevent="onSubmit" class="login-form" v-if="!successOn">
            <div class="input-field">
            <label>Nume firma</label>
            <input type="text" v-model="nume_firma" />
          </div>
          <div class="input-field">
            <label>Nume SRL</label>
            <input type="text" v-model="nume_srl" />
          </div>
          <div class="input-field">
            <label>Domeniu</label>
            <input type="text" v-model="domeniu" />
          </div>
          <div class="input-field">
            <label>Descriere firma</label>
            <textarea type="text" v-model="descriere" class="contract-textarea2" />
          </div>
          <div class="input-field">
            <label>Servicii / produse oferite</label>
            <textarea type="text" v-model="servicii" class="contract-textarea2" />
          </div>
          <div class="input-field">
            <label>Ce comision sunteti dispus sa oferiti? (%)</label>
            <input type="text" v-model="comision" />
          </div>
          <div class="input-field">
            <label>Nume persoana de contact</label>
            <input type="text" v-model="persoana_contact" />
          </div>
          <div class="input-field">
            <label>Telefon contact</label>
            <input type="text" v-model="telefon_contact" />
          </div>
          <div class="input-field">
            <label>Email contact</label>
            <input type="email" v-model="email_contact" />
          </div>
          <div class="error-login-row">
            <div class="error-text">
              <span v-if="emailPassError"
                >Trebuie sa completati toate campurile!</span
              >
              <span v-if="emailPassWrong">Email sau parola gresita!</span>
            </div>
          </div>
          <button type="submit" class="signin-button" @click.prevent="trimiteAplicatie()">
            Trimite aplicatie
          </button>
  

        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { supabase } from "../../src/clients/supabase";
  import { ref, computed, onMounted } from "vue";
  import { useStore } from "vuex";
import router from '@/router';

  
  export default {
    name: "AplicaFirma",
    components: {},
    data() {
      return {
        error: false,
      };
    },
    setup() {
      const store = useStore();

  
      const isAuth = computed(() => {
        return store.getters["user/isAuth"];
      });
  
      const isAdmin = computed(() => {
        return store.getters["user/isAdmin"];
      });
  
      onMounted(() => {

        
      })
  
      const userRole = ref("");
      const userId = ref("");
      const nume_firma = ref('')
      const nume_srl = ref('')
      const domeniu = ref('')
      const descriere = ref('')
      const servicii = ref('')
      const comision = ref('')
      const persoana_contact = ref('')
      const telefon_contact = ref('')
      const email_contact = ref('')
      const successOn = ref(false)
      
      
      const emailPassError = ref(false);
      const emailPassWrong = ref(false);
  

    

  
    
  
      const trimiteAplicatie = async () => {
        if(nume_firma.value === '' || nume_srl.value === '' || domeniu.value === '' || descriere.value === '' || servicii.value === '' || comision.value === '' || persoana_contact.value === '' || telefon_contact.value === '' || email_contact.value === '') {
            emailPassError.value = true
            setTimeout(() => {
                emailPassError.value = false
            }, 3000);
            return
        }
        const { data, error } = await supabase
          .from("aplicatiifirma")
          .insert({
            nume_firma: nume_firma.value,
            nume_srl: nume_srl.value,
            domeniu: domeniu.value,
            descriere: descriere.value,
            servicii: servicii.value,
            comision: comision.value,
            persoana_contact: persoana_contact.value,
            telefon_contact: telefon_contact.value,
            email_contact: email_contact.value
          })
          
  
        if (error) {
          console.log(error);
        } else {
            nume_firma.value = ''
            nume_srl.value = ''
            domeniu.value = ''
            descriere.value = ''
            servicii.value = ''
            comision.value = ''
            persoana_contact.value = ''
            telefon_contact.value = ''
            email_contact.value = ''

          console.log(data);
          successOn.value = true
          setTimeout(() => {
            successOn.value = false
            router.push('/login')
          }, 3000);
          
  
          
        }
      };
  

  
      return {
        userRole,

        isAuth,
        isAdmin,
        userId,
        successOn,
        store,
        emailPassError,
        emailPassWrong,
        nume_firma,
        nume_srl,
        domeniu,
        descriere,
        servicii,
        comision,
        persoana_contact,
        telefon_contact,
        email_contact,
        trimiteAplicatie
      };
    },
    methods: {},
  };
  </script>
  