/* eslint-disable */
<template>
  <div class="page-container">
    
    <div class="boarding-container">
      <div class="b-header-img">
        <img src="../assets/img/vissia-logo2.png" class="b-header-logo-img">
      </div>
      <div class="b-row">
        <div class="b-half">
          <div class="b-header-title">
              Ce este Vissia?
            </div>
            <div class="b-subheader">
              Vissia este prima platforma cu circuit
              inchis de affiliate marketing offline
              din Romania
            </div>
            <div class="b-line-sep">

            </div>
            <div class="b-section-title">
              Recomandari de cea mai buna calitate
            </div>
            <div class="b-section-text">
              Cele mai bune lead-uri pe care le poate primi o companie
              sunt recomandarile directe, din gura in gura. Pe platforma
              Vissia primesti doar astfel de recomandari de la utilizatorii nostri.
            </div>
            <div class="b-section-title-margin-top">
              Dashboard
            </div>
            <div class="b-section-text">
              Fiecare firma are dashboard-ul ei, de unde poate accesa recomandarile, genera contractele si gestiona clientii primite de la utilizatorii Vissia.
            </div>
            <div class="b-section-title-margin-top">
              Comisionul se plateste doar dupa incasare
            </div>
            <div class="b-section-text">
              Platesti comision doar pentru recomandarile care se transforma in clienti. 
            </div>
            
        </div>
        <div class="b-half2">
          <img src="../assets/img/vissia-login-img.png" class="login-img-b">
          <div class="b-hi-title">
            Circuit inchis
          </div>
          <div class="b-hi-text">
            Vissia este o platforma ce se poate accesa
            doar cu invitatie. Avem un numar limitat de
            maximum 100 de firme partenere si peste 3000
            de utilizatori care genereaza lead-uri.
            <br><br>
            Am abordat un concept de tip 'boutique', in
            care calitatea serviciilor si produselor este
            foarte importanta pentru noi.
          </div>
        </div>

      </div>
      <div class="row-separator"></div>
      <div class="b-row2">
       <div class="b-half-j">
        <div class="b-section-title-j">
          Cum functioneaza?
        </div>
        <div class="pas1left" @click="pasSelectat = 1" :class="{selectedpasonboarding: pasSelectat === 1}">
          <span class="pasnr">1</span> Primesti o recomandare
        </div>
        <div class="pas1left" @click="pasSelectat = 2" :class="{selectedpasonboarding: pasSelectat === 2}">
          <span class="pasnr">2</span> Contactezi recomandarea primita
        </div>
        <div class="pas1left" @click="pasSelectat = 3" :class="{selectedpasonboarding: pasSelectat === 3}">
          <span class="pasnr">3</span> Generezi contract
        </div>
        <div class="pas1left" @click="pasSelectat = 4" :class="{selectedpasonboarding: pasSelectat === 4}">
          <span class="pasnr">4</span> Platesti comisionul
        </div>
       </div>
       <div class="b-half2">
        <div class="pas1" v-if="pasSelectat === 1">
          <div class="pas-title">
            Primesti o recomandare
          </div>
          <div class="pas-text">
            Utilizatorii Vissia trimit recomandari catre firma ta. Recomandarile contin informatii de contact si detalii privind persoana interesata de serviciile sau produsele tale. In dashboard poti vedea toate recomandarile primite, in timp real.
          </div>
          <viewer class="pas-img" v-viewer :options="options">
            <img src="../assets/img/onboarding-recomandari.png" class="onboarding-recomandari-img">
          </viewer>
         
        </div>
        <div class="pas1" v-if="pasSelectat === 2">
          <div class="pas-title">
            Contactezi recomandarea primita
          </div>
          <div class="pas-text">
            Folosesti datele primite in recomandare pentru a contacta clientul si a finaliza vanzarea. Poti salva notite importante pentru fiecare recomandare sau client.
          </div>
          <viewer class="pas-img2" v-viewer :options="options">
            <img src="../assets/img/onboarding-pas2.png" class="onboarding-recomandari-img2">
            
            
          </viewer>
         
        </div>
        <div class="pas1" v-if="pasSelectat === 3">
          <div class="pas-title">
            Generezi contract
          </div>
          <div class="pas-text">
            Generati contractul pe platforma Vissia care sa reflecte serviciul si suma din contractul real. Comisionul se calculeaza automat. Daca este un contract recurent, sau in mai multe transe, puteti selecta numarul de luni/transe.
          </div>
          <viewer class="pas-img2" v-viewer :options="options">
            <img src="../assets/img/onboarding-pas3.png" class="onboarding-recomandari-img2">
            
            
          </viewer>
         
        </div>
        <div class="pas1" v-if="pasSelectat === 4">
          <div class="pas-title">
            Platesti comisionul
          </div>
          <div class="pas-text">
            Vissia iti factureaza comisionul calculat automat pe platforma si il plateste utilizatorului care a facut recomandarea.
          </div>
          <viewer class="pas-img2" v-viewer :options="options">
            <img src="../assets/img/onboarding-pas4.jpg" class="onboarding-recomandari-img4">
            
            
          </viewer>
         
        </div>
       </div>
      </div>
      <div class="row-separator"></div>
      <div class="b-row3">
        <div class="b-section-title-j">
          Pagina firmei
        </div>
        <div class="b-inner-row">
          <viewer class="pag-firmei-img" v-viewer :options="options">
            <img src="../assets/img/onboarding-firma-page.png" class="onboarding-pag-firmei-img">
            
            
          </viewer>
          <div class="b-left-col">
            <div class="b-pag-title">
              Pagina firmei este vizibila pentru toti utilizatorii platformei Vissia si contine informatii esentiale pentru generarea de recomandari
            </div>
            <div class="b-pag-text">
              Aceasta pagina contine:
            </div>
            
              <div class="b-list-item">Datele de contact</div>
              <div class="b-list-item">Comisionul oferit</div>
              <div class="b-list-item">Materiale de marketing (prezentari, logo, etc.)</div>
              <div class="b-list-item">Descrierea firmei si a serviciilor sau produselor oferite</div>
              <div class="b-list-item">Recomandarile firmei cu privire la modalitatea de promovare catre potentialii clienti</div>
              <div class="b-list-item">Punctele forte (ce anume diferentiaza firma de alte companii din domeniu)</div>
              <div class="b-list-item">Exemplu de incasari concret, pentru ca utilizatorii sa isi faca o idee mai clara despre potentialul de venituri pe care il poate genera din recomandarile trimise catre firma respectiva</div>
            
          </div>
          
        </div>
        
      </div>
      <div class="b-demo-section">
        <div class="b-demo-title">
          Vezi cont demo
        </div>
        <div class="b-demo-subtitle">
          Intra pe contul nostru demo sa vizualizezi cum arata dashboard-ul unei firme pe platforma Vissia
        </div>
        <a href="https://vissia.ro/demologin" target="_blank">
        <div class="b-demo-button">
          Dashboard firma
        </div>
      </a>
      </div>
      <div class="b-row4">
        <div class="b-buy-title">
          Inscrie-te acum
        </div>
        <div class="b-buy-subtitle">
          Listeaza-ti firma pe cea mai buna platforma de generare de lead-uri!
        </div>
        <div class="b-card-row">
        <div class="b-buy-card">
          <div class="b-buy-price">
            600 <span class="b-ron">RON</span>
          </div>
          <div class="b-buy-time">
            / 3 luni
          </div>
          <div class="b-logo-card">
            <img src="../assets/img/iconimg.png" class="b-logo-img">
          </div>
          <a href="https://pay.vissia.ro/b/bIY8A8d1V97Igwg3cf" target="_blank">
          <div class="b-buy-button">
            Inregistreaza firma
          </div>
        </a>
        </div>
        <div class="b-card-right">
          <div class="b-pas-buy">
           <span class="b-pas-buy-nr">1</span> Cumperi abonamentul pe 3 luni
          </div>
          <div class="b-pas-buy">
            <span class="b-pas-buy-nr">2</span> Te contactam pentru detaliile firmei
          </div>
          <div class="b-pas-buy">
            <span class="b-pas-buy-nr">3</span> Firma ta devine listata pe platforma si incepi sa primesti recomandari
          </div>
          <div class="b-pas-buy">
            <span class="b-pas-buy-nr">4</span> Transformi recomandarile in clienti si iti cresti vanzarile
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "OnboardingFirmaPage",
  components: {},
  data() {
    return {
      options: {
        toolbar: false,
        navbar: false,
        title: false,
        movable: false
      },
      pasSelectat: 1
    };
  },
  created() {},
  methods: {},
};
</script>
