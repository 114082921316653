/* eslint-disable */
<template>
  <div class="page-container">
   <div class="despre-container">
    <div class="vissia-logo">
      <img src="../assets/img/vissia-logo2.png" class="vissia-about-logo">
    </div>
    <div class="vissia-about-text" v-if="userData && userData.language === 'romana'">
      Prima platforma de affiliate marketing offline din Romania cu circuit inchis
    </div>
    <div class="vissia-about-text" v-if="userData && userData.language === 'english'">
      The first invite-only offline affiliate marketing platform in Romania
    </div>

      <div class="section-2">
      <div class="title-row">
      <div class="section-title" v-if="userData && userData.language === 'romana'">PENTRU UTILIZATORI</div>
      <div class="section-title" v-if="userData && userData.language === 'english'">FOR USERS</div>
      <div class="line-separator"></div>
    </div>
        <div class="row-s1">
          <div class="s2-left">
            <img src="../assets/img/users-hp.jpg" class="s2-img">
          </div>
          <div class="s2-right">
            <div class="section-text-serv" v-if="userData && userData.language === 'romana'">
              Cu totii recomandam diverse servicii si produse prietenilor, cunoscutilor sau diverselor persoane cu care intram in contact in diferite situatii sau contexte. Rareori, sau poate chiar niciodata nu monetizam aceste recomandari. Acum poti comisiona toate aceste recomandari prin platforma Vissia. 

              <br /><br />Ca utilizator Vissia, ai la dispozitie un arsenal de servicii si produse diverse de la peste 100 firme din multiple domenii. Aceste firme iti platesc un comision din clientii sau vanzarile care se materializeaza din recomandarile trimise de tine. 
              <br> <br> Deasemenea, primesti 10% din tot ce incaseaza utilizatorii pe care i-ai invitat tu pe platforma.
              
            </div>
            <div class="section-text-serv" v-if="userData && userData.language === 'english'">
              We all recommend different services or products to friends, acquaintances or even random people that we encounter in social gatherings. We rarely, or even never, monetize on all these recommendations. Now you can earn a commission on them through our platform. 

              
              <br /><br />As a Vissia user, you have an arsenal of services and products from over 100 different companies at your finger tips. These companies pay you a commission from the clients or sales generated by your recommendations. 
              <br> <br> You also earn 10% from all the commissions earned by the people you invite on the platform.
            </div>
            
          </div>
        </div>

        
      </div>
      <div class="services-items-row" v-if="userData && userData.language === 'romana'">
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Oferta generoasa de firme
          </div>
        </div>
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Comisioane atractive
          </div>
        </div>
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Servicii si produse diverse
          </div>
        </div>
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Plata automata
          </div>
        </div>
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Bonusuri consistente
          </div>
        </div>
      </div>
      <div class="services-items-row" v-if="userData && userData.language === 'english'">
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Large database of companies
          </div>
        </div>
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Big commissions
          </div>
        </div>
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Diverse selection of services and producs
          </div>
        </div>
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Automated payout
          </div>
        </div>
        <div class="services-item">
          <div class="item-icon">
            &#10003;
          </div>
          <div class="item-text">
            Attractive bonuses
          </div>
        </div>
      </div>
   </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "AboutPage",
  components: {},
  data() {
    return {};
  },
  setup() {
    const store = useStore();
    const userData = computed(() => {
      return store.getters["user/getUserData"];
    });

    return {
      userData
    }
  },
  created() {},
  methods: {},
};
</script>
