


export default {
    namespaced: true,
    state: {
        
        allClients: null,
        recommendedClients: null,
        clientClients: null,
        archivedClients: null
    },
    getters: {
        getAllClients(state) {
           return state.allClients
        },
        getRecommendedClients(state) {
            return state.recommendedClients
         },
         getArchivedClients(state) {
            return state.archivedClients
         },
         getClientClients(state) {
            return state.clientClients
         },
    },
    mutations: {
        setAllClients(state, response) {
            state.allClients = response

        },
        setRecommendedClients(state, response) {
            state.recommendedClients = response

        },
        setArchivedClients(state, response) {
            state.archivedClients = response

        },
        setClientClients(state, response) {
            state.clientClients = response

        },
        setReset(state, response) {
            if(response === 'resetAll') {
                state.allClients = null
                state.recommendedClients = null
                state.clientClients = null
            }
            
        }
    },
    actions: {
        setAllClients({ commit }, payload) {
            commit('setAllClients', payload)

            
        },
        setRecommendedClients({ commit }, payload) {
            commit('setRecommendedClients', payload)

            
        },

        setArchivedClients({ commit }, payload) {
            commit('setArchivedClients', payload)

            
        },

        setClientClients({ commit }, payload) {
            commit('setClientClients', payload)

            
        },
        reset({ commit }, payload) {
            if(payload === 'resetAll') {
                commit('setReset', 'resetAll')
            }
            
        }
    }
}