<template>
    <div class="signin-page">
      <div class="login-video-wrapper" v-if=!loginProceed>
        <img src="../assets/img/vissia-logo2.png" class="login-logo-color">
        <div class="login-video-title">
          
          <span class="signup-header-title">Daca te afli aici inseamna ca ai fost invitat pe platforma Vissia!</span> 
          <br><br> <span class="signup-header-title2">Inainte sa iti creezi contul, iti recomandam sa urmaresti clipul de mai jos pentru a vedea foarte pe scurt despre ce e vorba si cum functioneaza aceasta platforma.</span>
          <br><br><span class="signup-header-title3"> Daca nu doresti sa vizionezi filmuletul acum, o poti face oricand din sectiunea Tutoriale, dupa ce iti creezi contul.</span>
        </div>
        <div class="proceed-btn" @click="loginProceed = true">
        Creeaza cont
      </div>
        <div class="video-bg-signup">

        
      <video-player 
      src="https://kmgppqgrgckdzebbtvqy.supabase.co/storage/v1/object/public/images/agentclipfinal.mp4"
      controls
      class="vjs-big-play-centered"
      :fluid="true"
      width=100%
      height=100%
      />
    </div>
      
    </div>
    <div class="signin-section-1" v-if="loginProceed">

      <img src="../../src/assets/img/vissia-logo2.png" class="login-logo-color">

      <form ref="form" @submit.prevent="onSubmit" class="login-form" v-if="inviteCorrect === 'true' && signupSuccess != 'true'">
        <div class="input-field">
          <label>Email</label>
          <input type="email" v-model="email" disabled />
        </div>
        <div class="input-field">
          <label>Nume</label>
          <input type="text" v-model="nume" />
        </div>
        <div class="input-field">
          <label>Prenume</label>
          <input type="text" v-model="prenume" />
        </div>
        
        <div class="input-field">
          <label>Parola</label>
          <input type="password" v-model="password" />
        </div>
        <div class="input-field">
          <label>Confirma Parola</label>
          <input type="password" v-model="password2" />
        </div>

        <button type="submit" class="signin-button" @click.prevent="signup()">Creeaza cont</button>

        
      </form>
      <div class="invite-signupsuccess" v-if="signupSuccess === 'true'">
        Cont creat cu success! Veti fi redirectionat catre pagina de login in urmatoarele 3 secunde!
        <br> Daca nu doriti sa asteptati sau sunteti redirectionat dupa cele 3 secunde, va rugam apasati <a href="https://vissia.ro/login">aici</a>.
      </div>
      <div class="invite-loading" v-if="inviteCorrect === 'loading'">
        Loading...
      </div>
      <div class="invite-incorrect" v-if="inviteCorrect === 'false'">
        Ne pare rau, codul invitatiei este incorect sau invitatia a expirat!
      </div>
    </div>
  </div>
</template>

<script>
import { supabase } from "../../src/clients/supabase"
import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from "vue-router";

export default {
  name: "LoginPage",
  components: {
   
  },
  data() {
    return {
      error: false,
    };
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const isAuth = computed(() => {
      return store.getters['user/isAuth']
    })

    const isAdmin = computed(() => {
      return store.getters['user/isAdmin']
    })
    const userRole = ref('')
    const userId = ref('')
    const email = ref('')
    const password = ref('')
    const password2 = ref('')
    const nume = ref('')
    const prenume = ref('')
    const signupSuccess = ref('')
    
    const firmaId = ref(0)
    const inviteCorrect = ref('loading')
    const inviteOwner = ref()

    const loginProceed = ref(false)
    
    onMounted(() => {
      console.log(route.query.hash)
      checkHash()
      
    })

    const checkHash = async () => {
      let hash = route.query.hash
      const { data, error } = await supabase.from('invites').select().eq('hash', hash)
      console.log(data)
      if(data && data.length > 0) {
        
        inviteCorrect.value = 'true'
        email.value = data[0].invitee_email
        if(data[0].owner_id) {
          inviteOwner.value = data[0].owner_id
        }
        
        
      }
      else {
        inviteCorrect.value = 'false'
        return
      }

      if (error) {
        console.log(error)
        inviteCorrect.value = 'false'
      }

    }

    const signup = async () => {
      console.log(email.value)
      if(password2.value !== password.value || password2.value === '' || password.value === '') {
        console.log('Passwords do not match!')
        return
      }
      if(email.value === null) {
        console.log('No email entered!')
        return
      }
      let hash = route.query.hash
      const { data, error } = await supabase.functions.invoke('signup', {
        body: { email: email.value, password: password.value, hash: hash, nume: nume.value, prenume: prenume.value }
     })

     if(data) {
      signupSuccess.value = 'true'
      setTimeout(() => {
        
        router.push('/login')
      }, 3000);

      
     }
      if (error) {
        console.log(error)
      }
      
      
    }

    const checkRole = async () => {
          const { data, error } = await supabase.from('roles').select().eq('user_id', userId.value)

          if(error) {
            console.log(error)
          }
          else {
            console.log('fromcheckrolelogin')
              console.log(data)
              store.dispatch('user/setInvitedBy', data[0].invited_by)
              if(data.length != 0) {
                if(data[0].firma_id != null) {
                  store.dispatch('user/setFirmaId', data[0].firma_id)
                  firmaId.value = data[0].firma_id
                  getFirmaName()
                }
                store.dispatch('user/setRole', data[0].role)
                store.dispatch('user/setUserData', data[0])
              } else {
                store.dispatch('user/setRole', 'agent')
                store.dispatch('user/setInvitedBy', data[0].invited_by)
                console.log('de la user Data')
                console.log(data[0])
                
              }
              
            
          }
        }

        const getFirmaName = async () => {
          const { data, error } = await supabase.from('firma').select().eq('id', firmaId.value)

          if(error) {
            console.log(error)
          }
          else {
            console.log(data)
            
                store.dispatch('user/setFirmaName', data[0].nume)
              
              
            
          }
        }

    watch(isAuth, (newValue) => {
      if(newValue === true) {
        checkRole()
      }
    })

    return {
      userRole,
      signup,
      isAuth,
      isAdmin,
      userId,
      email,
      password,
      password2,
      store,
      nume,
      prenume,
      inviteCorrect,
      signupSuccess,
      loginProceed
    }

  },
  methods: {
    
  },
}
</script>

