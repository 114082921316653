<template>
    <div class="signin-page">
      <video autoplay muted loop id="myVideo" class="video-login">
        <source src="../assets/img/vid2.mp4" type="video/mp4" />
      </video>
      <div class="signin-section-1">
        <img
          src="../../src/assets/img/vissia-logo2.png"
          class="login-logo-color"
        />
  
        <form ref="form" @submit.prevent="onSubmit" class="login-form">
          <div class="input-field">
            <label>Email</label>
            <input type="email" v-model="email" disabled />
          </div>
          <div class="input-field">
            <label>Parola</label>
            <input type="password" v-model="password" disabled />
          </div>
          <div class="error-login-row">
            <div class="error-text">
              <span v-if="emailPassError"
                >Trebuie sa introduceti email si parola!</span
              >
              <span v-if="emailPassWrong">Email sau parola gresita!</span>
            </div>
          </div>
          <button type="submit" class="signin-button" @click.prevent="login()">
            Login
          </button>
  
          <div class="row-forgot">
            <div class="forgot" @click="seeCurrentUser()">Am uitat parola</div>
            <div class="apply">Aplica pentru membership</div>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { supabase } from "../../src/clients/supabase";
  import { ref, computed, watch, onMounted } from "vue";
  import { useStore } from "vuex";

  
  export default {
    name: "LoginPageDemo",
    components: {},
    data() {
      return {
        error: false,
      };
    },
    setup() {
      const store = useStore();

  
      const isAuth = computed(() => {
        return store.getters["user/isAuth"];
      });
  
      const isAdmin = computed(() => {
        return store.getters["user/isAdmin"];
      });
  
      onMounted(() => {

        
      })
  
      const userRole = ref("");
      const userId = ref("");
      const email = ref("demofirma@vissia.ro");
      const password = ref("demofirma");
      const session_token = ref("");
      const firmaId = ref(0);
      const emailPassError = ref(false);
      const emailPassWrong = ref(false);
  
      const login = async () => {
        if (email.value === "" || password.value === "") {
          emailPassError.value = true;
          setTimeout(() => {
            emailPassError.value = false;
          }, 3000);
          return;
        }
        const { data, error } = await supabase.auth.signInWithPassword({
          email: email.value,
          password: password.value,
        });
        if (error) {
          console.log(error);
          emailPassWrong.value = true;
  
          setTimeout(() => {
            emailPassWrong.value = false;
          }, 3000);
        } else {
          console.log(data);
          userId.value = data.user.id;
          session_token.value = data.session.access_token;
          store.dispatch("user/signInToken", data.session.access_token);
          console.log(data.user.id);
          store.dispatch("user/signIn", data);
        }
      };
  
      const checkRole = async () => {
        const { data, error } = await supabase
          .from("roles")
          .select()
          .eq("user_id", userId.value);
  
        if (error) {
          console.log(error);
        } else {
          console.log("fromcheckrolelogin");
          console.log(data);
          store.dispatch("user/setInvitedBy", data[0].invited_by);
          if (data.length != 0) {
            if (data[0].firma_id != null) {
              store.dispatch("user/setFirmaId", data[0].firma_id);
              firmaId.value = data[0].firma_id;
              getFirmaName();
            }
            store.dispatch("user/setRole", data[0].role);
            store.dispatch("user/setUserData", data[0]);
          } else {
            if (data[0].role === "admin") {
              store.dispatch("user/setRole", "admin");
              return;
            } else if (data[0].role === "agent" || data[0].role === null) {
              store.dispatch("user/setRole", "agent");
              store.dispatch("user/setInvitedBy", data[0].invited_by);
              console.log("de la user Data");
              console.log(data[0]);
            }
          }
        }
      };
  
      const getFirmaName = async () => {
        const { data, error } = await supabase
          .from("firma")
          .select()
          .eq("id", firmaId.value);
  
        if (error) {
          console.log(error);
        } else {
          console.log(data);
  
          store.dispatch("user/setFirmaName", data[0].nume);
        }
      };
  
      watch(isAuth, (newValue) => {
        if (newValue === true) {
          checkRole();
        }
      });
  
      return {
        userRole,
        login,
        isAuth,
        isAdmin,
        userId,
        email,
        password,
        store,
        emailPassError,
        emailPassWrong,
      };
    },
    methods: {},
  };
  </script>
  