/* eslint-disable */
<template>
  <div class="page-container">
    <div class="width-container">
      <div class="page-title-pub">
        <div class="titlu-companii-page" v-if="userData && userData.language === 'romana'">Portofoliu firme</div>
        <div class="titlu-companii-page" v-if="userData && userData.language === 'english'">Companies</div>
        <div v-if="userData && userData.subscription_status != 'sub'">
        <div class="activate-companii-page" v-if="userData && userData.language === 'romana'" @click="showModal = true; modalSubscribe = true">Activeaza cont-ul premium ca sa primesti comisioanele intregi!</div>
        <div class="activate-companii-page" v-if="userData && userData.language === 'english'" @click="showModal = true; modalSubscribe = true">Activate the premium account to receive full commissions!</div>
      </div>
          <div class="companii-page-icons">
            <img src="../assets/img/list-icon.png" class="list-icon-img" v-if="userData && userData.firme_view === 'box'" @click="setLista()">
          <img src="../assets/img/grid-icon.png" class="list-icon-img" v-if="userData && userData.firme_view === 'lista'" @click="setBox()">
          <img src="../assets/img/search-icon.png" class="search-icon-firme" @click="showSearch = !showSearch"/>
          </div>
          
      </div>
      <div class="search-container-firme" v-if="showSearch">
        <div class="search-title" v-if="userData && userData.language === 'romana'">Cautare</div>
        <div class="search-title" v-if="userData && userData.language === 'english'">Search</div>
        <form @submit.prevent="cautaButton" class="search-form">
          <input
            type="text"
            class="search-input"
            v-model="searchString"
            
          />
          <div class="search-btn" @click="cautaButton()">
          <span v-if="userData && userData.language === 'romana'">Cauta</span>
          <span v-if="userData && userData.language === 'english'">Search</span>
          </div>
        </form>
      </div>

      <div class="public-page">
        <div class="container-search-firme" v-if="userData && userData.firme_view === 'box' && cautaActive">
          <div class="close-search-firme" @click="inchideSearch()">
            <div class="close-button-firme">X</div>
          </div>
          <div
            class="fara-clienti"
            v-if="searchResponse && searchResponse.length === 0"
          >
          <span v-if="userData && userData.language === 'romana'">Nu s-a gasit niciun rezultat</span>
          <span v-if="userData && userData.language === 'english'">We couldn't find any company that matches your search</span>
          </div>
          <div class="companies-row" v-if="searchResponse">
            <div
              @click="toCompanie(firma.id)"
              class="company-router-link"
              v-for="firma in searchResponse"
              :key="firma.id"
            >

              <div class="company-item">
                <div class="company-comision-box-notsub" v-if="userData && userData.subscription_status != 'sub'" @click="modalSubscribe = true">
                  <span class="cutout-comision">{{ firma.comision }}%</span>
                  
                  <span class="half-comision">{{ firma.comision / 2 }}%</span> 
                  <span class="grey-accent" v-if="userData && userData.language === 'romana'">comision</span>
                  <span class="grey-accent" v-if="userData && userData.language === 'english'">commission</span>
                </div>
                <div class="company-comision-box" v-if="userData && userData.subscription_status === 'sub'">
                  {{ firma.comision }}%
                  <span class="grey-accent" v-if="userData && userData.language === 'romana'">comision</span>
                  <span class="grey-accent" v-if="userData && userData.language === 'english'">commission</span>
                </div>
                <div class="company-title">{{ firma.nume }}</div>
                
                <div class="company-industry">{{ firma.domeniu }}</div>
                <div class="company-logo" v-if="firma.logo_image">
                  <img
                    :src="`https://kmgppqgrgckdzebbtvqy.supabase.co/storage/v1/object/public/images/${firma.logo_image}`"
                    class="company-logo-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="overflow-wrapper"  v-if="userData && userData.firme_view === 'lista' && cautaActive">
          <div class="close-search-firme" @click="inchideSearch()">
            <div class="close-button-firme">X</div>
          </div>
          <table class="orders-table-firme">
                <col class="col-header-firma" />
                <col class="col-header-firma" />

                <col class="col-header-firma" />
                <col class="col-header-firma" />

                <col class="col-header-firma" />
                <tr class="firma-head-table"  >
                  
                  <th></th>

                  <th></th>
                  <th></th>
                  <th></th>

                 
                </tr>
                <tr v-for="firma in searchResponse"
            :key="firma.id" :to="`/companie/${firma.id}`" @click="toCompanie(firma.id)" class="firma-item-list">

                
                  <th class="company-logo-list"><img v-if="firma.logo_image"
                  :src="`https://kmgppqgrgckdzebbtvqy.supabase.co/storage/v1/object/public/images/${firma.logo_image}`"
                  class="company-logo-img-list"
                /></th>
                <th class="company-title-list">{{ firma.nume }}</th>
                
                <th class="company-industry-list">{{ firma.domeniu }}</th>
                <th class="company-comision-box-list">
                  
                  <span class="cutout-comision" v-if="userData && userData.subscription_status != 'sub'">{{ firma.comision }}%</span> 
                  <span class="half-comision" v-if="userData && userData.subscription_status != 'sub'">{{ firma.comision / 2 }}%</span> 
                  <span class="company-comision-box-list" v-if="userData && userData.subscription_status === 'sub'">{{ firma.comision }}%</span>
                  
                  <span class="grey-accent" v-if="userData && userData.language === 'romana'">comision</span>
                  <span class="grey-accent" v-if="userData && userData.language === 'english'">commission</span></th>
                </tr>
                </table>
        </div>

        <div class="companies-row" v-if="userData && userData.firme_view === 'box' && !cautaActive">
          <div
            
            @click="toCompanie(firma.id)"
            class="company-router-link"
            v-for="firma in firmeSubbed"
            :key="firma.id"
          >

            <div class="company-item">
              <div class="company-comision-box-notsub" v-if="userData && userData.subscription_status != 'sub'">
                  <span class="cutout-comision">{{ firma.comision }}%</span> 
                  <span class="half-comision">{{ firma.comision / 2 }}%</span> 
                  <span class="grey-accent" v-if="userData && userData.language === 'romana'">comision</span>
                  <span class="grey-accent" v-if="userData && userData.language === 'english'">commission</span>
                </div>
                <div class="company-comision-box" v-if="userData && userData.subscription_status === 'sub'">
                  {{ firma.comision }}%
                  <span class="grey-accent" v-if="userData && userData.language === 'romana'">comision</span>
                  <span class="grey-accent" v-if="userData && userData.language === 'english'">commission</span>
                </div>
              <div class="company-title">{{ firma.nume }}</div>
              
              <div class="company-industry">{{ firma.domeniu }}</div>
              <div class="company-logo" v-if="firma.logo_image">
                <img
                  :src="`https://kmgppqgrgckdzebbtvqy.supabase.co/storage/v1/object/public/images/${firma.logo_image}`"
                  class="company-logo-img"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="overflow-wrapper"  v-if="userData && userData.firme_view === 'lista' && !cautaActive">
          <table class="orders-table-firme">
                <col class="col-header-firma" />
                <col class="col-header-firma" />

                <col class="col-header-firma" />
                <col class="col-header-firma" />

                <col class="col-header-firma" />
                <tr class="firma-head-table"  >
                  
                  <th></th>

                  <th></th>
                  <th></th>
                  <th></th>

                 
                </tr>
                <tr v-for="firma in firmeSubbed"
            :key="firma.id" :to="`/companie/${firma.id}`" @click="toCompanie(firma.id)" class="firma-item-list">

                
                  <th class="company-logo-list"><img v-if="firma.logo_image"
                  :src="`https://kmgppqgrgckdzebbtvqy.supabase.co/storage/v1/object/public/images/${firma.logo_image}`"
                  class="company-logo-img-list"
                /></th>
                <th class="company-title-list">{{ firma.nume }}</th>
                
                <th class="company-industry-list">{{ firma.domeniu }}</th>
                <th class="company-comision-box-list">
                  
                  <span class="cutout-comision" v-if="userData && userData.subscription_status != 'sub'">{{ firma.comision }}%</span> 
                  <span class="half-comision" v-if="userData && userData.subscription_status != 'sub'">{{ firma.comision / 2 }}%</span> 
                  <span class="company-comision-box-list" v-if="userData && userData.subscription_status === 'sub'">{{ firma.comision }}%</span>
                  
                  <span class="grey-accent" v-if="userData && userData.language === 'romana'">comision</span>
                  <span class="grey-accent" v-if="userData && userData.language === 'english'">commission</span></th>
                </tr>
                </table>
        </div>
       
      </div>
      <vue-final-modal
        v-model="showModal"
        classes="modal-container"
        content-class="modal-content"
        v-if="modalSubscribe"
        
      >
      <div class="contract-item">
         <div class="regulament-bounties2">
          <div class="regulament-bounties-item" v-if="userData && userData.language === 'romana'">
            Vissia <b>NU</b> are acces la datele dumneavoastra de plata. Folosim <a href="https://www.stripe.com" class="stripe-link">Stripe</a>, unul dintre cei mai mari procesatori de plati din lume.
          </div>
          <div class="regulament-bounties-item" v-if="userData && userData.language === 'english'">
            Vissia does <b>NOT</b> have access to your payment information. We use <a href="https://www.stripe.com" class="stripe-link">Stripe</a>, one of the biggest payment processors in the world.
          </div>
          <div class="regulament-bounties-item" v-if="userData && userData.language === 'romana'">
            Puteti sa va anulati subscriptia in orice moment din pagina de profil.
          </div>
          <div class="regulament-bounties-item" v-if="userData && userData.language === 'english'">
            You can cancel your subscription at any time from your profile page.
          </div>
          <div class="regulament-bounties-item" v-if="userData && userData.language === 'romana'">
            Email-ul din pagina de plata <b>trebuie</b> sa fie acelasi cu email-ul dumneavoastra de utilizator Vissia.
          </div>
          <div class="regulament-bounties-item" v-if="userData && userData.language === 'english'">
            The email address from the payment page must be <b>the same</b> with your Vissia user email.
          </div>
          
          <a
                    v-if="userData"
                    :href="`https://pay.vissia.ro/b/fZe7w46DxdnY5RC3cg?prefilled_email=${userData.email}`"
                    ><div class="cover-sub-btn2"><span v-if="userData && userData.language === 'romana'">Activeaza cont premium</span><span v-if="userData && userData.language === 'english'">Activate premium account</span></div></a
                  >
         </div>
        </div>

      </vue-final-modal>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, ref, computed, onUnmounted } from "vue";
import { supabase } from "../clients/supabase";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "CompaniiPage",
  components: {},
  data() {
    return {
      showModal: false
    };
  },
  setup() {
    const store = useStore();
    const router = useRouter()

    onMounted(() => {
      getAllFirme();
      checkRole()
    });

    onUnmounted(() => {
      resetFirmeStore();
    });

    // formular

    // contracte

    const listaFirme = ref("");

    const searchString = ref("");
    const showSearch = ref(false)
    const modalSubscribe = ref(false)
    const recomandariKey = ref(0);
    const listaActive = ref(false);
    const cautaActive = ref(false);

    const userId = computed(() => {
      return store.getters["user/getUserId"];
    });

    const firmeSubbed = computed(() => {
      return store.getters["firme/getFirmeSubbed"];
    });

    const contracteStore = computed(() => {
      return store.getters["contracte/getAllContracte"];
    });

    const allClientsStore = computed(() => {
      return store.getters["clienti/getAllClients"];
    });

    const allFirmeStore = computed(() => {
      return store.getters["firme/getAllFirme"];
    });

    const recommendedClientsStore = computed(() => {
      return store.getters["clienti/getRecommendedClients"];
    });

    const searchResponse = computed(() => {
      return store.getters["user/getSearchResponse"];
    });

    const userData = computed(() => {
      return store.getters["user/getUserData"];
    });

    function resetFirmeStore() {
      store.dispatch("firme/setAllFirme", null);
    }

    function inchideSearch() {
      cautaActive.value = false;
      searchString.value = "";
      store.dispatch("user/setSearchResponse", null);
    }

    function toCompanie(id) {

      router.push(`/companie/${id}`)
    }

    function sendTo(id) {
      router.push(`/companie/${id}`)
    }

    const checkRole = async () => {
      const { data, error } = await supabase
        .from("roles")
        .select()
        .eq("user_id", userId.value);

      if (error) {
        console.log(error);
      }
      store.dispatch("user/setUserData", data[0]);
      
    };

    const setBox = async () => {
      const { data, error } = await supabase
        .from("roles")
        .update({firme_view: 'box'})
        .eq("user_id", userId.value)
        .select()

      if (error) {
        console.log(error);
      }
      checkRole()
      console.log(data)
      
    };

    const setLista = async () => {
      const { data, error } = await supabase
        .from("roles")
        .update({firme_view: 'lista'})
        .eq("user_id", userId.value)
        .select()

      if (error) {
        console.log(error);
      }
      checkRole()
      console.log(data)
      console.log(userId.value)
      
    };


    const cautaButton = async () => {
      if (searchString.value === "") {
        return;
      }

      if (cautaActive.value === false) {
        cautaActive.value = true;
      }

      try {
        let cautaText = searchString.value;
        console.log(cautaText);
        const { data, error } = await supabase.rpc("search_firme_category", {
          search_query: cautaText,
        });

        if (error) throw error;
        let firmeSubbed = data.filter((firma) => firma.sub_status === "sub");
        store.dispatch("user/setSearchResponse", firmeSubbed);
      } catch (error) {
        console.log(error);
      }
    };

    // Send contract

    // Get firme

    const getAllFirme = async () => {
      try {
        const { data, error } = await supabase
          .from("firma")
          .select('*')
          .order("order_id", { ascending: false })
          
        if (error) throw error;
        listaFirme.value = data;

        store.dispatch("firme/setAllFirme", data);
        let firmeSubbed = data.filter((firma) => firma.sub_status === "sub");

        store.dispatch("firme/setFirmeSubbed", firmeSubbed);
       
      } catch (error) {
        console.log(error);
      }
    };

    return {
      getAllFirme,
      listaFirme,
      modalSubscribe,
      cautaButton,
      searchString,
      cautaActive,
      searchResponse,
      setBox,
      setLista,
      userId,
      contracteStore,
      allClientsStore,
      allFirmeStore,
      recomandariKey,
      recommendedClientsStore,
      inchideSearch,
      firmeSubbed,
      sendTo,
      listaActive,
      showSearch,
      userData,
      toCompanie
    };
  },
  created() {},
  methods: {},
};
</script>
