/* eslint-disable */
<template>
  <div class="page-container">
    <div class="width-container">
      <div class="page-title-pub">FAQ - Intrebari frecvente</div>
      <div class="home-page">
        <div class="main-content">
          <div class="contracte-main-content">
            <div class="intrebare" @click="addRaspuns(1)">
              Ce este Vissia?
              <div class="raspuns" v-if="raspuns === 1">
                Vissia este prima platforma de affiliate marketing offline care
                iti pune la dispozitie un arsenal de servicii si produse de la
                diverse firme ce ofera comisioane consistente pentru fiecare
                recomandare care se transforma in client.
              </div>
            </div>
            <div class="intrebare" @click="addRaspuns(2)">
              De ce subscriptie lunara de 75 RON?
              <div class="raspuns" v-if="raspuns === 2">
                Am optat pentru o subscriptie lunara din mai multe considerente.
                Una dintre ele ar fi ca noi nu luam niciun comision de la
                firmele partenere de pe urma clientilor recomandati de tine.
                Intreg comisionul merge direct in contul tau. Un alt motiv este
                acela ca vrem sa avem oameni activi pe platforma, avand in
                vedere ca locurile sunt limitate, nu ne dorim sa blocam aceste
                locuri cu conturi inactive.
              </div>
            </div>
            <div class="intrebare" @click="addRaspuns(3)">
              De unde stim ca firmele isi onoreaza obligatia de a inregistra
              contractele pe platforma?
              <div class="raspuns" v-if="raspuns === 3">
                Contractul semnat cu noi obliga firma sa inregistreze pe
                platforma orice contract semnat cu un client venit prin
                recomandarile noastre. Pe langa despagubirea substantiala pe
                care trebuie sa o suporte in cazul in care gasim astfel de
                neregularitati, firmele care sunt prinse ca nu isi onoreaza
                aceasta obligatie vor fi scoase de pe platforma noastra. Este in
                interesul lor sa primeasca un flux constant de recomandari prin
                intermediul platformei pe termen cat mai lung, decat sa 'fure'
                un comision, astfel riscand sa piarda accesul la serviciile
                noastre si sa plateasca despagubirea de peste 25.000 RON.
              </div>
            </div>
            <div class="intrebare" @click="addRaspuns(4)">
              Care sunt comisioanele?
              <div class="raspuns" v-if="raspuns === 4">
                Comisionul oferit de catre firme pentru recomandarile ce se
                transforma in contracte ajunge integral in contul tau. Comisionul oferit difera de la firma la firma si poate fi gasit pe pagina portofoliu firme. Vissia nu
                se plaseaza deloc intre tine si firma din punct de vedere
                financiar, cu exceptia subscriptiei lunare fixa si o taxa de 3% pentru plati care se opreste in momentul cand se trimite plata catre contul tau.
              </div>
            </div>
            <div class="intrebare" @click="addRaspuns(5)">
              De ce este un numar limitat de locuri pe platforma?
              <div class="raspuns" v-if="raspuns === 5">
                Viziunea noastra pentru aceasta platforma este una de tip
                'boutique'. Nu ne dorim o platforma cu zeci de mii de
                utilizatori si cu sute de firme partenere. Vrem sa fim cat mai aproape de fiecare utiliator si firma in parte, si sa putem gestiona cu eficienta si implicare maxima orice provocare sau nevoie apare.
              </div>
            </div>

            <div class="intrebare" @click="addRaspuns(7)">
              Ce sunt bounty-urile?
              <div class="raspuns" v-if="raspuns === 7">
                Bounty-urile sunt niste bonusuri oferite de catre firmele
                partenere. Pentru a te califica trebuie sa recomanzi un client
                care sa semneze un contract ce indeplineste anumite cerinte.
                Premiile oferite din aceste bounty-uri sunt extra, pe langa comisioanele normale
                oferite de catre firme la fiecare contract. Pentru un bounty se pot califica maxim 10 persoane. La sfarsitul lunii se
                alege o persoana din cele 10 la intamplare care va castiga
                bonusul oferit de catre fiecare firma.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, ref, computed, onUnmounted } from "vue";
import { supabase } from "../clients/supabase";
import { useStore } from "vuex";

export default {
  name: "CompaniiPage",
  components: {},
  data() {
    return {};
  },
  setup() {
    const store = useStore();

    const raspuns = ref(0);

    onMounted(() => {
      getAllFirme();
    });

    onUnmounted(() => {
      resetFirmeStore();
    });

    // formular

    // contracte

    const listaFirme = ref("");

    const recomandariKey = ref(0);

    const userId = computed(() => {
      return store.getters["user/getUserId"];
    });

    const contracteStore = computed(() => {
      return store.getters["contracte/getAllContracte"];
    });

    const allClientsStore = computed(() => {
      return store.getters["clienti/getAllClients"];
    });

    const allFirmeStore = computed(() => {
      return store.getters["firme/getAllFirme"];
    });

    const recommendedClientsStore = computed(() => {
      return store.getters["clienti/getRecommendedClients"];
    });

    function resetFirmeStore() {
      store.dispatch("firme/setAllFirme", null);
    }

    // Send contract

    // Get firme

    const getAllFirme = async () => {
      try {
        const { data, error } = await supabase
          .from("firma")
          .select("*")
          .order("id", { ascending: true });
        if (error) throw error;
        listaFirme.value = data;

        store.dispatch("firme/setAllFirme", data);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

    function addRaspuns(e) {
      if (raspuns.value === e) {
        raspuns.value = 0;
      } else if (raspuns.value != e) {
        raspuns.value = e;
      }
    }

    return {
      getAllFirme,
      listaFirme,
      raspuns,
      addRaspuns,
      userId,
      contracteStore,
      allClientsStore,
      allFirmeStore,
      recomandariKey,
      recommendedClientsStore,
    };
  },
  created() {},
  methods: {},
};
</script>
