/* eslint-disable */
<template>
  <div class="page-container">
    <div class="width-container">
      <div class="page-title" v-if="userData && userData.language === 'romana'">Retea</div>
      <div class="page-title" v-if="userData && userData.language === 'english'">Network</div>
      <div class="home-page">
        <div class="main-content">
          <div class="retea-main-content">
            <div class="retea-container">
              <div class="todo-title" v-if="userData && userData.language === 'romana'">Date generale</div>
              <div class="todo-title" v-if="userData && userData.language === 'english'">General Info</div>
              <div class="profile-field">
                <div class="profile-field-left" v-if="userData && userData.language === 'romana'">Subscriptie</div>
                <div class="profile-field-left" v-if="userData && userData.language === 'english'">Subscription</div>
                <div class="profile-field-right" v-if="userData && userData.subscription_status === 'sub'">
                  <div class="substatustext" v-if="userData && userData.language === 'romana'">
                  Activa - expira {{ dataExpirare() }}
                </div>
                <div class="substatustext" v-if="userData && userData.language === 'english'">
                  Active - expires on {{ dataExpirare() }}
                </div>
                </div>
                <div class="profile-field-right" v-if="userData && userData.subscription_status === 'not_sub'">
                  <div v-if="userData && userData.language === 'romana'">
                  Inactiva
                </div>
                <div v-if="userData && userData.language === 'english'">
                  Inactive
                </div>
                </div>
                <div class="profile-field-right" v-if="userData && userData.subscription_status === 'sub_expired'">
                  
                  <div class="substatustext" v-if="userData && userData.language === 'romana'">
                  Expirata - {{ dataExpirare() }}
                </div>
                <div class="substatustext" v-if="userData && userData.language === 'english'">
                  Expired - {{ dataExpirare() }}
                </div>
                </div>
              </div>
              <div class="profile-field">
                <div class="profile-field-left" v-if="userData && userData.language === 'romana'">Invitat de</div>
                <div class="profile-field-left" v-if="userData && userData.language === 'english'">Invited by</div>
                <div class="profile-field-right" v-if="invitedByName">
                  {{ invitedByName }}
                </div>
              </div>
              <div class="profile-field">
                <div class="profile-field-left" v-if="userData && userData.language === 'romana'">Invitatiile tale</div>
                <div class="profile-field-left" v-if="userData && userData.language === 'english'">Your invites</div>
                
                  <span class="right-field" v-if="invitesOwned"
                  > {{ invitesOwned.length }}
                  <a v-if="userData" :href="`https://pay.vissia.ro/b/5kA3fOaTNfw6cg0aEJ?prefilled_email=${userData.email}`"><div class="buy-btn"> <span v-if="userData && userData.language === 'romana'">Cumpara</span><span v-if="userData && userData.language === 'english'">Buy invite</span> </div></a></span
                >
                
              </div>
              <div class="profile-field">
                <div class="profile-left-field" v-if="userData && userData.language === 'romana'">Invitatii disponibile</div>
                <div class="profile-left-field" v-if="userData && userData.language === 'english'">Available invites</div>
                <div class="profile-right-field">
                  <span class="right-field" v-if="allInvites"> {{ allInvites.filter((item) => item.status === 'available').length }} / 10000</span>
                  
                </div>
              </div>
            </div>
            <div class="retea-container-1">
              <div class="todo-title" v-if="userData && userData.language === 'romana'">Invita membru nou</div>
              <div class="todo-title" v-if="userData && userData.language === 'english'">Invite new member</div>
              <div class="invite-field">
                <div class="invite-group">
                  <div class="invite-label">Email</div>
                  <input
                    type="email"
                    class="invite-input"
                    v-model="inviteMail"
                  />
                </div>
                <div class="invite-btn" @click="sendInvite()">
                  <span v-if="userData && userData.language === 'romana'">Trimite invitatie</span>
                  <span v-if="userData && userData.language === 'english'">Send invite</span>
                </div>
              </div>
              <div class="invite-field-info" v-if="userData && userData.language === 'romana'">
                <div class="invite-field-text">
                  Persoana invitata va avea la dispozitie 7 zile sa creeze un
                  cont folosind invitatia primita.
                </div>
                <div class="invite-field-text-3">
                  Daca persoana invitata nu isi creeaza contul in cele 7 zile, invitatia va reveni la dvs. si
                  o puteti trimite din nou catre aceeasi persoana sau catre
                  altcineva.
                </div>
              </div>
              <div class="invite-field-info" v-if="userData && userData.language === 'english'">
                <div class="invite-field-text">
                  The person you invite has 7 days to create an account using your invitation link.
                </div>
                <div class="invite-field-text-3">
                  If the person you invited does not create an account within those 7 days, the invitation returns to your account and you can send it again to the same person or a different one.
                </div>
              </div>
            </div>
          </div>
          <div class="profile-second-content" v-if="allRecruti">
            <div class="box-header-row">
                  <div class="todo-title" v-if="userData && userData.language === 'romana'">Retea recruti</div>
                  <div class="todo-title" v-if="userData && userData.language === 'english'">Your recruits</div>

                  <div class="show-more-recruits-length" v-if="allRecruti">
                      {{ allRecruti.length }}
                    </div>
                   
                </div>
            <div class="fara-clienti" v-if="allRecruti.length === 0">
              <span v-if="userData && userData.language === 'romana'">Nu aveti niciun recrut</span>
              <span v-if="userData && userData.language === 'english'">You have no recruits</span>
            </div>
            <div class="overflow-wrapper">
            <table class="orders-table" v-if="allRecruti.length != 0">
              <col class="col-order-no" />
              <col class="col-order-name" />

              <col class="col-order-id" />
              <col class="col-order-ref" />

              <col class="col-order-more" />
              <tr class="products-table-head">
                <th class="col-order-no">ID</th>
                <th><span v-if="userData && userData.language === 'romana'">Nume</span>
              <span v-if="userData && userData.language === 'english'">Name</span></th>

                <th>Email</th>
                <th><span v-if="userData && userData.language === 'romana'">Telefon</span>
              <span v-if="userData && userData.language === 'english'">Telephone</span></th>

                <th>Status</th>
                <th><span v-if="userData && userData.language === 'romana'">Venit generat</span>
              <span v-if="userData && userData.language === 'english'">Income generated</span></th>
              </tr>
              <tr
                class="products-table-item-retea"
                v-for="recrut in allRecruti"
                :key="recrut.id"
              >
                <th class="client-cell">{{ recrut.id }}</th>
                <th class="product-name client-cell">
                  {{ recrut.nume }}
                  {{ recrut.prenume }}
                </th>
                <th class="product-name client-cell">{{ recrut.email }}</th>

                <th class="suma-tabel-contracte client-cell">
                  {{ recrut.telefon }}
                </th>
                <th class="product-name client-cell" v-if="recrut">
                  <span v-if="recrut.subscription_status === 'not_sub'"
                    ><span v-if="userData && userData.language === 'romana'">Subscriptie neactivata</span>
              <span v-if="userData && userData.language === 'english'">Subscription not activated</span></span
                  >
                  <span v-if="recrut.subscription_status === 'sub'"
                    ><span v-if="userData && userData.language === 'romana'">Subscriptie activa</span>
              <span v-if="userData && userData.language === 'english'">Subscription active</span></span
                  >
                  <span v-if="recrut.subscription_status === 'sub_expired'"
                    ><span v-if="userData && userData.language === 'romana'">Subscriptie expirata</span>
              <span v-if="userData && userData.language === 'english'">Subscription expired</span></span
                  >
                  
                  
                </th>

                <th>{{ recrut.venit_incasat }} RON</th>
              </tr>
            </table>
          </div>
          </div>
          <div class="profile-second-content" v-if="invitesSent">
            <div class="todo-title" v-if="userData && userData.language === 'romana'">Invitatii trimise</div>
            <div class="todo-title" v-if="userData && userData.language === 'english'">Sent invites</div>
            <div class="fara-clienti" v-if="invitesSent.length === 0">
              Nu aveti nicio invitatie trimisa
            </div>
            <div class="overflow-wrapper">
            <table class="orders-table" v-if="invitesSent.length != 0">
              <col class="col-order-no" />
              <col class="col-order-name" />

              <col class="col-order-id" />
              <col class="col-order-ref" />

              <col class="col-order-more" />
              <tr class="products-table-head">
                <th class="col-order-no">ID</th>

                <th>Email</th>

                <th><span v-if="userData && userData.language === 'romana'">Optiuni</span>
              <span v-if="userData && userData.language === 'english'">Options</span></th>
              </tr>
              <tr
                class="products-table-item-retea"
                v-for="invite in invitesSent"
                :key="invite.id"
              >
                <th class="client-cell">{{ invite.id }}</th>

                <th class="product-name client-cell">{{ invite.invitee_email }}</th>
                <th class="product-name client-cell" v-if="invite">
                  
                  <div
                    class="invitatie-trimisa"
                    v-if="invite.status === 'sent'"
                  >
                    <div
                      class="retrimite-invite"
                      @click="resendInvite(invite.invitee_email)"
                    >
                      <img
                        src="../assets/img/resend-email.png"
                        class="invite-icon"
                      />
                    </div>
                    <div
                      class="anuleaza-invite"
                      @click="anuleazaInvite(invite.invitee_email)"
                    >
                      <img
                        src="../assets/img/cancel-invite.png"
                        class="invite-icon"
                      />
                    </div>
                  </div>
                </th>
              </tr>
            </table>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, ref, computed, onUnmounted } from "vue";
import { supabase } from "../clients/supabase";
import { useStore } from "vuex";


export default {
  name: "CompaniePage",
  components: {},
  data() {
    return {
      showModal: false,
      error: false,
    };
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      getAllFirme();
      getAllRecruti();
      getInvitedByName();
      getInvitesOwned();
      getInvitesSent()
      getAllInvites();
    });

    onUnmounted(() => {
      resetThisFirma();
    });

    // formular

    // contracte

    const inviteMail = ref('');
    const listaFirme = ref("");
    const totalRecruti = ref(0);

    const recomandariKey = ref(0);

    const userId = computed(() => {
      return store.getters["user/getUserId"];
    });

    const userToken = computed(() => {
      return store.getters["user/getToken"];
    });

    const contracteStore = computed(() => {
      return store.getters["contracte/getAllContracte"];
    });

    const allClientsStore = computed(() => {
      return store.getters["clienti/getAllClients"];
    });

    const allFirmeStore = computed(() => {
      return store.getters["firme/getAllFirme"];
    });

    const invitedByName = computed(() => {
      return store.getters["user/getInvitedByName"];
    });

    const recommendedClientsStore = computed(() => {
      return store.getters["clienti/getRecommendedClients"];
    });

    const userData = computed(() => {
      return store.getters["user/getUserData"];
    });

    const allRecruti = computed(() => {
      return store.getters["user/getAllRecruti"];
    });

    const invitesOwned = computed(() => {
      return store.getters["user/getInvitesOwned"];
    });

    const invitesSent = computed(() => {
      return store.getters["user/getInvitesSent"];
    });

    const allInvites = computed(() => {
      return store.getters["user/getAllInvites"];
    });

    function resetThisFirma() {
      store.dispatch("firme/setThisFirma", null);
    }

    const getAllInvites = async () => {
      try {
        const { data, error } = await supabase
          .from("invites")
          .select("*")
          
          .order("created_at", { ascending: false });
        if (error) throw error;
        
        store.dispatch("user/setAllInvites", data);
        
      } catch (error) {
        console.log(error);
      }
    };

    const getAllRecruti = async () => {
      const arrayInvitati = ref([]);
      let roluri = {};

      try {
        const { data, error } = await supabase
          .from("roles")
          .select()
          .eq("invited_by", userId.value)
          .order("created_at", { ascending: false });
        if (error) throw error;

        arrayInvitati.value = data.map(({ user_id }) => user_id);
        totalRecruti.value = arrayInvitati.value.length;
        roluri = data;
      } catch (error) {
        console.log(error);
      }

      let iduriInvitati = arrayInvitati.value.map((id) => id);

      try {
        

        const { data, error } = await supabase
          .from("contract")
          .select()
          .in("agent", iduriInvitati);
        if (error) throw error;

        

       

        let incasariPerAgent = {};

        data.forEach((item) => {
          if (
            !Object.prototype.hasOwnProperty.call(incasariPerAgent, item.agent)
          ) {
            incasariPerAgent[item.agent] = 0;
          }

          incasariPerAgent[item.agent] += parseInt(item.total_incasat_parinte);
        });
        let final = roluri.map((item) => {
          item.total_incasat_parinte = incasariPerAgent[item.user_id] ?? 0;
          item.venit_incasat =
            item.total_incasat_parinte > 0 ? item.total_incasat_parinte : 0;

          return item;
        });
        store.dispatch("user/setAllRecruti", final);
      } catch (error) {
        console.log(error);
      }
    };

    function dataExpirare() { 
      
      let data = ''
      const monthNames = ["Ianuarie", "Februarie", "Martie", "Aprilie", "Mai", "Iunie",
  "Iulie", "August", "Septembrie", "Octombrie", "Noiembrie", "Decembrie"
]
      const ziua = userData.value.sub_expiry.toString().slice(8, 10)
      const luna = monthNames[parseInt(userData.value.sub_expiry.toString().slice(6, 8))]
      const anul = userData.value.sub_expiry.toString().slice(0, 4)

      data = ziua + ' ' + luna + ' ' + anul

      return data

    }

    const sendInvite = async () => {
      console.log(inviteMail.value);
      if(inviteMail.value === null || inviteMail.value === '') {
        store.commit(
            "user/setToasterError",
            `Va rugam introduceti adresa de email!`
          );
        return
      }
      const tokenul = localStorage.getItem("token");

      try {
        const { data, error } = await supabase.functions.invoke("send-invite", {
          body: { email: inviteMail.value, token: tokenul },
        });
        if (data && !data.error) {
          store.commit(
            "user/setToaster",
            `Invitatie trimisa cu succes catre ${inviteMail.value}!`
          );
        }
        if (data && data.error) {
          store.commit(
            "user/setToasterError",
            `Deja exista un utilizator cu aceasta adresa de email!`
          );
        }
        getInvitesSent()
        getInvitesOwned()
        inviteMail.value = ''
        console.log(data)
        if (error) {
          console.log(error)
        }
      } catch (error) {
        console.log(error);
        inviteMail.value = ''
      }
      
    };

    const resendInvite = async (invitee) => {
      try {
        const { data, error } = await supabase.functions.invoke(
          "resend-invite",
          {
            body: { email: invitee },
          }
        );
        if (data) {
          store.commit(
            "user/setToaster",
            `Invitatie retrimisa cu succes catre ${invitee}!`
          );
        }
        console.log(data);
        if (error) {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const anuleazaInvite = async (invitee) => {
      try {
        const { data, error } = await supabase.functions.invoke(
          "cancel-invite",
          {
            body: { email: invitee },
          }
        );
        if (data) {
          store.commit(
            "user/setToaster",
            `Invitatie catre ${invitee} anulata cu succes!`
          );

          getAllRecruti();
          getInvitesOwned();
        }

        if (error) {
          console.log(error);
        } else {
          getInvitesSent()
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getInvitedByName = async () => {
      try {
        const { data, error } = await supabase
          .from("roles")
          .select()
          .eq("user_id", userData.value.invited_by);
        if (error) throw error;

        store.dispatch(
          "user/setInvitedByName",
          data[0].nume + " " + data[0].prenume
        );
      } catch (error) {
        console.log(error);
      }
    };

    const getInvitesOwned = async () => {
      try {
        const { data, error } = await supabase
          .from("invites")
          .select()
          .eq("owner_id", userId.value)
          .eq("status", 'owned')
        if (error) throw error;
        store.dispatch("user/setInvitesOwned", data);
      } catch (error) {
        console.log(error);
      }
    };

    const getInvitesSent = async () => {
      try {
        const { data, error } = await supabase
          .from("invites")
          .select()
          .eq("owner_id", userId.value)
          .eq('status', 'sent')
          .order('id', {ascending: false})

        if (error) throw error;
        store.dispatch("user/setInvitesSent", data);
      } catch (error) {
        console.log(error);
      }
    };

    // Send contract

    // Get firme

    const getAllFirme = async () => {
      try {
        const { data, error } = await supabase.from("firma").select("*");
        if (error) throw error;
        listaFirme.value = data;
        store.dispatch("firme/setAllFirme", data);
      } catch (error) {
        console.log(error);
      }
    };

    return {
      getAllFirme,
      listaFirme,
      invitedByName,
      userToken,
      userId,
      contracteStore,
      allClientsStore,
      allFirmeStore,
      recomandariKey,
      recommendedClientsStore,
      allRecruti,
      invitesOwned,
      sendInvite,
      inviteMail,
      resendInvite,
      anuleazaInvite,
      invitesSent,
      allInvites,
      dataExpirare,
      userData
    };
  },
  created() {},
  methods: {},
};
</script>
