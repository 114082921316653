<template>
    <div class="signin-page">

    <div class="signin-section-1-addfirma">

      <div class="page-title">
        Adauga Firma
      </div>

      <form ref="form" @submit.prevent="onSubmit" class="login-form-addfirma">
        <div class="input-field">
          <label>Nume</label>
          <input type="text" v-model="nume" />
        </div>
        <div class="input-field">
          <label>Domeniu</label>
          <input type="text" v-model="domeniu" />
        </div>
        <div class="input-field">
          <label>Tags</label>
          <input type="text" v-model="tags" />
        </div>
        
        <div class="input-field">
          <label>Comision</label>
          <input type="text" v-model="comision" />
        </div>
        <div class="input-field">
          <label>Website</label>
          <input type="text" v-model="website" />
        </div>
        <div class="input-field">
          <label>Email Contact</label>
          <input type="text" v-model="email_contact" />
        </div>
        <div class="input-field">
          <label>Logo Image</label>
          <input type="text" v-model="logo_image" />
        </div>
        <div class="input-field">
          <label>Descriere</label>
          <textarea type="text" v-model="descriere" />
        </div>
        <div class="input-field">
          <label>Recomandare</label>
          <textarea type="text" v-model="recomandare" />
        </div>
        <div class="input-field">
          <label>Puncte Forte</label>
          <textarea type="text" v-model="forte" />
        </div>
        <div class="input-field">
          <label>Exemplu comision</label>
          <textarea type="text" v-model="exemplu" />
        </div>
        <div class="input-field">
          <label>Nume SRL</label>
          <input type="text" v-model="nume_srl" />
        </div>

        <button type="submit" class="signin-button" @click.prevent="signup()">Creeaza firma</button>

        
      </form>
      
    </div>
  </div>
</template>

<script>
import { supabase } from "../../src/clients/supabase"
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from "vue-router";

export default {
  name: "AddFirmaPage",
  components: {
   
  },
  data() {
    return {
      error: false,
    };
  },
  setup() {
    const route = useRoute()
    
    const store = useStore()

    const isAuth = computed(() => {
      return store.getters['user/isAuth']
    })

    const isAdmin = computed(() => {
      return store.getters['user/isAdmin']
    })
    const userRole = ref('')
    const userId = ref('')
    
    const password = ref('')
    const password2 = ref('')


    const nume = ref('')
    const domeniu = ref('')
    const tags = ref('')
    const comision = ref('')
    const website = ref('')
    const email_contact = ref('')
    const logo_image = ref('')
    const descriere = ref('')
    const recomandare = ref('')
    const forte = ref('')
    const exemplu = ref('')
    const nume_srl = ref('')
    
   
    const inviteCorrect = ref('loading')
    
    
    onMounted(() => {
      console.log(route.query.hash)
      
      
    })

   

    const signup = async () => {
     
        const { data, error } = await supabase.from('firma')
        .insert({
            nume: nume.value,
            domeniu: domeniu.value,
            tags: tags.value,
            comision: Number(comision.value),
            website: website.value,
            email_contact: email_contact.value,
            logo_image: logo_image.value,
            descriere: descriere.value,
            recomandare: recomandare.value,
            exemplu: exemplu.value,
            forte: forte.value,
            nume_srl: nume_srl.value
        }).select()
        console.log(data)

        if (error) {
        console.log(error)
      }
      
     }
     

    return {
      userRole,
      signup,
      isAuth,
      isAdmin,
      userId,
      
      password,
      password2,
      store,
      nume,
      domeniu,
      tags,
      comision,
      website,
      email_contact,
      logo_image,
      descriere,
      recomandare,
      forte,
      exemplu,
      nume_srl,
      inviteCorrect,
      
    }

  },
  methods: {
    
  },
}
</script>

