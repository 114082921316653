import { createStore } from 'vuex'
import user from './user'
import firme from './firme'
import contracte from './contracte'
import clienti from './clienti'



export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    firme,
    contracte,
    clienti
  }
})