/* eslint-disable */
<template>
  <div class="page-container">
    <div class="width-container">
      <div class="page-title" v-if="userData && userData.language === 'romana'">Reclamatie noua</div>
      <div class="page-title" v-if="userData && userData.language === 'english'">New complaint</div>
      <div class="home-page">
        <div class="main-content">
          <div class="companie-main-content">

            <div class="reclamatie-container">
                <div class="firme-reclamatie-container">
                    <div class="header-reclamatie" v-if="userData && userData.language === 'romana'">
                        Selectati firma pentru care vreti sa faceti o reclamatie
                    </div>
                    <div class="header-reclamatie" v-if="userData && userData.language === 'english'">
                        Select the company for which you want to create a complaint against
                    </div>
                    <div class="firme-row-reclamatie">
                      <div
                        class="firma-btn"
                        v-for="firma in allFirmeStore"
                        v-bind:key="firma.id"
                        @click="setFirma(firma.id)"
                        :class="{ firmaselected: firma.id === clickedFirma }"
                      >
                      <span v-if="userData && userData.subscription_status ==='sub'">{{ firma.nume }}</span>
                      <span class="blurred-text" v-if="userData && userData.subscription_status != 'sub'">Firma</span>
                        
                      </div>
                    </div>
                </div>
                <form ref="form" @submit.prevent="onSubmit" class="reclamatie-form">
                    <div class="input-field">
                    <label v-if="userData && userData.language === 'romana'">Firma</label>
                    <label v-if="userData && userData.language === 'english'">Company</label>
                    <input type="text" v-model="formFirma" disabled />
                    </div>
                    
                    <div class="input-field">
                      <label v-if="userData && userData.language === 'romana'">Descriere</label>
                    <label v-if="userData && userData.language === 'english'">Description</label>
                    <textarea type="text" v-model="formDescriere" rows="10"
                          cols="30"
                          
                          class="contract-textarea"/>
                    </div>


                    <button type="submit" class="signin-button" @click.prevent="trimiteReclamatie()">
                      <span v-if="userData && userData.language === 'romana'">Trimite Reclamatie</span>
                      <span v-if="userData && userData.language === 'english'">Send complaint</span></button>

                    
                </form>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, ref, computed, onUnmounted } from "vue";
import { supabase } from "../clients/supabase";
import { useStore } from "vuex";


export default {
  name: "CompaniePage",
  components: {},
  data() {
    return {
      showModal: false,
      error: false,
    };
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      getAllFirme();
      
    });

    onUnmounted(() => {
      resetThisFirma();
    });

    // formular



    // contracte

 
    
    const listaFirme = ref("");
    const clickedFirma = ref("");


    const formFirma = ref("")
    const formTitlu = ref("")
    const formDescriere = ref("")

    const recomandariKey = ref(0);



    const userId = computed(() => {
      return store.getters["user/getUserId"];
    });

    const contracteStore = computed(() => {
      return store.getters["contracte/getAllContracte"];
    });


    const allClientsStore = computed(() => {
      return store.getters["clienti/getAllClients"];
    });

    const allFirmeStore = computed(() => {
      return store.getters["firme/getAllFirme"];
    });

    const userData = computed(() => {
      return store.getters["user/getUserData"];
    });

    const recommendedClientsStore = computed(() => {
      return store.getters["clienti/getRecommendedClients"];
    });

    const thisFirma = computed(() => {
      return store.getters["firme/getThisFirma"];
    });


    function resetThisFirma() {
      store.dispatch("firme/setThisFirma", null);
      
    }





    // Send contract

    function setFirma(id) {
      if(userData.value.subscription_status != 'sub') {
        return
      }
      if(clickedFirma.value && formFirma.value === id) {
        clickedFirma.value = null
        formFirma.value = null
      } else {
        clickedFirma.value = id;
        const firmaSelectata = allFirmeStore.value.filter((firma) => firma.id === id)
        
      formFirma.value = firmaSelectata[0].nume
      }
      
    }

    // Get firme

    const getAllFirme = async () => {
      try {
        const { data, error } = await supabase.from("firma").select("*");
        if (error) throw error;
        listaFirme.value = data;
        store.dispatch("firme/setAllFirme", data);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

    const trimiteReclamatie = async () => {
      if(formDescriere.value === '' || clickedFirma.value === '') {
        return
      }
        console.log('trimite')
        const { data, error } = await supabase.from('reclamatii')
        .insert({
          firma_id: clickedFirma.value,
          firma_nume: formFirma.value,
          descriere: formDescriere.value,
          agent_id: userId.value,
          agent_nume: userData.value.nume + ' ' + userData.value.prenume,
          agent_email: userData.value.email,
          agent_telefon: userData.value.telefon
        })
        .select()
        if(data) {
          clickedFirma.value = ''
          formFirma.value = ''
          formDescriere.value = ''
          store.commit("user/setToaster", "Reclamatie inregistrata cu succes!");
        }
        console.log(data)
        if(error) {
          console.log(error)
        }
    }
    

    

    return {

      
      trimiteReclamatie,
      getAllFirme,
      listaFirme,
        setFirma,
      formTitlu,
      formDescriere,
      clickedFirma,
      formFirma,
      userId,
      contracteStore,
      allClientsStore,
      allFirmeStore,
      recomandariKey,
      recommendedClientsStore,
      thisFirma,
      userData
    };
  },
  created() {},
  methods: {},
};
</script>
