/* eslint-disable */
<template>
  <div class="page-container">
    <div class="width-container">
      <div class="page-title" v-if="userData && userData.language === 'romana'">Castiguri</div>
      <div class="page-title" v-if="userData && userData.language === 'english'">Earnings</div>
      <div class="home-page">
        <div class="main-content">
          <div class="profile-main-content">
            <div class="profile-container-2">
              <div class="todo-title">
                <span v-if="userData && userData.language === 'romana'">Incasat</span>
                <span v-if="userData && userData.language === 'english'">Collected</span>
                
                <img
                  v-if="userData.show_incasat"
                  src="../assets/img/hide-balance.png"
                  class="balance-icon"
                  @click="hideBalance()"
                /><img
                  v-if="!userData.show_incasat"
                  src="../assets/img/view-balance.png"
                  class="balance-icon"
                  @click="showBalance()"
                />
              </div>
              <div class="profile-row-wrapper">
                <div class="castiguri-field-new" v-if="userData">
                  <div class="castiguri-bounty-wrapper">
                    <div class="castiguri-field-top-new">Total</div>
                    <div
                      class="castiguri-field-bottom-new incasat-tabel"
                      v-if="allIncasat && userData.show_incasat"
                    >
                      {{
                        (
                          Number(allIncasat) +
                          Number(allIncasatRecruti)
                        ).toFixed(2)
                      }}
                      RON
                    </div>
                    <div
                      class="castiguri-field-bottom-new incasat-tabel blurred-text"
                      v-if="allIncasat && !userData.show_incasat"
                    >
                      {{
                        (
                          Number(allIncasat) +
                          Number(allIncasatRecruti)
                        ).toFixed(2)
                      }}
                      RON
                    </div>
                    <div
                      class="castiguri-field-bottom-new incasat-tabel"
                      v-if="allIncasat === 0 && userData.show_incasat"
                    >
                      0 RON
                    </div>
                    <div
                      class="castiguri-field-bottom-new incasat-tabel blurred-text"
                      v-if="allIncasat === 0 && !userData.show_incasat"
                    >
                      0 RON
                    </div>
                  </div>
                </div>
                <div class="castiguri-field-new" v-if="userData">
                  <div class="castiguri-bounty-wrapper">
                    <div class="castiguri-field-top-new"><span v-if="userData && userData.language === 'romana'">Contracte proprii</span>
                <span v-if="userData && userData.language === 'english'">Your contracts</span></div>
                    <div
                      class="castiguri-field-bottom-new"
                      v-if="allIncasat && userData.show_incasat"
                    >
                      {{ allIncasat}} RON
                    </div>
                    <div
                      class="castiguri-field-bottom-new blurred-text"
                      v-if="allIncasat && !userData.show_incasat"
                    >
                      {{ allIncasat }} RON
                    </div>
                    <div
                      class="castiguri-field-bottom-new incasat-tabel"
                      v-if="allIncasat === 0 && userData.show_incasat"
                    >
                      0 RON
                    </div>
                    <div
                      class="castiguri-field-bottom-new incasat-tabel blurred-text"
                      v-if="allIncasat === 0 && !userData.show_incasat"
                    >
                      0 RON
                    </div>
                  </div>
                </div>
                <!--               <div class="castiguri-field-new" v-if="userData">
                <div class="castiguri-bounty-wrapper">
                <div class="castiguri-field-top-new">Bounty-uri</div>
                <div class="castiguri-field-bottom-new" v-if="allIncasat && userData.show_incasat">DE CALCULAT</div>
                <div class="castiguri-field-bottom-new blurred-text" v-if="allIncasat && !userData.show_incasat">DE CALCULAT</div>
                <div class="castiguri-field-bottom-new incasat-tabel" v-if="!allIncasat">0
                  RON</div>
              </div>
              </div> -->
                <div class="castiguri-field-new" v-if="userData">
                  <div class="castiguri-bounty-wrapper">
                    <div class="castiguri-field-top-new"><span v-if="userData && userData.language === 'romana'">Recruti</span>
                <span v-if="userData && userData.language === 'english'">From recruits</span></div>
                    <div
                      class="castiguri-field-bottom-new"
                      v-if="allIncasatRecruti && userData.show_incasat"
                    >
                      {{ allIncasatRecruti }} RON
                    </div>
                    <div
                      class="castiguri-field-bottom-new blurred-text"
                      v-if="allIncasatRecruti && !userData.show_incasat"
                    >
                      {{ allIncasatRecruti }} RON
                    </div>
                  </div>
                </div>
                <div class="castiguri-field-new" v-if="userData">
                  <div class="castiguri-bounty-wrapper">
                    <div class="castiguri-field-top-new"><span v-if="userData && userData.language === 'romana'">Ultimele 30 zile</span>
                <span v-if="userData && userData.language === 'english'">Last 30 days</span></div>
                    <div
                      class="castiguri-field-bottom-new"
                      v-if="userData.show_incasat"
                    >
                      {{ ultimele30Incasat }} RON
                    </div>
                    <div
                      class="castiguri-field-bottom-new blurred-text"
                      v-if="!userData.show_incasat"
                    >
                      {{ ultimele30Incasat }} RON
                    </div>
                  </div>
                </div>
              </div>
              <div class="todo-title-inside"><span v-if="userData && userData.language === 'romana'">De incasat</span>
                <span v-if="userData && userData.language === 'english'">To be collected</span></div>
              <div class="profile-row-wrapper">
                <div class="castiguri-field-new">
                  <div class="castiguri-bounty-wrapper" v-if="userData">
                    <div class="castiguri-field-top-new">
                      <span v-if="userData && userData.language === 'romana'">Contracte one-time</span>
                <span v-if="userData && userData.language === 'english'">One-time contracts</span><span class="profile-star">*</span
                      ><br /><span class="info-castiguri">
                        <span v-if="userData && userData.language === 'romana'">Aceasta suma poate varia in realitate, deoarece este
                        posibil ca unele contracte sa fie anulate</span>
                <span v-if="userData && userData.language === 'english'">This amount may vary in reality, because it's possible that some contracts will be cancelled</span>
                        </span
                      >
                    </div>
                    <div
                      class="castiguri-field-bottom-new de-incasat-profil"
                      v-if="
                        deIncasatSemnate &&
                        deIncasatSemnate != 0 &&
                        userData.show_incasat
                      "
                    >
                      {{ deIncasatSemnate }} RON
                    </div>
                    <div
                      class="castiguri-field-bottom-new de-incasat-profil blurred-text"
                      v-if="
                        deIncasatSemnate &&
                        deIncasatSemnate != 0 &&
                        !userData.show_incasat
                      "
                    >
                      {{ deIncasatSemnate}} RON
                    </div>
                    <div
                      class="castiguri-field-bottom-new de-incasat-profil"
                      v-if="deIncasatSemnate < 1 && userData.show_incasat"
                    >
                      0 RON
                    </div>
                    <div
                      class="castiguri-field-bottom-new de-incasat-profil blurred-text"
                      v-if="deIncasatSemnate < 1 && !userData.show_incasat"
                    >
                      0 RON
                    </div>
                  </div>
                </div>
                <div class="castiguri-field-new">
                  <div class="castiguri-bounty-wrapper" v-if="userData">
                    <div class="castiguri-field-top-new">
                      <span v-if="userData && userData.language === 'romana'">Total contracte recurente</span>
                <span v-if="userData && userData.language === 'english'">Total recurring contracts</span>
                      <span class="profile-star">*</span><br />
                      <span class="info-castiguri"
                        ><span v-if="userData && userData.language === 'romana'">Suma reprezinta totalul ramas de incasat din
                        contractele recurente</span>
                <span v-if="userData && userData.language === 'english'">This amount reflects the total amount to be collected from all recurring contracts</span></span
                      >
                    </div>
                    <div
                      class="castiguri-field-bottom-new de-incasat-profil"
                      v-if="
                        deIncasatRecurentTotal &&
                        deIncasatRecurentTotal != 0 &&
                        userData.show_incasat
                      "
                    >
                      {{ deIncasatRecurentTotal }} RON
                    </div>
                    <div
                      class="castiguri-field-bottom-new de-incasat-profil blurred-text"
                      v-if="
                        deIncasatRecurentTotal &&
                        deIncasatRecurentTotal != 0 &&
                        !userData.show_incasat
                      "
                    >
                      {{ deIncasatRecurentTotal }} RON
                    </div>
                    <div
                      class="castiguri-field-bottom-new de-incasat-profil"
                      v-if="deIncasatRecurentTotal < 1 && userData.show_incasat"
                    >
                      0 RON
                    </div>
                    <div
                      class="castiguri-field-bottom-new de-incasat-profil blurred-text"
                      v-if="
                        deIncasatRecurentTotal < 1 && !userData.show_incasat
                      "
                    >
                      0 RON
                    </div>
                  </div>
                </div>
                <div class="castiguri-field-new">
                  <div class="castiguri-bounty-wrapper" v-if="userData">
                    <div class="castiguri-field-top-new">
                      <span v-if="userData && userData.language === 'romana'">Contracte recurente urmatoarea transa</span>
                <span v-if="userData && userData.language === 'english'">Recurring contracts next payout</span>
                      
                      <span class="profile-star">*</span> <br />
                      <span class="info-castiguri"
                        ><span v-if="userData && userData.language === 'romana'">Suma reprezinta totalul de incasat din contractele
                        recurente pentru urmatoarea transa</span>
                <span v-if="userData && userData.language === 'english'">This amount reflects the total amount to be collected from recurring conctracts on the next payout</span></span
                      >
                    </div>
                    <div
                      class="castiguri-field-bottom-new de-incasat-profil"
                      v-if="
                        deIncasatRecurent &&
                        deIncasatRecurent != 0 &&
                        userData.show_incasat
                      "
                    >
                      {{ deIncasatRecurent }} RON
                    </div>
                    <div
                      class="castiguri-field-bottom-new de-incasat-profil blurred-text"
                      v-if="
                        deIncasatRecurent &&
                        deIncasatRecurent != 0 &&
                        !userData.show_incasat
                      "
                    >
                      {{ deIncasatRecurent }} RON
                    </div>
                    <div
                      class="castiguri-field-bottom-new de-incasat-profil"
                      v-if="deIncasatRecurent < 1 && userData.show_incasat"
                    >
                      0 RON
                    </div>
                    <div
                      class="castiguri-field-bottom-new de-incasat-profil blurred-text"
                      v-if="deIncasatRecurent < 1 && !userData.show_incasat"
                    >
                      0 RON
                    </div>
                  </div>
                </div>
                <div class="castiguri-field-new">
                  <div class="castiguri-bounty-wrapper" v-if="userData">
                    <div class="castiguri-field-top-new">
                      <span v-if="userData && userData.language === 'romana'">Bounty-uri calificate</span>
                <span v-if="userData && userData.language === 'english'">Qualified bounties</span><span class="profile-star">*</span>
                      <br />
                      <span class="info-castiguri"
                        ><span v-if="userData && userData.language === 'romana'">Suma reprezinta totalul posibil de incasat din
                        bounty-urile pentru care sunteti calificat</span>
                <span v-if="userData && userData.language === 'english'">This amount reflects the total possible amount to be collected from all the bounties that you are qualified in</span></span
                      >
                    </div>
                    <div
                      class="castiguri-field-bottom-new de-incasat-profil"
                      v-if="userData.show_incasat"
                    >
                      {{ bountiesSumaDeIncasat }}
                    </div>
                    <div
                      class="castiguri-field-bottom-new de-incasat-profil blurred-text"
                      v-if="!userData.show_incasat"
                    >
                      {{ bountiesSumaDeIncasat }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, ref, computed, onUnmounted } from "vue";
import { supabase } from "../clients/supabase";
import { useStore } from "vuex";

export default {
  name: "ProfilePage",
  components: {},
  data() {
    return {
      error: false,
    };
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      getAllFirme();
      getContracte();
      seeCurrentUser();
      getAllClients();
      getRecommendedClients();
      getAllIncasat();
      getAllIncasatRecruti();
      getDeIncasatSemnate();
      getDeIncasatRecurent();
      getDeIncasatRecurentTotal();
      sumOfSuma();
      getLunaInCurs();
    });

    onUnmounted(() => {
      resetContractStore();
      store.dispatch("contracte/setAllBounties", null);
    });

    // formular
    const clickedFirma = ref("");
    const formNume = ref("");
    const formPrenume = ref("");
    const formTelefon = ref("");
    const formEmail = ref("");

    const formAgentId = ref("");

    // contracte

    const contracte = ref("");

    const listaFirme = ref("");
    const allClients = ref();

    const recomandariKey = ref(0);

    const modalContract = ref();
    const modalName = ref("");
    const modalDate = ref("");

    const modalRatePlatite = ref(0);
    const modalDurata = ref(0);
    const modalStatus = ref("");
    const anuleazaToggle = ref(false);
    const modalComision = ref(0);
    const modalTotalIncasat = ref(0);
    const totalRecruti = ref(0);
    const bountiesSumaDeIncasat = ref(0);

    const userId = computed(() => {
      return store.getters["user/getUserId"];
    });

    const contracteStore = computed(() => {
      return store.getters["contracte/getAllContracte"];
    });

    const userData = computed(() => {
      return store.getters["user/getUserData"];
    });

    const allIncasat = computed(() => {
      return store.getters["user/getAllIncasat"];
    });

    const allIncasatRecruti = computed(() => {
      return store.getters["user/getAllIncasatRecruti"];
    });

    const deIncasatSemnate = computed(() => {
      return store.getters["user/getDeIncasatSemnate"];
    });

    const deIncasatRecurent = computed(() => {
      return store.getters["user/getDeIncasatRecurent"];
    });

    const deIncasatRecurentTotal = computed(() => {
      return store.getters["user/getDeIncasatRecurentTotal"];
    });

    const recommendedClientsStore = computed(() => {
      return store.getters["clienti/getRecommendedClients"];
    });

    const allBounties = computed(() => {
      return store.getters["contracte/getAllBounties"];
    });

    const ultimele30Incasat = computed(() => {
      return store.getters["contracte/getUltimele30Incasat"];
    });

    function resetData() {
      modalContract.value = "";
      modalContract.value = "";
      modalName.value = "";
      modalDate.value = "";
      modalRatePlatite.value = "";
      modalDurata.value = "";
      modalStatus.value = "";
      modalComision.value = "";
      modalTotalIncasat.value = "";
    }

    function resetContractStore() {
      store.dispatch("contracte/setContractePag", null);
      store.dispatch("contracte/setAllContracte", null);
      store.dispatch("clienti/setRecommendedClients", null);
    }

    async function sumOfSuma() {
      let sum = 0;
      const user = await userId.value;
      await getBounties();

      for (const obj of allBounties.value) {
        if (obj && obj.calificati !== null && obj.calificati.includes(user)) {
          sum += obj.bonus;
        }
      }

      return (bountiesSumaDeIncasat.value = sum);
    }

    const getBounties = async () => {
      try {
        const { data, error } = await supabase.from("bounties").select("*");

        if (error) throw error;

        store.dispatch("contracte/setAllBounties", data);
      } catch (error) {
        console.log(error);
      }
    };

    const getLunaInCurs = async () => {
      const incasariArray = ref([]);
      const user = await userId.value;
      console.log("from getlunis");
      console.log(user);
      try {
        const { data, error } = await supabase.rpc("get_entries_last_30_days", {
          agent_id: userId.value,
        });

        console.log("from records");
        console.log(data);
        if (error) throw error;

        incasariArray.value = data.map(({ total_incasat }) => total_incasat);

        let cacat = incasariArray.value;

        let pisat = cacat.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);

        console.log(pisat);
        store.dispatch("contracte/setUltimele30Incasat", pisat);
      } catch (error) {
        console.log(error);
      }
    };

    const hideBalance = async () => {
      try {
        const { error } = await supabase
          .from("roles")
          .update({
            show_incasat: false,
          })
          .eq("user_id", userId.value)
          .select();
        if (error) throw error;
        checkRole();
      } catch (error) {
        console.log(error);
      }
    };

    const showBalance = async () => {
      try {
        const { error } = await supabase
          .from("roles")
          .update({
            show_incasat: true,
          })
          .eq("user_id", userId.value)
          .select();
        if (error) throw error;
        checkRole();
      } catch (error) {
        console.log(error);
      }
    };

    const checkRole = async () => {
      const { data, error } = await supabase
        .from("roles")
        .select()
        .eq("user_id", userId.value);

      if (error) {
        console.log(error);
      }
      store.dispatch("user/setUserData", data[0]);
    };

    const getRecommendedClients = async () => {
      try {
        const { data, error } = await supabase
          .from("client")
          .select()
          .eq("agent_id", userId.value)
          .eq("status", "recomandat");
        if (error) throw error;

        store.dispatch("clienti/setRecommendedClients", data);
      } catch (error) {
        console.log(error);
      }
    };

    const getAllClients = async () => {
      try {
        const { data, error } = await supabase
          .from("client")
          .select("*")
          .eq("agent_id", userId.value);
        if (error) throw error;
        allClients.value = data;
        store.dispatch("clienti/setAllClients", data);
        console.log(allClients.value);
      } catch (error) {
        console.log(error);
      }
    };

    const getContracte = async () => {
      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("agent", userId.value);

        if (error) throw error;
        contracte.value = data;
        store.dispatch("contracte/setAllContracte", data);
      } catch (error) {
        console.log(error);
      }
    };

    const getAllIncasat = async () => {
      const incasariArray = ref([]);

      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("agent", userId.value);
        if (error) throw error;

        incasariArray.value = data.map(({ total_incasat }) => total_incasat);

        let cacat = incasariArray.value;

        let pisat = cacat.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);

        console.log(pisat);
        store.dispatch("user/setAllIncasat", pisat);
      } catch (error) {
        console.log(error);
      }
    };

    const getDeIncasatSemnate = async () => {
      const incasariArray = ref([]);
      let incasariFull = []
      let incasariHalf = []

      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("agent", userId.value)
          .eq("tip", "oneTimeCheck")
          .eq("status", "semnat");

        if (error) throw error;

        incasariArray.value = data.map(({ comision }) => comision);

        incasariFull = data.filter(function(obj) {
          return obj.agent_status === 'sub'
        }).map(function(obj) {return obj.comision})
        console.log('incasari full')
        console.log(incasariFull)

        incasariHalf = data.filter(function(obj) {
          return obj.agent_status !== 'sub'
        }).map(function(obj) {return obj.comision})
        console.log('incasari half')
        console.log(incasariHalf)

        let finalIncasariFull = incasariFull.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0)

        let finalIncasariHalf = incasariHalf.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0)

        let finalulCalcul = ((finalIncasariFull * 0.87) + (finalIncasariHalf * 0.445)).toFixed(2)

        console.log(finalulCalcul)

        let cacat = incasariArray.value;

        let pisat = cacat.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);

        console.log(pisat);
        store.dispatch("user/setDeIncasatSemnate", finalulCalcul);
      } catch (error) {
        console.log(error);
      }
    };

    const getDeIncasatRecurent = async () => {
      const incasariArray = ref([]);
      let incasariFull = []
      let incasariHalf = []

      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("agent", userId.value)
          .eq("tip", "recurentCheck")
          .in("status", ["semnat", "derulare"]);

        if (error) throw error;

        incasariArray.value = data.map(({ comision }) => comision);

        incasariFull = data.filter(function(obj) {
          return obj.agent_status === 'sub'
        }).map(function(obj) {return obj.comision})
        console.log('incasari full')
        console.log(incasariFull)

        incasariHalf = data.filter(function(obj) {
          return obj.agent_status !== 'sub'
        }).map(function(obj) {return obj.comision})
        console.log('incasari half')
        console.log(incasariHalf)

        let finalIncasariFull = incasariFull.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0)

        let finalIncasariHalf = incasariHalf.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0)

        let finalulCalcul = ((finalIncasariFull * 0.87) + (finalIncasariHalf * 0.445)).toFixed(2)

        

        let cacat = incasariArray.value;

        let pisat = cacat.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);

        console.log(pisat);
        store.dispatch("user/setDeIncasatRecurent", finalulCalcul);
      } catch (error) {
        console.log(error);
      }
    };


    //////// AM RAMAS AICI DE FACUT!!!!!!!

    const getDeIncasatRecurentTotal = async () => {
      const incasariArray = ref([]);

      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("agent", userId.value)
          .eq("tip", "recurentCheck")
          .in("status", ["semnat", "derulare"]);

        if (error) throw error;
        console.log("de la de incasat recurente total");
        console.log(data);

        let incasariFull = []
      let incasariHalf = []
      

      incasariFull = data.filter(function(obj) {
          return obj.agent_status === 'sub'
        }).map(function(obj) {return obj.comision})
        console.log('incasari full')
        console.log(incasariFull)

        incasariHalf = data.filter(function(obj) {
          return obj.agent_status !== 'sub'
        }).map(function(obj) {return obj.comision})
        console.log('incasari half')
        console.log(incasariHalf)

        incasariArray.value = data.map(
          (item) => item.comision * (item.durata - item.rate_platite)
        );

        let incasariFullCalculat = incasariFull.map(
          (item) => (item.comision * (item.durata - item.rate_platite)) * 0.87
        )

        let incasariHalfCalculat = incasariHalf.map(
          (item) => (item.comision * (item.durata - item.rate_platite)) * 0.445
        )

        let incasariTotalCalcul = (incasariFullCalculat + incasariHalfCalculat).toFixed(2)

        console.log("totalul");
        console.log(incasariArray.value);

        let cacat = incasariArray.value;

        let pisat = cacat.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);

        console.log(pisat);
        store.dispatch("user/setDeIncasatRecurentTotal", incasariTotalCalcul);
      } catch (error) {
        console.log(error);
      }
    };

    const getAllIncasatRecruti = async () => {
      const incasariArray = ref([]);
      const arrayInvitati = ref([]);

      try {
        const { data, error } = await supabase
          .from("roles")
          .select()
          .eq("invited_by", userId.value);
        if (error) throw error;

        arrayInvitati.value = data.map(({ user_id }) => user_id);
      } catch (error) {
        console.log(error);
      }

      let iduriInvitati = arrayInvitati.value.map((id) => id);

      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .in("agent", iduriInvitati);
        if (error) throw error;

        incasariArray.value = data.map(({ total_incasat_parinte }) => total_incasat_parinte);

        let cacat = incasariArray.value;

        let pisat = cacat.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);

        store.dispatch("user/setAllIncasatRecruti", pisat.toFixed(2));
      } catch (error) {
        console.log(error);
      }
    };

    const seeCurrentUser = async () => {
      try {
        const { data } = await supabase.auth.getUser();

        formAgentId.value = data.user.id;
      } catch (error) {
        console.log(error);
      }
    };

    // Send contract

    const sendClientButton = async () => {
      try {
        const { error } = await supabase.from("client").insert({
          status: "recomandat",

          nume: formNume.value,
          prenume: formPrenume.value,
          email: formEmail.value,
          telefon: formTelefon.value,

          agent_id: formAgentId.value,
        });
        getAllClients();
        recomandariKey.value += 1;

        formNume.value = "";
        formPrenume.value = "";
        formEmail.value = "";
        formTelefon.value = "";

        clickedFirma.value = "";

        store.commit("user/setToaster", "Recomandare inregistrata cu succes!");
        if (error) throw error;
      } catch (error) {
        console.log(error);
      }
    };

    // Get firme

    const getAllFirme = async () => {
      try {
        const { data, error } = await supabase.from("firma").select("*");
        if (error) throw error;
        listaFirme.value = data;
        store.dispatch("firme/setAllFirme", data);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

    return {
      sendClientButton,
      anuleazaToggle,
      getAllFirme,
      listaFirme,
      allBounties,
      seeCurrentUser,
      getContracte,
      userId,
      contracteStore,
      recomandariKey,
      recommendedClientsStore,
      resetData,
      allIncasat,
      getAllIncasatRecruti,
      allIncasatRecruti,
      totalRecruti,
      resetContractStore,
      userData,
      deIncasatSemnate,
      deIncasatRecurent,
      deIncasatRecurentTotal,
      bountiesSumaDeIncasat,
      ultimele30Incasat,
      showBalance,
      hideBalance,
    };
  },
  created() {},
  methods: {},
};
</script>
