/* eslint-disable */
<template>
  <div class="page-container">
    <div class="width-container">
      <div class="page-title-pub">
        <div class="titlu-companii-page" v-if="userData && userData.language === 'romana'">Tutoriale video</div>
        <div class="titlu-companii-page" v-if="userData && userData.language === 'english'">Video tutorials</div>
      </div>
      

      <div class="public-page">
        <div class="container-vids-tut">

        
        <div class="video-bg-tut">
            <div class="video-title">
                Introducere platforma
            </div>
                    
            <video-player 
            src="https://kmgppqgrgckdzebbtvqy.supabase.co/storage/v1/object/public/images/agentclipfinal.mp4"
            controls
            class="vjs-big-play-centered"
            :fluid="true"
                      />
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, ref, computed, onUnmounted } from "vue";
import { supabase } from "../clients/supabase";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "TutorialeVideoPage",
  components: {},
  data() {
    return {
      showModal: false
    };
  },
  setup() {
    const store = useStore();
    const router = useRouter()

    onMounted(() => {
      getAllFirme();
      checkRole()
    });

    onUnmounted(() => {
      resetFirmeStore();
    });

    // formular

    // contracte

    const listaFirme = ref("");

    const searchString = ref("");
    const showSearch = ref(false)
    const modalSubscribe = ref(false)
    const recomandariKey = ref(0);
    const listaActive = ref(false);
    const cautaActive = ref(false);

    const userId = computed(() => {
      return store.getters["user/getUserId"];
    });

    const firmeSubbed = computed(() => {
      return store.getters["firme/getFirmeSubbed"];
    });

    const contracteStore = computed(() => {
      return store.getters["contracte/getAllContracte"];
    });

    const allClientsStore = computed(() => {
      return store.getters["clienti/getAllClients"];
    });

    const allFirmeStore = computed(() => {
      return store.getters["firme/getAllFirme"];
    });

    const recommendedClientsStore = computed(() => {
      return store.getters["clienti/getRecommendedClients"];
    });

    const searchResponse = computed(() => {
      return store.getters["user/getSearchResponse"];
    });

    const userData = computed(() => {
      return store.getters["user/getUserData"];
    });

    function resetFirmeStore() {
      store.dispatch("firme/setAllFirme", null);
    }

    function inchideSearch() {
      cautaActive.value = false;
      searchString.value = "";
      store.dispatch("user/setSearchResponse", null);
    }

    function toCompanie(id) {
      if(userData.value.subscription_status != 'sub') {
        return
      }
      router.push(`/companie/${id}`)
    }

    function sendTo(id) {
      router.push(`/companie/${id}`)
    }

    const checkRole = async () => {
      const { data, error } = await supabase
        .from("roles")
        .select()
        .eq("user_id", userId.value);

      if (error) {
        console.log(error);
      }
      store.dispatch("user/setUserData", data[0]);
    };


    const cautaButton = async () => {
      if (searchString.value === "") {
        return;
      }

      if (cautaActive.value === false) {
        cautaActive.value = true;
      }

      try {
        let cautaText = searchString.value;
        console.log(cautaText);
        const { data, error } = await supabase.rpc("search_firme_category", {
          search_query: cautaText,
        });

        if (error) throw error;
        let firmeSubbed = data.filter((firma) => firma.sub_status === "sub");
        store.dispatch("user/setSearchResponse", firmeSubbed);
      } catch (error) {
        console.log(error);
      }
    };

    // Send contract

    // Get firme

    const getAllFirme = async () => {
      try {
        const { data, error } = await supabase
          .from("firma")
          .select('*')
          .order("order_id", { ascending: false })
          
        if (error) throw error;
        listaFirme.value = data;

        store.dispatch("firme/setAllFirme", data);
        let firmeSubbed = data.filter((firma) => firma.sub_status === "sub");

        store.dispatch("firme/setFirmeSubbed", firmeSubbed);
       
      } catch (error) {
        console.log(error);
      }
    };

    return {
      getAllFirme,
      listaFirme,
      modalSubscribe,
      cautaButton,
      searchString,
      cautaActive,
      searchResponse,
      userId,
      contracteStore,
      allClientsStore,
      allFirmeStore,
      recomandariKey,
      recommendedClientsStore,
      inchideSearch,
      firmeSubbed,
      sendTo,
      listaActive,
      showSearch,
      userData,
      toCompanie
    };
  },
  created() {},
  methods: {},
};
</script>
