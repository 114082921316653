/* eslint-disable */
<template>
  <div class="page-container">
    <div class="width-container">
      <div class="page-title" v-if="userData && userData.language === 'romana'">Recomandari</div>
      <div class="page-title" v-if="userData && userData.language === 'english'">Recommendations</div>
      <div class="home-page">
        <div class="main-content">
          <div class="contracte-main-content">

            <div class="recomandari-container" v-if="recommendedClientsStore">
              <div class="box-header-row">
                  <div class="todo-title" v-if="userData && userData.language === 'romana'">Recomandari trimise</div>
                  <div class="todo-title" v-if="userData && userData.language === 'english'">Sent recommendations</div>

                  <router-link to="/recomandariarhivate"
                    ><div class="show-more mobile-hide">
                      <span v-if="userData && userData.language === 'romana'">Recomandari arhivate</span>
                      <span v-if="userData && userData.language === 'english'">Archived recommendations</span>
                    </div></router-link
                  >
                </div>
              
              <div
                class="fara-clienti"
                v-if="recommendedClientsStore.length === 0"
              >
              <span v-if="userData && userData.language === 'romana'">Nu aveti nicio recomandare trimisa</span>
                      <span v-if="userData && userData.language === 'english'">You haven't sent any recommendations</span>
              </div>
              <table
                class="orders-table"
                v-if="recommendedClientsStore.length != 0"
              >
                <col class="col-order-no" />
                <col class="col-order-name" />

                <col class="col-order-id" />
                <col class="col-order-ref" />

                <col class="col-order-more" />
                <tr class="products-table-head">
                  <th class="col-order-no">ID</th>
                  <th><span v-if="userData && userData.language === 'romana'">Firma</span>
                      <span v-if="userData && userData.language === 'english'">Company</span></th>
                  <th><span v-if="userData && userData.language === 'romana'">Nume</span>
                      <span v-if="userData && userData.language === 'english'">Name</span></th>

                  <th><span v-if="userData && userData.language === 'romana'">Telefon</span>
                      <span v-if="userData && userData.language === 'english'">Telephone</span></th>
                  <th>Email</th>
                  
                  <th><span v-if="userData && userData.language === 'romana'">Detalii</span>
                      <span v-if="userData && userData.language === 'english'">Details</span></th>
                  
                </tr>
                <tr
                  class="products-table-item"
                  @click="
                    activateModal(client.id)
                    showModal = true;
                  "
                  v-for="client in recommendedClientsStore"
                  :key="client.id"
                >
                  <th class="table-item">{{ client.id }}</th>
                  <th class="table-item" v-if="allFirmeStore">{{
                      allFirmeStore.find((ele) => ele.id === client.firma)
                        .nume
                    }}
                  </th>
                  <th class="product-name">
                    {{ client.prenume }} {{ client.nume }}
                  </th>

                  <th class="table-item">{{ client.telefon }}</th>
                  <th class="table-item">{{ client.email }}</th>
                  
                  <th class="table-item-detalii">{{ client.detalii }}</th>
                  
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <vue-final-modal
        v-model="showModal"
        classes="modal-container"
        content-class="modal-content"
        v-if="modalContract"
        @closed="resetData()"
      >
        <div class="contract-item">
            <div class="profile-field-agent">
            <div class="profile-field-left">ID</div>
            <div class="profile-field-right">{{ modalId }}</div>
          </div>
          <div class="profile-field-agent">
            <div class="profile-field-left"><span v-if="userData && userData.language === 'romana'">Nume</span>
                      <span v-if="userData && userData.language === 'english'">Name</span></div>
            <div class="profile-field-right">{{ modalName }}</div>
          </div>
          <div class="profile-field-agent">
            <div class="profile-field-left"><span v-if="userData && userData.language === 'romana'">Telefon</span>
                      <span v-if="userData && userData.language === 'english'">Telephone</span></div>
            <div class="profile-field-right">{{ modalTelefon}}</div>
          </div>
          <div class="profile-field-agent">
            <div class="profile-field-left">Email</div>
            <div class="profile-field-right">{{ modalEmail }}</div>
          </div>
          <div class="profile-field-agent">
            <div class="profile-field-left"><span v-if="userData && userData.language === 'romana'">Recomandat catre</span>
                      <span v-if="userData && userData.language === 'english'">Recommended to</span></div>
            <div class="profile-field-right">{{
                      allFirmeStore.find((ele) => ele.id === modalFirma)
                        .nume
                    }}</div>
          </div>
          <div class="profile-field-agent">
            <div class="profile-field-left"><span v-if="userData && userData.language === 'romana'">Data recomandarii</span>
                      <span v-if="userData && userData.language === 'english'">Recommendation date</span></div>
            <div class="profile-field-right">{{ modalDate }}</div>
          </div>
          
          <div class="profile-field-detalii">
            <div class="profile-field-left"><span v-if="userData && userData.language === 'romana'">Detalii</span>
                      <span v-if="userData && userData.language === 'english'">Details</span></div>
            <textarea class="profile-field-right-textarea" v-model="modalDetalii" disabled>
              
            </textarea>


          </div>
        </div>
      </vue-final-modal>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, ref, computed, onUnmounted, watch } from "vue";
import { supabase } from "../clients/supabase";
import { useStore } from "vuex";

export default {
  name: "ContractePage",
  components: {},
  data() {
    return {
      showModal: false,
      error: false,
      formdata: {
        firma: "",
        nume: "",
        telefon: "",
        descriere: "",
        mesaj: "",
      },
    };
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      getAllFirme();
      getContracte();
      seeCurrentUser();
      getAllClients();
      getRecommendedClients();
      getAllIncasat();
      getAllIncasatRecruti();
      getContractePag();
    });

    onUnmounted(() => {
      resetContractStore();
    });

    // formular
    const clickedFirma = ref("");
    const formNume = ref("");
    const formPrenume = ref("");
    const formTelefon = ref("");
    const formEmail = ref("");
    const formDetalii = ref("");
    const formFirma = ref("");

    const formAgentId = ref("");

    // contracte

    const contracte = ref("");

    const listaFirme = ref("");
    const allClients = ref();

    const modalId = ref("");
    

    const recomandariKey = ref(0);

    const modalContract = ref();
    const modalName = ref("");
    const modalDate = ref("");
    const modalFirma = ref('')
    const modalTelefon = ref('');
    const modalEmail = ref('');
    const modalDetalii = ref("");
    const anuleazaToggle = ref(false);
    const totalRecruti = ref(0);

    const contractPagePagination = ref(0);
    const firstPage = ref(true);
    const lastPage = ref(true);

    const arrayFiltrePag = ref(["finalizat", "anulat", "derulare", "semnat"]);

    const filtruContractSemnat = ref(true);
    const filtruContractDerulare = ref(true);
    const filtruContractFinalizat = ref(true);
    const filtruContractAnulat = ref(true);

    const userId = computed(() => {
      return store.getters["user/getUserId"];
    });

    const contracteStore = computed(() => {
      return store.getters["contracte/getAllContracte"];
    });

    const contractePag = computed(() => {
      return store.getters["contracte/getContractePag"];
    });

    const allClientsStore = computed(() => {
      return store.getters["clienti/getAllClients"];
    });

    const allFirmeStore = computed(() => {
      return store.getters["firme/getAllFirme"];
    });

    const allIncasat = computed(() => {
      return store.getters["user/getAllIncasat"];
    });

    const userData = computed(() => {
      return store.getters["user/getUserData"];
    });

    const allIncasatRecruti = computed(() => {
      return store.getters["user/getAllIncasatRecruti"];
    });

    const recommendedClientsStore = computed(() => {
      return store.getters["clienti/getRecommendedClients"];
    });

    watch(filtruContractSemnat, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("semnat");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "semnat"
        );

        getContractePag();
      }
    });

    watch(filtruContractDerulare, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("derulare");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "derulare"
        );

        getContractePag();
      }
    });

    watch(filtruContractFinalizat, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("finalizat");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "finalizat"
        );

        getContractePag();
      }
    });

    watch(filtruContractAnulat, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("anulat");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "anulat"
        );

        getContractePag();
      }
    });

    function setFirma(id) {
      clickedFirma.value = id;
      formFirma.value = id;
    }

    function resetData() {
      modalContract.value = "";
      modalContract.value = "";
      modalName.value = "";
      modalDate.value = "";
    }

    function resetContractStore() {
      store.dispatch("contracte/setContractePag", null);
      store.dispatch("contracte/setAllContracte", null);
      store.dispatch("clienti/setRecommendedClients", null);
    }

    function anuleazaContractToggle() {
      anuleazaToggle.value = true;
      setTimeout(() => {
        anuleazaToggle.value = false;
      }, 3000);
    }

    function activateModal(id) {
      modalId.value = id;
      
      getModalInfo();
    }

    const getContractePag = async () => {
      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("agent", userId.value)
          .in("status", arrayFiltrePag.value)
          .range(0, 19);

        store.dispatch("contracte/setContractePag", data);
        if (error) throw error;
        if (contractPagePagination.value + 20 <= contracteStore.value.length) {
          lastPage.value = false;
          console.log("last page");
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getNext = async () => {
      try {
        if (contractPagePagination.value + 20 >= contracteStore.value.length) {
          lastPage.value = true;
          console.log("last page");
        } else {
          lastPage.value = false;
          contractPagePagination.value += 20;
          let rangePagination = contractPagePagination.value + 19;
          const { data, error } = await supabase
            .from("contract")
            .select()
            .eq("agent", userId.value)
            .in("status", arrayFiltrePag.value)
            .range(contractPagePagination.value, rangePagination);

          if (
            contractPagePagination.value + 20 >=
            contracteStore.value.length
          ) {
            lastPage.value = true;
          }
          if (contractPagePagination.value >= 0) {
            firstPage.value = false;
            console.log("first page");
          }
          if (error) throw error;

          store.dispatch("contracte/setContractePag", data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getPrev = async () => {
      try {
        if (contractPagePagination.value <= 0) {
          firstPage.value = true;
          console.log("first page");
        } else {
          firstPage.value = false;
          let rangePagination = contractPagePagination.value - 20;
          const { data, error } = await supabase
            .from("contract")
            .select()
            .eq("agent", userId.value)
            .in("status", arrayFiltrePag.value)
            .range(rangePagination, contractPagePagination.value - 1);
          contractPagePagination.value -= 20;
          if (contractPagePagination.value <= 0) {
            firstPage.value = true;
            console.log("first page");
          }
          if (
            contractPagePagination.value + 20 <=
            contracteStore.value.length
          ) {
            lastPage.value = false;
          }
          if (error) throw error;

          store.dispatch("contracte/setContractePag", data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getRecommendedClients = async () => {
      try {
        const { data, error } = await supabase
          .from("client")
          .select()
          .eq("agent_id", userId.value)
          .eq("status", "recomandat")
          .order('created_at', { ascending: false})
        if (error) throw error;

        store.dispatch("clienti/setRecommendedClients", data);
      } catch (error) {
        console.log(error);
      }
    };

    const getAllClients = async () => {
      try {
        const { data, error } = await supabase
          .from("client")
          .select("*")
          .eq("agent_id", userId.value);
        if (error) throw error;
        allClients.value = data;
        store.dispatch("clienti/setAllClients", data);
        console.log(allClients.value);
      } catch (error) {
        console.log(error);
      }
    };

    const getContracte = async () => {
      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("agent", userId.value);

        if (error) throw error;
        contracte.value = data;
        store.dispatch("contracte/setAllContracte", data);
      } catch (error) {
        console.log(error);
      }
    };

    const getModalInfo = async () => {
      try {
        const { data, error } = await supabase
          .from("client")
          .select()
          .eq("id", modalId.value);
        if (error) throw error;

        modalContract.value = data[0];
        modalName.value = data[0].nume + ' ' + data[0].prenume
        modalDate.value = data[0].created_at.slice(0, 10);
        modalEmail.value = data[0].email;
        modalTelefon.value = data[0].telefon;
        modalDetalii.value = data[0].detalii;
        modalFirma.value = data[0].firma
      } catch (error) {
        console.log(error);
      }
    };

    const getAllIncasat = async () => {
      const incasariArray = ref([]);

      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("agent", userId.value);
        if (error) throw error;

        incasariArray.value = data.map(({ total_incasat }) => total_incasat);

        let cacat = incasariArray.value;

        let pisat = cacat.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);

        console.log(pisat);
        store.dispatch("user/setAllIncasat", pisat);
      } catch (error) {
        console.log(error);
      }
    };

    const getAllIncasatRecruti = async () => {
      const incasariArray = ref([]);
      const arrayInvitati = ref([]);

      try {
        const { data, error } = await supabase
          .from("roles")
          .select()
          .eq("invited_by", userId.value);
        if (error) throw error;

        arrayInvitati.value = data.map(({ user_id }) => user_id);
        totalRecruti.value = arrayInvitati.value.length;
      } catch (error) {
        console.log(error);
      }

      let iduriInvitati = arrayInvitati.value.map((id) => id);

      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .in("agent", iduriInvitati);
        if (error) throw error;

        console.log("data de la incasat recruti");

        console.log(data);

        incasariArray.value = data.map(({ total_incasat }) => total_incasat);

        let cacat = incasariArray.value;

        let pisat = cacat.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);

        console.log(pisat);
        store.dispatch("user/setAllIncasatRecruti", pisat.toFixed(2));
      } catch (error) {
        console.log(error);
      }
    };

    const seeCurrentUser = async () => {
      try {
        const { data } = await supabase.auth.getUser();
        console.log("from seecurentuser");
        console.log(data.user.id);
        formAgentId.value = data.user.id;
      } catch (error) {
        console.log(error);
      }
    };

    // Send contract

    const sendClientButton = async () => {
      try {
        const { error } = await supabase.from("client").insert({
          status: "recomandat",
          firma: formFirma.value,
          nume: formNume.value,
          prenume: formPrenume.value,
          email: formEmail.value,
          telefon: formTelefon.value,
          detalii: formDetalii.value,
          agent_id: formAgentId.value,
        });
        getAllClients();
        recomandariKey.value += 1;
        formFirma.value = "";
        formNume.value = "";
        formPrenume.value = "";
        formEmail.value = "";
        formTelefon.value = "";
        formDetalii.value = "";
        clickedFirma.value = "";

        store.commit("user/setToaster", "Recomandare inregistrata cu succes!");
        if (error) throw error;
      } catch (error) {
        console.log(error);
      }
    };

    // Get firme

    const getAllFirme = async () => {
      try {
        const { data, error } = await supabase.from("firma").select("*");
        if (error) throw error;
        listaFirme.value = data;
        store.dispatch("firme/setAllFirme", data);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

    return {
      formFirma,
      formNume,
      formPrenume,
      formTelefon,
      formEmail,
      formDetalii,
      anuleazaContractToggle,
      clickedFirma,
      sendClientButton,
      anuleazaToggle,
      getAllFirme,
      listaFirme,
      setFirma,
      seeCurrentUser,
      getContracte,
      userId,
      contracteStore,
      allClientsStore,
      allFirmeStore,
      recomandariKey,
      recommendedClientsStore,
      resetData,
      allIncasat,
      activateModal,
      getModalInfo,
      modalName,
      modalDate,
      modalContract,
      modalTelefon,
      modalEmail,
      modalDetalii,
      modalFirma,
      getAllIncasatRecruti,
      allIncasatRecruti,
      totalRecruti,
      getNext,
      contractePag,
      getPrev,
      firstPage,
      lastPage,
      resetContractStore,
      filtruContractSemnat,
      filtruContractDerulare,
      filtruContractFinalizat,
      filtruContractAnulat,
      modalId,
      userData
    };
  },
  created() {},
  methods: {},
};
</script>
