/* eslint-disable */
<template>
  <div class="page-container">
    <div class="width-container">
      <div class="page-title">Verificari timere</div>
      <div class="home-page">
        <div class="main-content">

          <div class="profile-main-content" v-if="uploadedContracts">
            <div class="todo-title">Command center</div>

            <div class="admin-box-meniu" @click="verificaInvitesGlobal()">
                      <div class="admin-meniu-text">
                          Verifica INVITES: global expiry
                      </div>
            </div>
            <div class="admin-box-meniu" @click="verificaInvitesRedeem()">
                      <div class="admin-meniu-text">
                          Verifica INVITES: redeem time
                      </div>
            </div>
            <div class="admin-box-meniu" @click="verificaInvitesSub()">
                      <div class="admin-meniu-text">
                          Verifica INVITES: subscribe time
                      </div>
            </div>
            <div class="admin-box-meniu" @click="verificatimpul()">
                      <div class="admin-meniu-text">
                          Verifica TIMPUL
                      </div>
            </div>
            <div class="admin-box-meniu" @click="verificaStripe()">
                      <div class="admin-meniu-text">
                          Verifica stripe
                      </div>
            </div>
            

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, ref, computed, onUnmounted } from "vue";
import { supabase } from "../clients/supabase";
import { useStore } from "vuex";
import { uuid } from 'vue3-uuid'



export default {
  name: "AdminVerificari",
  components: {},
  data() {
    return {
      showModal: false,
      error: false,
    };
  },
  setup() {
    const store = useStore();
   

    onMounted(() => {
      getAllFirme();
      getAllRecruti()
      getInvitedByName();
      getInvitesOwned()
      getUploadedContracts()
    });

    onUnmounted(() => {
      resetThisFirma();
    });

    // formular



    // contracte

    
    const inviteMail = ref()
    const listaFirme = ref("");
    const totalRecruti = ref(0);


    const recomandariKey = ref(0);



    const userId = computed(() => {
      return store.getters["user/getUserId"];
    });

    const contracteStore = computed(() => {
      return store.getters["contracte/getAllContracte"];
    });


    const allClientsStore = computed(() => {
      return store.getters["clienti/getAllClients"];
    });

    const allFirmeStore = computed(() => {
      return store.getters["firme/getAllFirme"];
    });

    const invitedByName = computed(() => {
      return store.getters["user/getInvitedByName"];
    });

    const recommendedClientsStore = computed(() => {
      return store.getters["clienti/getRecommendedClients"];
    });

    const userData = computed(() => {
      return store.getters["user/getUserData"];
    });

    const allRecruti = computed(() => {
      return store.getters["user/getAllRecruti"];
    });

    const invitesOwned = computed(() => {
      return store.getters["user/getInvitesOwned"];
    });

    const uploadedContracts = computed(() => {
      return store.getters["user/getUploadedContracts"];
    });


    function resetThisFirma() {
      store.dispatch("firme/setThisFirma", null);
      
    }

    function verificatimpul() {
      const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');

    // set redeem time to 7 days from now and format it to YYYY-MM-DD HH:MM:SS
    const subscribeTime = new Date();
    subscribeTime.setDate(subscribeTime.getDate() + 2);
    const subscribeTimestamp = subscribeTime.getFullYear() +
        '-' + pad(subscribeTime.getMonth() + 1) +
        '-' + pad(subscribeTime.getDate()) +
        ' ' + pad(subscribeTime.getHours()) +
        ':' + pad(subscribeTime.getMinutes()) +
        ':' + pad(subscribeTime.getSeconds());

        console.log(subscribeTimestamp)
        console.log(subscribeTimestamp)
    }

   

    const verificaInvitesGlobal = async () => {
        const UUID = ref(null)
        UUID.value = uuid.v4()
        let currentDate = new Date().toISOString()
        const { data, error } = await supabase
          .from("invites")
          .update({
            test_column: 'expirat',
            owner_id: 'fe0d3457-6b3b-4a15-b660-2883b436b6d0',
            hash: UUID.value,
            invitee_id: null,
            invitee_email: null,
            redeem_time: null,
            subscribe_time: null,
            global_expiry_time: null,
            status: 'available'
        })
        .neq('status', 'activated')
          .lt('global_expiry_time', currentDate)
          .select()
          console.log(data)
        // await invitesForEach(data)

        if (error) throw error;
    }

    const verificaInvitesRedeem = async () => {
        const UUID = ref(null)
        UUID.value = uuid.v4()
        let currentDate = new Date().toISOString()
        const { data, error } = await supabase
          .from("invites")
          .update({
            test_column: 'expirat_redeem',
            hash: UUID.value,
            invitee_id: null,
            invitee_email: null,
            redeem_time: null,
            subscribe_time: null,
            status: 'owned'
        })
        .eq('status', 'sent')
          .lt('redeem_time', currentDate)
          .select()
          console.log(data)
        // await invitesForEach(data)

        if (error) throw error;
    }

    const verificaInvitesSub = async () => {
        const UUID = ref(null)
        UUID.value = uuid.v4()
        let currentDate = new Date().toISOString()
        const { data, error } = await supabase
          .from("invites")
          .update({
            test_column: 'expirat_sub',
            hash: UUID.value,
            invitee_id: null,
            invitee_email: null,
            redeem_time: null,
            subscribe_time: null,
            status: 'owned'
        })
        .eq('status', 'accepted')
          .lt('subscribe_time', currentDate)
          .select()
          console.log(data)
        // await invitesForEach(data)

        if (error) throw error;
    }


    /* const invitesForEach = async (data) => {
      
      for (const item of data) {
        await updateInvite(item)
      }
    } */

   /*  const updateInvite = async (item) => {
        console.log('iteratie')
        console.log(item)
        let currentDate = new Date().toISOString()
        if(currentDate > item.global_expiry_time) {

            const { data, error } = await supabase
            .from('invites')
            .update({
                test_column: 'depasit'
            })
            .eq('id', item.id)
            .select()
            console.log(data)
            if (error) {
                console.log(error)
            }
            console.log('depasit')
        }
        if(currentDate < item.global_expiry_time) {
            
            const { data: inTermen, inTermenError } = await supabase
            .from('invites')
            .update({
                test_column: 'intermen'
            })
            .eq('id', item.id)
            
            console.log(inTermen)
            if (inTermenError) {
                console.log(inTermenError)
            }
            console.log('inTermen')
        }
    } */

    const confirmaContract = async (userIdContract) => {
        const { data, error } = await supabase.from("roles").update({ contract_status: 'confirmat' }).eq('user_id', userIdContract)
        if (error) throw error
        else {
            getUploadedContracts()
        }
        console.log(data)
    }

    const getAllRecruti = async () => {
      const arrayInvitati = ref([]);
      let roluri = {}

      try {
        const { data, error } = await supabase
          .from("roles")
          .select()
          .eq("invited_by", userId.value);
        if (error) throw error;
        
        arrayInvitati.value = data.map(({ user_id }) => user_id);
        totalRecruti.value = arrayInvitati.value.length;
        roluri = data
      } catch (error) {
        console.log(error);
      }

      let iduriInvitati = arrayInvitati.value.map((id) => id);
      
      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .in("agent", iduriInvitati);
        if (error) throw error;

        let incasariPerAgent = {}
        
        data.forEach((item) => {
          if(!Object.prototype.hasOwnProperty.call(incasariPerAgent, item.agent)) {
            incasariPerAgent[item.agent] = 0
          }

          incasariPerAgent[item.agent] += parseInt(item.total_incasat)
        })
        let final = roluri.map(item => {
          item.total_incasat = incasariPerAgent[item.user_id] ?? 0
          item.venit_incasat = item.total_incasat > 0 ? item.total_incasat / 10 : 0
          
          return item
        })
        store.dispatch("user/setAllRecruti", final);

      } catch (error) {
        console.log(error);
      }
    };

    const sendInvite = async () => {
      console.log(inviteMail.value)
      try {
        const { data, error } = await supabase.functions.invoke('send-invite', {
        body: { email: inviteMail.value }
     })
     console.log(data)
     if(error) {
      console.log(error)
     }
    }
     catch(error) {
      console.log(error)
     }
    
     
    }

    const getInvitedByName = async () => {
      try {
        const { data, error } = await supabase
          .from("roles")
          .select()
          .eq("user_id", userData.value.invited_by);
        if (error) throw error;
        
        store.dispatch("user/setInvitedByName", data[0].nume + ' ' + data[0].prenume);
      } catch (error) {
        console.log(error);
      }
    };

    const getUploadedContracts = async () => {
      try {
        const { data, error } = await supabase
          .from("roles")
          .select()
          .eq("contract_status", 'uploaded');
        if (error) throw error;
        
        store.dispatch("user/setUploadedContracts", data);
      } catch (error) {
        console.log(error);
      }
    };

    const getInvitesOwned = async () => {
      try {
        const { data, error } = await supabase
          .from("invites")
          .select()
          .eq("owner_id", userId.value);
        if (error) throw error;
        store.dispatch("user/setInvitesOwned", data)
        
      } catch (error) {
        console.log(error);
      }
    };

    // Send contract

    

    // Get firme

    const getAllFirme = async () => {
      try {
        const { data, error } = await supabase.from("firma").select("*");
        if (error) throw error;
        listaFirme.value = data;
        store.dispatch("firme/setAllFirme", data);
        
      } catch (error) {
        console.log(error);
      }
    };

  
    const verificaStripe = async () => {

      console.log('stripe')
      const { data, error } = await supabase.functions.invoke('unsubscribe-stripe', {
        body: { email: 'rvng.va@proton.me' }
     })

     console.log(data)
     if(error) {
      console.log(error)
     }
    }


    

    return {

      
      verificaStripe,
      getAllFirme,
      listaFirme,
      invitedByName,
      uploadedContracts,
      userId,
      contracteStore,
      allClientsStore,
      allFirmeStore,
      recomandariKey,
      recommendedClientsStore,
      allRecruti,
      invitesOwned,
      sendInvite,
      inviteMail,
      confirmaContract,
      verificaInvitesGlobal,
      verificaInvitesRedeem,
      verificaInvitesSub,
      verificatimpul

    };
  },
  created() {},
  methods: {},
};
</script>
