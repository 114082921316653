/* eslint-disable */
<template>
  <div class="page-container">
    <div class="width-container">
      <div class="promo-banner" @click="toFirma()">
        <div class="promo-wrapper" v-if="userData">
          <div class="promo-left">
            <div class="promo-title">
               <span v-if="userData.language === 'romana'">Felicitari!</span>
               <span v-if="userData.language === 'english'">Congratulations!</span> 
              </div>
              <div class="promo-subtitle" v-if="userData.language === 'romana'">
                Esti in primul val de utilizatori!
              </div>
              <div class="promo-subtitle" v-if="userData.language === 'english'">
                You are in the first wave of users!
              </div>
          </div>
          <div class="promo-mid">
            <div class="promo-text" v-if="userData.language === 'romana'">
              Ai prima luna de subscriptie <span class="banner-accent">gratuita</span>, sa te obisnuiesti cu platforma!
            </div>
            <div class="promo-text" v-if="userData.language === 'english'">
              First month of subscription is <span class="banner-accent">free</span>, so you can get used with our platform!
            </div>
            <div class="promo-text" v-if="userData.language === 'romana'">
              Poti face recomandari de firme pentru listare si primesti <span class="banner-accent">20% comision</span>!
            </div>
            <div class="promo-text" v-if="userData.language === 'english'">
              You can recommend companies to Vissia for a limited time for <span class="banner-accent">20% commission</span>!
            </div>
           
          </div>
        <div class="promo-right">
          
        </div>
        
      </div>
      </div>
      <div class="page-title">Dashboard
        <div v-if="userData && userData.subscription_status != 'sub'">
        <div class="activate-companii-page" v-if="userData && userData.language === 'romana'" @click="showModal = true; modalSubscribe = true">Activeaza cont-ul premium ca sa primesti comisioanele intregi!</div>
        <div class="activate-companii-page" v-if="userData && userData.language === 'english'" @click="showModal = true; modalSubscribe = true">Activate the premium account to receive full commissions!</div>
      </div>
      </div>
      <div class="home-page">
        <div class="main-content">
          <div class="home-mid-row-mobile">

            <div
              class="cover-recomandare"
              v-if="
                userData &&
                userData.subscription_status === 'sub' &&
                userData.contract_status === 'not_uploaded'
              "
            >
              <div class="cover-con-btn">
                <div class="download-pdf-icon">
                  <img src="../assets/img/pdf-icon.png" class="pdf-icon-img" />
                </div>
                Contract
              </div>
              <span v-if="userData.language === 'romana'">Va rugam semnati si sa uploadati contractul de colaborare pentru a
              putea trimite recomandari</span>
              <span v-if="userData.language === 'english'">Please sign and upload the contract to be able to start sending recommendations</span>
              

              <div class="cover-upload">
                <input
                  type="file"
                  @change="uploadContract($event)"
                  accepp="application/pdf"
                  :disabled="uploadingFile"
                  class="fileUpload-dashboard"
                />
                <div class="file-input-overlay-dashboard upload-btn">
                  Upload
                </div>
              </div>
            </div>
            <div
              class="cover-recomandare-uploaded"
              v-if="
                userData &&
                userData.subscription_status === 'sub' &&
                userData.contract_status === 'uploaded'
              "
            >
            <span v-if="userData.language === 'romana'">Contractul dumneavoastra este sub verificare. Veti putea
                  trimite recomandari imediat ce confirmam ca totul este in
                  regula</span>
                  <span v-if="userData.language === 'english'">Your contract is under review. You will be able to start sending recommendations as soon as we finish the reviewing process</span>
            </div>
            <div class="contract-container">
              <div class="todo-title" v-if="userData && userData.language === 'romana'">Recomandare noua</div>
              <div class="todo-title" v-if="userData && userData.language === 'english'">New recommendation</div>

              <form
                ref="contractform"
                @submit.prevent="submitContract"
                class="contract-form"
              >
                <div class="contract-row">
                  <div class="contract-top-left">
                    <div class="input-field-left">
                      <label v-if="userData && userData.language === 'romana'">Nume</label>
                          <label v-if="userData && userData.language === 'english'">Last Name</label>
                      <input type="text" v-model="formNume" />
                    </div>
                    <div class="input-field">
                      <label class="label-home-space" v-if="userData && userData.language === 'romana'">Prenume</label>
                          <label class="label-home-space" v-if="userData && userData.language === 'english'">First Name</label>
                      <input type="text" v-model="formPrenume" />
                    </div>
                  </div>
                  <div class="contract-top-right">
                    <div class="input-field-left">
                      <label>Email</label>
                      <input type="text" v-model="formEmail" />
                    </div>
                    <div class="input-field">
                      <label class="label-home-space" v-if="userData && userData.language === 'romana'">Telefon client</label>
                          <label class="label-home-space" v-if="userData && userData.language === 'english'">Client telephone</label>
                      <input type="text" v-model="formTelefon" />
                    </div>
                  </div>
                </div>
                <div class="contract-detalii-row">
                  <div class="input-field">
                    <label v-if="userData && userData.language === 'romana'">Firma (optional)</label>
                    <label v-if="userData && userData.language === 'english'">Company (optional)</label>
                    <input type="text" v-model="formFirmaRecomandata" />
                  </div>
                </div>
                <div class="contract-detalii-row">
                  <div class="input-field">
                    <label v-if="userData && userData.language === 'romana'">Din partea</label>
                        <label v-if="userData && userData.language === 'english'">Recommended by</label>
                    <input type="text" v-model="formDinPartea" />
                  </div>
                </div>
                <div class="contract-detalii-row2">
                  <div class="hint-detalii" v-if="userData && userData.language === 'romana'">
                        Detalii despre temperament, cum ii place sa fie abordat,
                        ce buget are si orice alte informatii care pot sa ajute
                        firma sa inchida vanzarea.
                      </div>
                      <div class="hint-detalii" v-if="userData && userData.language === 'english'">
                        Details about the client, how he likes to be approached, what is his budget and any other informations that can help the company close the deal.
                      </div>
                </div>
                <div class="contract-detalii-row">
                  <div class="input-field">
                    <label v-if="userData && userData.language === 'romana'">Detalii</label>
                        <label v-if="userData && userData.language === 'english'">Details</label>
                    <textarea
                      rows="5"
                      cols="20"
                      v-model="formDetalii"
                      class="contract-textarea"
                    />
                  </div>
                </div>

                <div class="firme-row">
                  <div class="input-field">
                    <label v-if="userData && userData.language === 'romana'">Cauta firma</label>
                        <label v-if="userData && userData.language === 'english'">Search company</label>
                        
                        <input type="text" v-model="cautaFirmaInput" />
                      </div>
                      <div class="firme-spacer"></div>
                      <div class="firma-searching" v-if="loadingSearch === 'activ' && cautaFirmaInput !== ''">
                        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                      </div>
                      <div class="firma-not-found" v-if="loadingSearch === 'negasit' && cautaFirmaInput !== ''">
                        <span v-if="userData && userData.language === 'romana'">Nu am gasit nicio firma</span>
                        <span v-if="userData && userData.language === 'english'">We haven't found any company that matches your search.</span>
                        
                      </div>
                      <div class="firme-btns-wrapper" v-if="loadingSearch === 'inactiv' && allFirmeSearched && allFirmeSearched.length > 0">
                     
                  <div
                    class="firma-btn"
                    v-for="firma in allFirmeSearched"
                    v-bind:key="firma.id"
                    @click="setFirma(firma.id)"
                    :class="{ firmaselected: firma.id === clickedFirma }"
                  >
                  
                      <span v-if="userData && userData.subscription_status === 'sub'">{{ firma.nume }}</span>
                  </div>
                      </div>
                </div>
                <div class="submit-recomandare-btn" @click="sendClientButton()">
                  <span v-if="userData && userData.language === 'romana'">Trimite</span>
                    <span v-if="userData && userData.language === 'english'">Send</span>
                </div>
              </form>
            </div>
          </div>
          <router-link to="/companii">
            <div class="companii-popup-mobile">
              <div class="companii-popup-title" v-if="userData && userData.language === 'romana'">Portofoliu firme</div>
              <div class="companii-popup-title" v-if="userData && userData.language === 'english'">Companies list</div>
            </div>
          </router-link>
          <div class="home-top-row">
            <div class="home-top-box">
              <div class="box-header-row2">
                <div class="home-box-title" v-if="userData">
                  <span v-if="userData.language === 'romana'">castiguri</span>
                  <span v-if="userData.language === 'english'">Earnings</span>
                  <img
                    v-if="userData.show_incasat"
                    src="../assets/img/hide-balance.png"
                    class="balance-icon"
                    @click="hideBalance()"
                  /><img
                    v-if="!userData.show_incasat"
                    src="../assets/img/view-balance.png"
                    class="balance-icon"
                    @click="showBalance()"
                  />
                </div>
                <router-link to="/castiguri">
                  <div class="show-more" v-if="userData && userData.language === 'romana'">Mai mult</div>
                  <div class="show-more" v-if="userData && userData.language === 'english'">Show more</div>
                </router-link>
              </div>

              <div class="home-box-field" v-if="userData">
                <span class="left-field" v-if="userData && userData.language === 'romana'">Total incasat</span>
                <span class="left-field" v-if="userData && userData.language === 'english'">Total earnings</span>
                
                <span class="right-field" v-if="!allIncasat"> <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></span>
                <span
                  class="right-field"
                  v-if="allIncasat && userData.show_incasat"
                  >{{
                    (
                      Number(allIncasat) +
                      Number(allIncasatRecruti)
                    ).toFixed(2)
                  }}
                  RON</span
                >
                <span
                  class="right-field blurred-text"
                  v-if="allIncasat && !userData.show_incasat"
                  >{{
                    (
                      Number(allIncasat) +
                      Number(allIncasatRecruti)
                    ).toFixed(2)
                  }}
                  RON</span
                >
              </div>
              <div class="home-box-field" v-if="userData">
                <span class="left-field" v-if="userData && userData.language === 'romana'">Contracte proprii</span>
                <span class="left-field" v-if="userData && userData.language === 'english'">My contracts</span>
                <span class="right-field" v-if="!allIncasat"> <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></span>
                <span
                  class="right-field"
                  v-if="allIncasat && userData.show_incasat"
                  >{{ allIncasat }} RON</span
                >
                <span
                  class="right-field blurred-text"
                  v-if="allIncasat && !userData.show_incasat"
                  >{{ allIncasat }} RON</span
                >
              </div>
              <div class="home-box-field" v-if="userData">
                <span class="left-field" v-if="userData && userData.language === 'romana'">Contracte recruti</span>
                <span class="left-field" v-if="userData && userData.language === 'english'">Network contracts</span>
                <span class="right-field" v-if="!allIncasatRecruti"
                  > <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></span
                >
                <span
                  class="right-field"
                  v-if="allIncasatRecruti && userData.show_incasat"
                  >{{ allIncasatRecruti }} RON</span
                >
                <span
                  class="right-field blurred-text"
                  v-if="allIncasatRecruti && !userData.show_incasat"
                  >{{ allIncasatRecruti }} RON</span
                >
              </div>
            </div>
            <div class="home-top-box">
              <div class="box-header-row2">
                <div class="home-box-title" v-if="userData && userData.language === 'romana'">Contracte</div>
                <div class="home-box-title" v-if="userData && userData.language === 'english'">Contracts</div>
                <router-link to="/contracte"
                  ><div class="show-more" v-if="userData && userData.language === 'romana'">Mai mult</div>
                  <div class="show-more" v-if="userData && userData.language === 'english'">Show more</div></router-link
                >
              </div>
              <div class="home-box-field">
                <span class="left-field" v-if="userData && userData.language === 'romana'">Semnate</span>
                <span class="left-field" v-if="userData && userData.language === 'english'">Signed</span>
                <span class="right-field" v-if="contracteStore">
                  {{
                    contracteStore.filter((ele) => ele.status === "semnat")
                      .length
                  }}</span
                >
              </div>
              <div class="home-box-field">
                <span class="left-field" v-if="userData && userData.language === 'romana'">In derulare</span>
                <span class="left-field" v-if="userData && userData.language === 'english'">Ongoing</span>
                <span class="right-field" v-if="contracteStore">{{
                  contracteStore.filter((ele) => ele.status === "derulare")
                    .length
                }}</span>
              </div>
              <div class="home-box-field">
                <span class="left-field" v-if="userData && userData.language === 'romana'">Finalizate</span>
                <span class="left-field" v-if="userData && userData.language === 'english'">Finalized</span>
                <span class="right-field" v-if="contracteStore">{{
                  contracteStore.filter((ele) => ele.status === "finalizat")
                    .length
                }}</span>
              </div>
            </div>

            
            <div class="home-top-box breakthrough">
              <div class="box-header-row">
                <div class="home-box-title" v-if="userData && userData.language === 'romana'">retea</div>
                <div class="home-box-title" v-if="userData && userData.language === 'english'">network</div>
                <router-link to="/retea">
                  <div class="show-more" v-if="userData && userData.language === 'romana'">Mai mult</div>
                  <div class="show-more" v-if="userData && userData.language === 'english'">Show more</div>
                </router-link>
              </div>
              <div class="home-box-field">
                <span class="left-field" v-if="userData && userData.language === 'romana'">Recruti</span>
                <span class="left-field" v-if="userData && userData.language === 'english'">Recruits</span>
                <span class="right-field">{{ totalRecruti }}</span>
              </div>
              <div class="home-box-field">
                <span class="left-field" v-if="userData && userData.language === 'romana'">Invitatii disponibile</span>
                <span class="left-field" v-if="userData && userData.language === 'english'">Available invites</span>
                <span class="right-field" v-if="allInvites">
                  {{
                    allInvites.filter((item) => item.status === "available")
                      .length
                  }}
                  / 10000</span
                >
              </div>
              <div class="home-box-field">
                <span class="left-field" v-if="userData && userData.language === 'romana'">Invitatiile tale</span>
                <span class="left-field" v-if="userData && userData.language === 'english'">Your invites</span>
                <span class="right-field" v-if="invitesOwned">
                  <img
                    src="./../assets/img/refresh-icon.png"
                    class="refresh-icon"
                    @click="getInvitesOwned()"
                  />
                  {{ invitesOwned.length }}
                  <a
                    v-if="userData"
                    :href="`https://pay.vissia.ro/b/5kA3fOaTNfw6cg0aEJ?prefilled_email=${userData.email}`"
                    ><div class="buy-btn" v-if="userData && userData.language === 'romana'">Cumpara</div>
                    <div class="buy-btn" v-if="userData && userData.language === 'english'">Buy Invite</div></a
                  ></span
                >
              </div>
            </div>
          </div>
          <div class="home-top-box showbreakthrough">
              <div class="box-header-row2">
                <div class="home-box-title" v-if="userData && userData.language === 'romana'">retea</div>
                <div class="home-box-title" v-if="userData && userData.language === 'english'">network</div>
                <router-link to="/retea">
                  <div class="show-more" v-if="userData && userData.language === 'romana'">Mai mult</div>
                  <div class="show-more" v-if="userData && userData.language === 'english'">Show more</div>
                </router-link>
              </div>
              <div class="home-box-field">
                <span class="left-field" v-if="userData && userData.language === 'romana'">Recruti</span>
                <span class="left-field" v-if="userData && userData.language === 'english'">Recruits</span>
                <span class="right-field">{{ totalRecruti }}</span>
              </div>
              <div class="home-box-field">
                <span class="left-field" v-if="userData && userData.language === 'romana'">Invitatii disponibile</span>
                <span class="left-field" v-if="userData && userData.language === 'english'">Available invites</span>
                <span class="right-field" v-if="allInvites">
                  {{
                    allInvites.filter((item) => item.status === "available")
                      .length
                  }}
                  / 10000</span
                >
              </div>
              <div class="home-box-field">
                <span class="left-field" v-if="userData && userData.language === 'romana'">Invitatiile tale</span>
                <span class="left-field" v-if="userData && userData.language === 'english'">Your invites</span>
                <span class="right-field" v-if="invitesOwned">
                  <img
                    src="./../assets/img/refresh-icon.png"
                    class="refresh-icon"
                    @click="getInvitesOwned()"
                  />
                  {{ invitesOwned.length }}
                  <a
                    v-if="userData"
                    :href="`https://pay.vissia.ro/b/5kA3fOaTNfw6cg0aEJ?prefilled_email=${userData.email}`"
                    ><div class="buy-btn" v-if="userData && userData.language === 'romana'">Cumpara</div>
                    <div class="buy-btn" v-if="userData && userData.language === 'english'">Buy Invite</div></a
                  ></span
                >
              </div>
            </div>
          
          <div class="dashboard-big-row">
            <div class="home-mid-row4">
              <div class="box-header-row">
                <div class="todo-title" v-if="userData && userData.language === 'romana'">Descopera firma</div>
                <div class="todo-title" v-if="userData && userData.language === 'english'">Discover company</div>

                <router-link to="/companii"
                  ><div class="show-more mobile-hide" v-if="userData && userData.language === 'romana'">
                    Toate firmele
                  </div>
                  <div class="show-more mobile-hide" v-if="userData && userData.language === 'english'">
                    All companies
                  </div></router-link
                >
              </div>
              <div
                class="company-router-link"
                v-if="randomFirmaStore"
                @click="seeRandomFirma(randomFirmaStore.id)"
              >

              <div class="company-item-random">
              <div class="company-comision-box-notsub" v-if="userData && userData.subscription_status != 'sub'">
                  <span class="cutout-comision">{{ randomFirmaStore.comision }}%</span> 
                  <span class="half-comision">{{ randomFirmaStore.comision / 2 }}%</span> 
                  <span class="grey-accent" v-if="userData && userData.language === 'romana'">comision</span>
                  <span class="grey-accent" v-if="userData && userData.language === 'english'">commission</span>
                </div>
                <div class="company-comision-box" v-if="userData && userData.subscription_status === 'sub'">
                  {{ randomFirmaStore.comision }}%
                  <span class="grey-accent" v-if="userData && userData.language === 'romana'">comision</span>
                  <span class="grey-accent" v-if="userData && userData.language === 'english'">commission</span>
                </div>
              <div class="company-title">{{ randomFirmaStore.nume }}</div>
              
              <div class="company-industry-random">{{ randomFirmaStore.domeniu }}</div>
              <div class="company-logo" v-if="randomFirmaStore.logo_image">
                <img
                  :src="`https://kmgppqgrgckdzebbtvqy.supabase.co/storage/v1/object/public/images/${randomFirmaStore.logo_image}`"
                  class="company-logo-img"
                />
              </div>
            </div>
              </div>

              <div class="contracte-container-home" v-if="contracteStore">
                <div class="box-header-row">
                  <div class="todo-title" v-if="userData && userData.language === 'romana'">Ultimele contracte</div>
                  <div class="todo-title" v-if="userData && userData.language === 'english'">Latest contracts</div>

                  <router-link to="/contracte"
                    ><div class="show-more mobile-hide" v-if="userData && userData.language === 'romana'">
                      Toate contractele
                    </div>
                    <div class="show-more mobile-hide" v-if="userData && userData.language === 'english'">
                      All contracts
                    </div></router-link
                  >
                </div>
                <div class="filtre-wrapper">
                  <div
                    class="filtru-contracte"
                    :class="{ filtruselected: filtruContractSemnat }"
                    @click="filtruContractSemnat = !filtruContractSemnat"
                  >
                  <span v-if="userData && userData.language === 'romana'">Semnat</span>
                  <span v-if="userData && userData.language === 'english'">Signed</span>
                  </div>
                  <div
                    class="filtru-contracte"
                    :class="{ filtruselected: filtruContractDerulare }"
                    @click="filtruContractDerulare = !filtruContractDerulare"
                  >
                  <span v-if="userData && userData.language === 'romana'">In derulare</span>
                  <span v-if="userData && userData.language === 'english'">Ongoing</span>
                  </div>
                  <div
                    class="filtru-contracte"
                    :class="{ filtruselected: filtruContractFinalizat }"
                    @click="filtruContractFinalizat = !filtruContractFinalizat"
                  >
                  <span v-if="userData && userData.language === 'romana'">Finalizat</span>
                  <span v-if="userData && userData.language === 'english'">Finalized</span>
                  </div>
                  <div
                    class="filtru-contracte"
                    :class="{ filtruselected: filtruContractAnulat }"
                    @click="filtruContractAnulat = !filtruContractAnulat"
                  >
                  <span v-if="userData && userData.language === 'romana'">Anulat</span>
                  <span v-if="userData && userData.language === 'english'">Cancelled</span>
                  </div>
                </div>
                <div class="fara-clienti" v-if="contracteStore.length === 0">
                  
                  <span v-if="userData && userData.language === 'romana'">Nu aveti niciun contract</span>
                  <span v-if="userData && userData.language === 'english'">You don't have any contracts</span>
                </div>
                <div class="overflow-wrapper">
                  <table class="orders-table" v-if="contracteStore.length != 0">
                    <col class="col-order-no" />
                    <col class="col-order-name" />

                    <col class="col-order-id" />
                    <col class="col-order-ref" />

                    <col class="col-order-more" />
                    <tr class="products-table-head">
                      <th class="col-order-no">ID</th>
                      <th><span v-if="userData && userData.language === 'romana'">Firma</span>
                  <span v-if="userData && userData.language === 'english'">Company</span></th>

                      <th>Client</th>
                      <th><span v-if="userData && userData.language === 'romana'">Serviciu</span>
                  <span v-if="userData && userData.language === 'english'">Service</span></th>

                      <th><span v-if="userData && userData.language === 'romana'">Comision</span>
                  <span v-if="userData && userData.language === 'english'">Commission</span></th>
                      <th><span v-if="userData && userData.language === 'romana'">Incasat</span>
                  <span v-if="userData && userData.language === 'english'">Collected</span></th>
                      <th><span v-if="userData && userData.language === 'romana'">Tip</span>
                  <span v-if="userData && userData.language === 'english'">Type</span></th>
                      <th><span v-if="userData && userData.language === 'romana'">Status</span>
                  <span v-if="userData && userData.language === 'english'">Status</span></th>
                    </tr>
                    <tr
                      class="products-table-item"
                      @click="
                        activateModal(contract.client_id, contract.id);
                        showModal = true;
                      "
                      v-for="contract in contractePag"
                      :key="contract.id"
                    >
                      <th class="table-id client-cell">{{ contract.id }}</th>
                      <th class="product-name client-cell" v-if="allFirmeStore">
                        {{
                          allFirmeStore.find((ele) => ele.id === contract.firma)
                            .nume
                        }}
                      </th>

                      <th
                        class="product-name client-cell"
                        v-if="allClientsStore"
                      >
                        {{
                          allClientsStore.find(
                            (ele) => ele.id === contract.client_id
                          ).nume
                        }}
                        {{
                          allClientsStore.find(
                            (ele) => ele.id === contract.client_id
                          ).prenume
                        }}
                      </th>
                      <th class="product-name client-cell">
                        {{ contract.serviciu }}
                      </th>

                      <th class="suma-tabel-contracte client-cell">
                        {{ contract.comision }} RON
                        <span v-if="contract.tip === 'recurentCheck'">
                          /  <span v-if="userData && userData.language === 'romana'">luna</span>
                  <span v-if="userData && userData.language === 'english'">month</span></span
                        >
                      </th>
                      <th class="product-name client-cell">
                        <span
                          v-if="contract.total_incasat != 0"
                          class="suma-tabel-contracte2"
                          >{{ contract.total_incasat }} RON</span
                        ><span
                          v-if="
                            contract.total_incasat === 0 &&
                            contract.status != 'anulat'
                          "
                          class="incasare-wait"
                          ><span v-if="userData && userData.language === 'romana'">Se asteapta incasare</span>
                  <span v-if="userData && userData.language === 'english'">Awaiting payment</span></span
                        ><span
                          v-if="
                            contract.total_incasat === 0 &&
                            contract.status === 'anulat'
                          "
                          class="incasare-wait"
                          ><span v-if="userData && userData.language === 'romana'">Contract anulat</span>
                  <span v-if="userData && userData.language === 'english'">Contract cancelled</span></span
                        >
                      </th>
                      <th
                        class="product-name client-cell"
                        v-if="contract.tip === 'oneTimeCheck'"
                      >
                        One Time
                      </th>
                      <th
                        class="product-name client-cell"
                        v-if="contract.tip === 'recurentCheck'"
                      >
                      <span v-if="userData && userData.language === 'romana'">Recurent</span>
                  <span v-if="userData && userData.language === 'english'">Recurring</span>
                        <span
                          v-if="
                            contract.tip === 'recurentCheck' &&
                            contract.status != 'finalizat'
                          "
                          class="rate-platite-tabel"
                          >{{ contract.rate_platite }} /
                          {{ contract.durata }}</span
                        >
                        <span
                          v-if="
                            contract.tip === 'recurentCheck' &&
                            contract.status === 'finalizat'
                          "
                          class="rate-platite-final-tabel"
                          >{{ contract.rate_platite }} /
                          {{ contract.durata }}</span
                        >
                      </th>
                      <th class="status-col client-cell">
                        <span
                          class="status-tabel"
                          v-if="contract.status === 'derulare'"
                          ><span v-if="userData && userData.language === 'romana'">In derulare</span>
                  <span v-if="userData && userData.language === 'english'">Ongoing</span></span
                        >
                        <span
                          class="status-tabel"
                          v-if="contract.status != 'derulare'"
                          >{{ contract.status }}</span
                        >
                        <div
                          class="status-confirmat"
                          v-if="contract.status === 'semnat'"
                        ></div>
                        <div
                          class="status-incasat"
                          v-if="contract.status === 'derulare'"
                        ></div>
                        <div
                          class="status-finalizat"
                          v-if="contract.status === 'finalizat'"
                        ></div>
                        <div
                          class="status-anulat"
                          v-if="contract.status === 'anulat'"
                        ></div>
                      </th>
                    </tr>
                  </table>
                </div>
                <div
                  class="buttons-table-wrapper"
                  v-if="!firstPage || !lastPage"
                >
                  <div
                    class="btn-pagination"
                    @click="getPrev()"
                    v-if="!firstPage"
                  >
                  <span v-if="userData && userData.language === 'romana'">&#x2039; Inapoi</span>
                  <span v-if="userData && userData.language === 'english'">&#x2039; Previous</span>
                    
                  </div>
                  <div class="btn-pagination-cancelled" v-if="firstPage">
                    
                    <span v-if="userData && userData.language === 'romana'">&#x2039; Inapoi</span>
                  <span v-if="userData && userData.language === 'english'">&#x2039; Previous</span>
                  </div>
                  <div class="btn-pagination-cancelled" v-if="lastPage">
                    <span v-if="userData && userData.language === 'romana'">Inainte &#x203A;</span>
                  <span v-if="userData && userData.language === 'english'">Next &#x203A;</span>
                    
                  </div>
                  <div
                    class="btn-pagination"
                    @click="getNext()"
                    v-if="!lastPage"
                  >
                  <span v-if="userData && userData.language === 'romana'">Inainte &#x203A;</span>
                  <span v-if="userData && userData.language === 'english'">Next &#x203A;</span>
                  </div>
                </div>
              </div>
              <div
                class="contracte-container"
                v-if="recommendedClientsStore"
                :key="recomandariKey"
              >
                <div class="box-header-row">
                  <div class="todo-title" v-if="userData && userData.language === 'romana'">Ultimele recomandari</div>
                  <div class="todo-title" v-if="userData && userData.language === 'english'">Latest recommendations</div>

                  <router-link to="/recomandari"
                    ><div class="show-more mobile-hide" v-if="userData && userData.language === 'romana'">
                      Toate recomandarile
                    </div>
                    <div class="show-more mobile-hide" v-if="userData && userData.language === 'english'">
                      All recommendations
                    </div></router-link
                  >
                </div>
                <div
                  class="fara-clienti"
                  v-if="recommendedClientsStore.length === 0"
                >
                <span v-if="userData && userData.language === 'romana'">Nu aveti nicio recomandare</span>
                <span v-if="userData && userData.language === 'english'">You haven't sent any recommendation</span>
                  
                </div>
                <div class="overflow-wrapper">
                  <table
                    class="orders-table"
                    v-if="recommendedClientsStore.length != 0"
                  >
                    <col class="col-order-no" />
                    <col class="col-order-name" />

                    <col class="col-order-id" />
                    <col class="col-order-ref" />

                    <col class="col-order-more" />
                    <tr class="products-table-head">
                      <th class="col-order-no">ID</th>
                      <th><span v-if="userData && userData.language === 'romana'">Firma</span>
                  <span v-if="userData && userData.language === 'english'">Company</span></th>
                      <th><span v-if="userData && userData.language === 'romana'">Nume</span>
                  <span v-if="userData && userData.language === 'english'">Name</span></th>

                      <th><span v-if="userData && userData.language === 'romana'">Telefon</span>
                  <span v-if="userData && userData.language === 'english'">Telephone</span></th>
                      <th><span v-if="userData && userData.language === 'romana'">Email</span>
                  <span v-if="userData && userData.language === 'english'">Email</span></th>

                      <th><span v-if="userData && userData.language === 'romana'">Detalii</span>
                  <span v-if="userData && userData.language === 'english'">Details</span></th>
                    </tr>
                    <tr
                      class="products-table-item"
                      v-for="client in recommendedClientsStore"
                      :key="client.id"
                    >
                      <th class="table-item client-cell">{{ client.id }}</th>
                      <th class="table-item client-cell" v-if="allFirmeStore">
                        {{
                          allFirmeStore.find((ele) => ele.id === client.firma)
                            .nume
                        }}
                      </th>
                      <th class="product-name client-cell">
                        {{ client.prenume }} {{ client.nume }}
                      </th>

                      <th class="table-item client-cell">
                        {{ client.telefon }}
                      </th>
                      <th class="table-item client-cell">{{ client.email }}</th>

                      <th class="table-item-detalii client-cell">
                        {{ client.detalii }}
                      </th>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="home-mid-left-column">
              <router-link to="/bounties">
                <div class="bounty-popup">
                  <div class="bounty-popup-title" v-if="userData && userData.language === 'romana'">
                    Vezi bounty-urile de luna aceasta!
                  </div>
                  <div class="bounty-popup-title" v-if="userData && userData.language === 'english'">
                    Check out this month's bounties!
                  </div>
                </div>
              </router-link>
              <div class="home-mid-row">

                <div
                  class="cover-recomandare"
                  v-if="
                    userData &&
                    userData.subscription_status === 'sub' &&
                    userData.contract_status === 'not_uploaded'
                  "
                >
                  <div class="cover-con-btn">
                    <div class="download-pdf-icon">
                      <img
                        src="../assets/img/pdf-icon.png"
                        class="pdf-icon-img"
                      />
                    </div>
                    Contract
                  </div>
                  <span v-if="userData.language === 'romana'">Va rugam semnati si sa uploadati contractul de colaborare pentru a
              putea trimite recomandari</span>
              <span v-if="userData.language === 'english'">Please sign and upload the contract to be able to start sending recommendations</span>

                  <div class="cover-upload">
                    <input
                      type="file"
                      @change="uploadContract($event)"
                      accepp="application/pdf"
                      :disabled="uploadingFile"
                      class="fileUpload-dashboard"
                    />
                    <div class="file-input-overlay-dashboard upload-btn">
                      Upload
                    </div>
                  </div>
                </div>
                <div
                  class="cover-recomandare-uploaded"
                  v-if="
                    userData &&
                    userData.subscription_status === 'sub' &&
                    userData.contract_status === 'uploaded'
                  "
                >
                <span v-if="userData.language === 'romana'">Contractul dumneavoastra este sub verificare. Veti putea
                  trimite recomandari imediat ce confirmam ca totul este in
                  regula</span>
                  <span v-if="userData.language === 'english'">Your contract is under review. You will be able to start sending recommendations as soon as we finish the reviewing process</span>
                  
                </div>
                <div class="contract-container">
                  <div class="todo-title" v-if="userData && userData.language === 'romana'">Recomandare noua</div>
              <div class="todo-title" v-if="userData && userData.language === 'english'">New recommendation</div>

                  <form
                    ref="contractform"
                    @submit.prevent="submitContract"
                    class="contract-form"
                  >
                    <div class="contract-row">
                      <div class="contract-top-left">
                        <div class="input-field-left">
                          <label v-if="userData && userData.language === 'romana'">Nume</label>
                          <label v-if="userData && userData.language === 'english'">Last name</label>
                          <input type="text" v-model="formNume" />
                        </div>
                        <div class="input-field">
                          <label class="label-home-space" v-if="userData && userData.language === 'romana'">Prenume</label>
                          <label class="label-home-space" v-if="userData && userData.language === 'english'">First name</label>
                          <input type="text" v-model="formPrenume" />
                        </div>
                      </div>
                      <div class="contract-top-right">
                        <div class="input-field-left">
                          <label>Email</label>
                          <input type="text" v-model="formEmail" />
                        </div>
                        <div class="input-field">
                          <label class="label-home-space" v-if="userData && userData.language === 'romana'">Telefon client</label>
                          <label class="label-home-space" v-if="userData && userData.language === 'english'">Client telephone</label>
                          <input type="text" v-model="formTelefon" />
                        </div>
                      </div>
                    </div>
                    <div class="contract-detalii-row">
                  <div class="input-field">
                    <label v-if="userData && userData.language === 'romana'">Firma (optional)</label>
                    <label v-if="userData && userData.language === 'english'">Company (optional)</label>
                    <input type="text" v-model="formFirmaRecomandata" />
                  </div>
                </div>
                    <div class="contract-detalii-row">
                      <div class="input-field">
                        <label v-if="userData && userData.language === 'romana'">Din partea</label>
                        <label v-if="userData && userData.language === 'english'">Recommended by</label>
                        <input type="text" v-model="formDinPartea" />
                      </div>
                    </div>
                    <div class="contract-detalii-row2">
                      <div class="hint-detalii" v-if="userData && userData.language === 'romana'">
                        Detalii despre temperament, cum ii place sa fie abordat,
                        ce buget are si orice alte informatii care pot sa ajute
                        firma sa inchida vanzarea.
                      </div>
                      <div class="hint-detalii" v-if="userData && userData.language === 'english'">
                        Details about the client, how he likes to be approached, what is his budget and any other informations that can help the company close the deal.
                      </div>
                    </div>
                    <div class="contract-detalii-row">
                      <div class="input-field">
                        <label v-if="userData && userData.language === 'romana'">Detalii</label>
                        <label v-if="userData && userData.language === 'english'">Details</label>
                        <textarea
                          rows="5"
                          cols="20"
                          v-model="formDetalii"
                          class="contract-textarea"
                        />
                      </div>
                    </div>

                    <div class="firme-row">
                      <div class="input-field">
                        <label v-if="userData && userData.language === 'romana'">Cauta firma</label>
                        <label v-if="userData && userData.language === 'english'">Search company</label>
                        <input type="text" v-model="cautaFirmaInput" />
                      </div>
                      <div class="firme-spacer"></div>
                      <div class="firma-searching" v-if="loadingSearch === 'activ' && cautaFirmaInput !== ''">
                        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                      </div>
                      <div class="firma-not-found" v-if="loadingSearch === 'negasit' && cautaFirmaInput !== ''">
                        <span v-if="userData && userData.language === 'romana'">Nu am gasit nicio firma</span>
                        <span v-if="userData && userData.language === 'english'">We haven't found any company that matches your search.</span>
                        
                      </div>
                      <div class="firme-btns-wrapper" v-if="loadingSearch === 'inactiv' && allFirmeSearched && allFirmeSearched.length > 0">
                      <div
                        class="firma-btn"
                        v-for="firma in allFirmeSearched"
                        v-bind:key="firma.id"
                        @click="setFirma(firma.id)"
                        :class="{ firmaselected: firma.id === clickedFirma }"
                      >
                      
                      <span>{{ firma.nume }}</span>
                      </div>
                    </div>
                    </div>
                    <div
                      class="submit-recomandare-btn"
                      @click="sendClientButton(clickedFirma)"
                    >
                    <span v-if="userData && userData.language === 'romana'">Trimite</span>
                    <span v-if="userData && userData.language === 'english'">Send</span>
                    </div>
                  </form>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <vue-final-modal
        v-model="showModal"
        classes="modal-container"
        content-class="modal-content"
        v-if="modalSubscribe"
        
      >
      <div class="contract-item">
         <div class="regulament-bounties2">
          <div class="regulament-bounties-item" v-if="userData && userData.language === 'romana'">
            Vissia <b>NU</b> are acces la datele dumneavoastra de plata. Folosim <a href="https://www.stripe.com" class="stripe-link">Stripe</a>, unul dintre cei mai mari procesatori de plati din lume.
          </div>
          <div class="regulament-bounties-item" v-if="userData && userData.language === 'english'">
            Vissia does <b>NOT</b> have access to your payment information. We use <a href="https://www.stripe.com" class="stripe-link">Stripe</a>, one of the biggest payment processors in the world.
          </div>
          <div class="regulament-bounties-item" v-if="userData && userData.language === 'romana'">
            Puteti sa va anulati subscriptia in orice moment din pagina de profil.
          </div>
          <div class="regulament-bounties-item" v-if="userData && userData.language === 'english'">
            You can cancel your subscription at any time from your profile page.
          </div>
          <div class="regulament-bounties-item" v-if="userData && userData.language === 'romana'">
            Email-ul din pagina de plata <b>trebuie</b> sa fie acelasi cu email-ul dumneavoastra de utilizator Vissia.
          </div>
          <div class="regulament-bounties-item" v-if="userData && userData.language === 'english'">
            The email address from the payment page must be <b>the same</b> with your Vissia user email.
          </div>
          
          <a
                    v-if="userData"
                    :href="`https://pay.vissia.ro/b/fZe7w46DxdnY5RC3cg?prefilled_email=${userData.email}`"
                    ><div class="cover-sub-btn2"><span v-if="userData && userData.language === 'romana'">Activeaza cont premium</span><span v-if="userData && userData.language === 'english'">Activate premium account</span></div></a
                  >
         </div>
        </div>

      </vue-final-modal>
      <vue-final-modal
        v-model="showModal"
        classes="modal-container"
        content-class="modal-content"
        v-if="modalContract"
        @closed="resetData()"
      >
        <div class="contract-item">
          <div
            class="modal-status-semnat"
            v-if="modalContract.status === 'semnat'"
          />
          <div
            class="modal-status-derulare"
            v-if="modalContract.status === 'derulare'"
          />
          <div
            class="modal-status-finalizat"
            v-if="modalContract.status === 'finalizat'"
          />
          <div
            class="modal-status-anulat"
            v-if="modalContract.status === 'anulat'"
          />
          <div class="profile-field-agent">
            <div class="profile-field-left">Client</div>
            <div class="profile-field-right">{{ modalName }}</div>
          </div>
          <div class="profile-field-agent">
            <div class="profile-field-left">Serviciu</div>
            <div class="profile-field-right">{{ modalContract.serviciu }}</div>
          </div>
          <div class="profile-field-agent">
            <div class="profile-field-left">Data semnarii</div>
            <div class="profile-field-right">{{ modalDate }}</div>
          </div>

          <div class="profile-field-agent">
            <div class="profile-field-left">Suma</div>
            <div class="profile-field-right">{{ modalContract.suma }} RON</div>
          </div>
          <div class="profile-field-agent">
            <div class="profile-field-left">Comision</div>
            <div class="profile-field-right">
              {{ modalContract.comision }} RON<span
                v-if="modalContract.tip === 'recurentCheck'"
              >
                / luna</span
              >
            </div>
          </div>
          <div
            class="profile-field-agent"
            v-if="modalContract.tip === 'recurentCheck'"
          >
            <div class="profile-field-left">Rate platite</div>
            <div class="profile-field-right">
              {{ modalContract.rate_platite }} / {{ modalContract.durata }}
            </div>
          </div>
          <div class="profile-field-agent">
            <div class="profile-field-left">Incasat</div>
            <div class="profile-field-right agent-incasat">
              {{ modalContract.total_incasat }} RON
            </div>
          </div>
          <div
            class="profile-field-agent"
            v-if="modalContract.tip === 'recurentCheck'"
          >
            <div class="profile-field-left">Ramas de incasat</div>
            <div
              class="profile-field-right"
              v-if="
                modalContract.comision *
                  (modalContract.durata - modalContract.rate_platite) ===
                0
              "
            >
              Incasat integral
            </div>
            <div
              class="profile-field-right ramas-de-incasat"
              v-if="
                modalContract.comision *
                  (modalContract.durata - modalContract.rate_platite) !=
                0
              "
            >
              {{
                modalContract.comision *
                (modalContract.durata - modalContract.rate_platite)
              }}
              RON
            </div>
          </div>
          <div
            class="profile-field-agent"
            v-if="modalContract.status === 'anulat'"
          >
            <div class="profile-field-left">Data anularii</div>
            <div class="profile-field-right">{{ modalDate }}</div>
          </div>
          <div
            class="profile-field-agent"
            v-if="modalContract.status === 'anulat'"
          >
            <div class="profile-field-left">Motivul anularii</div>
            <div class="profile-field-right">
              {{ modalContract.motiv_anulare }}
            </div>
          </div>
        </div>
      </vue-final-modal>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, ref, computed, onUnmounted, watch } from "vue";
import { supabase } from "../clients/supabase";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import debounce from 'lodash.debounce'

export default {
  name: "HomePage",
  components: {},
  data() {
    return {
      showModal: false,
      error: false,
    };
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      getAllFirme();
      getContracte();
      seeCurrentUser();
      getAllClients();
      getRecommendedClients();
      getAllIncasat();
      getAllIncasatRecruti();
      getContractePag();
      getContractePagAll();

      getAllInvites();
      getInvitesOwned();
    });

    onUnmounted(() => {
      resetContractStore();
    });

    // formular
    const clickedFirma = ref("");
    const formNume = ref("");
    const formPrenume = ref("");
    const formTelefon = ref("");
    const formEmail = ref("");
    const formDetalii = ref("");
    const formFirma = ref("");
    const formDinPartea = ref("");
    const formFirmaRecomandata = ref("")

    const formAgentId = ref("");

    const cautaFirmaInput = ref('')

    // contracte

    const contracte = ref("");

    const listaFirme = ref("");
    const allClients = ref();

    const modalId = ref("");
    const contractId = ref("");

    const recomandariKey = ref(0);

    const modalContract = ref();
    const modalName = ref("");
    const modalDate = ref("");

    const modalRatePlatite = ref(0);
    const modalDurata = ref(0);
    const modalStatus = ref("");
    const anuleazaToggle = ref(false);
    const modalComision = ref(0);
    const modalTotalIncasat = ref(0);
    const totalRecruti = ref(0);

    const contractPagePagination = ref(0);
    const firstPage = ref(true);
    const lastPage = ref(true);
    const modalSubscribe = ref(false)
    const arrayFiltrePag = ref(["finalizat", "anulat", "derulare", "semnat"]);

    const filtruContractSemnat = ref(true);
    const filtruContractDerulare = ref(true);
    const filtruContractFinalizat = ref(true);
    const filtruContractAnulat = ref(true);

    const randomFirmaId = ref(null);

    const userId = computed(() => {
      return store.getters["user/getUserId"];
    });

    const contracteStore = computed(() => {
      return store.getters["contracte/getAllContracte"];
    });

    const firmeSubbed = computed(() => {
      return store.getters["firme/getFirmeSubbed"];
    });

    const contractePag = computed(() => {
      return store.getters["contracte/getContractePag"];
    });

    const contractePagAll = computed(() => {
      return store.getters["contracte/getContractePagAll"];
    });

    const allClientsStore = computed(() => {
      return store.getters["clienti/getAllClients"];
    });

    const allFirmeStore = computed(() => {
      return store.getters["firme/getAllFirme"];
    });

    const allFirmeSearched = computed(() => {
      return store.getters["firme/getFirmeSearched"];
    });

    const randomFirmaStore = computed(() => {
      return store.getters["firme/getRandomFirma"];
    });

    const allIncasat = computed(() => {
      return store.getters["user/getAllIncasat"];
    });

    const allInvites = computed(() => {
      return store.getters["user/getAllInvites"];
    });

    const userData = computed(() => {
      return store.getters["user/getUserData"];
    });

    const allIncasatRecruti = computed(() => {
      return store.getters["user/getAllIncasatRecruti"];
    });

    const recommendedClientsStore = computed(() => {
      return store.getters["clienti/getRecommendedClients"];
    });

    const invitesOwned = computed(() => {
      return store.getters["user/getInvitesOwned"];
    });

    
    const loadingSearch = ref('inactiv')

    watch(cautaFirmaInput, (oldValue, newValue) => {
      
      if (newValue !== oldValue) {
        loadingSearch.value = 'inactiv'
      updateCautaFirme()
        
        
      }
    });

    watch(filtruContractSemnat, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("semnat");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "semnat"
        );

        getContractePag();
      }
    });

    watch(filtruContractDerulare, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("derulare");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "derulare"
        );

        getContractePag();
      }
    });

    watch(filtruContractFinalizat, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("finalizat");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "finalizat"
        );

        getContractePag();
      }
    });

    watch(filtruContractAnulat, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("anulat");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "anulat"
        );

        getContractePag();
      }
    });

    function setFirma(id) {
      if (clickedFirma.value && formFirma.value === id) {
        clickedFirma.value = null;
        formFirma.value = null;
      } else {
        clickedFirma.value = id;
        formFirma.value = id;
      }
    }

    function toFirma() {
      router.push('/companie/111')
    }

    function resetData() {
      modalContract.value = "";
      modalContract.value = "";
      modalName.value = "";
      modalDate.value = "";
      modalRatePlatite.value = "";
      modalDurata.value = "";
      modalStatus.value = "";
      modalComision.value = "";
      modalTotalIncasat.value = "";
    }

    function seeRandomFirma(id) {
      
      router.push(`/companie/${id}`);
    }

    function resetContractStore() {
      store.dispatch("contracte/setContractePag", null);
      store.dispatch("contracte/setAllContracte", null);
      store.dispatch("clienti/setRecommendedClients", null);
      store.dispatch("clienti/setAllClients", null);
    }

    function anuleazaContractToggle() {
      anuleazaToggle.value = true;
      setTimeout(() => {
        anuleazaToggle.value = false;
      }, 3000);
    }

    function activateModal(id, contractid) {
      modalId.value = id;
      contractId.value = contractid;
      getModalInfo();
    }

    const updateCautaFirme = debounce(async () => {
      
      loadingSearch.value = 'activ'

      if(cautaFirmaInput.value === '') {
        store.dispatch('firme/setFirmeSearched', null)
        loadingSearch.value = 'inactiv'
      }

      if(cautaFirmaInput.value.includes(' ')) {
        const { data, error } = await supabase.from('firma').select().textSearch('tags', cautaFirmaInput.value, {type: 'phrase'}).eq('sub_status', 'sub')
        console.log(data)
        if(data && data.length > 0) {
          store.dispatch('firme/setFirmeSearched', data)
          loadingSearch.value = 'inactiv'
        }

        if(data && data.length === 0) {
          loadingSearch.value = 'negasit'
        }
        
        if(error) {
          console.log(error)
        }
      } else {
        const { data, error } = await supabase.from('firma').select().textSearch('tags', cautaFirmaInput.value).eq('sub_status', 'sub')
        console.log(data)
        if(data && data.length > 0) {
          store.dispatch('firme/setFirmeSearched', data)
          loadingSearch.value = 'inactiv'
        }

        if(data && data.length === 0) {
          loadingSearch.value = 'negasit'
        }
        if(error) {
          console.log(error)
        }
      }
      
      
    }, 1000)

    const getInvitesOwned = async () => {
      if (!userId.value) {
        setTimeout(() => {
          getInvitesOwned;
        }, 1000);
        return;
      }

      try {
        const { data, error } = await supabase
          .from("invites")
          .select()
          .eq("owner_id", userId.value)
          .eq("status", "owned");
        if (error) throw error;
        store.dispatch("user/setInvitesOwned", data);
      } catch (error) {
        console.log(error);
      }
    };

    const hideBalance = async () => {
      try {
        const { error } = await supabase
          .from("roles")
          .update({
            show_incasat: false,
          })
          .eq("user_id", userId.value)
          .select();
        if (error) throw error;
        checkRole();
      } catch (error) {
        console.log(error);
      }
    };

    const showBalance = async () => {
      try {
        const { error } = await supabase
          .from("roles")
          .update({
            show_incasat: true,
          })
          .eq("user_id", userId.value)
          .select();
        if (error) throw error;
        checkRole();
      } catch (error) {
        console.log(error);
      }
    };

    const checkRole = async () => {
      const { data, error } = await supabase
        .from("roles")
        .select()
        .eq("user_id", userId.value);

      if (error) {
        console.log(error);
      }
      store.dispatch("user/setUserData", data[0]);
    };

    const getContractePagAll = async () => {
      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("agent", userId.value)
          .in("status", arrayFiltrePag.value)
          .order("created_at", { ascending: false });

        if (error) throw error;

        store.dispatch("contracte/setContractePagAll", data);
      } catch (error) {
        console.log(error);
      }
    };

    const getContractePag = async () => {
      contractPagePagination.value = 0;
      firstPage.value = true;
      await getContractePagAll();
      if (contractPagePagination.value + 5 >= contractePagAll.value.length) {
        lastPage.value = true;
      } else {
        lastPage.value = false;
      }

      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("agent", userId.value)
          .in("status", arrayFiltrePag.value)
          .range(0, 4)
          .order("created_at", { ascending: false });

        store.dispatch("contracte/setContractePag", data);

        if (error) throw error;
      } catch (error) {
        console.log(error);
      }
    };

    const getNext = async () => {
      try {
        if (contractPagePagination.value + 5 >= contractePagAll.value.length) {
          lastPage.value = true;
        } else {
          lastPage.value = false;
          contractPagePagination.value += 5;
          let rangePagination = contractPagePagination.value + 4;
          const { data, error } = await supabase
            .from("contract")
            .select()
            .eq("agent", userId.value)
            .in("status", arrayFiltrePag.value)
            .range(contractPagePagination.value, rangePagination)
            .order("created_at", { ascending: false });

          if (
            contractPagePagination.value + 5 >=
            contractePagAll.value.length
          ) {
            lastPage.value = true;
          }
          if (contractPagePagination.value >= 0) {
            firstPage.value = false;
          }
          if (error) throw error;

          store.dispatch("contracte/setContractePag", data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getPrev = async () => {
      try {
        if (contractPagePagination.value <= 0) {
          firstPage.value = true;
        } else {
          firstPage.value = false;
          let rangePagination = contractPagePagination.value - 5;
          const { data, error } = await supabase
            .from("contract")
            .select()
            .eq("agent", userId.value)
            .in("status", arrayFiltrePag.value)
            .range(rangePagination, contractPagePagination.value - 1)
            .order("created_at", { ascending: false });
          contractPagePagination.value -= 5;
          if (contractPagePagination.value <= 0) {
            firstPage.value = true;
          }
          if (
            contractPagePagination.value + 5 <=
            contractePagAll.value.length
          ) {
            lastPage.value = false;
          }
          if (error) throw error;

          store.dispatch("contracte/setContractePag", data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getRecommendedClients = async () => {
      try {
        const { data, error } = await supabase
          .from("client")
          .select()
          .eq("agent_id", userId.value)
          .eq("status", "recomandat")
          .order("created_at", { ascending: false })
          .range(0, 4);
        if (error) throw error;

        store.dispatch("clienti/setRecommendedClients", data);
      } catch (error) {
        console.log(error);
      }
    };

    const getAllClients = async () => {
      try {
        const { data, error } = await supabase
          .from("client")
          .select("*")
          .eq("agent_id", userId.value)
          .order("created_at", { ascending: false });
        if (error) throw error;
        allClients.value = data;
        store.dispatch("clienti/setAllClients", data);
      } catch (error) {
        console.log(error);
      }
    };

    const getAllInvites = async () => {
      try {
        const { data, error } = await supabase
          .from("invites")
          .select("*")

          .order("created_at", { ascending: false });
        if (error) throw error;

        store.dispatch("user/setAllInvites", data);
      } catch (error) {
        console.log(error);
      }
    };

    const getContracte = async () => {
      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("agent", userId.value)
          .order("created_at", { ascending: false });

        if (error) throw error;
        contracte.value = data;
        store.dispatch("contracte/setAllContracte", data);
      } catch (error) {
        console.log(error);
      }
    };

    const getModalInfo = async () => {
      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("id", contractId.value);
        if (error) throw error;

        modalContract.value = data[0];
        modalName.value =
          allClientsStore.value.find((ele) => ele.id === modalId.value)
            .prenume +
          " " +
          allClientsStore.value.find((ele) => ele.id === modalId.value).nume;
        modalDate.value = data[0].created_at.slice(0, 10);
        modalRatePlatite.value = data[0].rate_platite;
        modalDurata.value = data[0].durata;
        modalStatus.value = data[0].status;
        modalComision.value = data[0].comision;
        modalTotalIncasat.value = data[0].total_incasat;
      } catch (error) {
        console.log(error);
      }
    };

    const getAllIncasat = async () => {
      const incasariArray = ref([]);

      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("agent", userId.value);
        if (error) throw error;

        incasariArray.value = data.map(({ total_incasat }) => total_incasat);

        let cacat = incasariArray.value;

        let pisat = cacat.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);

        store.dispatch("user/setAllIncasat", pisat.toFixed(2));
      } catch (error) {
        console.log(error);
      }
    };

    const getAllIncasatRecruti = async () => {
      const incasariArray = ref([]);
      const arrayInvitati = ref([]);

      try {
        const { data, error } = await supabase
          .from("roles")
          .select()
          .eq("invited_by", userId.value);
        if (error) throw error;

        arrayInvitati.value = data.map(({ user_id }) => user_id);
        totalRecruti.value = arrayInvitati.value.length;
      } catch (error) {
        console.log(error);
      }

      let iduriInvitati = arrayInvitati.value.map((id) => id);

      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .in("agent", iduriInvitati);
        if (error) throw error;

        incasariArray.value = data.map(({ total_incasat_parinte }) => total_incasat_parinte);

        let cacat = incasariArray.value;

        let pisat = cacat.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);

        store.dispatch("user/setAllIncasatRecruti", pisat.toFixed(2));
      } catch (error) {
        console.log(error);
      }
    };

    const seeCurrentUser = async () => {
      try {
        const { data } = await supabase.auth.getUser();

        formAgentId.value = data.user.id;
        store.dispatch("user/setUserId", data.user.id);
      } catch (error) {
        console.log(error);
      }
    };

    // Send contract

    const sendClientButton = async (firma) => {
      const firmaApelata = allFirmeStore.value.find(
        (item) => item.id === firma
      );

      if (userData.value.contract_status === "not_uploaded") {
        console.log("Semnati contractul de colaborare!");
        return;
      }
      if (
        formFirma.value === "" ||
        formNume.value === "" ||
        formPrenume.value === ""
      ) {
        store.commit(
          "user/setToasterError",
          "Va rugam introduceti toate datele obligatorii!"
        );
        return;
      }
      if (formEmail.value === "" && formTelefon.value === "") {
        store.commit(
          "user/setToasterError",
          "Va rugam introduceti cel putin o modalitate de contact!"
        );
        return;
      }

      try {
        const { error } = await supabase.from("client").insert({
          status: "recomandat",
          firma: formFirma.value,
          nume: formNume.value,
          prenume: formPrenume.value,
          email: formEmail.value,
          telefon: formTelefon.value,
          detalii: formDetalii.value,
          agent_id: formAgentId.value,
          din_partea: formDinPartea.value,
          firma_recomandata: formFirmaRecomandata.value,
          agent_status: userData.value.subscription_status
        });

        const { data: notificationData, error: notificationError } =
          await supabase.functions.invoke("send-notification", {
            body: {
              titlu: "Ati primit o recomandare noua!",
              continut: `Tocmai ati primit o recomandare pentru ${firmaApelata.nume}!
              <br><br> Nume: <strong>${formNume.value} ${formPrenume.value}</strong>
              <br> Email: <strong>${formEmail.value}</strong>
              <br> Telefon: <strong>${formTelefon.value}</strong>
              <br> Firma Recomandata: <strong>${formFirmaRecomandata.value}</strong>
              <br> Din partea: <strong>${formDinPartea.value}</strong>
              <br> Detalii: <strong>${formDetalii.value}</strong>`,
              email: firmaApelata.email_contact,
            },
          });
        if (notificationData) {
          console.log(notificationData);
        }
        if (notificationError) {
          console.log(notificationError);
        }

        await getAllClients();
        recomandariKey.value += 1;
        formFirma.value = "";
        formNume.value = "";
        formPrenume.value = "";
        formEmail.value = "";
        formTelefon.value = "";
        formDetalii.value = "";
        clickedFirma.value = "";
        formDinPartea.value = "";
        formFirmaRecomandata.value = "";
        getRecommendedClients();
        store.commit("user/setToaster", "Recomandare inregistrata cu succes!");
        if (error) throw error;
      } catch (error) {
        console.log(error);
      }
    };

    // Get firme

    const getAllFirme = async () => {
      try {
        const { data, error } = await supabase
          .from("firma")
          .select("*")
          .order("created_at", { ascending: false });
        if (error) throw error;
        listaFirme.value = data;
        store.dispatch("firme/setAllFirme", data);
        let firmeSubbed = data.filter((firma) => firma.sub_status === "sub");

        store.dispatch("firme/setFirmeSubbed", firmeSubbed);

        let randomFirma =
          firmeSubbed[Math.floor(Math.random() * firmeSubbed.length)];
        store.dispatch("firme/setRandomFirma", randomFirma);
      } catch (error) {
        console.log(error);
      }
    };

    return {
      formFirma,
      formNume,
      formPrenume,
      formTelefon,
      formEmail,
      formDetalii,
      anuleazaContractToggle,
      clickedFirma,
      sendClientButton,
      anuleazaToggle,
      modalSubscribe,
      getAllFirme,
      listaFirme,
      setFirma,
      seeCurrentUser,
      getContracte,
      userId,
      contracteStore,
      allClientsStore,
      allFirmeStore,
      recomandariKey,
      recommendedClientsStore,
      resetData,
      allIncasat,
      activateModal,
      getModalInfo,
      modalComision,
      modalTotalIncasat,
      modalName,
      modalDate,
      modalContract,
      getAllIncasatRecruti,
      allIncasatRecruti,
      totalRecruti,
      getNext,
      contractePag,
      getPrev,
      firstPage,
      lastPage,
      resetContractStore,
      getContractePagAll,
      filtruContractSemnat,
      filtruContractDerulare,
      filtruContractFinalizat,
      filtruContractAnulat,
      userData,
      cautaFirmaInput,
      randomFirmaId,
      randomFirmaStore,
      seeRandomFirma,
      formDinPartea,
      allInvites,
      firmeSubbed,
      invitesOwned,
      hideBalance,
      showBalance,
      getInvitesOwned,
      formFirmaRecomandata,
      toFirma,
      allFirmeSearched,
      loadingSearch
    };
  },
  created() {},
  methods: {},
};
</script>
