<template>
    <div class="signin-page">
    <div class="signin-section-1">
      <h1 class="signin-header">-- -</h1>
      <img src="../../src/assets/img/arkana-color.png" class="login-logo-color">
      <h1 class="signin-description"><span class="signin-accent">secret knowledge</span><br>
Noun. arkana. arcana (secret knowledge)</h1>
      <div class="signed-in-box">
        <div class="signed-in-title">
            Loading...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { supabase } from "../../src/clients/supabase"
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: "SignIn",
  components: {
   
  },
  data() {
    return {
      error: false,
    };
  },
  setup() {
    const router = useRouter()
    const store = useStore()

    const isAuth = computed(() => {
      return store.getters['user/isAuth']
    })

    const isAdmin = computed(() => {
      return store.getters['user/isAdmin']
    })

    const user_role = computed(() => {
        return store.getters['user/user_role']
    })

    const userRole = ref('')
    const userId = ref('')
    const email = ref('')
    const password = ref('')
    
    if (user_role.value === 'firma') {
        router.push('/firmadashboard')
    }
    

    const checkRole = async () => {
          const { data, error } = await supabase.from('roles').select().eq('user_id', userId.value)

          if(error) {
            console.log(error)
          }
          else {
            
              store.dispatch('user/setRole', data[0].role)
            
          }
        }

    watch(isAuth, (newValue) => {
      if(newValue === true) {
        checkRole()
      }
    })

    return {
      userRole,
      isAuth,
      isAdmin,
      userId,
      email,
      password,
      store,
      user_role,
      
    }

  },
  methods: {
   
  },
}
</script>

