/* eslint-disable */
<template>
  <div class="page-container">
    <div class="width-container">
      <div class="page-title">{{ firmaName }}</div>
      <div class="home-page">
        <div class="main-content">
          <div class="contracte-main-content">
            <div class="contracte-sumar-container">
              <div class="contracte-sumar-title" v-if="userData && userData.language === 'romana'">Sumar</div>
              <div class="contracte-sumar-title" v-if="userData && userData.language === 'english'">Summary</div>
              <div class="contracte-sumar-field">
                <div class="profile-field-left" v-if="userData && userData.language === 'romana'">Recomandari</div>
                <div class="profile-field-left" v-if="userData && userData.language === 'english'">Recommendations</div>
                <div
                  class="profile-field-right dashboard-summary-value"
                  v-if="recommendedClients"
                >
                  {{ recommendedClients.length }}
                </div>
              </div>
              <div class="contracte-sumar-field">
                <div class="profile-field-left" v-if="userData && userData.language === 'romana'">Contracte semnate</div>
                <div class="profile-field-left" v-if="userData && userData.language === 'english'">Signed contracts</div>
                <div
                  class="profile-field-right dashboard-summary-value-blue"
                  v-if="contracteStore"
                >
                  {{
                    contracteStore.filter((ele) => ele.status === "semnat")
                      .length
                  }}
                </div>
              </div>
              <div class="contracte-sumar-field">
                <div class="profile-field-left" v-if="userData && userData.language === 'romana'">Contracte in derulare</div>
                <div class="profile-field-left" v-if="userData && userData.language === 'english'">Ongoing contracts</div>
                <div
                  class="profile-field-right dashboard-summary-value-yellow"
                  v-if="contracteStore"
                >
                  {{
                    contracteStore.filter((ele) => ele.status === "derulare")
                      .length
                  }}
                </div>
              </div>
              <div class="contracte-sumar-field">
                <div class="profile-field-left" v-if="userData && userData.language === 'romana'">Contracte finalizate</div>
                <div class="profile-field-left" v-if="userData && userData.language === 'english'">Finalized contracts</div>
                <div
                  class="profile-field-right dashboard-summary-value-green"
                  v-if="contracteStore"
                >
                  {{
                    contracteStore.filter((ele) => ele.status === "finalizat")
                      .length
                  }}
                </div>
              </div>
              <div class="contracte-sumar-field">
                <div class="profile-field-left" v-if="userData && userData.language === 'romana'">
                  Contracte anulate
                </div>
                <div class="profile-field-left" v-if="userData && userData.language === 'english'">
                  Cancelled contracts
                </div>
                <div
                  class="profile-field-right dashboard-summary-value-red"
                  v-if="contracteStore"
                >
                  {{
                    contracteStore.filter((ele) => ele.status === "anulat")
                      .length
                  }}
                </div>
              </div>
            </div>
            <div class="search-container-firma">
              <div class="search-title" v-if="userData && userData.language === 'romana'">Cautare</div>
              <div class="search-title" v-if="userData && userData.language === 'english'">Search</div>
              <form @submit.prevent="cautaButton" class="search-form">
                <input
                  type="text"
                  class="search-input"
                  v-model="searchString"
                  placeholder="Cauta dupa nume / prenume"
                  v-if="userData && userData.language === 'romana'"
                />
                <input
                  type="text"
                  class="search-input"
                  v-model="searchString"
                  placeholder="Search by name"
                  v-if="userData && userData.language === 'english'"
                />
                <div class="search-btn" @click="cautaButton()">
                  <span v-if="userData && userData.language === 'romana'">Cauta</span>
                  <span v-if="userData && userData.language === 'english'">Search</span>
                </div>
              </form>
            </div>
            <div
              class="contracte-container-firma-dashboard"
              v-if="cautaActive && searchResponse"
              @keyup.esc="cautaActive = false"
            >
              <div class="box-header-row">
                <div class="todo-title" v-if="userData && userData.language === 'romana'">Cautare</div>
                <div class="todo-title" v-if="userData && userData.language === 'english'">Search</div>
                <div class="close-search" @click="inchideSearch()">X</div>
              </div>
              <div class="fara-clienti" v-if="searchResponse.length === 0">
                <span v-if="userData && userData.language === 'romana'">Nu s-a gasit niciun rezultat</span>
                <span v-if="userData && userData.language === 'english'">No results were found for your search</span>
                
              </div>
              <div class="overflow-wrapper">
                <table class="orders-table" v-if="searchResponse.length != 0">
                  <col class="col-order-no" />
                  <col class="col-order-name" />

                  <col class="col-order-id" />
                  <col class="col-order-ref" />

                  <col class="col-order-more" />
                  <tr class="products-table-head">
                    <th class="col-order-no">ID</th>
                    <th>
                      <span v-if="userData && userData.language === 'romana'">Client</span>
                      <span v-if="userData && userData.language === 'english'">Client</span>
                      
                    </th>

                    <th>
                      <span v-if="userData && userData.language === 'romana'">Serviciu</span>
                      <span v-if="userData && userData.language === 'english'">Service</span>
                    </th>
                    <th>
                      <span v-if="userData && userData.language === 'romana'">Suma</span>
                      <span v-if="userData && userData.language === 'english'">Amount</span>
                    </th>
                    <th>
                      <span v-if="userData && userData.language === 'romana'">Comision</span>
                      <span v-if="userData && userData.language === 'english'">Commission</span>
                    </th>

                    <th>
                      <span v-if="userData && userData.language === 'romana'">Data</span>
                      <span v-if="userData && userData.language === 'english'">Date</span>
                    </th>
                    <th>
                      <span v-if="userData && userData.language === 'romana'">Tip</span>
                      <span v-if="userData && userData.language === 'english'">Type</span>
                    </th>
                    <th>Status</th>
                  </tr>
                  <tr
                    v-for="contract in searchResponse"
                    :key="contract.id + 's'"
                    class="products-table-item"
                    @click="
                      activateModal(contract.client_id, contract.id);
                      showModal = true;
                    "
                  >
                    <th class="client-cell">{{ contract.id }}</th>
                    <th
                      class="product-name client-cell"
                      v-if="allClientsComp && searchResponse"
                    >
                      {{
                        allClientsComp.find(
                          (ele) => ele.id === contract.client_id
                        )?.prenume ?? "Nume"
                      }}
                      {{
                        allClientsComp.find(
                          (ele) => ele.id === contract.client_id
                        )?.nume ?? "Necunoscut"
                      }}
                    </th>

                    <th>{{ contract.serviciu }}</th>
                    <th class="suma-tabel-contracte client-cell">
                      {{ contract.suma.toLocaleString() }} RON
                    </th>
                    <th class="comision-tabel-contracte client-cell">
                      {{ contract.comision.toLocaleString() }} RON
                      <span v-if="userData && contract.tip === 'recurentCheck' && userData.language === 'romana'"
                        >/ luna</span>
                        <span v-if="userData && contract.tip === 'recurentCheck' && userData.language === 'english'"
                        >/ month</span
                      >
                    </th>

                    <th class="data-tabel client-cell">
                      {{ contract.created_at.slice(0, 10) }}
                    </th>

                    <th
                      class="product-name client-cell"
                      v-if="contract.tip === 'oneTimeCheck'"
                    >
                      One Time
                    </th>
                    <th
                      class="product-name client-cell"
                      v-if="contract.tip === 'recurentCheck'"
                    >
                    <span v-if="userData && userData.language === 'romana'">Recurent</span>
                    <span v-if="userData && userData.language === 'english'">Recurrent</span>
                      
                      <span
                        v-if="
                          contract.tip === 'recurentCheck' &&
                          contract.status != 'finalizat'
                        "
                        class="rate-platite-tabel"
                        >{{ contract.rate_platite }} /
                        {{ contract.durata }}</span
                      >
                      <span
                        v-if="
                          contract.tip === 'recurentCheck' &&
                          contract.status === 'finalizat'
                        "
                        class="rate-platite-final-tabel"
                        >{{ contract.rate_platite }} /
                        {{ contract.durata }}</span
                      >
                    </th>
                    <th class="status-col client-cell">
                      <span v-if="contract.status === 'derulare'"
                        ><span v-if="userData && userData.language === 'romana'">In derulare</span>
                        <span v-if="userData && userData.language === 'english'">Ongoing</span></span
                      >
                      <span v-if="contract.status != 'derulare'">{{
                        contract.status
                      }}</span>
                      <div
                        class="status-confirmat"
                        v-if="contract.status === 'semnat'"
                      ></div>
                      <div
                        class="status-incasat"
                        v-if="contract.status === 'derulare'"
                      ></div>
                      <div
                        class="status-finalizat"
                        v-if="contract.status === 'finalizat'"
                      ></div>
                      <div
                        class="status-anulat"
                        v-if="contract.status === 'anulat'"
                      ></div>
                    </th>
                  </tr>
                </table>
              </div>
            </div>

            <div
              class="contracte-container-firma-dashboard"
              v-if="recommendedClients && !cautaActive"
            >
            <div class="box-header-row">
                  <div class="todo-title">
                    <span v-if="userData && userData.language === 'romana'">Recomandari</span>
                    <span v-if="userData && userData.language === 'english'">Recommendations</span>
                  </div>

                  <router-link to="/arhivarecomandari"
                    ><div class="show-more mobile-hide">
                      <span v-if="userData && userData.language === 'romana'">Arhiva recomandari</span>
                    <span v-if="userData && userData.language === 'english'">Archived recommendations</span>
                    </div></router-link
                  >
                  <img src="../assets/img/scroll-icon.png" class="scroll-icon" />
                </div>

              <div class="fara-clienti" v-if="recommendedClients.length === 0">
                <span v-if="userData && userData.language === 'romana'">Nu aveti nicio recomandare</span>
                    <span v-if="userData && userData.language === 'english'">You haven't received any recommendations</span>
              </div>
              <div class="overflow-wrapper">
                <table
                  class="orders-table"
                  v-if="recommendedClients.length != 0"
                >
                  <col class="col-order-no" />
                  <col class="col-order-name" />

                  <col class="col-order-id" />
                  <col class="col-order-ref" />

                  <col class="col-order-more" />
                  <tr class="products-table-head">
                    <th class="col-order-no">ID</th>
                    <th>
                      <span v-if="userData && userData.language === 'romana'">Nume</span>
                    <span v-if="userData && userData.language === 'english'">Name</span>
                    </th>

                    <th>
                      <span v-if="userData && userData.language === 'romana'">Telefon</span>
                    <span v-if="userData && userData.language === 'english'">Telephone</span>
                    </th>
                    <th>Email</th>
                    <th>
                      <span v-if="userData && userData.language === 'romana'">Detalii</span>
                    <span v-if="userData && userData.language === 'english'">Details</span>
                    </th>
                    <th>
                      <span v-if="userData && userData.language === 'romana'">Data</span>
                    <span v-if="userData && userData.language === 'english'">Date</span>
                    </th>
                  </tr>
                  <tr
                    class="products-table-item"
                    @click="toOrder(client.id)"
                    v-for="client in recommendedClientsStore"
                    :key="client.id"
                  >
                    <th class="table-item client-cell">{{ client.id }}</th>
                    <th class="product-name client-cell">
                      {{ client.prenume }} {{ client.nume }}
                    </th>

                    <th class="table-item client-cell">{{ client.telefon }}</th>
                    <th class="table-item client-cell">{{ client.email }}</th>
                    <th class="table-item-detalii client-cell">
                      {{ client.detalii }}
                    </th>
                    <th class="table-item client-cell">
                      {{ client.created_at.slice(0, 10) }}
                    </th>
                  </tr>
                </table>
              </div>
            </div>
            <div
              class="contracte-container"
              v-if="contractePag && !cautaActive"
            >
              <div class="box-header-row-firma">
                <div class="todo-title">
                  <span v-if="userData && userData.language === 'romana'">Contracte</span>
                    <span v-if="userData && userData.language === 'english'">Contracts</span>
                </div>
                <img src="../assets/img/scroll-icon.png" class="scroll-icon" />
              </div>
              <div class="filtre-wrapper">
                <div
                  class="filtru-contracte"
                  :class="{ filtruselected: filtruContractSemnat }"
                  @click="filtruContractSemnat = !filtruContractSemnat"
                >
                <span v-if="userData && userData.language === 'romana'">Semnat</span>
                <span v-if="userData && userData.language === 'english'">Signed</span>
                </div>
                <div
                  class="filtru-contracte"
                  :class="{ filtruselected: filtruContractDerulare }"
                  @click="filtruContractDerulare = !filtruContractDerulare"
                >
                <span v-if="userData && userData.language === 'romana'">In derulare</span>
                <span v-if="userData && userData.language === 'english'">Ongoing</span>
                </div>
                <div
                  class="filtru-contracte"
                  :class="{ filtruselected: filtruContractFinalizat }"
                  @click="filtruContractFinalizat = !filtruContractFinalizat"
                >
                <span v-if="userData && userData.language === 'romana'">Finalizat</span>
                <span v-if="userData && userData.language === 'english'">Finalized</span>
                </div>
                <div
                  class="filtru-contracte"
                  :class="{ filtruselected: filtruContractAnulat }"
                  @click="filtruContractAnulat = !filtruContractAnulat"
                >
                <span v-if="userData && userData.language === 'romana'">Anulat</span>
                <span v-if="userData && userData.language === 'english'">Cancelled</span>
                </div>
              </div>
              <div class="fara-clienti" v-if="contractePag.length === 0">
                <span v-if="userData && userData.language === 'romana'">Nu aveti niciun contract</span>
                    <span v-if="userData && userData.language === 'english'">You don't have any contracts</span>
              </div>
              <div class="overflow-wrapper">
                <table class="orders-table" v-if="contractePag.length != 0">
                  <col class="col-order-no" />
                  <col class="col-order-name" />

                  <col class="col-order-id" />
                  <col class="col-order-ref" />

                  <col class="col-order-more" />
                  <tr class="products-table-head">
                    <th class="col-order-no">ID</th>
                    <th>Client</th>

                    <th>
                      <span v-if="userData && userData.language === 'romana'">Serviciu</span>
                    <span v-if="userData && userData.language === 'english'">Service</span>
                    </th>
                    <th>
                      <span v-if="userData && userData.language === 'romana'">Suma</span>
                    <span v-if="userData && userData.language === 'english'">Amount</span>
                    </th>
                    <th>
                      <span v-if="userData && userData.language === 'romana'">Comision</span>
                    <span v-if="userData && userData.language === 'english'">Commission</span>
                    </th>

                    <th>
                      <span v-if="userData && userData.language === 'romana'">Data</span>
                    <span v-if="userData && userData.language === 'english'">Date</span>
                    </th>
                    <th>
                      <span v-if="userData && userData.language === 'romana'">Tip</span>
                    <span v-if="userData && userData.language === 'english'">Type</span>
                    </th>
                    <th>Status</th>
                  </tr>
                  <tr
                    v-for="contract in contractePag"
                    :key="contract.id"
                    class="products-table-item"
                    @click="
                      activateModal(contract.client_id, contract.id);
                      showModal = true;
                    "
                  >
                    <th class="width-cell">{{ contract.id }}</th>
                    <th class="product-name client-cell" v-if="allClientsComp">
                      {{
                        allClientsComp.find(
                          (ele) => ele.id === contract.client_id
                        ).prenume
                      }}
                      {{
                        allClientsComp.find(
                          (ele) => ele.id === contract.client_id
                        ).nume
                      }}
                    </th>

                    <th class="client-cell">{{ contract.serviciu }}</th>
                    <th class="suma-tabel-contracte client-cell">
                      {{ contract.suma.toLocaleString() }} RON
                    </th>
                    <th class="comision-tabel-contracte client-cell">
                      {{ contract.comision.toLocaleString() }} RON
                      <span v-if="contract.tip === 'recurentCheck'"
                        >/ <span v-if="userData && userData.language === 'romana'">luna</span>
                        <span v-if="userData && userData.language === 'english'">month</span></span
                      >
                    </th>

                    <th class="data-tabel client-cell">
                      {{ contract.created_at.slice(0, 10) }}
                    </th>

                    <th
                      class="product-name"
                      v-if="contract.tip === 'oneTimeCheck'"
                    >
                      One Time
                    </th>
                    <th
                      class="product-name"
                      v-if="contract.tip === 'recurentCheck'"
                    >
                    <span v-if="userData && userData.language === 'romana'">Recurent</span>
                    <span v-if="userData && userData.language === 'english'">Recurrent</span>
                      <span
                        v-if="
                          contract.tip === 'recurentCheck' &&
                          contract.status != 'finalizat'
                        "
                        class="rate-platite-tabel"
                        >{{ contract.rate_platite }} /
                        {{ contract.durata }}</span
                      >
                      <span
                        v-if="
                          contract.tip === 'recurentCheck' &&
                          contract.status === 'finalizat'
                        "
                        class="rate-platite-final-tabel"
                        >{{ contract.rate_platite }} /
                        {{ contract.durata }}</span
                      >
                    </th>
                    <th class="status-col client-cell">
                      <span v-if="contract.status === 'derulare'"
                        ><span v-if="userData && userData.language === 'romana'">In derulare</span>
                        <span v-if="userData && userData.language === 'english'">Ongoing</span></span
                      >
                      <span v-if="contract.status != 'derulare'">{{
                        contract.status
                      }}</span>
                      <div
                        class="status-confirmat client-cell"
                        v-if="contract.status === 'semnat'"
                      ></div>
                      <div
                        class="status-incasat client-cell"
                        v-if="contract.status === 'derulare'"
                      ></div>
                      <div
                        class="status-finalizat client-cell"
                        v-if="contract.status === 'finalizat'"
                      ></div>
                      <div
                        class="status-anulat client-cell"
                        v-if="contract.status === 'anulat'"
                      ></div>
                    </th>
                  </tr>
                </table>
              </div>
              <div class="buttons-table-wrapper" v-if="!firstPage || !lastPage">
                <div
                  class="btn-pagination"
                  @click="getPrev()"
                  v-if="!firstPage"
                >
                <span v-if="userData && userData.language === 'romana'">Inapoi</span>
                <span v-if="userData && userData.language === 'english'">Back</span>
                </div>
                <div class="btn-pagination-cancelled" v-if="firstPage">
                  <span v-if="userData && userData.language === 'romana'">Inapoi</span>
                    <span v-if="userData && userData.language === 'english'">Back</span>
                </div>
                <div class="btn-pagination-cancelled" v-if="lastPage">
                  <span v-if="userData && userData.language === 'romana'">Inainte</span>
                    <span v-if="userData && userData.language === 'english'">Next</span>
                </div>
                <div class="btn-pagination" @click="getNext()" v-if="!lastPage">
                  <span v-if="userData && userData.language === 'romana'">Inainte</span>
                    <span v-if="userData && userData.language === 'english'">Next</span>
                </div>
              </div>
            </div>
            <div
              class="contracte-container"
              v-if="clientClients && !cautaActive"
            >
              <div class="box-header-row-firma">
                <div class="todo-title">Clienti</div>
                <img src="../assets/img/scroll-icon.png" class="scroll-icon" />
              </div>
              <div class="fara-clienti" v-if="clientClients.length === 0">
                <span v-if="userData && userData.language === 'romana'">Nu aveti niciun client</span>
                    <span v-if="userData && userData.language === 'english'">You don't have any clients</span>
              </div>
              <div class="overflow-wrapper">
                <table class="orders-table" v-if="clientClients.length != 0">
                  <col class="col-order-no" />
                  <col class="col-order-name" />

                  <col class="col-order-id" />
                  <col class="col-order-ref" />

                  <tr class="products-table-head">
                    <th class="col-order-no">ID</th>

                    <th>Client</th>
                    <th><span v-if="userData && userData.language === 'romana'">Telefon</span>
                      <span v-if="userData && userData.language === 'english'">Telephone</span></th>
                    <th>Email</th>
                    <th>
                      <span v-if="userData && userData.language === 'romana'">Data</span>
                    <span v-if="userData && userData.language === 'english'">Date</span>
                    </th>
                  </tr>
                  <tr
                    class="products-table-item"
                    @click="toOrder(clienta.id)"
                    v-for="clienta in clientClientsStore"
                    :key="clienta.id"
                  >
                    <th class="table-item client-cell">{{ clienta.id }}</th>
                    <th class="product-name client-cell">
                      {{ clienta.prenume }} {{ clienta.nume }}
                    </th>

                    <th class="table-item client-cell">
                      {{ clienta.telefon }}
                    </th>
                    <th class="table-item client-cell">{{ clienta.email }}</th>
                    <th class="table-item client-cell">
                      {{ clienta.created_at.slice(0, 10) }}
                    </th>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <vue-final-modal
        v-model="showModal"
        classes="modal-container"
        content-class="modal-content"
        v-if="modalContract"
        @closed="resetData()"
      >
        <div class="contract-item" v-if="!dejaCalificat">
          <div
            class="modal-status-semnat"
            v-if="modalContract.status === 'semnat'"
          />
          <div
            class="modal-status-derulare"
            v-if="modalContract.status === 'derulare'"
          />
          <div
            class="modal-status-finalizat"
            v-if="modalContract.status === 'finalizat'"
          />
          <div
            class="modal-status-anulat"
            v-if="modalContract.status === 'anulat'"
          />
          <div class="profile-field">
            <div class="profile-field-left">ID</div>
            <div class="profile-field-right">{{ modalContract.id }}</div>
          </div>
          <div class="profile-field">
            <div class="profile-field-left">Client</div>
            <div class="profile-field-right">{{ modalName }}</div>
          </div>
          <div class="profile-field">
            <div class="profile-field-left">
              <span v-if="userData && userData.language === 'romana'">Serviciu</span>
                    <span v-if="userData && userData.language === 'english'">Service</span>
            </div>
            <div class="profile-field-right">{{ modalContract.serviciu }}</div>
          </div>
          <div class="profile-field-detalii2" v-if="modalContract.detalii">
            <div class="profile-field-left">
              <span v-if="userData && userData.language === 'romana'">Detalii</span>
                    <span v-if="userData && userData.language === 'english'">Details</span>
            </div>
            <div class="profile-field-right-detalii2">
              {{ modalContract.detalii }}
            </div>
          </div>
          <div class="profile-field">
            <div class="profile-field-left">
              <span v-if="userData && userData.language === 'romana'">Data semnarii</span>
                    <span v-if="userData && userData.language === 'english'">Date signed</span>
            </div>
            <div class="profile-field-right">{{ modalDate }}</div>
          </div>
          <div class="profile-field">
            <div class="profile-field-left">
              <span v-if="userData && userData.language === 'romana'">Suma</span>
                    <span v-if="userData && userData.language === 'english'">Amount</span>
            </div>
            <div class="profile-field-right">{{ modalContract.suma }} RON</div>
          </div>
          <div class="profile-field">
            <div class="profile-field-left">
              <span v-if="userData && userData.language === 'romana'">Comision</span>
                    <span v-if="userData && userData.language === 'english'">Commission</span>
            </div>
            <div class="profile-field-right">
              {{ modalContract.comision }} RON<span
                v-if="modalContract.tip === 'recurentCheck'"
              >
                / <span v-if="userData && userData.language === 'romana'">luna</span>
                <span v-if="userData && userData.language === 'english'">month</span></span
              >
            </div>
          </div>
          <div
            class="profile-field"
            v-if="modalContract.tip === 'recurentCheck'"
          >
            <div class="profile-field-left">
              <span v-if="userData && userData.language === 'romana'">Platit</span>
                    <span v-if="userData && userData.language === 'english'">Paid</span>
            </div>
            <div class="profile-field-right">
              {{ modalContract.rate_platite }} / {{ modalContract.durata }}
            </div>
          </div>
          <div
            class="profile-field"
            v-if="
              modalContract.status === 'semnat' &&
              modalContract.tip === 'oneTimeCheck' &&
              !confirmBounty
            "
          >
            <div class="contract-btn" @click="contractIncasat()">
              <span v-if="userData && userData.language === 'romana'">Incasat</span>
                    <span v-if="userData && userData.language === 'english'">Collected</span>
            </div>
          </div>
          <div
            class="profile-field"
            v-if="
              modalContract.tip === 'recurentCheck' &&
              modalContract.status != 'finalizat' &&
              modalContract.status != 'anulat' &&
              !confirmBounty
            "
          >
            <div class="contract-btn" @click="platesteTransa()">
              <span v-if="userData && userData.language === 'romana'">Plateste transa</span>
                    <span v-if="userData && userData.language === 'english'">Pay instalment</span>
            </div>
          </div>
          <div class="profile-field" v-if="modalContract.status === 'anulat'">
            <div class="profile-field-left">
              <span v-if="userData && userData.language === 'romana'">Data anularii</span>
                    <span v-if="userData && userData.language === 'english'">Cancellation date</span>
            </div>
            <div class="profile-field-right">{{ modalDate }}</div>
          </div>
          <div
            class="profile-field-agent"
            v-if="modalContract.status === 'anulat'"
          >
            <div class="profile-field-left">
              <span v-if="userData && userData.language === 'romana'">Motivul anularii</span>
                    <span v-if="userData && userData.language === 'english'">Cancellation reason</span>
            </div>
            <div class="profile-field-right">
              {{ modalContract.motiv_anulare }}
            </div>
          </div>
          <div
            class="profile-field"
            v-if="
              modalContract.status != 'finalizat' &&
              modalContract.status != 'anulat' &&
              !anuleazaToggle &&
              !confirmBounty
            "
          >
            <div class="contract-btn-anulat" @click="anuleazaContractToggle()">
              <span v-if="userData && userData.language === 'romana'">Anuleaza contractul</span>
                    <span v-if="userData && userData.language === 'english'">Cancel contract</span>
            </div>
          </div>

          <div class="profile-field" v-if="anuleazaToggle">
            <div class="profile-field-anuleaza-left">
              <span v-if="userData && userData.language === 'romana'">Sunteti sigur ca vreti sa anulati?</span>
                    <span v-if="userData && userData.language === 'english'">Are you sure you want to cancel this contract?</span>
            </div>
            <div class="profile-field-anuleaza-right" @click="anuleazaMotiv()">
              <span v-if="userData && userData.language === 'romana'">Da, anuleaza</span>
                    <span v-if="userData && userData.language === 'english'">Yes, cancel</span>
            </div>
          </div>
        </div>
        <div class="anuleaza-motiv-screen" v-if="anuleazaMotivScreen">
          Anuleaza Screen
          <div class="anuleaza-btn" @click="anuleazaContract()">
            <span v-if="userData && userData.language === 'romana'">Anuleaza</span>
                    <span v-if="userData && userData.language === 'english'">Cancel</span>
          </div>
        </div>
        <div
          class="bounty-confirm-screen"
          v-if="confirmBounty && !dejaCalificat"
        >
          <div class="bounty-header">
            <span v-if="userData && userData.language === 'romana'">Conform sumei, se pare ca acest contract se califica la bounty-ul tau</span>
                    <span v-if="userData && userData.language === 'english'">According to the amount, it looks like this contract qualifies for your bounty</span>
            
          </div>
          <div class="profile-field" v-if="bountyCerinta">
            <div class="profile-field-left">
              <span v-if="userData && userData.language === 'romana'">Cerinta bounty</span>
                    <span v-if="userData && userData.language === 'english'">Bounty requirement</span>
            </div>
            <div class="profile-field-right">{{ bountyCerinta }}</div>
          </div>
          <div class="profile-field" v-if="bountySuma">
            <div class="profile-field-left">
              <span v-if="userData && userData.language === 'romana'">Suma bounty</span>
                    <span v-if="userData && userData.language === 'english'">Bounty amount</span>
            </div>
            <div class="profile-field-right">{{ bountySuma }} RON</div>
          </div>
          <div class="bounty-confirm-row">
            <div class="cancel-bounty" @click="nuAdaugaBounty()">
              <span v-if="userData && userData.language === 'romana'">Nu se incadreaza</span>
                    <span v-if="userData && userData.language === 'english'">It doesn't qualify</span>
            </div>
            <div class="accept-bounty" @click="adaugaLaBounty()">
              <span v-if="userData && userData.language === 'romana'">Da, adauga la bounty</span>
                    <span v-if="userData && userData.language === 'english'">Yes, add to bounty</span>
            </div>
          </div>
        </div>
        <div class="deja-calificat" v-if="dejaCalificat">
          <span v-if="userData && userData.language === 'romana'">Utilizatorul este deja calificat pentru acest bounty!</span>
                    <span v-if="userData && userData.language === 'english'">The user is already qualified for this bounty!</span>
        </div>
      </vue-final-modal>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { supabase } from "../clients/supabase";
import { useStore } from "vuex";

export default {
  name: "FirmaPage",
  components: {},
  data() {
    return {
      showModal: false,
      error: false,
    };
  },
  setup() {
    const store = useStore();
    const allClients = ref("");
    const recommendedClients = ref("");
    const clientClients = ref("");
    const contracte = ref("");
    const modalId = ref("");
    const contractId = ref("");
    const modalContract = ref();
    const modalName = ref("");
    const modalDate = ref("");
    const rataNoua = ref(0);
    const modalRatePlatite = ref(0);
    const modalDurata = ref(0);
    const modalStatus = ref("");
    const anuleazaToggle = ref(false);
    const modalComision = ref(0);
    const modalTotalIncasat = ref(0);
    const bountySuma = ref();
    const bountyCerinta = ref();
    const modalSuma = ref();
    const confirmBounty = ref(false);
    const modalAgent = ref();
    const dejaCalificat = ref(false);
    const cautaActive = ref(false);
    const searchString = ref("");
    const anuleazaMotivScreen = ref(false);

    const arrayFiltrePag = ref(["finalizat", "anulat", "derulare", "semnat"]);

    const filtruContractSemnat = ref(true);
    const filtruContractDerulare = ref(true);
    const filtruContractFinalizat = ref(true);
    const filtruContractAnulat = ref(true);

    const contractPagePagination = ref(0);
    const firstPage = ref(true);
    const lastPage = ref(true);

    const userId = computed(() => {
      return store.getters["user/getUserId"];
    });

    const firmaId = computed(() => {
      return store.getters["user/getFirmaId"];
    });

    const allClientsComp = computed(() => {
      return store.getters["clienti/getAllClients"];
    });

    const clientClientsStore = computed(() => {
      return store.getters["clienti/getClientClients"];
    });

    const recommendedClientsStore = computed(() => {
      return store.getters["clienti/getRecommendedClients"];
    });

    const contracteStore = computed(() => {
      return store.getters["contracte/getAllContracte"];
    });

    const firmaName = computed(() => {
      return store.getters["user/getFirmaName"];
    });

    const contractePag = computed(() => {
      return store.getters["contracte/getContractePag"];
    });

    const contractePagAll = computed(() => {
      return store.getters["contracte/getContractePagAll"];
    });

    const searchResponse = computed(() => {
      return store.getters["user/getSearchResponse"];
    });

    const userData = computed(() => {
      return store.getters["user/getUserData"];
    });

    onMounted(() => {
      getAllClients();
      seeCurrentUser();
      getRecommendedClients();
      getFirmaComision();
      getClientClients();
      getContracte();
      getContractePag();
      getContractePagAll();

    });

    onUnmounted(() => {
      resetAllStores();
    });

    watch(filtruContractSemnat, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("semnat");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "semnat"
        );

        getContractePag();
      }
    });

    watch(filtruContractDerulare, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("derulare");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "derulare"
        );

        getContractePag();
      }
    });

    watch(filtruContractFinalizat, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("finalizat");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "finalizat"
        );

        getContractePag();
      }
    });

    watch(filtruContractAnulat, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("anulat");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "anulat"
        );

        getContractePag();
      }
    });

    const seeCurrentUser = async () => {
      const { data, error } = await supabase.auth.getSession();

      store.dispatch("user/setUserId", data.session.user.id);
      if (error) {
        console.log(error);
      }
    };

    const getContractePagAll = async () => {
      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("firma", firmaId.value)
          .in("status", arrayFiltrePag.value)
          .order("created_at", { ascending: false });

        if (error) throw error;

        store.dispatch("contracte/setContractePagAll", data);
      } catch (error) {
        console.log(error);
      }
    };

    const getContractePag = async () => {
      contractPagePagination.value = 0;
      firstPage.value = true;
      await getContractePagAll();
      if (contractPagePagination.value + 20 >= contractePagAll.value.length) {
        lastPage.value = true;
      } else {
        lastPage.value = false;
      }
      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("firma", firmaId.value)
          .in("status", arrayFiltrePag.value)
          .range(0, 19)
          .order("created_at", { ascending: false });

        store.dispatch("contracte/setContractePag", data);
        if (error) throw error;
      } catch (error) {
        console.log(error);
      }
    };

    const getNext = async () => {
      try {
        if (contractPagePagination.value + 20 >= contractePagAll.value.length) {
          lastPage.value = true;
        } else {
          lastPage.value = false;
          contractPagePagination.value += 20;
          let rangePagination = contractPagePagination.value + 19;
          const { data, error } = await supabase
            .from("contract")
            .select()
            .eq("firma", firmaId.value)
            .in("status", arrayFiltrePag.value)
            .range(contractPagePagination.value, rangePagination)
            .order("created_at", { ascending: false });

          if (
            contractPagePagination.value + 20 >=
            contractePagAll.value.length
          ) {
            lastPage.value = true;
          }
          if (contractPagePagination.value >= 0) {
            firstPage.value = false;
          }
          if (error) throw error;

          store.dispatch("contracte/setContractePag", data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getPrev = async () => {
      try {
        if (contractPagePagination.value <= 0) {
          firstPage.value = true;
        } else {
          firstPage.value = false;
          let rangePagination = contractPagePagination.value - 20;
          const { data, error } = await supabase
            .from("contract")
            .select()
            .eq("firma", firmaId.value)
            .in("status", arrayFiltrePag.value)
            .range(rangePagination, contractPagePagination.value - 1)
            .order("created_at", { ascending: false });
          contractPagePagination.value -= 20;
          if (contractPagePagination.value <= 0) {
            firstPage.value = true;
          }
          if (
            contractPagePagination.value + 20 <=
            contractePagAll.value.length
          ) {
            lastPage.value = false;
          }
          if (error) throw error;

          store.dispatch("contracte/setContractePag", data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    function activateModal(id, contractid) {
      modalId.value = id;
      contractId.value = contractid;
      getModalInfo();
    }

    function inchideSearch() {
      cautaActive.value = false;
      store.commit("user/setSearchResponse", null);
      searchString.value = "";
    }

    function resetAllStores() {
      store.dispatch("contracte/reset", "resetAll");
    }

    function anuleazaMotiv() {
      anuleazaMotivScreen.value = true;
    }

    function resetData() {
      modalContract.value = "";
      modalContract.value = "";
      modalName.value = "";
      modalDate.value = "";
      modalRatePlatite.value = "";
      modalDurata.value = "";
      modalStatus.value = "";
      modalComision.value = "";
      modalTotalIncasat.value = "";
      modalSuma.value = "";
      bountySuma.value = "";
      confirmBounty.value = false;
      modalAgent.value = "";
    }

    const cautaButton = async () => {
      if (searchString.value === "") {
        return;
      }
     
      if (cautaActive.value === false) {
        cautaActive.value = true;
      }

      try {
        let cautaText = searchString.value;
        console.log(cautaText);
        const { data, error } = await supabase.rpc(
          "search_contracts_by_client_name",
          { search_query: cautaText, firma_id: firmaId.value }
        );
        

        if (error) throw error;
       
        store.dispatch("user/setSearchResponse", data);
      } catch (error) {
        console.log(error);
      }
    };

    function anuleazaContractToggle() {
      anuleazaToggle.value = true;
      setTimeout(() => {
        anuleazaToggle.value = false;
      }, 3000);
    }

    const getBountySuma = async () => {
      try {
        const { data, error } = await supabase
          .from("bounties")
          .select()
          .eq("firma", firmaId.value);
        if (error) throw error;

        if (data[0].suma != null) {
          bountySuma.value = data[0].suma;
          bountyCerinta.value = data[0].cerinta;
        }
        if (data[0].suma === null) {
          bountySuma.value = null;
        }
      } catch (error) {
        console.log(error);
      }
    };


    const contractIncasat = async () => {
      
      if(firmaId.value === 2) {
        return
      }
      const statusUpdate = ref(false);
      const totalIncasatUpdate = ref(false);

      await getBountySuma();
      if (modalSuma.value <= bountySuma.value || bountySuma.value === null) {
        try {
          const { data, error } = await supabase
            .from("contract")
            .update({ status: "finalizat" })
            .eq("id", contractId.value);
          if (error) throw error;

          console.log(data);
          statusUpdate.value = true;

          getContracte();
          getContractePag();
          getModalInfo();
        } catch (error) {
          console.log(error);
        }

        try {
          const time = new Date().toISOString();
          const { data: contractData, error: contractError } = await supabase
            .from("contract")
            .select()
            .eq("id", contractId.value)
            
          if (contractError) throw contractError;

          console.log('de la CE IMI TREB')
          console.log(contractData)

          if(contractData[0].agent_status != 'sub') {
            let totalIncasatCalcul = null
            let totalIncasatParinte = null
            let totalIncasatVissia = null

            totalIncasatCalcul = (modalComision.value * 0.445).toFixed(2) 
            totalIncasatParinte = (modalComision.value * 0.05).toFixed(2)
            totalIncasatVissia = (modalComision.value * 0.505).toFixed(2)

            const { data, error } = await supabase
            .from("contract")
            .update({ total_incasat: totalIncasatCalcul, total_incasat_parinte: totalIncasatParinte, total_incasat_vissia: totalIncasatVissia, incasat_at: time })
            .eq("id", contractId.value)
            .select();
          if (error) throw error;

          console.log(data);
          totalIncasatUpdate.value = true;
          getContracte();
          getContractePag();
          getModalInfo();
          const contractAgent = data[0].agent;

          const { data: agentInfo, error: agentInfoError } = await supabase
            .from("roles")
            .select()
            .eq("user_id", contractAgent);
          if (agentInfoError) throw agentInfoError;
         

          const { data: notificationData, error: notificationError } =
            await supabase.functions.invoke("send-notification", {
              body: {
                titlu: "S-a incasat un contract!",
                continut: `S-a incasat un contract! Comisionul dvs. este: ${totalIncasatCalcul} RON.`,
                email: agentInfo[0].email,
              },
            });
          if (notificationData) {
            console.log(notificationData);
          }
          if (notificationError) {
            console.log(notificationError);
          }
          }

          if(contractData[0].agent_status === 'sub') {
            let totalIncasatCalcul = null
            let totalIncasatParinte = null
            let totalIncasatVissia = null

            totalIncasatCalcul = (modalComision.value * 0.87).toFixed(2) 
            totalIncasatParinte = (modalComision.value * 0.1).toFixed(2)
            totalIncasatVissia = (modalComision.value * 0.03).toFixed(2)

            const { data, error } = await supabase
            .from("contract")
            .update({ total_incasat: totalIncasatCalcul, total_incasat_parinte: totalIncasatParinte, total_incasat_vissia: totalIncasatVissia, incasat_at: time })
            .eq("id", contractId.value)
            .select();
          if (error) throw error;

          console.log(data);
          totalIncasatUpdate.value = true;
          getContracte();
          getContractePag();
          getModalInfo();
          const contractAgent = data[0].agent;

          const { data: agentInfo, error: agentInfoError } = await supabase
            .from("roles")
            .select()
            .eq("user_id", contractAgent);
          if (agentInfoError) throw agentInfoError;
         

          const { data: notificationData, error: notificationError } =
            await supabase.functions.invoke("send-notification", {
              body: {
                titlu: "S-a incasat un contract!",
                continut: `S-a incasat un contract! Comisionul dvs. este: ${totalIncasatCalcul} RON.`,
                email: agentInfo[0].email,
              },
            });
          if (notificationData) {
            console.log(notificationData);
          }
          if (notificationError) {
            console.log(notificationError);
          }
          }
          
        } catch (error) {
          console.log(error);
        }

        if (statusUpdate.value && totalIncasatUpdate.value) {
          store.commit("user/setToaster", "Contract finalizat!");
        }
      } else {
        console.log("se califica la bounty");
        confirmBounty.value = true;
      }
    };

    const nuAdaugaBounty = async () => {
      if(firmaId.value === 2) {
        return
      }
      const statusUpdate = ref(false);
      const totalIncasatUpdate = ref(false);
      confirmBounty.value = false;

      try {
        const { data, error } = await supabase
          .from("contract")
          .update({ status: "finalizat" })
          .eq("id", contractId.value);
        if (error) throw error;

        console.log(data);
        statusUpdate.value = true;

        getContracte();
        getContractePag();
        getModalInfo();
      } catch (error) {
        console.log(error);
      }
      try {
        const time = new Date().toISOString();
        const { data: contractData, error: contractError } = await supabase
            .from("contract")
            .select()
            .eq("id", contractId.value)
            
          if (contractError) throw contractError;

          console.log('de la CE IMI TREB')
          console.log(contractData)

          if(contractData[0].agent_status != 'sub') {
            let totalIncasatCalcul = null
            let totalIncasatParinte = null
            let totalIncasatVissia = null

            totalIncasatCalcul = (modalComision.value * 0.445).toFixed(2) 
            totalIncasatParinte = (modalComision.value * 0.05).toFixed(2)
            totalIncasatVissia = (modalComision.value * 0.505).toFixed(2)

            const { data, error } = await supabase
            .from("contract")
            .update({ total_incasat: totalIncasatCalcul, total_incasat_parinte: totalIncasatParinte, total_incasat_vissia: totalIncasatVissia, incasat_at: time })
            .eq("id", contractId.value)
            .select();
          if (error) throw error;

          console.log(data);
          totalIncasatUpdate.value = true;
          getContracte();
          getContractePag();
          getModalInfo();
          const contractAgent = data[0].agent;

          const { data: agentInfo, error: agentInfoError } = await supabase
            .from("roles")
            .select()
            .eq("user_id", contractAgent);
          if (agentInfoError) throw agentInfoError;
         

          const { data: notificationData, error: notificationError } =
            await supabase.functions.invoke("send-notification", {
              body: {
                titlu: "S-a incasat un contract!",
                continut: `S-a incasat un contract! Comisionul dvs. este: ${totalIncasatCalcul} RON.`,
                email: agentInfo[0].email,
              },
            });
          if (notificationData) {
            console.log(notificationData);
          }
          if (notificationError) {
            console.log(notificationError);
          }
          }

          if(contractData[0].agent_status === 'sub') {
            let totalIncasatCalcul = null
            let totalIncasatParinte = null
            let totalIncasatVissia = null

            totalIncasatCalcul = (modalComision.value * 0.87).toFixed(2) 
            totalIncasatParinte = (modalComision.value * 0.1).toFixed(2)
            totalIncasatVissia = (modalComision.value * 0.03).toFixed(2)

            const { data, error } = await supabase
            .from("contract")
            .update({ total_incasat: totalIncasatCalcul, total_incasat_parinte: totalIncasatParinte, total_incasat_vissia: totalIncasatVissia, incasat_at: time })
            .eq("id", contractId.value)
            .select();
          if (error) throw error;

          console.log(data);
          totalIncasatUpdate.value = true;
          getContracte();
          getContractePag();
          getModalInfo();
          const contractAgent = data[0].agent;

          const { data: agentInfo, error: agentInfoError } = await supabase
            .from("roles")
            .select()
            .eq("user_id", contractAgent);
          if (agentInfoError) throw agentInfoError;
         

          const { data: notificationData, error: notificationError } =
            await supabase.functions.invoke("send-notification", {
              body: {
                titlu: "S-a incasat un contract!",
                continut: `S-a incasat un contract! Comisionul dvs. este: ${totalIncasatCalcul} RON.`,
                email: agentInfo[0].email,
              },
            });
          if (notificationData) {
            console.log(notificationData);
          }
          if (notificationError) {
            console.log(notificationError);
          }
          }
      } catch (error) {
        console.log(error);
      }

      if (statusUpdate.value && totalIncasatUpdate.value) {
        store.commit("user/setToaster", "Contract finalizat!");
      }
    };

    const adaugaLaBounty = async () => {
      if(firmaId.value === 2) {
        return
      }
      const arrayCalificati = ref([]);
      const statusUpdate = ref(false);
      const totalIncasatUpdate = ref(false);

      try {
        const { data, error } = await supabase
          .from("contract")
          .update({ status: "finalizat" })
          .eq("id", contractId.value);
        if (error) throw error;

        console.log(data);
        statusUpdate.value = true;

        getContracte();
        getModalInfo();
        getContractePag();
      } catch (error) {
        console.log(error);
      }
      try {
        const time = new Date().toISOString();
        const { data: contractData, error: contractError } = await supabase
            .from("contract")
            .select()
            .eq("id", contractId.value)
            
          if (contractError) throw contractError;

          console.log('de la CE IMI TREB')
          console.log(contractData)

          if(contractData[0].agent_status != 'sub') {
            let totalIncasatCalcul = null
            let totalIncasatParinte = null
            let totalIncasatVissia = null

            totalIncasatCalcul = (modalComision.value * 0.445).toFixed(2) 
            totalIncasatParinte = (modalComision.value * 0.05).toFixed(2)
            totalIncasatVissia = (modalComision.value * 0.505).toFixed(2)

            const { data, error } = await supabase
            .from("contract")
            .update({ total_incasat: totalIncasatCalcul, total_incasat_parinte: totalIncasatParinte, total_incasat_vissia: totalIncasatVissia, incasat_at: time })
            .eq("id", contractId.value)
            .select();
          if (error) throw error;

          console.log(data);
          totalIncasatUpdate.value = true;
          getContracte();
          getContractePag();
          getModalInfo();
          const contractAgent = data[0].agent;

          const { data: agentInfo, error: agentInfoError } = await supabase
            .from("roles")
            .select()
            .eq("user_id", contractAgent);
          if (agentInfoError) throw agentInfoError;
         

          const { data: notificationData, error: notificationError } =
            await supabase.functions.invoke("send-notification", {
              body: {
                titlu: "S-a incasat un contract!",
                continut: `S-a incasat un contract! Comisionul dvs. este: ${totalIncasatCalcul} RON.`,
                email: agentInfo[0].email,
              },
            });
          if (notificationData) {
            console.log(notificationData);
          }
          if (notificationError) {
            console.log(notificationError);
          }
          }

          if(contractData[0].agent_status === 'sub') {
            let totalIncasatCalcul = null
            let totalIncasatParinte = null
            let totalIncasatVissia = null

            totalIncasatCalcul = (modalComision.value * 0.87).toFixed(2) 
            totalIncasatParinte = (modalComision.value * 0.1).toFixed(2)
            totalIncasatVissia = (modalComision.value * 0.03).toFixed(2)

            const { data, error } = await supabase
            .from("contract")
            .update({ total_incasat: totalIncasatCalcul, total_incasat_parinte: totalIncasatParinte, total_incasat_vissia: totalIncasatVissia, incasat_at: time })
            .eq("id", contractId.value)
            .select();
          if (error) throw error;

          console.log(data);
          totalIncasatUpdate.value = true;
          getContracte();
          getContractePag();
          getModalInfo();
          const contractAgent = data[0].agent;

          const { data: agentInfo, error: agentInfoError } = await supabase
            .from("roles")
            .select()
            .eq("user_id", contractAgent);
          if (agentInfoError) throw agentInfoError;
         

          const { data: notificationData, error: notificationError } =
            await supabase.functions.invoke("send-notification", {
              body: {
                titlu: "S-a incasat un contract!",
                continut: `S-a incasat un contract! Comisionul dvs. este: ${totalIncasatCalcul} RON.`,
                email: agentInfo[0].email,
              },
            });
          if (notificationData) {
            console.log(notificationData);
          }
          if (notificationError) {
            console.log(notificationError);
          }
          }
      } catch (error) {
        console.log(error);
      }
      try {
        
        const { data, error } = await supabase
          .from("bounties")
          .select()
          .eq("firma", firmaId.value);
        if (error) throw error;

        console.log("de la bounties calificati");
        console.log(firmaId.value)
        console.log(data)
        
        arrayCalificati.value = data[0].calificati || [] || null;
        console.log(arrayCalificati.value);
        if (arrayCalificati.value.includes(modalAgent.value)) {
          dejaCalificat.value = true;
          setTimeout(() => {
            dejaCalificat.value = false;
          }, 3000);
          getContracte();
          getModalInfo();
          getContractePag();
        }

        if (!arrayCalificati.value.includes(modalAgent.value)) {
          arrayCalificati.value.push(modalAgent.value);
          console.log(arrayCalificati.value);
          try {
            const { data, error } = await supabase
              .from("bounties")
              .update({ calificati: arrayCalificati.value })
              .eq("firma", firmaId.value);
            if (error) throw error;

            console.log(data);
            getContracte();
            getModalInfo();
            getContractePag();
          } catch (error) {
            console.log(error);
          }
        }

        getContracte();
        getModalInfo();
        getContractePag();

        confirmBounty.value = false;
      } catch (error) {
        console.log(error);
      }

      if (statusUpdate.value && totalIncasatUpdate.value) {
        store.commit("user/setToaster", "Contract finalizat!");
        getContracte();
        getModalInfo();
        getContractePag();
      }
    };

    const platesteTransa = async () => {
      if(firmaId.value === 2) {
        return
      }
      try {
        if (modalStatus.value === "semnat") {
          const { data, error } = await supabase
            .from("contract")
            .update({ status: "derulare" })
            .eq("id", contractId.value);

          getContracte();
          getContractePag();
          getModalInfo();
          if (error) throw error;
          console.log(data);
        }

        rataNoua.value = modalRatePlatite.value + 1;

        if (rataNoua.value < modalDurata.value && rataNoua.value != 0) {
          const { data, error } = await supabase
            .from("contract")
            .update({
              rate_platite: rataNoua.value,
              total_incasat: modalTotalIncasat.value + modalComision.value,
              afiseaza: Boolean(true),
            })
            .eq("id", contractId.value);
          if (error) throw error;
          console.log(data);
          store.commit("user/setToaster", "Transa platita!");
          getContracte();
          getContractePag();
          getModalInfo();
        } else if (rataNoua.value === modalDurata.value) {
          const { data, error } = await supabase
            .from("contract")
            .update({
              rate_platite: rataNoua.value,
              status: "finalizat",
              total_incasat: modalTotalIncasat.value + modalComision.value,
              afiseaza: Boolean(true),
            })
            .eq("id", contractId.value);
          if (error) throw error;
          console.log(data);
          store.commit(
            "user/setToaster",
            "Ultima transa platita! Contract finalizat!"
          );
          getContracte();
          getContractePag();
          getModalInfo();
        }
      } catch (error) {
        console.log(error);
      }
    };

    const anuleazaContract = async () => {
      if(firmaId.value === 2) {
        return
      }
      try {
        const { data, error } = await supabase
          .from("contract")
          .update({ status: "anulat" })
          .eq("id", contractId.value);
        if (error) throw error;
        console.log(data);
        store.commit("user/setToaster", "Contract anulat!");
        anuleazaToggle.value = false;
        getContracte();
        getContractePag();
        getModalInfo();
      } catch (error) {
        console.log(error);
      }
    };

    const getModalInfo = async () => {
      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("id", contractId.value);
        if (error) throw error;

        modalContract.value = data[0];
        modalName.value =
          allClientsComp.value.find((ele) => ele.id === modalId.value).prenume +
          " " +
          allClientsComp.value.find((ele) => ele.id === modalId.value).nume;
        modalDate.value = data[0].created_at.slice(0, 10);
        modalRatePlatite.value = data[0].rate_platite;
        modalDurata.value = data[0].durata;
        modalStatus.value = data[0].status;
        modalComision.value = data[0].comision;
        modalTotalIncasat.value = data[0].total_incasat;
        modalSuma.value = data[0].suma;
        modalAgent.value = data[0].agent;
      } catch (error) {
        console.log(error);
      }
    };

    const getFirmaComision = async () => {
      try {
        const { data, error } = await supabase
          .from("firma")
          .select()
          .eq("id", firmaId.value);
        if (error) throw error;
        store.dispatch("user/setComision", data[0].comision);
      } catch (error) {
        console.log(error);
      }
    };

    const getContracte = async () => {
      try {
         
          const { data, error } = await supabase
            .from("contract")
            .select()
            .eq("firma", firmaId.value)
            .order("created_at", { ascending: false });
          if (error) throw error;
          contracte.value = data.filter((ele) => ele.status != "anulat");
          store.dispatch("contracte/setAllContracte", data);
        
      } catch (error) {
        console.log(error);
      }
    };

    const getAllClients = async () => {
      try {
         
          const { data, error } = await supabase
            .from("client")
            .select()
            .eq("firma", firmaId.value)
            .order("created_at", { ascending: false });
          if (error) throw error;
          allClients.value = data;
          store.dispatch("clienti/setAllClients", data);
          console.log(allClients.value);
        
      } catch (error) {
        console.log(error);
      }
    };

    const getRecommendedClients = async () => {
      try {
        
          const { data, error } = await supabase
            .from("client")
            .select()
            .eq("firma", firmaId.value)
            .eq("status", "recomandat")
            .order("created_at", { ascending: false });
          if (error) throw error;
          recommendedClients.value = data;
          store.dispatch("clienti/setRecommendedClients", data);
          console.log(recommendedClients.value);
        
      } catch (error) {
        console.log(error);
      }
    };

    const getClientClients = async () => {
      try {
         
          const { data, error } = await supabase
            .from("client")
            .select()
            .eq("firma", firmaId.value)
            .eq("status", "client")
            .order("created_at", { ascending: false });
          if (error) throw error;
          clientClients.value = data;
          console.log("clientclientsdata");
          store.dispatch("clienti/setClientClients", data);
          console.log(clientClients.value);
        
      } catch (error) {
        console.log(error);
      }
    };

    return {
      getAllClients,
      getRecommendedClients,
      getClientClients,
      recommendedClients,
      seeCurrentUser,
      allClients,
      userId,
      getFirmaComision,
      clientClients,
      getContracte,
      contracte,
      allClientsComp,
      activateModal,
      modalContract,
      contracteStore,
      modalName,
      modalDate,
      clientClientsStore,
      recommendedClientsStore,
      contractIncasat,
      anuleazaContract,
      platesteTransa,
      firmaName,
      anuleazaContractToggle,
      anuleazaToggle,
      resetData,
      modalComision,
      modalTotalIncasat,
      modalSuma,
      confirmBounty,
      bountyCerinta,
      bountySuma,
      adaugaLaBounty,
      dejaCalificat,
      getNext,
      getPrev,
      contractePag,
      firstPage,
      lastPage,
      filtruContractAnulat,
      filtruContractDerulare,
      filtruContractFinalizat,
      filtruContractSemnat,
      cautaButton,
      cautaActive,
      searchString,
      searchResponse,
      inchideSearch,
      nuAdaugaBounty,
      anuleazaMotiv,
      anuleazaMotivScreen,
      userData
    };
  },
  created() {},
  methods: {
    setFirma(e) {
      console.log(e);
    },
    toOrder(id) {
      this.$router.push("/firmaorder/" + id);
    },
  },
};
</script>
