import router from "../router/index"


export default {
    namespaced: true,
    state: {
        
        isAdmin: false,
        isAuth: false,
        isFirma: false,
        user_role: 'nothing',
        toaster: null,
        toasterError: null,
        token: null,
        name: null,
        email: null,
        user_id: null,
        firmaId: null,
        comision: null,
        firmaName: null,
        allIncasat: null,
        allIncasatRecruti: null,
        invited_by: null,
        allRecruti: null,
        userData: null,
        invitedByName: null,
        deIncasatSemnate: null,
        deIncasatRecurent: null,
        deIncasatRecurentTotal: null,
        searchResponse: null,
        invitesOwned: null,
        uploadedContracts: null,
        arhivaBounties: null,
        allNames: null,
        allAgenti: null,
        allInvites: null,
        invitesSent: null,
        stripeSub: null,
        adminAllUsers: null
    },
    getters: {
        isAuth(state) {
           return state.isAuth
        },
        isAdmin(state) {
           return state.isAdmin
        },
        isFirma(state) {
            return state.isFirma
        },
        getToaster(state) {
            return state.toaster
        },
        getStripeSub(state) {
            return state.stripeSub
        },
        getAdminAllUsers(state) {
            return state.adminAllUsers
        },
        getToasterError(state) {
            return state.toasterError
        },
        user_role(state) {
            return state.user_role
        },
        getToken(state) {
            return state.token
        },
        getEmail(state) {
            return state.email
        },
        getUserId(state) {
            return state.user_id
        },
        getFirmaId(state) {
            return state.firmaId
        },
        getFirmaName(state) {
            return state.firmaName
        },
        getComision(state) {
            return state.comision
        },
        getAllIncasat(state) {
            return state.allIncasat
        },
        getAllRecruti(state) {
            return state.allRecruti
        },
        getAllInvites(state) {
            return state.allInvites
        },
        getAllIncasatRecruti(state) {
            return state.allIncasatRecruti
        },
        getInvitedBy(state) {
            return state.invited_by
        },
        getUserData(state) {
            return state.userData
        },
        getDeIncasatSemnate(state) {
            return state.deIncasatSemnate
        },
        getDeIncasatRecurent(state) {
            return state.deIncasatRecurent
        },
        getDeIncasatRecurentTotal(state) {
            return state.deIncasatRecurentTotal
        },
        getInvitedByName(state) {
            return state.invitedByName
        },
        getInvitesOwned(state) {
            return state.invitesOwned
        },
        getInvitesSent(state) {
            return state.invitesSent
        },
        getUploadedContracts(state) {
            return state.uploadedContracts
        },
        getSearchResponse(state) {
            return state.searchResponse
        },
        getArhivaBounties(state) {
            return state.arhivaBounties
        },
        getAllNames(state) {
            return state.allNames
        },

        getAllAgenti(state) {
            return state.allAgenti
        }
    },
    mutations: {
        authUser(state, response) {
            if(response != 'logout') {
            state.isAuth = true
            state.user_id = response.user.id
            state.email = response.user.email
            
            }
            if(response === 'logout') {
                state.isAdmin = false
                state.isAuth = false
                state.isFirma = false
                state.token = null
                state.user_id = null
                state.firmaId = null
                state.name = null
                state.email = null
                state.user_role = 'nothing'
                state.comision = null
                localStorage.setItem('token', null)
            }
            
            console.log(state.isAdmin)
        },
        setRole(state, payload) {
            state.user_role = payload
            if (payload === 'admin') {
                state.isAdmin = true
                state.user_role = 'admin'
            }
            if (payload === 'firma') {
                state.isFirma = true
            }
            if (payload === 'agent') {
                state.user_role = 'agent'
            }
        },
        setAuthUser(state, payload) {
            if(payload === 'no') {
                state.isAuth = false
            } else {
                state.isAuth = true
            }
            
        },
        setInvitedBy (state, payload) {
            state.invited_by = payload
        },
        setToaster (state, payload) {
            state.toaster = payload
            
            setTimeout(() => {
                state.toaster = null
            }, 5000)
            
        },

        setToasterError (state, payload) {
            state.toasterError = payload
            
            setTimeout(() => {
                state.toasterError = null
            }, 5000)
            
        },
        setToken (state, payload) {
            state.token = payload
        },
        setAdminAllUsers (state, payload) {
            state.adminAllUsers = payload
        },
        setUserId(state, payload) {
            state.user_id = payload
        },
        setFirmaId(state, payload) {
            state.firmaId = payload
        },
        setStripeSub(state, payload) {
            state.stripeSub = payload
        },
        setArhivaBounties(state, payload) {
            state.arhivaBounties = payload
        },
        setFirmaName(state, payload) {
            state.firmaName = payload
        },
        setAllIncasat(state, payload) {
            state.allIncasat = payload
        },
        setAllInvites(state, payload) {
            state.allInvites = payload
        },
        setAllRecruti(state, payload) {
            state.allRecruti = payload
        },
        setAllIncasatRecruti(state, payload) {
            state.allIncasatRecruti = payload
        },
        setComision(state, payload) {
            state.comision = payload
        },
        setInvitedByName(state, payload) {
            state.invitedByName = payload
        },
        setDeIncasatSemnate(state, payload) {
            state.deIncasatSemnate = payload
        },
        setDeIncasatRecurent(state, payload) {
            state.deIncasatRecurent = payload
        },
        setDeIncasatRecurentTotal(state, payload) {
            state.deIncasatRecurentTotal = payload
        },
        setUserData(state, payload) {
            state.userData = payload
        },
        setUserEmail(state, payload) {
            state.email = payload
        },
        setInvitesOwned(state, payload) {
            state.invitesOwned = payload
        },
        setInvitesSent(state, payload) {
            state.invitesSent = payload
        },
        setUploadedContracts(state, payload) {
            state.uploadedContracts = payload
        },
        setSearchResponse(state, payload) {
            state.searchResponse = payload
        },
        setAllNames(state, payload) {
            state.allNames = payload
        },

        setAllAgenti(state, payload) {
            state.allAgenti = payload
        }
    },
    actions: {
        signIn({ commit, dispatch }, payload) {
            console.log(this.state.isAdmin)
            if (payload != 'logout') {
                commit('authUser', payload)
                
                
                router.push('/')
            }
            if (payload === 'logout') {
                commit('authUser', 'logout')
                dispatch('contracte/reset', 'resetAll', { root: true })
                dispatch('firme/reset', 'resetAll', { root: true })
                
                dispatch('clienti/reset', 'resetAll', { root: true })

                router.push('/login')
            }
        },
        setAuthUser({ commit }, payload) {
            commit('setAuthUser', payload)
        },
        setSearchResponse({ commit }, payload) {
            commit('setSearchResponse', payload)
        },
        setUserEmail({ commit }, payload) {
            commit('setUserEmail', payload)
        },
        testModal() {
            console.log('modalCloseWORKS')
        },
        setUserId({ commit }, payload) {
            commit('setUserId', payload)
        },
        setAdminAllUsers({ commit }, payload) {
            commit('setAdminAllUsers', payload)
        },
        setUserData({ commit }, payload) {
            commit('setUserData', payload)
        },
        setInvitedBy({ commit }, payload) {
            commit('setInvitedBy', payload)
        },
        setFirmaName({ commit }, payload) {
            commit('setFirmaName', payload)
        },
        setStripeSub({ commit }, payload) {
            commit('setStripeSub', payload)
        },
        setComision({ commit }, payload) {
            commit('setComision', payload)
        },
        signInToken({ commit }, payload) {
            localStorage.setItem('token', payload)
            commit('setToken', payload)
        },
        setRole({ commit }, payload) {
            commit('setRole', payload)
        },
        setFirmaId({ commit }, payload) {
            commit('setFirmaId', payload)
        },
        setAllInvites({ commit }, payload) {
            commit('setAllInvites', payload)
        },
        setAllIncasat({ commit }, payload) {
            commit('setAllIncasat', payload)
        },
        setDeIncasatSemnate({ commit }, payload) {
            commit('setDeIncasatSemnate', payload)
        },
        setDeIncasatRecurent({ commit }, payload) {
            commit('setDeIncasatRecurent', payload)
        },
        setDeIncasatRecurentTotal({ commit }, payload) {
            commit('setDeIncasatRecurentTotal', payload)
        },
        setAllRecruti({ commit }, payload) {
            commit('setAllRecruti', payload)
        },
        setInvitedByName({ commit }, payload) {
            commit('setInvitedByName', payload)
        },
        setInvitesOwned({ commit }, payload) {
            commit('setInvitesOwned', payload)
        },
        setInvitesSent({ commit }, payload) {
            commit('setInvitesSent', payload)
        },
        setAllIncasatRecruti({ commit }, payload) {
            commit('setAllIncasatRecruti', payload)
        },
        setUploadedContracts({ commit }, payload) {
            commit('setUploadedContracts', payload)
        },
        setArhivaBounties({ commit }, payload) {
            commit('setArhivaBounties', payload)
        },
        setAllNames({ commit }, payload) {
            commit('setAllNames', payload)
        },
        setAllAgenti({ commit }, payload) {
            commit('setAllAgenti', payload)
        },
        setToaster({commit}, payload) {
        
           
            commit('setToaster', payload)
           },
           setToasterError({commit}, payload) {
        
           
            commit('setToasterError', payload)
           }
    }
}
