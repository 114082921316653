


export default {
    namespaced: true,
    state: {
        
        allContracte: null,
        allBounties: null,
        contractePag: null,
        contractePagAll: null,
        ultimele30Incasat: null
    },
    getters: {
        getAllContracte(state) {
            return state.allContracte
         },
         getContractePag(state) {
            return state.contractePag
         },
         getContractePagAll(state) {
            return state.contractePagAll
         },
         getUltimele30Incasat(state) {
            return state.ultimele30Incasat
         },
         getAllBounties(state) {
            return state.allBounties
         },
    },
    mutations: {
        setAllContracte(state, response) {
            state.allContracte = response

        },
        setContractePag(state, response) {
            state.contractePag = response

        },
        setContractePagAll(state, response) {
            state.contractePagAll = response

        },
        setAllBounties(state, response) {
            state.allBounties = response

        },
        setUltimele30Incasat(state, response) {
            state.ultimele30Incasat = response

        },
        setReset(state, response) {
            if(response === 'resetAll') {
                state.allContracte = null
            }
            
        }
    },
    actions: {
        setAllContracte({ commit }, payload) {
            commit('setAllContracte', payload)

            
        },
        setAllBounties({ commit }, payload) {
            commit('setAllBounties', payload)

            
        },
        setContractePag({ commit }, payload) {
            commit('setContractePag', payload)
        },
        setContractePagAll({ commit }, payload) {
            commit('setContractePagAll', payload)
        },
        setUltimele30Incasat({ commit }, payload) {
            commit('setUltimele30Incasat', payload)
        },
        reset({ commit }, payload) {
            if(payload === 'resetAll') {
                commit('setReset', 'resetAll')
            }
            
            
        }
    }
}
