/* eslint-disable */
<template>
  <div class="page-container">
    <div class="width-container">
      <div class="page-title" v-if="userData && userData.language === 'romana'">Contracte</div>
      <div class="page-title" v-if="userData && userData.language === 'english'">Contracts</div>
      <div class="home-page">
        <div class="main-content">
          <div class="contracte-main-content">
            <div class="contracte-sumar-container">
              <div class="contracte-sumar-title" v-if="userData && userData.language === 'romana'">Sumar</div>
              <div class="contracte-sumar-title" v-if="userData && userData.language === 'english'">Summary</div>

              <div class="contracte-sumar-field sumar-total">
                <div class="profile-field-left-contracte" v-if="userData && userData.language === 'romana'">Total Contracte</div>
                <div class="profile-field-left-contracte" v-if="userData && userData.language === 'english'">All contracts</div>
                <div class="profile-field-right" v-if="contracteStore">
                  {{ contracteStore.length }}
                </div>
              </div>
              <div class="contracte-sumar-field sumar-semnat">
                <div class="profile-field-left-contracte" v-if="userData && userData.language === 'romana'">
                  Contracte semnate
                </div>
                <div class="profile-field-left-contracte" v-if="userData && userData.language === 'english'">
                  Signed contracts
                </div>
                <div class="profile-field-right" v-if="contracteStore">
                  {{
                    contracteStore.filter((ele) => ele.status === "semnat")
                      .length
                  }}
                </div>
              </div>
              <div class="contracte-sumar-field sumar-derulare">
                <div class="profile-field-left-contracte" v-if="userData && userData.language === 'romana'">
                  Contracte in derulare
                </div>
                <div class="profile-field-left-contracte" v-if="userData && userData.language === 'english'">
                  Ongoing contracts
                </div>
                <div class="profile-field-right" v-if="contracteStore">
                  {{
                    contracteStore.filter((ele) => ele.status === "derulare")
                      .length
                  }}
                </div>
              </div>
              <div class="contracte-sumar-field sumar-finalizat">
                <div class="profile-field-left-contracte" v-if="userData && userData.language === 'romana'">
                  Contracte finalizate
                </div>
                <div class="profile-field-left-contracte" v-if="userData && userData.language === 'english'">
                  Finalized contracts
                </div>
                <div class="profile-field-right" v-if="contracteStore">
                  {{
                    contracteStore.filter((ele) => ele.status === "finalizat")
                      .length
                  }}
                </div>
              </div>
              <div class="contracte-sumar-field sumar-anulat">
                <div class="profile-field-left-contracte" v-if="userData && userData.language === 'romana'">
                  Contracte anulate
                </div>
                <div class="profile-field-left-contracte" v-if="userData && userData.language === 'english'">
                  Cancelled contracts
                </div>
                <div class="profile-field-right" v-if="contracteStore">
                  {{
                    contracteStore.filter((ele) => ele.status === "anulat")
                      .length
                  }}
                </div>
              </div>
            </div>

            <div class="contracte-container-home" v-if="contractePag">
              <div class="box-header-row">
                <div class="todo-title" v-if="userData && userData.language === 'romana'">Contracte</div>
                <div class="todo-title" v-if="userData && userData.language === 'english'">Contracts</div>
              </div>
              <div class="filtre-wrapper">
                <div
                  class="filtru-contracte"
                  :class="{ filtruselected: filtruContractSemnat }"
                  @click="filtruContractSemnat = !filtruContractSemnat"
                >
                <span v-if="userData && userData.language === 'romana'">Semnat</span>
                <span v-if="userData && userData.language === 'english'">Signed</span>
                </div>
                <div
                  class="filtru-contracte"
                  :class="{ filtruselected: filtruContractDerulare }"
                  @click="filtruContractDerulare = !filtruContractDerulare"
                >
                <span v-if="userData && userData.language === 'romana'">In derulare</span>
                <span v-if="userData && userData.language === 'english'">Ongoing</span>
                </div>
                <div
                  class="filtru-contracte"
                  :class="{ filtruselected: filtruContractFinalizat }"
                  @click="filtruContractFinalizat = !filtruContractFinalizat"
                >
                <span v-if="userData && userData.language === 'romana'">Finalizat</span>
                <span v-if="userData && userData.language === 'english'">Finalized</span>
                </div>
                <div
                  class="filtru-contracte"
                  :class="{ filtruselected: filtruContractAnulat }"
                  @click="filtruContractAnulat = !filtruContractAnulat"
                >
                <span v-if="userData && userData.language === 'romana'">Anulat</span>
                <span v-if="userData && userData.language === 'english'">Cancelled</span>
                </div>
              </div>
              <div class="fara-clienti" v-if="contractePag.length === 0">
                <span v-if="userData && userData.language === 'romana'">Nu aveti niciun contract</span>
                <span v-if="userData && userData.language === 'english'">You don't have any contracts</span>
                
              </div>
              <table class="orders-table" v-if="contractePag.length != 0">
                <col class="col-order-no" />
                <col class="col-order-name" />

                <col class="col-order-id" />
                <col class="col-order-ref" />

                <col class="col-order-more" />
                <tr class="products-table-head">
                  <th class="col-order-no">ID</th>
                  <th><span v-if="userData && userData.language === 'romana'">Firma</span>
                <span v-if="userData && userData.language === 'english'">Company</span></th>

                  <th>Client</th>
                  <th><span v-if="userData && userData.language === 'romana'">Serviciu</span>
                <span v-if="userData && userData.language === 'english'">Service</span></th>

                  <th><span v-if="userData && userData.language === 'romana'">Comision</span>
                <span v-if="userData && userData.language === 'english'">Commission</span></th>
                  <th><span v-if="userData && userData.language === 'romana'">Incasat</span>
                <span v-if="userData && userData.language === 'english'">Collected</span></th>
                  <th><span v-if="userData && userData.language === 'romana'">Tip</span>
                <span v-if="userData && userData.language === 'english'">Type</span></th>
                  <th>Status</th>
                </tr>
                <tr
                  class="products-table-item"
                  @click="
                    activateModal(contract.client_id, contract.id);
                    showModal = true;
                  "
                  v-for="contract in contractePag"
                  :key="contract.id"
                >
                  <th>{{ contract.id }}</th>
                  <th class="product-name" v-if="allFirmeStore">
                    {{
                      allFirmeStore.find((ele) => ele.id === contract.firma)
                        .nume
                    }}
                  </th>

                  <th class="product-name" v-if="allClientsStore">
                    {{
                      allClientsStore.find(
                        (ele) => ele.id === contract.client_id
                      ).nume
                    }}
                    {{
                      allClientsStore.find(
                        (ele) => ele.id === contract.client_id
                      ).prenume
                    }}
                  </th>
                  <th class="product-name">{{ contract.serviciu }}</th>

                  <th class="suma-tabel-contracte">
                    {{ contract.comision }} RON
                    <span v-if="contract.tip === 'recurentCheck'"> / <span v-if="userData && userData.language === 'romana'">luna</span>
                  <span v-if="userData && userData.language === 'english'">month</span></span>
                  </th>
                  <th class="product-name">
                    <span
                      v-if="contract.total_incasat != 0"
                      class="suma-tabel-contracte2"
                      >{{ contract.total_incasat }} RON</span
                    ><span
                      v-if="
                        contract.total_incasat === 0 &&
                        contract.status != 'anulat'
                      "
                      class="incasare-wait"
                      ><span v-if="userData && userData.language === 'romana'">Se asteapta incasare</span>
                  <span v-if="userData && userData.language === 'english'">Awaiting payment</span></span
                    >
                    <span
                      v-if="
                        contract.total_incasat === 0 &&
                        contract.status === 'anulat'
                      "
                      class="incasare-wait"
                      ><span v-if="userData && userData.language === 'romana'">Contract anulat</span>
                  <span v-if="userData && userData.language === 'english'">Contract cancelled</span></span
                    >
                  </th>
                  <th
                    class="product-name"
                    v-if="contract.tip === 'oneTimeCheck'"
                  >
                    One Time
                  </th>
                  <th
                    class="product-name"
                    v-if="contract.tip === 'recurentCheck'"
                  >
                  <span v-if="userData && userData.language === 'romana'">Recurent</span>
                  <span v-if="userData && userData.language === 'english'">Recurring</span>
                    <span
                      v-if="
                        contract.tip === 'recurentCheck' &&
                        contract.status != 'finalizat'
                      "
                      class="rate-platite-tabel"
                      >{{ contract.rate_platite }} / {{ contract.durata }}</span
                    >
                    <span
                      v-if="
                        contract.tip === 'recurentCheck' &&
                        contract.status === 'finalizat'
                      "
                      class="rate-platite-final-tabel"
                      >{{ contract.rate_platite }} / {{ contract.durata }}</span
                    >
                  </th>
                  <th class="status-col">
                    <span
                      class="status-tabel"
                      v-if="contract.status === 'derulare'"
                      ><span v-if="userData && userData.language === 'romana'">In derulare</span>
                  <span v-if="userData && userData.language === 'english'">Ongoing</span></span
                    >
                    <span
                      class="status-tabel"
                      v-if="contract.status != 'derulare'"
                      >{{ contract.status }}</span
                    >
                    <div
                      class="status-confirmat"
                      v-if="contract.status === 'semnat'"
                    ></div>
                    <div
                      class="status-incasat"
                      v-if="contract.status === 'derulare'"
                    ></div>
                    <div
                      class="status-finalizat"
                      v-if="contract.status === 'finalizat'"
                    ></div>
                    <div
                      class="status-anulat"
                      v-if="contract.status === 'anulat'"
                    ></div>
                  </th>
                </tr>
              </table>
              <div class="buttons-table-wrapper" v-if="!firstPage || !lastPage">
                <div
                  class="btn-pagination"
                  @click="getPrev()"
                  v-if="!firstPage"
                >
                  Inapoi
                </div>
                <div class="btn-pagination-cancelled" v-if="firstPage">
                  Inapoi
                </div>
                <div class="btn-pagination-cancelled" v-if="lastPage">
                  Inainte
                </div>
                <div class="btn-pagination" @click="getNext()" v-if="!lastPage">
                  Inainte
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <vue-final-modal
        v-model="showModal"
        classes="modal-container"
        content-class="modal-content"
        v-if="modalContract"
        @closed="resetData()"
      >
        <div class="contract-item">
          <div
            class="modal-status-semnat"
            v-if="modalContract.status === 'semnat'"
          />
          <div
            class="modal-status-derulare"
            v-if="modalContract.status === 'derulare'"
          />
          <div
            class="modal-status-finalizat"
            v-if="modalContract.status === 'finalizat'"
          />
          <div
            class="modal-status-anulat"
            v-if="modalContract.status === 'anulat'"
          />
          <div class="profile-field-agent">
            <div class="profile-field-left"><span v-if="userData && userData.language === 'romana'">Client</span>
                  <span v-if="userData && userData.language === 'english'">Client</span></div>
            <div class="profile-field-right">{{ modalName }}</div>
          </div>
          <div class="profile-field-agent">
            <div class="profile-field-left"><span v-if="userData && userData.language === 'romana'">Serviciu</span>
                  <span v-if="userData && userData.language === 'english'">Service</span></div>
            <div class="profile-field-right">{{ modalContract.serviciu }}</div>
          </div>
          <div class="profile-field-agent">
            <div class="profile-field-left"><span v-if="userData && userData.language === 'romana'">Data semnarii</span>
                  <span v-if="userData && userData.language === 'english'">Date signed</span></div>
            <div class="profile-field-right">{{ modalDate }}</div>
          </div>
          <div class="profile-field-agent">
            <div class="profile-field-left"><span v-if="userData && userData.language === 'romana'">Suma</span>
                  <span v-if="userData && userData.language === 'english'">Amount</span></div>
            <div class="profile-field-right">{{ modalContract.suma }} RON</div>
          </div>
          <div class="profile-field-agent">
            <div class="profile-field-left"><span v-if="userData && userData.language === 'romana'">Comision</span>
                  <span v-if="userData && userData.language === 'english'">Commission</span></div>
            <div class="profile-field-right">
              {{ modalContract.comision }} RON<span
                v-if="modalContract.tip === 'recurentCheck'"
              >
                / <span v-if="userData && userData.language === 'romana'">luna</span>
                  <span v-if="userData && userData.language === 'english'">month</span></span
              >
            </div>
          </div>
          <div
            class="profile-field-agent"
            v-if="modalContract.tip === 'recurentCheck'"
          >
            <div class="profile-field-left"><span v-if="userData && userData.language === 'romana'">Rate platite</span>
                  <span v-if="userData && userData.language === 'english'">Instalments paid</span></div>
            <div class="profile-field-right">
              {{ modalContract.rate_platite }} / {{ modalContract.durata }}
            </div>
          </div>
          <div class="profile-field-agent">
            <div class="profile-field-left"><span v-if="userData && userData.language === 'romana'">Incasat</span>
                  <span v-if="userData && userData.language === 'english'">Collected</span></div>
            <div class="profile-field-right agent-incasat">
              {{ modalContract.total_incasat }} RON
            </div>
          </div>
          <div
            class="profile-field-agent"
            v-if="modalContract.tip === 'recurentCheck'"
          >
            <div class="profile-field-left"><span v-if="userData && userData.language === 'romana'">Ramas de incasat</span>
                  <span v-if="userData && userData.language === 'english'">Left to collect</span></div>
            <div
              class="profile-field-right"
              v-if="
                modalContract.comision *
                  (modalContract.durata - modalContract.rate_platite) ===
                0
              "
            >
            <span v-if="userData && userData.language === 'romana'">Incasat integral</span>
                  <span v-if="userData && userData.language === 'english'">Collected in full</span>
            </div>
            <div
              class="profile-field-right"
              v-if="
                modalContract.comision *
                  (modalContract.durata - modalContract.rate_platite) !=
                0
              "
            >
              {{
                modalContract.comision *
                (modalContract.durata - modalContract.rate_platite)
              }}
              RON
            </div>
          </div>
          <div
            class="profile-field-agent"
            v-if="modalContract.status === 'anulat'"
          >
            <div class="profile-field-left"><span v-if="userData && userData.language === 'romana'">Data anularii</span>
                  <span v-if="userData && userData.language === 'english'">Cancellation date</span></div>
            <div class="profile-field-right">{{ modalDate }}</div>
          </div>
          <div
            class="profile-field-agent"
            v-if="modalContract.status === 'anulat'"
          >
            <div class="profile-field-left"><span v-if="userData && userData.language === 'romana'">Motivul anularii</span>
                  <span v-if="userData && userData.language === 'english'">Cancellation reason</span></div>
            <div class="profile-field-right">
              {{ modalContract.motiv_anulare }}
            </div>
          </div>
        </div>
      </vue-final-modal>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, ref, computed, onUnmounted, watch } from "vue";
import { supabase } from "../clients/supabase";
import { useStore } from "vuex";

export default {
  name: "ContractePage",
  components: {},
  data() {
    return {
      showModal: false,
      error: false,
      formdata: {
        firma: "",
        nume: "",
        telefon: "",
        descriere: "",
        mesaj: "",
      },
    };
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      getAllFirme();
      getContracte();
      seeCurrentUser();
      getAllClients();
      getRecommendedClients();
      getAllIncasat();
      getAllIncasatRecruti();
      getContractePag();
      getContractePagAll();
    });

    onUnmounted(() => {
      resetContractStore();
    });

    // formular
    const clickedFirma = ref("");
    const formNume = ref("");
    const formPrenume = ref("");
    const formTelefon = ref("");
    const formEmail = ref("");
    const formDetalii = ref("");
    const formFirma = ref("");

    const formAgentId = ref("");

    // contracte

    const contracte = ref("");

    const listaFirme = ref("");
    const allClients = ref();

    const modalId = ref("");
    const contractId = ref("");

    const recomandariKey = ref(0);

    const modalContract = ref();
    const modalName = ref("");
    const modalDate = ref("");

    const modalRatePlatite = ref(0);
    const modalDurata = ref(0);
    const modalStatus = ref("");
    const anuleazaToggle = ref(false);
    const modalComision = ref(0);
    const modalTotalIncasat = ref(0);
    const totalRecruti = ref(0);

    const contractPagePagination = ref(0);
    const firstPage = ref(true);
    const lastPage = ref(true);

    const arrayFiltrePag = ref(["finalizat", "anulat", "derulare", "semnat"]);

    const filtruContractSemnat = ref(true);
    const filtruContractDerulare = ref(true);
    const filtruContractFinalizat = ref(true);
    const filtruContractAnulat = ref(true);

    const userId = computed(() => {
      return store.getters["user/getUserId"];
    });

    const contracteStore = computed(() => {
      return store.getters["contracte/getAllContracte"];
    });

    const contractePag = computed(() => {
      return store.getters["contracte/getContractePag"];
    });

    const contractePagAll = computed(() => {
      return store.getters["contracte/getContractePagAll"];
    });

    const allClientsStore = computed(() => {
      return store.getters["clienti/getAllClients"];
    });

    const allFirmeStore = computed(() => {
      return store.getters["firme/getAllFirme"];
    });

    const allIncasat = computed(() => {
      return store.getters["user/getAllIncasat"];
    });

    const allIncasatRecruti = computed(() => {
      return store.getters["user/getAllIncasatRecruti"];
    });

    const userData = computed(() => {
      return store.getters["user/getUserData"];
    });

    const recommendedClientsStore = computed(() => {
      return store.getters["clienti/getRecommendedClients"];
    });

    watch(filtruContractSemnat, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("semnat");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "semnat"
        );

        getContractePag();
      }
    });

    watch(filtruContractDerulare, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("derulare");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "derulare"
        );

        getContractePag();
      }
    });

    watch(filtruContractFinalizat, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("finalizat");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "finalizat"
        );

        getContractePag();
      }
    });

    watch(filtruContractAnulat, (value) => {
      if (value === true) {
        arrayFiltrePag.value.push("anulat");

        getContractePag();
      }
      if (value === false) {
        arrayFiltrePag.value = arrayFiltrePag.value.filter(
          (fil) => fil != "anulat"
        );

        getContractePag();
      }
    });

    function setFirma(id) {
      clickedFirma.value = id;
      formFirma.value = id;
    }

    function resetData() {
      modalContract.value = "";
      modalContract.value = "";
      modalName.value = "";
      modalDate.value = "";
      modalRatePlatite.value = "";
      modalDurata.value = "";
      modalStatus.value = "";
      modalComision.value = "";
      modalTotalIncasat.value = "";
    }

    function resetContractStore() {
      store.dispatch("contracte/setContractePag", null);
      store.dispatch("contracte/setAllContracte", null);
      store.dispatch("clienti/setRecommendedClients", null);
    }

    function anuleazaContractToggle() {
      anuleazaToggle.value = true;
      setTimeout(() => {
        anuleazaToggle.value = false;
      }, 3000);
    }

    function activateModal(id, contractid) {
      modalId.value = id;
      contractId.value = contractid;
      getModalInfo();
    }

    const getContractePagAll = async () => {
      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("agent", userId.value)
          .in("status", arrayFiltrePag.value);

        console.log("from pagall");
        console.log(data);

        if (error) throw error;

        store.dispatch("contracte/setContractePagAll", data);
      } catch (error) {
        console.log(error);
      }
    };

    const getContractePag = async () => {
      contractPagePagination.value = 0;
      firstPage.value = true;
      await getContractePagAll();
      if (contractPagePagination.value + 20 >= contractePagAll.value.length) {
        lastPage.value = true;
        console.log("last page");
      } else {
        lastPage.value = false;
      }

      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("agent", userId.value)
          .in("status", arrayFiltrePag.value)
          .range(0, 19);

        store.dispatch("contracte/setContractePag", data);
        if (error) throw error;
      } catch (error) {
        console.log(error);
      }
    };

    const getNext = async () => {
      try {
        if (contractPagePagination.value + 20 >= contractePagAll.value.length) {
          lastPage.value = true;
          console.log("last page");
        } else {
          lastPage.value = false;
          contractPagePagination.value += 20;
          let rangePagination = contractPagePagination.value + 19;
          const { data, error } = await supabase
            .from("contract")
            .select()
            .eq("agent", userId.value)
            .in("status", arrayFiltrePag.value)
            .range(contractPagePagination.value, rangePagination);

          if (
            contractPagePagination.value + 20 >=
            contractePagAll.value.length
          ) {
            lastPage.value = true;
          }
          if (contractPagePagination.value >= 0) {
            firstPage.value = false;
            console.log("first page");
          }
          if (error) throw error;

          store.dispatch("contracte/setContractePag", data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getPrev = async () => {
      try {
        if (contractPagePagination.value <= 0) {
          firstPage.value = true;
          console.log("first page");
        } else {
          firstPage.value = false;
          let rangePagination = contractPagePagination.value - 20;
          const { data, error } = await supabase
            .from("contract")
            .select()
            .eq("agent", userId.value)
            .in("status", arrayFiltrePag.value)
            .range(rangePagination, contractPagePagination.value - 1);
          contractPagePagination.value -= 20;
          if (contractPagePagination.value <= 0) {
            firstPage.value = true;
            console.log("first page");
          }
          if (
            contractPagePagination.value + 20 <=
            contractePagAll.value.length
          ) {
            lastPage.value = false;
          }
          if (error) throw error;

          store.dispatch("contracte/setContractePag", data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getRecommendedClients = async () => {
      try {
        const { data, error } = await supabase
          .from("client")
          .select()
          .eq("agent_id", userId.value)
          .eq("status", "recomandat");
        if (error) throw error;

        store.dispatch("clienti/setRecommendedClients", data);
      } catch (error) {
        console.log(error);
      }
    };

    const getAllClients = async () => {
      try {
        const { data, error } = await supabase
          .from("client")
          .select("*")
          .eq("agent_id", userId.value);
        if (error) throw error;
        allClients.value = data;
        store.dispatch("clienti/setAllClients", data);
        console.log(allClients.value);
      } catch (error) {
        console.log(error);
      }
    };

    const getContracte = async () => {
      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("agent", userId.value);

        if (error) throw error;
        contracte.value = data;
        store.dispatch("contracte/setAllContracte", data);
      } catch (error) {
        console.log(error);
      }
    };

    const getModalInfo = async () => {
      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("id", contractId.value);
        if (error) throw error;

        modalContract.value = data[0];
        modalName.value =
          allClientsStore.value.find((ele) => ele.id === modalId.value)
            .prenume +
          " " +
          allClientsStore.value.find((ele) => ele.id === modalId.value).nume;
        modalDate.value = data[0].created_at.slice(0, 10);
        modalRatePlatite.value = data[0].rate_platite;
        modalDurata.value = data[0].durata;
        modalStatus.value = data[0].status;
        modalComision.value = data[0].comision;
        modalTotalIncasat.value = data[0].total_incasat;
      } catch (error) {
        console.log(error);
      }
    };

    const getAllIncasat = async () => {
      const incasariArray = ref([]);

      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .eq("agent", userId.value);
        if (error) throw error;

        incasariArray.value = data.map(({ total_incasat }) => total_incasat);

        let cacat = incasariArray.value;

        let pisat = cacat.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);

        console.log(pisat);
        store.dispatch("user/setAllIncasat", pisat);
      } catch (error) {
        console.log(error);
      }
    };

    const getAllIncasatRecruti = async () => {
      const incasariArray = ref([]);
      const arrayInvitati = ref([]);

      try {
        const { data, error } = await supabase
          .from("roles")
          .select()
          .eq("invited_by", userId.value);
        if (error) throw error;

        arrayInvitati.value = data.map(({ user_id }) => user_id);
        totalRecruti.value = arrayInvitati.value.length;
      } catch (error) {
        console.log(error);
      }

      let iduriInvitati = arrayInvitati.value.map((id) => id);

      try {
        const { data, error } = await supabase
          .from("contract")
          .select()
          .in("agent", iduriInvitati);
        if (error) throw error;

        console.log("data de la incasat recruti");

        console.log(data);

        incasariArray.value = data.map(({ total_incasat }) => total_incasat);

        let cacat = incasariArray.value;

        let pisat = cacat.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);

        console.log(pisat);
        store.dispatch("user/setAllIncasatRecruti", pisat.toFixed(2));
      } catch (error) {
        console.log(error);
      }
    };

    const seeCurrentUser = async () => {
      try {
        const { data } = await supabase.auth.getUser();
        console.log("from seecurentuser");
        console.log(data.user.id);
        formAgentId.value = data.user.id;
      } catch (error) {
        console.log(error);
      }
    };

    // Send contract

    const sendClientButton = async () => {
      try {
        const { error } = await supabase.from("client").insert({
          status: "recomandat",
          firma: formFirma.value,
          nume: formNume.value,
          prenume: formPrenume.value,
          email: formEmail.value,
          telefon: formTelefon.value,
          detalii: formDetalii.value,
          agent_id: formAgentId.value,
        });
        getAllClients();
        recomandariKey.value += 1;
        formFirma.value = "";
        formNume.value = "";
        formPrenume.value = "";
        formEmail.value = "";
        formTelefon.value = "";
        formDetalii.value = "";
        clickedFirma.value = "";

        store.commit("user/setToaster", "Recomandare inregistrata cu succes!");
        if (error) throw error;
      } catch (error) {
        console.log(error);
      }
    };

    // Get firme

    const getAllFirme = async () => {
      try {
        const { data, error } = await supabase.from("firma").select("*");
        if (error) throw error;
        listaFirme.value = data;
        store.dispatch("firme/setAllFirme", data);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

    return {
      formFirma,
      formNume,
      formPrenume,
      formTelefon,
      formEmail,
      formDetalii,
      anuleazaContractToggle,
      clickedFirma,
      sendClientButton,
      anuleazaToggle,
      getAllFirme,
      listaFirme,
      setFirma,
      seeCurrentUser,
      getContracte,
      userId,
      contracteStore,
      allClientsStore,
      allFirmeStore,
      recomandariKey,
      recommendedClientsStore,
      resetData,
      allIncasat,
      activateModal,
      getModalInfo,
      modalComision,
      modalTotalIncasat,
      modalName,
      modalDate,
      modalContract,
      getAllIncasatRecruti,
      allIncasatRecruti,
      totalRecruti,
      getNext,
      contractePag,
      getPrev,
      firstPage,
      lastPage,
      resetContractStore,
      filtruContractSemnat,
      filtruContractDerulare,
      filtruContractFinalizat,
      filtruContractAnulat,
      userData
    };
  },
  created() {},
  methods: {},
};
</script>
