<template>
  <div class="container">
    
    <div class="mobile-toaster-wrapper" v-if="copied">
    <div class="toaster-mobile">
      {{ toaster }}
    </div>
  </div>
  <div class="mobile-toaster-wrapper" v-if="copiedError">
    <div class="toaster-mobile-error">
      {{ toasterError }}

    </div>
  </div>
    
    <div v-if="this.$route.fullPath != '/firma'">

    
    <div
      class="menu sticky2"
      v-if="userRole != 'firma' && isAuth && this.$route.params != '/login'"
    >
      <div v-if="copied" class="menu-toaster">
        {{ toaster }}
      </div>
      <div v-if="copiedError" class="menu-toaster-error-bar"></div>
      <div v-if="copiedError" class="menu-toaster-error">
        {{ toasterError }}
      </div>

      <div
        class="menu-opened"
        ref="menuOpened"
        v-if="isAuth && this.$route.params != '/login'"
      >
        <div class="menu-opened-content dispnone" ref="menuContent">
          <div class="opened-menu-nav" v-if="userData && userData.language === 'romana'">Navigatie</div>
          <div class="opened-menu-nav" v-if="userData && userData.language === 'english'">Navigation</div>

          <div class="menu-opened-row">
            <div class="menu-opened-column">
              <div class="menu-opened-title">Vissia</div>
              <div class="menu-opened-link">
                <router-link
                  to="/about"
                  @click="closeMenu()"
                  class="opened-link"
                  >
                  <span v-if="userData && userData.language === 'romana'">Despre</span>
                  <span v-if="userData && userData.language === 'english'">About</span>
                  </router-link
                >
              </div>
              <div class="menu-opened-link">
                <router-link
                  to="/companii"
                  @click="closeMenu()"
                  class="opened-link"
                  ><span v-if="userData && userData.language === 'romana'">Firme</span>
                  <span v-if="userData && userData.language === 'english'">Companies</span></router-link
                >
              </div>
              <div class="menu-opened-link">
                <router-link
                  to="/bounties"
                  @click="closeMenu()"
                  class="opened-link"
                  >Bounties</router-link
                >
              </div>
              <div class="menu-opened-link">
                <router-link
                  to="/contact"
                  @click="closeMenu()"
                  class="opened-link"
                  >Contact</router-link
                >
              </div>
            </div>
            <div class="menu-opened-column">
              <div class="menu-opened-link-first">
                <router-link
                  to="/faq"
                  @click="closeMenu()"
                  class="opened-link"
                  >FAQ</router-link
                >
              </div>
              <div class="menu-opened-link">
                <router-link
                  to="/tutoriale"
                  @click="closeMenu()"
                  class="opened-link"
                  ><span v-if="userData && userData.language === 'romana'">Tutoriale</span>
                  <span v-if="userData && userData.language === 'english'">Tutorials</span></router-link
                >
              </div>
              <div class="menu-opened-link disabled-link">Linkedin</div>
              <div class="menu-opened-link disabled-link">Discord</div>
            </div>
            <div class="menu-opened-column">
              <div class="menu-opened-title">User Area</div>
              <div class="menu-opened-link">
                <router-link to="/" @click="closeMenu()" class="opened-link"
                  >Dashboard</router-link
                >
              </div>
              <div class="menu-opened-link">
                <router-link
                  to="/profile"
                  @click="closeMenu()"
                  class="opened-link"
                  ><span v-if="userData && userData.language === 'romana'">Profil</span>
                  <span v-if="userData && userData.language === 'english'">Profile</span></router-link
                >
              </div>
              <div class="menu-opened-link">
                <router-link
                  to="/retea"
                  @click="closeMenu()"
                  class="opened-link"
                  ><span v-if="userData && userData.language === 'romana'">Retea</span>
                  <span v-if="userData && userData.language === 'english'">Network</span></router-link
                >
              </div>
              <div class="menu-opened-link">
                <router-link
                  to="/contracte"
                  @click="closeMenu()"
                  class="opened-link"
                  ><span v-if="userData && userData.language === 'romana'">Contracte</span>
                  <span v-if="userData && userData.language === 'english'">Contracts</span></router-link
                >
              </div>
            </div>
            <div class="menu-opened-column">
              <div class="menu-opened-link-first">
                <router-link
                  to="/recomandari"
                  @click="closeMenu()"
                  class="opened-link"
                  ><span v-if="userData && userData.language === 'romana'">Recomandari</span>
                  <span v-if="userData && userData.language === 'english'">Recommendations</span></router-link
                >
              </div>
              <div class="menu-opened-link">
                <router-link
                  to="/castiguri"
                  @click="closeMenu()"
                  class="opened-link"
                  ><span v-if="userData && userData.language === 'romana'">Castiguri</span>
                  <span v-if="userData && userData.language === 'english'">Earnings</span></router-link
                >
              </div>
              <div class="menu-opened-link">
                <router-link
                  to="/reclamatie"
                  @click="closeMenu()"
                  class="opened-link"
                  ><span v-if="userData && userData.language === 'romana'">Reclamatie</span>
                  <span v-if="userData && userData.language === 'english'">Complaint</span></router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="menu-logo2">
        <router-link to="/">
          <img src="./assets/img/va-logo.png" class="menu-logo-img" />
        </router-link>
      </div>
      <div class="menu-items">
        <div class="menu-blocker dispnone" ref="blocker"></div>
        <div class="hamburger" @click="toggleMidMenu($event)">
          <a class="main-nav-toggle" ref="burger"
            ><i @click="toggleMidMenu($event)" ref="burger2">Menu</i></a
          >
        </div>
      </div>
      <div class="menu-socials">
        <img
          src="./assets/img/logout2.png"
          class="menu-logout"
          @click="logout()"
        />
      </div>
    </div>
  </div>
  <div v-if="this.$route.fullPath != '/firma'">
    <div
      class="row-menu sticky"
      :class="{ 'change-menu-bg': scrollPosition > 50 }"
      v-if="isAuth && this.$route.params != '/login'"
    >
      <div class="width-container-menu">
        <div class="menu-logo">
          <router-link to="/">
            <img
              src="./assets/img/vissia-logo2.png"
              class="logo-menu"
              :class="{ 'change-menu-logo': scrollPosition > 50 }"
            />
          </router-link>
        </div>

        <div class="top-user-area">
          <div class="language-picker2" v-if="userData && userData.super_admin">
            <span class="lang-btn" :class="{ selectedlanguage: userData.role === 'admin' }" @click="changeDashboard('admin')">ADMIN</span>
          </div>
          <div class="language-picker2" v-if="userData && userData.double_role">
                <span class="lang-btn" :class="{ selectedlanguage: userData.role === 'firma' }" @click="changeDashboard('firma')">FIRMA</span> <span class='lang-separator'>|</span> <span class="lang-btn" :class="{ selectedlanguage: userData.role === 'agent' }" @click="changeDashboard('agent')">AGENT</span>
              </div>
          <router-link to="/profile" class="top-user-link">
          <img
            src="./assets/img/user-icon-top.png"
            class="top-user-img"
            v-if="userRole === 'agent'"
          />
        </router-link>
          <div class="user-logged">
            <router-link to="/profile" class="user-logged-email">{{
              userEmail
            }}</router-link>
            <div class="header-user-area-row">
              <div class="language-picker" v-if="userData">
                <span class="lang-btn" :class="{ selectedlanguage: userData.language === 'english' }" @click="changeLanguage('eng')">EN</span> <span class='lang-separator'>|</span> <span class="lang-btn" :class="{ selectedlanguage: userData.language === 'romana' }" @click="changeLanguage('rom')">RO</span>
              </div>
              <div
              class="logout-firma"
              @click="logout()">
              Logout
            </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    </div>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    <div class="footer" v-if="isAuth">
      <div class="termeni" v-if="userData && userData.language === 'romana'">Termeni si Conditii</div>
      <div class="termeni" v-if="userData && userData.language === 'english'">Terms & Conditions</div>
      <div class="contact-footer">
        +40 756 710 477
      </div>
      <div class="copyright">&#169; Copyright 2024 Vissia Software S.R.L.</div>
      
    </div>
  </div>
</template>

<script>
import { supabase } from "./clients/supabase";
import { onMounted, ref, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "App",
  components: {},
  data() {
    return {
      error: false,
      copied: false,
      copiedError: false,
      menuhide: true,
    };
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const route = useRoute()

    onMounted(() => {
      getUser();
      
      window.addEventListener("scroll", updateScroll);
    });

    const firmaId = ref();
    const scrollPosition = ref();


   

    function updateScroll() {
      scrollPosition.value = window.scrollY;
    }

    const userData = computed(() => {
      return store.getters["user/getUserData"];
    });

    const getUser = async () => {

      if(route.path === '/demologin') {
        router.push('/demologin')
        return
      }
      if(route.path === '/firma') {
        router.push('/firma')
        return
      }
      if(route.path === "/signup") {

      router.push(`/signup?hash=${route.query.hash}`)
      return
      }

    
      
      if (!localStorage.getItem("token")) {
        store.dispatch("user/signIn", "logout")
        if(route.path === "/signup") {

          router.push(`/signup?hash=${route.query.hash}`)
          return
        } if(route.path === '/firma') {
        router.push('/firma')
        return
      } if(route.path === '/demologin') {
        router.push('/demologin')
        return
      }
       else {
          router.push("/login");
        }
       
      }
      if (localStorage.getItem("token")) {
        const {
          data: { user },
        } = await supabase.auth.getUser(localStorage.getItem("token"));

        if (user) {
          await store.dispatch("user/setUserId", user.id);
          await store.dispatch("user/setUserEmail", user.email);
          await store.dispatch("user/setAuthUser");

          if (user.id) {
           
            await checkRole(user.id);
            
            
            router.push('/')
            
          }
        }
        if (!user) {
          store.dispatch("user/signIn", "logout")
          if(route.path === "/signup") {
            router.push(`/signup?hash=${route.query.hash}`)
            return
            } if(route.path === '/firma') {
        router.push('/firma')
        return
      } if(route.path === '/demologin') {
        router.push('/demologin')
        return
      } else {
              router.push("/login");
            }
          
        }
      }
    };

    

    const checkRole = async (id) => {
      const { data, error } = await supabase
        .from("roles")
        .select()
        .eq("user_id", id);

      if (error) {
        console.log(error);
      } else {
        

        if (data.length != 0) {
          if (data[0].firma_id != null) {
            store.dispatch("user/setFirmaId", data[0].firma_id);
            firmaId.value = data[0].firma_id;
            getFirmaName();
          }
          store.dispatch("user/setRole", data[0].role);
          store.dispatch("user/setUserData", data[0]);
        } else {
          if (data[0]) {
            store.dispatch("user/setRole", "agent");
            store.dispatch("user/setInvitedBy", data[0].invited_by);
           
          }
        }
      }
    };

    const changeLanguage = async (lang) => {
      if(lang === 'rom') {
        if(userData.value.language === 'romana') {
          return
        } else {
        const { data: data1, error } = await supabase.from('roles').update({language: 'romana'}).eq('user_id', userData.value.user_id).select()
        store.dispatch("user/setUserData", data1[0]);
        if (error) throw error
      }
      }
      if(lang === 'eng') {
        if(userData.value.language === 'english') {
          return
        } else {

        
        const { data: data2, error } = await supabase.from('roles').update({language: 'english'}).eq('user_id', userData.value.user_id).select()
        store.dispatch("user/setUserData", data2[0]);
        if (error) throw error
      }
      }
     
    }

    const changeDashboard = async (lang) => {
      if(lang === 'admin') {
        if(userData.value && !userData.value.super_admin) {
          return
        }
        if(userData.value && userData.value.super_admin) {
          const { data: data3, error } = await supabase.from('roles').update({role: 'admin'}).eq('user_id', userData.value.user_id).select()
        store.dispatch("user/setUserData", data3[0]);
        location.reload()
        if (error) throw error
        }
      }
      if(userData.value && !userData.value.double_role) {
        return
      }
      if(lang === 'firma') {
        if(userData.value.role === 'firma') {
          return
        } else {
        const { data: data1, error } = await supabase.from('roles').update({role: 'firma'}).eq('user_id', userData.value.user_id).select()
        store.dispatch("user/setUserData", data1[0]);
        location.reload()
        if (error) throw error
      }
      }
      if(lang === 'agent') {
        if(userData.value.role === 'agent') {
          return
        } else {

        
        const { data: data2, error } = await supabase.from('roles').update({role: 'agent'}).eq('user_id', userData.value.user_id).select()
        store.dispatch("user/setUserData", data2[0]);
        location.reload()
        if (error) throw error
      }
      }
     
    }

    const getFirmaName = async () => {
      const { data, error } = await supabase
        .from("firma")
        .select()
        .eq("id", firmaId.value);

      if (error) {
        console.log(error);
      } else {
        

        store.dispatch("user/setFirmaName", data[0].nume);
      }
    };
    return {
      scrollPosition,
      userData,
      changeLanguage,
      changeDashboard
    };
  },

  computed: {
    isAuth() {
      return this.$store.getters["user/isAuth"];
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"];
    },
    toaster() {
      return this.$store.getters["user/getToaster"];
    },
    toasterError() {
      return this.$store.getters["user/getToasterError"];
    },
    userRole() {
      return this.$store.getters["user/user_role"];
    },
    userEmail() {
      return this.$store.getters["user/getEmail"];
    },
  },
  watch: {
    toaster: function () {
      const vm = this;
      if (vm.toaster != null) {
        vm.copied = true;
        return;
      } else if (vm.toaster === null) {
        vm.copied = false;
        return;
      }
    },
    toasterError: function () {
      const vm = this;
      if (vm.toasterError != null) {
        vm.copiedError = true;
        return;
      } else if (vm.toasterError === null) {
        vm.copiedError = false;
        return;
      }
    },
  },
  methods: {
    async getUser() {
      const {
        data: { user },
      } = await supabase.auth.getUser(localStorage.getItem("token"));

      console.log(user);
    },
    async logout() {
      const { error } = await supabase.auth.signOut();

      if (error) {
        console.log(error);
      } else {
        console.log("logged out");
        this.$store.dispatch("user/signIn", "logout");
      }
    },
    toggleMidMenu($event) {
      if ($event.target.classList.contains("active-menu")) {
        $event.target.classList.remove("active-menu");
        this.closeMenu();
      } else if (!$event.target.classList.contains("active-menu")) {
        $event.target.classList.add("active-menu");
        this.openMenu();
      }
    },
    openMenu() {
      console.log(this.$refs);
      this.$refs.blocker.classList.remove("dispnone");
      this.$refs.menuOpened.style.height = "14rem";
      setTimeout(() => {
        this.$refs.menuOpened.style.background =
          "linear-gradient(to top, #111, #000000)";
      }, 250);
      setTimeout(() => {
        this.$refs.menuContent.classList.remove("dispnone");
      }, 300);
      setTimeout(() => {
        this.$refs.blocker.classList.add("dispnone");
      }, 400);
    },
    closeMenu() {
      this.$refs.blocker.classList.remove("dispnone");
      this.$refs.menuContent.classList.add("dispnone");
      console.log(this.$refs.burger2.classList);
      this.$refs.burger.classList.remove("active-menu");
      this.$refs.burger2.classList.remove("active-menu");
      this.$refs.menuOpened.style.height = "0rem";
      this.$refs.menuOpened.style.background = "#53bd8d";
      setTimeout(() => {
        this.$refs.menuOpened.style.height = "0rem";
      }, 200);
      setTimeout(() => {
        this.$refs.blocker.classList.add("dispnone");
      }, 300);
    },
    toggleMenu() {
      if (this.$refs.myNav.style.width !== "100%") {
        this.$refs.navLinks.style.display = "flex";
        this.$refs.myNav.style.width = "100%";
        this.$refs.navLinks.style.transition = "all 2s";
        this.$refs.myLogged.style.transition = "all 1s";

        setTimeout(() => {
          this.$refs.navLinks.style.opacity = "1";
          this.$refs.myLogged.style.opacity = "1";
          this.$refs.menuBtn.style.top = "3%";
        }, 500);
      } else if (this.$refs.myNav.style.width === "100%") {
        this.$refs.navLinks.style.display = "none";
        this.$refs.myNav.style.width = "4rem";
        this.$refs.navLinks.style.transition = "all 0s";
        this.$refs.myLogged.style.transition = "all 0s";

        setTimeout(() => {
          this.$refs.navLinks.style.opacity = "0";
          this.$refs.myLogged.style.opacity = "0";
          this.$refs.menuBtn.style.top = "50%";
        }, 100);
      }
    },
    closeNav() {
      /* Close when someone clicks on the "x" symbol inside the overlay */

      this.$refs.myNav.style.width = "4rem";
      setTimeout(() => {
        this.$refs.navLinks.style.opacity = "0";
        this.$refs.myLogged.style.opacity = "0";
      }, 100);
    },
    openNav() {
      /* Open when someone clicks on the span element */
      this.$refs.myNav.style.width = "100%";
      setTimeout(() => {
        this.$refs.navLinks.style.opacity = "1";
        this.$refs.myLogged.style.opacity = "1";
      }, 200);
    },
  },
};
</script>

<style>
@import "./css/style2.css";

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
